import VersionHistoryViewMore from '../components/VersionHistoryViewMore'
import { alertError, alertSuccess } from '../components/Alerts'
import PostSigninNavbar from '../components/PostSigninNavbar'
import { useLocation, useNavigate } from 'react-router-dom'
import CreateContract from '../components/CreateContract'
import RenameContract from '../components/RenameContract'
import DeleteContract from '../components/DeleteContract'
import VersionHistory from '../components/VersionHistory'
import { useDispatch, useSelector } from 'react-redux';
import FolderModal from '../components/FolderModal'
import NewFolder from '../components/NewFolder'
import ListViwer from '../components/ListViwer'
import Sidebar from '../components/Sidebar'
import React, { useEffect } from 'react'
import instance from '../auth/Axios'

const Dashboard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    let threeDotRef = React.useRef(null)

    const [data, setData] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [sidebar, setSidebar] = React.useState(false);
    const [folderData, setFolderData] = React.useState([]);
    const [deleteButton, setDeleteButton] = React.useState(false)
    const [newFolderModal, setNewFolderModal] = React.useState(false)
    const [selectedDropdown, setSelectedDropdown] = React.useState(null)
    const [questionnaireModal, setQuestionnaireModal] = React.useState(false)
    const [versionHistoryVisibility, setVersionHistoryVisibility] = React.useState(false)
    const [versionHistoryViewMoreVisibility, setVersionHistoryViewMoreVisibility] = React.useState(false)
    const [renameContractModal, setRenameContractModal] = React.useState({
        id: null,
        name: null,
        status: false,
    })
    const [deleteContractId, setDeleteContractId] = React.useState({
        id: null,
    })
    const [remainingContract, setRemainingContract] = React.useState(null)
    const [folderModalStatus, setFolderModaStatus] = React.useState({
        id: null,
        status: false,
    })
    const successId = new URLSearchParams(location.search).get('success');
    const failedId = new URLSearchParams(location.search).get('failed');
    const [searchData, setSearchData] = React.useState([])
    const [searchFolderData, setSearchFolderData] = React.useState([])
    const [searchInput, setSearchInput] = React.useState('')
    const [sort, setSort] = React.useState(null)

    const versionHistoryData = useSelector((state) => state.contract.versionHistoryData);
    const [contractTemplateName, setContractTemplateName] = React.useState(null)
    const [listViwerData, setListViwerData] = React.useState({
        status: false,
        data: []
    })
    const [instructionsModalVisibility, setInstructionsModalVisibility] = React.useState(false)

    React.useEffect(() => {
        async function saveData() {
            if (successId !== null && successId !== undefined) {
                try {
                    const res = await instance.post(`payment/save-Retrieve-session`, { sessionId: successId })
                    if (res.data.status) {
                        if (res.data.isFreeTrial) {
                            alertSuccess("Free Trial Activated!")
                        } else {
                            alertSuccess("Payment Successfull!")
                        }
                        navigate('/dashboard')
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        }
        saveData()
    }, [successId])

    React.useEffect(() => {
        if (failedId) {
            alertError("Payment Failed")
        }
    }, [failedId])

    function timeSince(date) {

        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;
        interval = seconds / 86400;

        if (interval > 3) {
            const formatter = new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
            return formatter.format(date)
        }
        if (Math.floor(interval) === 1) {
            return Math.floor(interval) + " day ago";
        }

        if (interval > 1) {
            return Math.floor(interval) + " days ago";
        }

        interval = seconds / 3600;

        if (Math.floor(interval) === 1) {
            return Math.floor(interval) + " hour ago";
        }

        if (interval > 1) {
            return Math.floor(interval) + " hours ago";
        }

        interval = seconds / 60;

        if (Math.floor(interval) === 1) {
            return Math.floor(interval) + " minute ago";
        }

        if (interval > 1) {
            return Math.floor(interval) + " minutes ago";
        }
        return Math.floor(seconds) < 0 ? "0 seconds ago" : "<1 minute ago";
    }

    const fetchData = async () => {
        const contractsResponse = await instance.get('/contract', {
            params: {
                id: "null"
            }
        })
        const contracts = contractsResponse.data.contracts.map(contract => {
            return {
                Id: contract.id,
                UUID: contract.uuid,
                Name: contract.name,
                Date: timeSince(new Date(contract.updatedAt)),
                Proposer: { Name: contract.drafter.firstName + " " + contract.drafter.lastName + (contract.drafter.association.length > 0 ? (" (" + contract.drafter.association + ")") : ""), email: contract.drafter.email, Pending: false },
                Responder: { Name: contract.counterParty ? contract.counterParty.firstName + " " + contract.counterParty.lastName + (contract.counterParty.association.length > 0 ? (" (" + contract.counterParty.association + ")") : "") : "", email: contract.counterParty?.email, Pending: false },
                Phase: contract.phase,
                currentOwnerTeam: contract.currentOwnerTeam,
                updatedAt: contract.updatedAt,
            }
        })
        setData(contracts)
        setSort('descending')
        setSearchData(sortData("updatedAt", contracts, 'descending'))

        if (contracts.length <= 2) {
            setInstructionsModalVisibility(true)
        }
        const folders = await instance.get('/folder')
        setFolderData(folders.data)
        setLoading(false)
    }

    React.useEffect(() => {
        for (let i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i).includes('staging')) {
                localStorage.removeItem(localStorage.key(i))
            }
        }
        if (!localStorage.getItem('accessToken')) {
            navigate('/signin')
            return
        }
        fetchData()
    }, [])

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (threeDotRef.current && !threeDotRef.current.contains(event.target) && !threeDotRef.current.parentElement.contains(event.target)) {
                threeDotRef.current.style.display = "none"
            }
        }
        if (threeDotRef.current) {
            threeDotRef.current.style.display = "none"
        }
        threeDotRef.current = document.getElementById(selectedDropdown)
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [selectedDropdown])

    function searchObjects(arr, term) {
        return arr.filter(obj => {
            const nameMatch = obj.Name.toLowerCase().includes(term.toLowerCase());
            const proposerMatch = obj.Proposer.Name.toLowerCase().includes(term.toLowerCase());
            const responderMatch = obj.Responder.Name.toLowerCase().includes(term.toLowerCase());
            return nameMatch || proposerMatch || responderMatch;
        });
    }

    function folderSearchObjects(arr, term) {
        return arr.filter(obj => {
            const nameMatch = obj.name.toLowerCase().includes(term.toLowerCase());
            return nameMatch;
        });
    }

    const handleSelectVersionHistory = (id, uuid) => {
        async function getData() {
            const contract = await instance.get(`/contract/id?uuid=${uuid}`)
            const response = await instance.post("/version/getVersionHistory", { contractId: id })
            dispatch({ type: 'ADD_VERSION_HISTORY_MODAL', payload: response.data })
            setContractTemplateName(contract.data.template.contractTemplateName)
            setVersionHistoryVisibility(true)
        }
        getData()
    }

    useEffect(() => {
        if (searchInput.length === 0) {
            setSearchData(data);
            setSearchFolderData(folderData);
            return;
        }
        if (data.length > 0 && searchInput.length > 0) {
            setSearchData(searchObjects(data, searchInput));
            setSearchFolderData(folderSearchObjects(folderData, searchInput));
        }
    }, [data, searchInput, folderData])

    const handleMoveSuccessful = () => {
        fetchData()
    }

    function sortData(ele, data, sortOrder) {

        if (ele === 'Name' || ele === 'Phase') {
            return data.sort((a, b) => {
                const nameA = a[ele].toLowerCase();
                const nameB = b[ele].toLowerCase();

                if (sortOrder === 'descending') {
                    if (nameA > nameB) return -1;
                    if (nameA < nameB) return 1;
                } else {
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                }
                return 0;
            });
        }
        if (ele === 'updatedAt') {
            return data.sort((a, b) => {
                const nameA = new Date(a[ele]);
                const nameB = new Date(b[ele]);

                if (sortOrder === 'descending') {
                    if (nameA > nameB) return -1;
                    if (nameA < nameB) return 1;
                } else {
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                }
                return 0;
            });
        }
        return data.sort((a, b) => {
            const nameA = a[ele].Name.toLowerCase();
            const nameB = b[ele].Name.toLowerCase();

            if (sortOrder === 'descending') {
                if (nameA > nameB) return -1;
                if (nameA < nameB) return 1;
            } else {
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
            }
            return 0;
        });
    }

    const handleSort = async (ele) => {
        if (sort === 'descending') {
            setSort('ascending')
            setSearchData(sortData(ele, searchData, 'ascending'))
        } else {
            setSort('descending')
            setSearchData(sortData(ele, searchData, 'descending'))
        }
    }

    const handleSelectListViwers = (UUID) => {
        async function getData() {
            const response = await instance.get(`/contract/id?uuid=${UUID}`)
            if (response) {
                setListViwerData({
                    status: true,
                    data: response.data.contract.collaborators
                })
            }
        }
        getData()
    }

    return (
        <div>
            <div className='flex'>
                <Sidebar value={sidebar} setSidebar={setSidebar} instructionsModalVisibility={instructionsModalVisibility} instructionsModalState={0} />
                <div className='w-full'>
                    <PostSigninNavbar value={sidebar} onChange={setSidebar} />
                    <div className='bg-[#FFFFFF]'>
                        <div className="p-1 px-3 pt-6 flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                            <div className="text-[#11192] mr-4 text-2xl font-bold">Dashboard</div>
                            <div className='flex items-center'>
                                <input
                                    className="w-[40vw] h-[35px] py-[20px] pl-[15px] text-base placeholder-[#6B7280] border border-[#E5E7EB] rounded-2xl"
                                    type="search"
                                    placeholder="Search for all contracts"
                                    onChange={(e) => setSearchInput(e.target.value)}
                                />
                                <svg className='ml-2' width="2" height="20" viewBox="0 0 2 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.584717 0L0.584717 20" stroke="#E5E7EB" />
                                </svg>
                                {deleteButton ?
                                    <svg className='p-2' width="33" height="33" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.38469 0.399902C7.1619 0.400021 6.94354 0.462162 6.75406 0.579365C6.56459 0.696568 6.41148 0.864205 6.31189 1.0635L5.44309 2.7999H1.38469C1.06643 2.7999 0.761208 2.92633 0.536164 3.15137C0.311121 3.37642 0.184692 3.68164 0.184692 3.9999C0.184692 4.31816 0.311121 4.62339 0.536164 4.84843C0.761208 5.07347 1.06643 5.1999 1.38469 5.1999V17.1999C1.38469 17.8364 1.63755 18.4469 2.08764 18.897C2.53772 19.347 3.14817 19.5999 3.78469 19.5999H13.3847C14.0212 19.5999 14.6317 19.347 15.0817 18.897C15.5318 18.4469 15.7847 17.8364 15.7847 17.1999V5.1999C16.103 5.1999 16.4082 5.07347 16.6332 4.84843C16.8583 4.62339 16.9847 4.31816 16.9847 3.9999C16.9847 3.68164 16.8583 3.37642 16.6332 3.15137C16.4082 2.92633 16.103 2.7999 15.7847 2.7999H11.7263L10.8575 1.0635C10.7579 0.864205 10.6048 0.696568 10.4153 0.579365C10.2258 0.462162 10.0075 0.400021 9.78469 0.399902H7.38469ZM4.98469 7.5999C4.98469 7.28164 5.11112 6.97642 5.33616 6.75137C5.56121 6.52633 5.86643 6.3999 6.18469 6.3999C6.50295 6.3999 6.80818 6.52633 7.03322 6.75137C7.25826 6.97642 7.38469 7.28164 7.38469 7.5999V14.7999C7.38469 15.1182 7.25826 15.4234 7.03322 15.6484C6.80818 15.8735 6.50295 15.9999 6.18469 15.9999C5.86643 15.9999 5.56121 15.8735 5.33616 15.6484C5.11112 15.4234 4.98469 15.1182 4.98469 14.7999V7.5999ZM10.9847 6.3999C10.6664 6.3999 10.3612 6.52633 10.1362 6.75137C9.91112 6.97642 9.78469 7.28164 9.78469 7.5999V14.7999C9.78469 15.1182 9.91112 15.4234 10.1362 15.6484C10.3612 15.8735 10.6664 15.9999 10.9847 15.9999C11.303 15.9999 11.6082 15.8735 11.8332 15.6484C12.0583 15.4234 12.1847 15.1182 12.1847 14.7999V7.5999C12.1847 7.28164 12.0583 6.97642 11.8332 6.75137C11.6082 6.52633 11.303 6.3999 10.9847 6.3999Z" fill="#111928" />
                                    </svg> : null}
                            </div>
                            <div className='flex ml-auto bg-white'>
                                <button className='flex items-center mr-6 bg-white' onClick={
                                    () => {
                                        setNewFolderModal(true)
                                    }
                                }>
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5 0C5.26522 0 5.51957 0.105357 5.70711 0.292893C5.89464 0.48043 6 0.734784 6 1V4H9C9.26522 4 9.51957 4.10536 9.70711 4.29289C9.89464 4.48043 10 4.73478 10 5C10 5.26522 9.89464 5.51957 9.70711 5.70711C9.51957 5.89464 9.26522 6 9 6H6V9C6 9.26522 5.89464 9.51957 5.70711 9.70711C5.51957 9.89464 5.26522 10 5 10C4.73478 10 4.48043 9.89464 4.29289 9.70711C4.10536 9.51957 4 9.26522 4 9V6H1C0.734784 6 0.48043 5.89464 0.292893 5.70711C0.105357 5.51957 0 5.26522 0 5C0 4.73478 0.105357 4.48043 0.292893 4.29289C0.48043 4.10536 0.734784 4 1 4H4V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0Z" fill="#1C64F2" />
                                    </svg>
                                    <span className='ml-2 text-sm font-semibold text-[#1C64F2] hover:text-blue-700'>New Folder</span>
                                </button>
                                <button className='flex items-center bg-[#1C64F2] rounded-xl py-2 px-3 hover:bg-blue-700' onClick={
                                    () => { setQuestionnaireModal(true) }
                                }>
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5 0C5.26522 0 5.51957 0.105357 5.70711 0.292893C5.89464 0.48043 6 0.734784 6 1V4H9C9.26522 4 9.51957 4.10536 9.70711 4.29289C9.89464 4.48043 10 4.73478 10 5C10 5.26522 9.89464 5.51957 9.70711 5.70711C9.51957 5.89464 9.26522 6 9 6H6V9C6 9.26522 5.89464 9.51957 5.70711 9.70711C5.51957 9.89464 5.26522 10 5 10C4.73478 10 4.48043 9.89464 4.29289 9.70711C4.10536 9.51957 4 9.26522 4 9V6H1C0.734784 6 0.48043 5.89464 0.292893 5.70711C0.105357 5.51957 0 5.26522 0 5C0 4.73478 0.105357 4.48043 0.292893 4.29289C0.48043 4.10536 0.734784 4 1 4H4V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0Z" fill="white" />
                                    </svg>
                                    <span className='ml-2 text-sm font-semibold text-[#FFFFFF]'>Create Contract</span>
                                </button>
                            </div>
                            <div></div>
                        </div>
                        {/* <div to="/dashboard" className='flex mx-3'>
                            <Link className='flex items-center'>
                                <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.67674 11.9266L5.03029 12.2802L4.67674 11.9266C4.62985 11.9735 4.56627 11.9998 4.49996 11.9998C4.43366 11.9998 4.37007 11.9735 4.32319 11.9266C4.2763 11.8797 4.24996 11.8161 4.24996 11.7498V3.06034V1.85323L3.39641 2.70679L1.67671 4.42648C1.67669 4.4265 1.67668 4.42652 1.67666 4.42654C1.62978 4.47337 1.56623 4.49968 1.49996 4.49968C1.43372 4.49968 1.37019 4.47339 1.32332 4.42659C1.3233 4.42657 1.32328 4.42655 1.32327 4.42654C1.32325 4.42652 1.32323 4.4265 1.32321 4.42648C1.27641 4.37961 1.25012 4.31608 1.25012 4.24984C1.25012 4.18355 1.27645 4.11997 1.32332 4.07309L4.32321 1.0732C4.37009 1.02633 4.43367 1 4.49996 1C4.56625 1 4.62983 1.02633 4.67671 1.0732L7.67276 4.06924C7.71692 4.11614 7.7413 4.17831 7.74074 4.2428C7.74017 4.30835 7.71387 4.37105 7.66752 4.4174C7.62117 4.46375 7.55847 4.49004 7.49292 4.49061C7.42843 4.49117 7.36627 4.4668 7.31937 4.42264L5.60352 2.70679L4.74996 1.85323V3.06034V11.7498C4.74996 11.8161 4.72362 11.8797 4.67674 11.9266Z" fill="#4B5563" stroke="#4B5563" />
                                </svg>
                                <span className='ml-2 text-sm font-medium text-[#3F83F8] hover:text-blue-700'>Home</span>
                            </Link>
                        </div> */}
                    </div>
                    <table className='w-full mt-2'>
                        <thead className='bg-[#F3F4F6]'>
                            <tr>
                                {/* <th className='w-[10%] px-6 py-3 text-sm font-semibold tracking-wider text-left text-[#4B5563]'>
                                    <input type="checkbox" className='bg-[#F9FAFB] rounded-[4px] border-gray-300' disabled />
                                </th> */}
                                <th className=' w-[18%] px-6 py-3 '>
                                    <div className='flex gap-x-2 '>
                                        <p className='text-sm font-semibold tracking-wider text-left text-[#4B5563]'>Name</p>
                                        <svg onClick={() => { handleSort('Name') }} width="20px" height="20px" className='text-[#4B5563] cursor-pointer' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7 5C7.55228 5 8 5.44772 8 6V15.5858L10.2929 13.2929C10.6834 12.9024 11.3166 12.9024 11.7071 13.2929C12.0976 13.6834 12.0976 14.3166 11.7071 14.7071L7.70711 18.7071C7.31658 19.0976 6.68342 19.0976 6.29289 18.7071L2.29289 14.7071C1.90237 14.3166 1.90237 13.6834 2.29289 13.2929C2.68342 12.9024 3.31658 12.9024 3.70711 13.2929L6 15.5858V6C6 5.44772 6.44772 5 7 5ZM16.2929 5.29289C16.6834 4.90237 17.3166 4.90237 17.7071 5.29289L21.7071 9.29289C22.0976 9.68342 22.0976 10.3166 21.7071 10.7071C21.3166 11.0976 20.6834 11.0976 20.2929 10.7071L18 8.41421V18C18 18.5523 17.5523 19 17 19C16.4477 19 16 18.5523 16 18V8.41421L13.7071 10.7071C13.3166 11.0976 12.6834 11.0976 12.2929 10.7071C11.9024 10.3166 11.9024 9.68342 12.2929 9.29289L16.2929 5.29289Z" fill="#0F1729" />
                                        </svg>
                                    </div>
                                </th>
                                <th className=' w-[15%] px-6 py-3 '>
                                    <div className='flex gap-x-2 '>
                                        <p className='text-sm  w-fit font-semibold tracking-wider text-left text-[#4B5563]'>Last Update</p>
                                        <svg onClick={() => { handleSort('updatedAt') }} width="20px" height="20px" className='text-[#4B5563] cursor-pointer' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7 5C7.55228 5 8 5.44772 8 6V15.5858L10.2929 13.2929C10.6834 12.9024 11.3166 12.9024 11.7071 13.2929C12.0976 13.6834 12.0976 14.3166 11.7071 14.7071L7.70711 18.7071C7.31658 19.0976 6.68342 19.0976 6.29289 18.7071L2.29289 14.7071C1.90237 14.3166 1.90237 13.6834 2.29289 13.2929C2.68342 12.9024 3.31658 12.9024 3.70711 13.2929L6 15.5858V6C6 5.44772 6.44772 5 7 5ZM16.2929 5.29289C16.6834 4.90237 17.3166 4.90237 17.7071 5.29289L21.7071 9.29289C22.0976 9.68342 22.0976 10.3166 21.7071 10.7071C21.3166 11.0976 20.6834 11.0976 20.2929 10.7071L18 8.41421V18C18 18.5523 17.5523 19 17 19C16.4477 19 16 18.5523 16 18V8.41421L13.7071 10.7071C13.3166 11.0976 12.6834 11.0976 12.2929 10.7071C11.9024 10.3166 11.9024 9.68342 12.2929 9.29289L16.2929 5.29289Z" fill="#0F1729" />
                                        </svg>
                                    </div>
                                </th>
                                <th className=' w-[25%] px-6 py-3 '>
                                    <div className='flex gap-x-2 '>
                                        <p className='text-sm  w-fit font-semibold tracking-wider text-left text-[#4B5563]'>Proposer</p>
                                        <svg onClick={() => { handleSort('Proposer') }} width="20px" height="20px" className='text-[#4B5563] cursor-pointer' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7 5C7.55228 5 8 5.44772 8 6V15.5858L10.2929 13.2929C10.6834 12.9024 11.3166 12.9024 11.7071 13.2929C12.0976 13.6834 12.0976 14.3166 11.7071 14.7071L7.70711 18.7071C7.31658 19.0976 6.68342 19.0976 6.29289 18.7071L2.29289 14.7071C1.90237 14.3166 1.90237 13.6834 2.29289 13.2929C2.68342 12.9024 3.31658 12.9024 3.70711 13.2929L6 15.5858V6C6 5.44772 6.44772 5 7 5ZM16.2929 5.29289C16.6834 4.90237 17.3166 4.90237 17.7071 5.29289L21.7071 9.29289C22.0976 9.68342 22.0976 10.3166 21.7071 10.7071C21.3166 11.0976 20.6834 11.0976 20.2929 10.7071L18 8.41421V18C18 18.5523 17.5523 19 17 19C16.4477 19 16 18.5523 16 18V8.41421L13.7071 10.7071C13.3166 11.0976 12.6834 11.0976 12.2929 10.7071C11.9024 10.3166 11.9024 9.68342 12.2929 9.29289L16.2929 5.29289Z" fill="#0F1729" />
                                        </svg>
                                    </div>
                                </th>
                                <th className=' w-[25%] px-6 py-3 '>
                                    <div className='flex gap-x-2 '>
                                        <p className='text-sm font-semibold tracking-wider text-left text-[#4B5563]'>Responder</p>
                                        <svg onClick={() => { handleSort('Responder') }} width="20px" height="20px" className='text-[#4B5563] cursor-pointer' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7 5C7.55228 5 8 5.44772 8 6V15.5858L10.2929 13.2929C10.6834 12.9024 11.3166 12.9024 11.7071 13.2929C12.0976 13.6834 12.0976 14.3166 11.7071 14.7071L7.70711 18.7071C7.31658 19.0976 6.68342 19.0976 6.29289 18.7071L2.29289 14.7071C1.90237 14.3166 1.90237 13.6834 2.29289 13.2929C2.68342 12.9024 3.31658 12.9024 3.70711 13.2929L6 15.5858V6C6 5.44772 6.44772 5 7 5ZM16.2929 5.29289C16.6834 4.90237 17.3166 4.90237 17.7071 5.29289L21.7071 9.29289C22.0976 9.68342 22.0976 10.3166 21.7071 10.7071C21.3166 11.0976 20.6834 11.0976 20.2929 10.7071L18 8.41421V18C18 18.5523 17.5523 19 17 19C16.4477 19 16 18.5523 16 18V8.41421L13.7071 10.7071C13.3166 11.0976 12.6834 11.0976 12.2929 10.7071C11.9024 10.3166 11.9024 9.68342 12.2929 9.29289L16.2929 5.29289Z" fill="#0F1729" />
                                        </svg>
                                    </div>
                                </th>
                                <th className='w-[18%] px-6 py-3 '>
                                    <div className='flex gap-x-2 '>
                                        <p className='text-sm  w-fit font-semibold tracking-wider text-left text-[#4B5563]'>Phase</p>
                                        <svg onClick={() => { handleSort('Phase') }} width="20px" height="20px" className='text-[#4B5563] cursor-pointer' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7 5C7.55228 5 8 5.44772 8 6V15.5858L10.2929 13.2929C10.6834 12.9024 11.3166 12.9024 11.7071 13.2929C12.0976 13.6834 12.0976 14.3166 11.7071 14.7071L7.70711 18.7071C7.31658 19.0976 6.68342 19.0976 6.29289 18.7071L2.29289 14.7071C1.90237 14.3166 1.90237 13.6834 2.29289 13.2929C2.68342 12.9024 3.31658 12.9024 3.70711 13.2929L6 15.5858V6C6 5.44772 6.44772 5 7 5ZM16.2929 5.29289C16.6834 4.90237 17.3166 4.90237 17.7071 5.29289L21.7071 9.29289C22.0976 9.68342 22.0976 10.3166 21.7071 10.7071C21.3166 11.0976 20.6834 11.0976 20.2929 10.7071L18 8.41421V18C18 18.5523 17.5523 19 17 19C16.4477 19 16 18.5523 16 18V8.41421L13.7071 10.7071C13.3166 11.0976 12.6834 11.0976 12.2929 10.7071C11.9024 10.3166 11.9024 9.68342 12.2929 9.29289L16.2929 5.29289Z" fill="#0F1729" />
                                        </svg>
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchFolderData.map((item, index) => {
                                return (
                                    <>
                                        <tr key={index}
                                            onMouseEnter={() =>
                                                document.getElementById(item.name).style.backgroundColor = '#F3F4F6'}
                                            onMouseLeave={() => {
                                                if (!selected.includes(item.name)) {
                                                    document.getElementById(item.name).style.backgroundColor = '#F9FAFB'
                                                }
                                            }}
                                            onClick={() => {
                                                navigate(`/dashboard/${item.name}`, {
                                                    state: {
                                                        folderId: item.id
                                                    }
                                                })
                                            }}
                                            className='border-t border-[#00000010]  hover:cursor-pointer' id={item.name}>
                                            <td className='px-6 py-4 text-base font-semibold text-[#1F2A37] flex'>
                                                <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M22.7812 4.24219H12.2637L8.82715 0.955078C8.78342 0.914144 8.72591 0.891139 8.66602 0.890625H1.21875C0.700195 0.890625 0.28125 1.30957 0.28125 1.82812V19.1719C0.28125 19.6904 0.700195 20.1094 1.21875 20.1094H22.7812C23.2998 20.1094 23.7188 19.6904 23.7188 19.1719V5.17969C23.7188 4.66113 23.2998 4.24219 22.7812 4.24219Z" fill="#9CA3AF" />
                                                </svg>
                                                <span className='ml-2'>{item.name}</span>
                                            </td>
                                            <td className='px-6 py-4 text-sm font-normal text-[#4B5563]'>{timeSince(new Date(item.updatedAt))}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </>)
                            }
                            )}
                            {searchData.map((item, index) => {
                                return (
                                    <tr key={index} onMouseEnter={() =>
                                        document.getElementById(item.Name).style.backgroundColor = '#F3F4F6'}
                                        onMouseLeave={() => {
                                            if (!selected.includes(item.Name)) {
                                                document.getElementById(item.Name).style.backgroundColor = '#F9FAFB'
                                            }
                                        }}
                                        className='border-t border-[#00000010] hover:cursor-pointer' id={item.Name}>
                                        <td
                                            onClick={() => {
                                                navigate(`/${item.UUID}`)
                                            }}
                                            className='px-6 py-4 text-base font-semibold text-[#1F2A37]'>{item.Name}</td>
                                        <td
                                            onClick={() => {
                                                navigate(`/${item.UUID}`)
                                            }}
                                            className='px-6 py-4 text-sm font-normal text-[#4B5563]'>{item.Date}</td>
                                        <td
                                            onClick={() => {
                                                navigate(`/${item.UUID}`)
                                            }}
                                            className='px-6 py-4 w-[300px] text-base font-medium text-[#1F2A37]'>
                                            <div className='flex gap-x-3 items-center'>
                                                <p className='w-full'>{item.Proposer.Name}</p>
                                                {item.Proposer.Pending ?
                                                    <p className='bg-[#C3DDFD] rounded-[10px] px-2 py-0.5 ml-2 text-[#1C64F2] text-xs font-medium cursor-pointer'>Waiting</p> : null
                                                }
                                                {
                                                    item.Phase !== "Signing" && item.currentOwnerTeam === "proposer" && (
                                                        <div className='relative group hover:cursor-pointer'>
                                                            <div id={`Proposer-${item.UUID}`} className='absolute hidden top-[35px] left-[-5px] w-64 transition-all duration-200 ease-in-out z-10 '>
                                                                <p className=" rounded bg-white border w-full border-gray-300 shadow-lg p-2 text-xs text-Inter text-gray-600 ">
                                                                    If ownership needs to be changed, the current owner can assign ownership to someone new by pressing Finish & Review in the Contract Composer.
                                                                </p>
                                                            </div>
                                                            <p
                                                                onMouseEnter={() => {
                                                                    document.getElementById(`Proposer-${item.UUID}`).style.display = "block"
                                                                }}
                                                                onMouseLeave={() => {
                                                                    document.getElementById(`Proposer-${item.UUID}`).style.display = "none"
                                                                }}
                                                                className='bg-[#8d929cf5] py-1 px-3 text-white text-Inter rounded-full text-sm'>Owner</p>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                        </td>
                                        <td
                                            onClick={() => {
                                                navigate(`/${item.UUID}`)
                                            }}
                                            className='px-6 py-4 text-base font-medium  text-[#1F2A37]'>
                                            <div className='flex gap-x-3 items-center'>
                                                <p className='w-full'>{item.Responder.Name.trim().length ? item.Responder.Name : item.Responder.email}</p>
                                                {item.Responder.Pending ?
                                                    <p className='bg-[#C3DDFD] rounded-[10px] px-2 py-0.5 ml-2 text-[#1C64F2] text-xs font-medium cursor-pointer'>Waiting</p> : null
                                                }
                                                {
                                                    item.Phase !== "Signing" && item.currentOwnerTeam === "counterParty" && (
                                                        <div className='relative group hover:cursor-pointer'>
                                                            <div id={`Responder-${item.UUID}`} className='absolute hidden top-[35px] left-[-5px] w-64 transition-all duration-200 ease-in-out z-10'>
                                                                <p className=" rounded bg-white border w-full border-gray-300 shadow-lg p-2 text-xs text-Inter text-gray-600">
                                                                    If ownership needs to be changed, the current owner can assign ownership to someone new by pressing Finish & Review in the Contract Composer.
                                                                </p>
                                                            </div>
                                                            <p
                                                                onMouseEnter={() => {
                                                                    document.getElementById(`Responder-${item.UUID}`).style.display = "block"
                                                                }}
                                                                onMouseLeave={() => {
                                                                    document.getElementById(`Responder-${item.UUID}`).style.display = "none"
                                                                }}
                                                                className='bg-[#8d929cf5] py-1 px-3 text-white text-Inter rounded-full text-sm'>Owner</p>
                                                        </div>

                                                    )
                                                }
                                            </div>
                                        </td>
                                        {
                                            item.Phase === 'Negotiating' ?
                                                <td
                                                    onClick={() => {
                                                        navigate(`/${item.UUID}`)
                                                    }}
                                                    className='px-6 py-4 text-base font-semibold text-[#D03801]'>
                                                    <ul className='group relative w-fit' style={{ listStyleType: "disc" }}>
                                                        <li>{item.Phase}</li>
                                                        <span className="absolute top-[30px] left-[-45px] right-0 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100 w-[300px] z-50 font-[400]">
                                                            <p className='mb-1.5'><b>Drafting | Stage 1</b> – The Proposer prepares the initial draft of the contract by making any necessary configurations and then sends the draft to the counterparty (the Responder).</p>
                                                            <p className='mb-1.5'><b>Negotiating | Stage 2</b> – The Responder reviews Proposer’s initial draft (including the comparison of that draft against the baseline Standard Draft form) and makes any necessary configurations. The Proposer and Responder continue the negotiation process until all contract configurations are agreed upon.</p>
                                                            <p className='mb-1.5'><b>Signing | Stage 3</b> – The parties sign the contract using Standard Draft’s integration with DocuSign!</p>
                                                        </span>
                                                    </ul>
                                                </td>
                                                :
                                                item.Phase === 'Drafting' ?
                                                    <td
                                                        onClick={() => {
                                                            navigate(`/${item.UUID}`)
                                                        }}
                                                        className='px-6 py-4 text-base font-semibold text-[#0E9F6E]'>
                                                        <ul className='group relative w-fit' style={{ listStyleType: "disc" }}>
                                                            <li>{item.Phase}</li>
                                                            <span className="absolute top-[30px] left-[-45px] scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100 w-[300px] z-50 font-[400]">
                                                                <p className='mb-1.5'><b>Drafting | Stage 1</b> – The Proposer prepares the initial draft of the contract by making any necessary configurations and then sends the draft to the counterparty (the Responder).</p>
                                                                <p className='mb-1.5'><b>Negotiating | Stage 2</b> – The Responder reviews Proposer’s initial draft (including the comparison of that draft against the baseline Standard Draft form) and makes any necessary configurations. The Proposer and Responder continue the negotiation process until all contract configurations are agreed upon.</p>
                                                                <p className='mb-1.5'><b>Signing | Stage 3</b> – The parties sign the contract using Standard Draft’s integration with DocuSign!</p>
                                                            </span>
                                                        </ul>
                                                    </td>
                                                    :
                                                    <td
                                                        onClick={() => {
                                                            navigate(`/${item.UUID}`)
                                                        }}
                                                        className='px-6 py-4 text-base font-semibold text-[blue]'>
                                                        <ul className='group relative w-fit' style={{ listStyleType: "disc" }}>
                                                            <li>{item.Phase}</li>
                                                            <span className="absolute top-[30px] left-[-45px] scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100 w-[300px] z-50 font-[400]">
                                                                <p className='mb-1.5'><b>Drafting | Stage 1</b> – The Proposer prepares the initial draft of the contract by making any necessary configurations and then sends the draft to the counterparty (the Responder).</p>
                                                                <p className='mb-1.5'><b>Negotiating | Stage 2</b> – The Responder reviews Proposer’s initial draft (including the comparison of that draft against the baseline Standard Draft form) and makes any necessary configurations. The Proposer and Responder continue the negotiation process until all contract configurations are agreed upon.</p>
                                                                <p className='mb-1.5'><b>Signing | Stage 3</b> – The parties sign the contract using Standard Draft’s integration with DocuSign!</p>
                                                            </span>
                                                        </ul>
                                                    </td>
                                        }
                                        <td className='flex flex-row-reverse'>
                                            <svg onClick={() => {
                                                if (selectedDropdown === index) {
                                                    setSelectedDropdown(null)
                                                    document.getElementById(index).style.display = "none"
                                                }
                                                else {
                                                    setSelectedDropdown(index)
                                                    document.getElementById(index).style.display = "block"
                                                }
                                            }
                                            } className='hover:cursor-pointer mr-5 mt-5 w-10' width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2 3.5C1.60218 3.5 1.22064 3.34196 0.93934 3.06066C0.658036 2.77936 0.5 2.39782 0.5 2C0.5 1.60218 0.658036 1.22064 0.93934 0.93934C1.22064 0.658035 1.60218 0.5 2 0.5C2.39782 0.5 2.77936 0.658035 3.06066 0.93934C3.34196 1.22064 3.5 1.60218 3.5 2C3.5 2.39782 3.34196 2.77936 3.06066 3.06066C2.77936 3.34196 2.39782 3.5 2 3.5ZM2 9.5C1.60218 9.5 1.22065 9.34196 0.93934 9.06066C0.658036 8.77936 0.5 8.39782 0.5 8C0.5 7.60218 0.658035 7.22065 0.93934 6.93934C1.22064 6.65804 1.60218 6.5 2 6.5C2.39782 6.5 2.77936 6.65804 3.06066 6.93934C3.34196 7.22065 3.5 7.60218 3.5 8C3.5 8.39782 3.34196 8.77936 3.06066 9.06066C2.77936 9.34196 2.39782 9.5 2 9.5ZM2 15.5C1.60218 15.5 1.22065 15.342 0.93934 15.0607C0.658036 14.7794 0.5 14.3978 0.5 14C0.5 13.6022 0.658036 13.2206 0.93934 12.9393C1.22065 12.658 1.60218 12.5 2 12.5C2.39782 12.5 2.77936 12.658 3.06066 12.9393C3.34196 13.2206 3.5 13.6022 3.5 14C3.5 14.3978 3.34196 14.7794 3.06066 15.0607C2.77936 15.342 2.39782 15.5 2 15.5Z" fill="#4B5563" stroke="#4B5563" />
                                            </svg>
                                            <ul id={index} className="origin-top-right absolute right-4 mt-12 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden">
                                                <li onClick={() => {
                                                    handleSelectVersionHistory(item.Id, item.UUID);
                                                    setSelectedDropdown(null)
                                                    document.getElementById(selectedDropdown).style.display = "none"

                                                }}
                                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                                                    Version History
                                                </li>
                                                <li onClick={() => {
                                                    handleSelectListViwers(item.UUID);
                                                    document.getElementById(selectedDropdown).style.display = "none"
                                                    setSelectedDropdown(null)
                                                }} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                                                    See Viewers
                                                </li>
                                                <li onClick={() => {
                                                    setRenameContractModal({ name: item.Name, status: true, id: item.Id });
                                                    document.getElementById(selectedDropdown).style.display = "none"
                                                    setSelectedDropdown(null)
                                                }}
                                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">Rename</li>
                                                <li onClick={() => {
                                                    setDeleteContractId({ id: item.Id });
                                                    document.getElementById(selectedDropdown).style.display = "none"
                                                    setSelectedDropdown(null)
                                                }}
                                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">Delete</li>
                                                <li onClick={() => {
                                                    setFolderModaStatus({ status: true, id: item.Id });
                                                    document.getElementById(selectedDropdown).style.display = "none"
                                                    setSelectedDropdown(null)
                                                }}
                                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">Move</li>
                                            </ul>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    {
                        !loading && searchData.length === 0 && searchFolderData.length === 0 &&
                        <div className='flex justify-center items-center h-96'>
                            <div className='flex flex-col justify-center items-center'>
                                <p className='text-2xl font-semibold text-gray-400'>No Contracts</p>
                                <button onClick={() => setQuestionnaireModal(true)} className='text-blue-500 font-semibold'>Create Contract</button>
                            </div>
                        </div>
                    }
                </div>
                {
                    listViwerData.status && (
                        <ListViwer listViwerData={listViwerData} setListViwerData={setListViwerData} />
                    )
                }
                {versionHistoryVisibility &&
                    <VersionHistory
                        setVersionHistoryVisibility={setVersionHistoryVisibility}
                        contractTemplateName={contractTemplateName}
                        versionHistoryData={versionHistoryData}
                        sectionHeading={''}
                        isSectionHistory={false}
                        setVersionHistoryViewMoreVisibility={setVersionHistoryViewMoreVisibility}
                    />
                }

                {
                    versionHistoryViewMoreVisibility &&
                    <VersionHistoryViewMore
                        setVersionHistoryViewMoreVisibility={setVersionHistoryViewMoreVisibility}
                        data={versionHistoryData[0]}
                        setVersionHistoryVisibility={setVersionHistoryVisibility}
                    />

                }
                {questionnaireModal ? <CreateContract folderId={null} value={questionnaireModal} onChange={setQuestionnaireModal} remainingContract={remainingContract} /> : null}
                {newFolderModal ? <NewFolder value={newFolderModal} onChange={setNewFolderModal} /> : null}
                {renameContractModal.status ? <RenameContract id={renameContractModal} onChange={setRenameContractModal} /> : null}
                {deleteContractId.id ? <DeleteContract id={deleteContractId} onChange={setDeleteContractId} /> : null}
                {folderModalStatus.status && <FolderModal handleMoveSuccessful={handleMoveSuccessful} setFolderModaStatus={setFolderModaStatus} setNewFolderModal={setNewFolderModal} folderData={folderData} contract={folderModalStatus} />}
            </div >
        </div>
    )
}

export default Dashboard
