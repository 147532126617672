import CheckBox from './CheckBox'
import React from 'react'
import '../../index.css'

const CMultiSelect = ({ obj, dispatch_handler, disabled, collapsibleHandler }) => {
    const [arrow, setArrow] = React.useState(false);

    return (
        <div id={obj.id}>
            {
                <div className="flex flex-wrap items-center">
                    {
                        obj.label ?
                            <>
                                {
                                    obj.classes ?
                                        arrow ?
                                            <div onClick={(e) => {
                                                collapsibleHandler(e.currentTarget)
                                                setArrow(false)
                                            }} className={"mr-2 mt-2 hover:cursor-pointer " + obj.classes}>
                                                <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.5 1.125L6.5 6.125L11.5 1.125" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            :
                                            <div onClick={(e) => {
                                                collapsibleHandler(e.currentTarget)
                                                setArrow(true)
                                            }} className={"mr-2 mt-1 hover:cursor-pointer " + obj.classes}>
                                                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.625 11L6.625 6L1.625 0.999999" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        :
                                        null
                                }
                                <div
                                    className={`mt-2 text-xs font-normal text-[#6B7280] ${obj.classes && `cursor-pointer dropDownTitleText `}`}
                                    onClick={(e) => {
                                        collapsibleHandler(e.currentTarget, 1)
                                        setArrow(!arrow)
                                    }}>
                                    {obj.label}
                                </div>
                            </>
                            :
                            null
                    }
                    <div className={obj.label ? "basis-full subSectionAlignment" : "basis-full"}>
                        {obj.options.map((item) => {
                            return (
                                <CheckBox obj={item} dispatch_handler={dispatch_handler} disabled={disabled} collapsibleHandler={collapsibleHandler} />
                            )
                        })}
                    </div>
                </div>
            }
        </div>
    )
}

export default CMultiSelect
