import React, { useEffect, useState } from 'react'
import instance from '../auth/Axios';

const VersionHistory = (props) => {
    const { setVersionHistoryVisibility, versionHistoryData, sectionHeading = '', setVersionHistoryViewMoreVisibility, isSectionHistory, contractTemplateName } = props
    function countObjectsWithChanges(objects) {
        let count = 0;
        objects.forEach((obj) => {
            if (obj.Edited.length > 0 || obj.customText.added.length > 0 || obj.customText.removed.length || obj.customText.edited.length || obj.customSubSection.added || obj.customSubSection.removed || obj.customSubSection.edited) {
                count++;
            }
        });
        return count;
    }

    function countObjectsWithChangesForASection(objects, sectionName) {
        let count = 0;
        objects.forEach((obj) => {
            if (obj.sectionName === sectionName.replace(/§[^]*? /g, '')) {
                if (obj.Edited.length > 0) {
                    count++;
                }
            }
        });
        return count;
    }

    function handleViewMore(item) {
        setVersionHistoryVisibility(false)
        setVersionHistoryViewMoreVisibility({
            status: true,
            data: item
        })
    }

    function countCustomSubSections(arr) {
        let count = 0;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].customText.added.length > 0 || arr[i].customText.removed.length || arr[i].customText.edited.length || arr[i].customSubSection.added || arr[i].customSubSection.removed || arr[i].customSubSection.edited) {
                count++;
            }
        }
        return count;
    }

    const DateTimeoptions = {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };
    const formatter = new Intl.DateTimeFormat('en-US', DateTimeoptions);
    return (
        <>
            {/* Main modal */}
            <div
                id="defaultModal"
                tabIndex={-1}
                className="fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full scrollbar-none overflow-auto"
            >
                <div className="flex items-center justify-center w-full h-full">
                    {/* Modal content */}
                    <div className="relative bg-white  w-full max-w-md  rounded-[20px]">
                        {/* Modal header */}
                        <div className="flex justify-between items-center p-4 rounded-t border-b">
                            <h3 className="text-lg font-semibold text-[#111928]">
                                {isSectionHistory ? `Section History` : `Version History`}
                            </h3>
                            <button onClick={() => { setVersionHistoryVisibility(false) }}>
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M0.292787 0.793081C0.480314 0.60561 0.734622 0.500295 0.999786 0.500295C1.26495 0.500295 1.51926 0.60561 1.70679 0.793081L5.99979 5.08608L10.2928 0.793081C10.385 0.697571 10.4954 0.621389 10.6174 0.56898C10.7394 0.516571 10.8706 0.488985 11.0034 0.487831C11.1362 0.486677 11.2678 0.511978 11.3907 0.562259C11.5136 0.61254 11.6253 0.686793 11.7192 0.780686C11.8131 0.874579 11.8873 0.986231 11.9376 1.10913C11.9879 1.23202 12.0132 1.3637 12.012 1.49648C12.0109 1.62926 11.9833 1.76048 11.9309 1.88249C11.8785 2.00449 11.8023 2.11483 11.7068 2.20708L7.41379 6.50008L11.7068 10.7931C11.8889 10.9817 11.9897 11.2343 11.9875 11.4965C11.9852 11.7587 11.88 12.0095 11.6946 12.1949C11.5092 12.3803 11.2584 12.4855 10.9962 12.4878C10.734 12.49 10.4814 12.3892 10.2928 12.2071L5.99979 7.91408L1.70679 12.2071C1.51818 12.3892 1.26558 12.49 1.00339 12.4878C0.741188 12.4855 0.490376 12.3803 0.304968 12.1949C0.11956 12.0095 0.0143906 11.7587 0.0121121 11.4965C0.00983372 11.2343 0.110629 10.9817 0.292787 10.7931L4.58579 6.50008L0.292787 2.20708C0.105316 2.01955 0 1.76525 0 1.50008C0 1.23492 0.105316 0.980609 0.292787 0.793081Z" fill="#111928" />
                                </svg>
                            </button>
                        </div>
                        {/* Modal body */}
                        <div className=" py-3 px-8 h-[400px] overflow-auto scrollbar-none w-full ">

                            {
                                versionHistoryData.sort((a, b) => (a.updatedAt > b.updatedAt) ? -1 : 1).map((item, index) => (
                                    <div className="flex">
                                        <div className="flex flex-col items-center mr-4">
                                            <div className='bg-blue-500 text-white font-semibold w-8 h-10 pt-1 rounded-full text-center'>
                                                {/* <div className="flex items-center justify-center w-10 h-10">
                                                    <img src='./images/VersionHistory/Avatar Image 2.png' alt="Avatar Image 2" />
                                                </div> */}
                                                v{versionHistoryData.length - index}
                                            </div>
                                            <div className="w-px h-full bg-gray-300" />
                                        </div>
                                        <div className="pt-1 pb-8">
                                            <p className="text-base text-[#4B5563] mb-1"><span className='font-[600]'> </span> {index === 0 && (props.phase === "Signing" || props.phase === "Signed") ? "Sent for signing by" : "Proposed by"} <span className='font-[600]'>{item.owner} </span> </p>
                                            {/* {versionHistoryData.length % 2 === 0 ? index % 2 === 0 && `(Counterparty)` : index % 2 !== 0 && `(Counterparty)`} */}
                                            {
                                                sectionHeading.length > 0 ?
                                                    //For section History 
                                                    countObjectsWithChangesForASection(item.difference, sectionHeading) > 0 || countCustomSubSections(item.difference) > 0 ? (
                                                        <>
                                                            <ul className='px-5' style={{ listStyleType: "disc" }}>

                                                                {
                                                                    countObjectsWithChangesForASection(item.difference, sectionHeading) > 0 && item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, ''))['Edited'].length > 0 && (
                                                                        <li className="text-sm text-[#4B5563]">
                                                                            <span>Edited:
                                                                                {
                                                                                    item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, ''))['Edited'].map((addedItem, index) => (
                                                                                        <span>{index !== 0 ? `, ` : ` `}{addedItem}</span>
                                                                                    ))
                                                                                }

                                                                            </span>
                                                                        </li>
                                                                    )
                                                                }
                                                                {
                                                                    //Custom Text
                                                                    item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, '')).customText.added.length > 0 && (
                                                                        <li className="text-sm text-[#4B5563]">
                                                                            <span>Proposed custom text in: {
                                                                                item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, '')).customText.added.map((ite, index) => {
                                                                                    return (index !== 0 ? `, ` : ` `) + ite
                                                                                })}
                                                                            </span>
                                                                        </li>
                                                                    )
                                                                }
                                                                {
                                                                    //Custom Text
                                                                    item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, '')).customText.removed.length > 0 && (
                                                                        <li className="text-sm text-[#4B5563]">
                                                                            <span>Rejected custom text in:  {
                                                                                item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, '')).customText.removed.map((ite, index) => {
                                                                                    return (index !== 0 ? `, ` : ` `) + ite
                                                                                })}
                                                                            </span>
                                                                        </li>
                                                                    )
                                                                }
                                                                {
                                                                    //Custom Text
                                                                    item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, '')).customText.edited.length > 0 && (
                                                                        <li className="text-sm text-[#4B5563]">
                                                                            <span>Edited custom text in: {
                                                                                item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, '')).customText.edited.map((ite, index) => {
                                                                                    return (index !== 0 ? `, ` : ` `) + ite
                                                                                })}
                                                                            </span>
                                                                        </li>
                                                                    )
                                                                }


                                                                {
                                                                    //Custom SubSection
                                                                    item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, '')).customSubSection.added && (
                                                                        <li className="text-sm text-[#4B5563]">
                                                                            <span>Proposed custom sub-section</span>
                                                                        </li>
                                                                    )
                                                                }
                                                                {
                                                                    //Custom SubSection
                                                                    item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, '')).customSubSection.edited && (
                                                                        <li className="text-sm text-[#4B5563]">
                                                                            <span>Edited custom sub-section</span>
                                                                        </li>
                                                                    )
                                                                }
                                                                {
                                                                    //Custom SubSection
                                                                    item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, '')).customSubSection.removed && (
                                                                        <li className="text-sm text-[#4B5563]">
                                                                            <span>Rejected custom sub-section</span>
                                                                        </li>
                                                                    )
                                                                }
                                                                {
                                                                    item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, '')).Edited.length <= 0 &&
                                                                    item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, '')).customText.added.length <= 0 &&
                                                                    item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, '')).customText.removed.length <= 0 &&
                                                                    item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, '')).customText.edited.length <= 0 &&
                                                                    item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, '')).customSubSection.added === false &&
                                                                    item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, '')).customSubSection.edited === false &&
                                                                    item.difference.find(section => section.sectionName === sectionHeading.replace(/§[^]*? /g, '')).customSubSection.removed === false && (
                                                                        <li className="text-sm text-[#4B5563]">
                                                                            <span>No changes</span>
                                                                        </li>
                                                                    )
                                                                }

                                                            </ul>
                                                            {/* <p onClick={() => { handleViewMore(item) }} className="text-sm text-[#3F83F8] cursor-pointer">view more</p> */}
                                                        </>
                                                    )
                                                        :
                                                        <li className="text-sm text-[#4B5563]"><span>No changes</span></li>

                                                    :

                                                    <>
                                                        <ul className='px-5' style={{ listStyleType: "disc" }}>
                                                            {countObjectsWithChanges(item.difference) > 0 && (
                                                                <li className="text-sm text-[#4B5563]">
                                                                    <span>Edited: {countObjectsWithChanges(item.difference)} sections</span>
                                                                </li>
                                                            )}
                                                            {countCustomSubSections(item.difference) > 0 && (
                                                                <li className="text-sm text-[#4B5563]">
                                                                    <span>Custom text edits in {countCustomSubSections(item.difference)} sections</span>
                                                                </li>
                                                            )}

                                                        </ul>
                                                        <p onClick={() => { handleViewMore(item) }} className="text-sm text-[#3F83F8] cursor-pointer">view more</p>
                                                    </>
                                            }

                                            {/* <li className="text-sm text-[#4B5563]">Removed Confidential Information</li> */}
                                            <p className="text-sm text-[#6B7280] mt-1">{formatter.format(new Date(item.updatedAt))}</p>
                                        </div>
                                    </div>
                                ))
                            }
                            <div className="flex">
                                <div className="flex flex-col items-center h-max py-0.5 -ml-1.5 px-0.5 border-[3.5px] border-blue-500 rounded-full">
                                    <div className='bg-blue-500 text-white font-semibold w-8 h-8 border-2  rounded-full text-center'>
                                        {/* <div className="flex items-center justify-center w-10 h-10">
                                            <img src='./images/VersionHistory/Avatar Image 2.png' alt="Avatar Image 2" />
                                        </div> */}

                                    </div>

                                </div>
                                <div className="pt-1 pb-8 ml-3">
                                    <p className="text-base mt-1 text-[#4B5563]"><span className='font-[600]'>{contractTemplateName.includes("Short-Form") ? "Standard Draft – NDA (Short-Form)" : "Standard Draft – NDA (Long-Form)"}</span></p>
                                </div>
                            </div>

                        </div>
                        <button onClick={() => { setVersionHistoryVisibility(false) }} className='block ml-auto mr-5 mb-5 w-[15vw] py-[9px] px-[17px] border border-[#D1D5DB] rounded-[12px] text-sm font-semibold text-[#111928]'>Close</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VersionHistory
