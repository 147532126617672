export function scrollToElement(elementId, heightAdject) {

  const div = document.getElementById("PreviewPane");
  if (!div) {
    return;
  }
  const element = document.getElementById(elementId);
  if (!element) {
    return;
  }
  const elementRect = element.getBoundingClientRect();
  const isVisible = (
    elementRect.top >= 0 &&
    elementRect.left >= 0 &&
    elementRect.bottom <= div.clientHeight &&
    elementRect.right <= div.clientWidth
  );
  if (!isVisible) {
    div.scrollTo({
      top: element.offsetTop - (heightAdject ? heightAdject : '300'),
      behavior: 'smooth',
    });
  }
}