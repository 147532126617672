import { triggerSubsectionClick } from "./utils/triggerSubsectionClick"
import { useSelector } from "react-redux";
import React, { useState } from 'react';
import { ObjtoHTML } from "../Composer";
import '../../index.css'

const CheckBox = ({ obj, dispatch_handler, disabled, collapsibleHandler }) => {
    const ans = useSelector((state) => state.contract.ans);
    const [arrow, setArrow] = useState(false);

    return (
        <div>
            <div className={"flex flex-wrap relative " + obj.classes}>
                {
                    obj.classes ?
                        arrow ?
                            <div onClick={(e) => {
                                setArrow(false)
                                collapsibleHandler(e.currentTarget.parentElement)
                            }} className={"mr-2 mt-2.5 hover:cursor-pointer"}>
                                <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.5 1.125L6.5 6.125L11.5 1.125" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            :
                            <div onClick={(e) => {
                                setArrow(true)
                                collapsibleHandler(e.currentTarget.parentElement)
                            }} className={"mr-3 mt-2.5 hover:cursor-pointer"} >
                                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.625 11L6.625 6L1.625 0.999999" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        :
                        null
                }
                {
                    !obj.actions || disabled ?
                        <div className="relative group hover:cursor-pointer">
                            <span className="absolute top-[-20px] w-40 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100">{
                                disabled ? "Selection unavailable" : "You can’t remove this option"
                            }</span>
                            <input type="checkbox"
                                disabled
                                id={obj.opt_id}
                                className="w-4 h-4 rounded focus:ring-[white]"
                            />
                            {" "}
                            <label className="text-xs text-[#6B7280] font-normal">{obj.label}</label>
                        </div>
                        :
                        <div>
                            <input type="checkbox"
                                id={obj.opt_id}
                                className="w-4 h-4 rounded focus:ring-[white]"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch_handler(obj.actions);
                                    dispatch_handler([{ type: 'CHANGE_TOGGLE' }])
                                    triggerSubsectionClick(obj.opt_id)
                                }}
                            />
                            {" "}
                            <label
                                className={`text-xs text-[#6B7280] font-normal ${obj.classes && `cursor-pointer`}`}
                                onClick={(e) => { collapsibleHandler(e.currentTarget.parentElement.parentElement); setArrow(!arrow) }}>
                                {obj.label}
                            </label>
                        </div>
                }
            </div>
            <div></div>
            <div style={{ flexBasis: "100%" }} id={obj.opt_id} className='subHeadingAlignment'>
                {obj.children && obj.children.map((item) => {
                    return (
                        <div>
                            {
                                item.hide_if_true ?
                                    <div className={
                                        ans[item.hide_if_true[0]] && ans[item.hide_if_true[0]].state === "true" ? "hidden" : "block"
                                    }>
                                        <ObjtoHTML obj={[item]} disabled={disabled ? disabled : document.getElementById(obj.opt_id).checked ? false : true} collapsibleHandler={collapsibleHandler} />
                                    </div>
                                    :
                                    <ObjtoHTML obj={[item]} disabled={disabled ? disabled : document.getElementById(obj.opt_id).checked ? false : true} collapsibleHandler={collapsibleHandler} />
                            }
                        </div>
                    )
                }
                )}
            </div>
        </div>
    )
}

export default CheckBox;