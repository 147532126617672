import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import instance from '../auth/Axios'
import ChatInput from './ChatInput'

const GPTMode = (props) => {
  const { isChatBotVisible = true, chatMessages, setChatMessages, contractId, contractStructuredOptions } = props

  const dispatch = useDispatch()

  const [height, setHeight] = useState(0)
  const [inputValue, setInputValue] = useState('')
  const [isBotResponding, setIsBotResponding] = useState(false)
  const [isStopResponding, setIsStopResponding] = useState(false)

  const findOptionById = (obj, id) => {
    if ((obj.id && obj.id == id) || (obj.opt_id && obj.opt_id == id)) {
      return obj
    }

    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        const found = findOptionById(obj[i], id)
        if (found) {
          return found
        }
      }
    }
    else if (typeof obj === "object" && obj !== null) {
      const keys = Object.keys(obj)
      for (let i = 0; i < keys.length; i++) {
        const found = findOptionById(obj[keys[i]], id)
        if (found) {
          return found
        }
      }
    }
    return null
  }

  const handleSendMessage = async () => {
    if (inputValue.length > 0 && !isBotResponding) {
      setIsBotResponding(true)
      setChatMessages((e) => [...e, { id: chatMessages.length + 1, message: inputValue, date: '22 May, 04:15 PM', isBot: false }])
      setInputValue('')

      const countWord = inputValue.split(' ').length
      if (countWord > 2000) {
        setChatMessages((e) => [...e, { id: chatMessages.length + 1, message: 'The input value was too long!', date: '22 May, 04:15 PM', isBot: true }])
        setIsBotResponding(false)
        setIsStopResponding(false)
        return
      }
      handleBotResponce(inputValue)
    }
    else if (isBotResponding) {
      return
    }
    else {
      setChatMessages((e) => [...e, { id: chatMessages.length + 1, message: 'Please enter a valid input value!', date: '22 May, 04:15 PM', isBot: true }])
    }
  }

  const handleBotResponce = async (data) => {
    const response = await instance.post('/conversation/chat', {
      contractId: contractId,
      message: data,
    })

    if (isStopResponding) {
      setIsBotResponding(false)
      setIsStopResponding(false)
      return
    }

    if (!isBotResponding) {
      const updatedChatMessages = [{ id: chatMessages.length + 1, message: response.data.messages[0], date: '22 May, 04:15 PM', isBot: true }]
      try {
        updatedChatMessages[0].message = JSON.parse(updatedChatMessages[0].message).commands_text
      } catch (error) {
        updatedChatMessages[0].message = response.data.messages[0]
      }

      if (response.data.messages[1]) {
        try {
          let changes = response.data.messages[1].replace(/```json/g, '').replace(/```/g, '')
          changes = JSON.parse(changes)
          if (!Array.isArray(changes)) {
            changes = [changes]
          }

          changes.forEach((change) => {
            if (change.set_selected || change.set_unselected) {
              change = change.set_selected ? change.set_selected : change.set_unselected
              change.forEach((item) => {
                const obj = findOptionById(contractStructuredOptions, item)
                if (obj) {
                  obj.actions.forEach((action) => {
                    dispatch(action)
                  })
                }
              })
            }
            if (change.set_value) {
              const obj = findOptionById(contractStructuredOptions, change.id)
              if (obj) {
                let t = {}
                t[obj.var_name] = {
                  "id": obj.id,
                  "value": change.set_value
                }
                dispatch({ "payload": t, "type": "ADD" });
              }
            }
          })
        } catch (error) {
          console.log(error)
          updatedChatMessages.push({ id: chatMessages.length + 1, message: (response.data.messages[1]), date: '22 May, 04:15 PM', isBot: true })
        }
      }
      console.log({
        "User Input": data,
        "Layer 1 Response": response.data.messages[0],
        "Layer 2 Response": response.data.messages[1],
      })

      setChatMessages((e) => [...e, ...updatedChatMessages])
      setIsBotResponding(false)
    } else {
      setIsBotResponding(false)
      setIsStopResponding(false)
      setChatMessages((e) => [...e, { id: chatMessages.length + 1, message: 'Something Went wrong', date: '22 May, 04:15 PM', isBot: true }])
    }
  }

  const handleCancelBotResponce = () => {
    setIsBotResponding(false)
    setIsStopResponding(true)
  }

  useEffect(() => {
    if (chatMessages.length === 0) {
      setTimeout(() => {
        setChatMessages([...chatMessages, { id: chatMessages.length + 1, message: 'Your Standard Draft NDA has been prepared! Please let me know if you have any questions or if you would like any modifications.', date: '22 May, 04:15 PM', isBot: true }])
      }, 1000)
    }
  }, [])

  useEffect(() => {
    setHeight(((window.innerHeight * 75) / 100) - 60)
  }, [window, isChatBotVisible])

  useEffect(() => {
    const chatbotContainer = document.getElementById('scrollable-content')
    chatbotContainer.scrollTop = chatbotContainer.scrollHeight
  }, [chatMessages])

  return (
    <div id={'chatbot-container'} className={`${isChatBotVisible ? ' visible' : 'hidden opacity-0'} h-full w-full transition-all duration-300 ease-in-out`}>
      <div className="absolute bottom-2 w-full px-2">
        <ChatInput
          inputValue={inputValue}
          setInputValue={setInputValue}
          isBotResponding={isBotResponding}
          handleSendMessage={handleSendMessage}
          setHeight={setHeight}
        />
      </div>

      <div style={{ height: `calc(${height - 20}px)`, overflowY: 'auto' }} className={'w-full flex flex-col justify-end  mt-5 px-3'} >
        <div id='scrollable-content' className="scrollable-content flex flex-col overflow-y-auto pb-10">
          {chatMessages.map((item, index) => (
            <div key={index} className={`relative w-fit mt-5 order-last ${!item.isBot ? 'self-end pl-10 pr-1' : 'pr-10 pl-1'}`}>
              {
                item.isBot && (
                  <div className="flex items-center gap-x-1">
                    <img src="/Images/Navbar/justLogo.png" className="w-4 h-4" />
                    <span className="font-semibold text-[13px] text-[#4B5563]">Standard Draft AI</span>
                  </div>
                )
              }
              <p
                className={`font-normal shadow-chat rounded-lg py-2.5 px-4 font-Inter text-[13px] ${!item.isBot ? 'text-white' : 'text-[#4B5563]'}`}
                style={{ background: !item.isBot ? 'linear-gradient(90deg, #2870EA 10.79%, #1B4AEF 87.08%)' : '#F3F4F6' }}>
                {item.message}
              </p>
              <span className={`absolute bottom-[-4px] ${!item.isBot ? "right-[1px]" : "left-[0px]"} text-[10px] text-[#828282]`}>
                {
                  !item.isBot ?
                    <svg aria-hidden="true" fill="#1B4AEF" height="18" viewBox="0 0 53 45" width="18" xmlns="http://www.w3.org/2000/svg">
                      <path class="transition-colors" d="M44 0H0V0C0 24.8528 20.1472 45 45 45H53V45C47.1396 36.535 44 26.4842 44 16.1886V0Z" fill="var(--ds-gray-1000)"></path>
                    </svg>
                    :
                    <svg aria-hidden="true" fill="#F3F4F6" height="18" viewBox="0 0 53 45" width="18" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.5 0.5H52.4972C52.2292 24.8463 32.4098 44.5 8 44.5H0.907203C6.52067 35.4543 9.5 25.0147 9.5 14.3567V0.5Z" fill="var(--ds-background-100)" stroke="var(--ds-gray-400)"></path>
                    </svg>
                }
              </span>
            </div>
          ))}

          {isBotResponding && (
            <div className="mx-auto mt-5 order-last" >
              <p
                onClick={() => handleCancelBotResponce()}
                className={'shadow-xs flex item-center gap-x-2 font-semibold shadow-[#174AE4] cursor-pointer w-fit hover:bg-[#EFF3FF] rounded-lg py-2 px-3 font-Inter text-[13px] text-[#174AE4] border-[2px] border-[#174AE4]'}>
                {/* <BsCircleFill className="text-[#174AE4] mt-1" /> */}
                Stop Responding
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default GPTMode