import { scrollToElement } from "./composer-components/utils/scrollToElement"
import { useSelector, useDispatch } from "react-redux";
import React from 'react'

const Signature = ({ data, setData }) => {
    const dispatch = useDispatch();
    let infoModalRef = React.useRef();
    let ans = useSelector((state) => state.contract.ans);

    const add_dispatch_handler = async (action) => {
        dispatch({
            type: "ADD", payload: {
                [action.var_name]: {
                    "id": ans[action.var_name] ? ans[action.var_name].id : null,
                    "value": action.value
                }
            }
        })
    }

    const [infoModalVisibility, setInfoModalVisibility] = React.useState(false);
    const [selectedDropdown, setSelectedDropdown] = React.useState(undefined)
    const [dateCheckbox, setDateCheckbox] = React.useState(false)

    React.useEffect(() => {
        const closeDropdown = (event) => {
            if (selectedDropdown !== undefined && document.getElementById(selectedDropdown).getElementsByTagName("ul")[0].classList.contains("block") && !document.getElementById(selectedDropdown).contains(event.target)) {
                document.getElementById(selectedDropdown).getElementsByTagName("ul")[0].classList.remove("block")
                setSelectedDropdown(undefined)
            }
        }
        document.removeEventListener('click', closeDropdown)
        document.addEventListener('click', closeDropdown);
        return () => { document.removeEventListener('click', closeDropdown) }
    }, [selectedDropdown]);

    React.useEffect(() => {
        let temp = [{}, {}]
        temp[0].partyName = ans["NAME1"] ? ans["NAME1"].value : ""
        temp[1].partyName = ans["NAME2"] ? ans["NAME2"].value : ""
        temp[0].partyType = ans["PARTY_TYPE_1"] ? ans["PARTY_TYPE_1"].value : "Entity"
        temp[1].partyType = ans["PARTY_TYPE_2"] ? ans["PARTY_TYPE_2"].value : "Entity"
        temp[0].signatoryName = ans["SIGNATORYNAME1"] ? ans["SIGNATORYNAME1"].value : ""
        temp[1].signatoryName = ans["SIGNATORYNAME2"] ? ans["SIGNATORYNAME2"].value : ""
        temp[0].signatoryTitle = ans["SIGNATORYTITLE1"] ? ans["SIGNATORYTITLE1"].value : ""
        temp[1].signatoryTitle = ans["SIGNATORYTITLE2"] ? ans["SIGNATORYTITLE2"].value : ""
        temp[0].contactInformation = ans["CONTACTINFORMATION1"] ? ans["CONTACTINFORMATION1"].value : ""
        temp[1].contactInformation = ans["CONTACTINFORMATION2"] ? ans["CONTACTINFORMATION2"].value : ""
        setData(temp)

        function handleClickOutside(event) {
            if (infoModalRef.current && !infoModalRef.current.contains(event.target)) {
                setInfoModalVisibility(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [])

    return (
        <>
            <div className='border border-[#E4E4E7] p-5 mb-5 rounded-[5px]'>
                <div className='text-lg font-semibold text-[#374151] pb-2'>Contract Effective Date</div>
                <input value={ans["DATE"].value} disabled={dateCheckbox}
                    onFocus={() => { setTimeout(() => scrollToElement("contract"), 1) }}
                    onChange={(e) => {
                        add_dispatch_handler({ var_name: "DATE", value: e.target.value })
                        setTimeout(() => scrollToElement("contract"), 1)
                        dispatch({ type: 'CHANGE_TOGGLE' })
                    }} className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border border-[#E5E7EB] rounded-[16px]' />
                <input type="checkbox" className="w-4 h-4 rounded focus:ring-[white]" onClick={() => {
                    setDateCheckbox(!dateCheckbox)
                    add_dispatch_handler({ var_name: "DATE", value: "as of the final signature date identified by DocuSign" })
                    setTimeout(() => scrollToElement("contract"), 1)
                    dispatch({ type: 'CHANGE_TOGGLE' })
                }} />
                {" "}
                <label className="text-xs text-[#6B7280] font-normal">Specify as the date of final DocuSign signature</label>
            </div>
            <div className="border border-[#E4E4E7] p-5 mb-5 rounded-[5px]">
                <div className='flex items-center text-lg font-semibold text-[#374151] pb-2'>
                    Signature Blocks
                    <div className='ml-auto mr-3 relative z-40'>
                        <svg onClick={() => setInfoModalVisibility(true)} className="ml-auto cursor-pointer" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.4685 13.8006C18.9019 12.7542 19.125 11.6327 19.125 10.5C19.125 8.21251 18.2163 6.01871 16.5988 4.4012C14.9813 2.7837 12.7875 1.875 10.5 1.875C8.21251 1.875 6.0187 2.7837 4.4012 4.4012C2.7837 6.01871 1.875 8.21251 1.875 10.5C1.875 11.6327 2.09809 12.7542 2.53154 13.8006C2.96499 14.8471 3.6003 15.7979 4.4012 16.5988C5.20211 17.3997 6.15292 18.035 7.19936 18.4685C8.24579 18.9019 9.36735 19.125 10.5 19.125C11.6327 19.125 12.7542 18.9019 13.8006 18.4685C14.8471 18.035 15.7979 17.3997 16.5988 16.5988C17.3997 15.7979 18.035 14.8471 18.4685 13.8006Z" fill="white" />
                            <path d="M11.4583 14.3333H10.5V10.5H9.54167M10.5 6.66667H10.5096M19.125 10.5C19.125 11.6327 18.9019 12.7542 18.4685 13.8006C18.035 14.8471 17.3997 15.7979 16.5988 16.5988C15.7979 17.3997 14.8471 18.035 13.8006 18.4685C12.7542 18.9019 11.6327 19.125 10.5 19.125C9.36735 19.125 8.24579 18.9019 7.19936 18.4685C6.15292 18.035 5.20211 17.3997 4.4012 16.5988C3.6003 15.7979 2.96499 14.8471 2.53154 13.8006C2.09809 12.7542 1.875 11.6327 1.875 10.5C1.875 8.21251 2.7837 6.01871 4.4012 4.4012C6.0187 2.7837 8.21251 1.875 10.5 1.875C12.7875 1.875 14.9813 2.7837 16.5988 4.4012C18.2163 6.01871 19.125 8.21251 19.125 10.5Z" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        {
                            infoModalVisibility &&
                            <div ref={infoModalRef} className="absolute right-[-25px] w-[28rem] bg-[white] h-52 top-0 z-50 border overflow-y-auto rounded-[5px] shadow-lg">
                                <div className="flex items-center justify-between px-4 py-2">
                                    <div className="text-sm font-medium text-[#374151]">Signature Blocks</div>
                                    <button onClick={() => setInfoModalVisibility(false)} className="focus:outline-none">
                                        <svg className="w-4 h-4 text-[#374151]" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L15 15M15 1L1 15" stroke="#374151" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="px-4 pb-2">
                                    <div className="text-xs font-medium text-[#374151]" >
                                        <p className="mb-1.5">Please submit the information necessary for the signature blocks.</p>
                                        <p className="mb-1.5">Please ensure that the information you submit as Contact Information is consistent with the Notices provision you included in the contract.</p>
                                        <p className="mb-1.5">For example, if the Notices provision contemplates notice by email, then submit email information or instructions as Contact Information. If the Notices provision contemplates notice by mail, then submit a physical address.</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <p className='text-sm font-normal text-[#6B7280] pb-3'></p>
                {
                    data.map((item, index) => {
                        return (
                            <div className="mb-5">
                                <div className='text-base font-medium text-[#374151]'>Signature {index + 1}</div>
                                <div className='flex gap-[10px] relative' id={"Signature " + (index + 1)}>
                                    <div className='w-[70%]'>
                                        <div className='text-sm font-medium text-[#6B7280] py-2'>Party Name</div>
                                        <input value={data[index].partyName}
                                            onFocus={() => { setTimeout(() => scrollToElement("signatures"), 1) }}
                                            onChange={(e) => {
                                                let temp = [...data]
                                                temp[index].partyName = e.target.value
                                                setData(temp)
                                                add_dispatch_handler({ var_name: "NAME" + (index + 1), value: e.target.value })
                                                setTimeout(() => scrollToElement("signatures"), 1)
                                                dispatch({ type: 'CHANGE_TOGGLE' })
                                            }} placeholder='Acme, Inc.' className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border border-[#E5E7EB] rounded-[16px]' />
                                    </div>
                                    <div className='w-[30%]'>
                                        <div className='text-sm font-medium text-[#6B7280] py-2'>Type</div>
                                        <div onClick={() => { setSelectedDropdown("Signature " + (index + 1)) }} className='flex justify-content items-center text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border border-[#E5E7EB] rounded-[16px] hover:cursor-pointer'>
                                            {item.partyType ? item.partyType : "Select Type"}
                                            <svg className='ml-auto mt-1' width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.33301 1.5L7.99967 8.16667L14.6663 1.5" stroke="#A1A1AA" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    <ul className={`right-0 top-[85px] absolute mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50 ${selectedDropdown === "Signature " + (index + 1) ? "block" : "hidden"}`}>
                                        <li onClick={() => {
                                            document.getElementById(selectedDropdown).getElementsByTagName("ul")[0].classList.remove("block")
                                            setSelectedDropdown(undefined)

                                            add_dispatch_handler({ var_name: "PARTY_TYPE_" + (index + 1), value: "Entity" })
                                            if (index + 1 === 1) {
                                                add_dispatch_handler({ var_name: "PARTY" + (index + 1), value: "{{NAME1}}, a {{STATE1}} {{TYPE_OF_ENTITY}}," })
                                                dispatch({ type: "DELETE", payload: "180" })
                                            }
                                            else {
                                                add_dispatch_handler({ var_name: "PARTY" + (index + 1), value: "{{NAME2}}, a {{STATE2}} {{TYPE_OF_ENTITY_2}}" })
                                                dispatch({ type: "DELETE", payload: "188" })
                                            }
                                            dispatch({
                                                type: "ADD", payload: {
                                                    ["SIGNATORY" + (index + 1)]: {
                                                        "id": undefined,
                                                    },
                                                }
                                            })

                                            let temp = [...data]
                                            temp[index].partyType = "Entity"
                                            temp[index].signatoryName = ans["SIGNATORYNAME" + (index + 1)] ? ans["SIGNATORYNAME" + (index + 1)].value : ""
                                            temp[index].signatoryTitle = ans["SIGNATORYTITLE" + (index + 1)] ? ans["SIGNATORYTITLE" + (index + 1)].value : ""
                                            setData(temp)
                                        }} className='text-base font-normal text-[#6B7280] py-[10px] px-[18px] hover:bg-[#F9FAFB] hover:cursor-pointer'>Entity</li>
                                        <li onClick={() => {
                                            document.getElementById(selectedDropdown).getElementsByTagName("ul")[0].classList.remove("block")
                                            setSelectedDropdown(undefined)

                                            add_dispatch_handler({ var_name: "PARTY_TYPE_" + (index + 1), value: "Individual" })
                                            if (index + 1 === 1) {
                                                add_dispatch_handler({ var_name: "PARTY" + (index + 1), value: "{{NAME1}}" })
                                                dispatch({
                                                    type: "ADD", payload: {
                                                        "180": {
                                                            "state": "true",
                                                        },
                                                    }
                                                })
                                            }
                                            else {
                                                add_dispatch_handler({ var_name: "PARTY" + (index + 1), value: "{{NAME2}}" })
                                                dispatch({
                                                    type: "ADD", payload: {
                                                        "188": {
                                                            "state": "true",
                                                        },
                                                    }
                                                })
                                            }
                                            dispatch({ type: "DELETE", payload: "SIGNATORY" + (index + 1) })

                                            let temp = [...data]
                                            temp[index].partyType = "Individual"
                                            delete temp[index].signatoryName
                                            delete temp[index].signatoryTitle
                                            setData(temp)
                                        }} className='text-base font-normal text-[#6B7280] py-[10px] px-[18px] hover:bg-[#F9FAFB] hover:cursor-pointer'>Individual</li>
                                    </ul>
                                </div>
                                {
                                    data[index].partyType === "Entity" ?
                                        <>
                                            <div className='text-sm font-medium text-[#6B7280] py-2'>Signatory Name</div>
                                            <input value={data[index].signatoryName}
                                                onFocus={() => { setTimeout(() => scrollToElement("signatures"), 1) }}
                                                onChange={(e) => {
                                                    let temp = [...data]
                                                    temp[index].signatoryName = e.target.value
                                                    setData(temp)
                                                    add_dispatch_handler({ var_name: "SIGNATORYNAME" + (index + 1), value: e.target.value })
                                                    setTimeout(() => scrollToElement("signatures"), 1)
                                                    dispatch({ type: 'CHANGE_TOGGLE' })
                                                }} className='w-full text-base font-normal text-[#6B7280] py-[10px] px-[18px] bg-[#F9FAFB] border border-[#E5E7EB] rounded-[16px]' />
                                            <div className='text-sm font-medium text-[#6B7280] py-2'>Signatory Title</div>
                                            <input value={data[index].signatoryTitle}
                                                onFocus={() => { setTimeout(() => scrollToElement("signatures"), 1) }}
                                                onChange={(e) => {
                                                    let temp = [...data]
                                                    temp[index].signatoryTitle = e.target.value
                                                    setData(temp)
                                                    add_dispatch_handler({ var_name: "SIGNATORYTITLE" + (index + 1), value: e.target.value })
                                                    setTimeout(() => scrollToElement("signatures"), 1)
                                                    dispatch({ type: 'CHANGE_TOGGLE' })
                                                }} className='w-full text-base font-normal text-[#6B7280] py-[10px] px-[18px] bg-[#F9FAFB] border border-[#E5E7EB] rounded-[16px]' />
                                        </>
                                        :
                                        null
                                }
                                <div className='text-sm font-medium text-[#6B7280] py-2'>Contact Information</div>
                                <textarea value={data[index].contactInformation}
                                    onFocus={() => { setTimeout(() => scrollToElement("signatures"), 1) }}
                                    onChange={(e) => {
                                        let temp = [...data]
                                        temp[index].contactInformation = e.target.value
                                        setData(temp)
                                        add_dispatch_handler({ var_name: "CONTACTINFORMATION" + (index + 1), value: e.target.value })
                                        setTimeout(() => scrollToElement("signatures"), 1)
                                        dispatch({ type: 'CHANGE_TOGGLE' })
                                    }} className='w-full text-base font-normal text-[#6B7280] py-[10px] px-[18px] bg-[#F9FAFB] border border-[#E5E7EB] rounded-[16px]' />
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default Signature
