import { useDispatch } from 'react-redux';
import instance from '../auth/Axios';
import React from 'react'

const Message = (props) => {

    let infoModalRef = React.useRef();
    const dispatch = useDispatch();

    const [infoModalVisibility, setInfoModalVisibility] = React.useState(false);
    const [cc, setCc] = React.useState("")

    const generateDocx = async () => {
        const check = props.handlePaymentModal()
        if (!check) {
            return
        }
        const response = await instance.post("/contract/getDocx", {
            data: props.cleanContract(),
            contractId: props.contractId,
        }, {
            responseType: 'blob',
        })
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', props.contractName + '.docx'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        const getUserDetails = await instance.get('/user/get-user-details')
        if (getUserDetails.data.status) {
            dispatch({
                type: 'SET_PAYMENT_DETAILS', remaining_contract: getUserDetails.data.remaining_contract,
                payment_status: getUserDetails.data.payment_status,
                planId: getUserDetails.data.planId,
                plan_type: getUserDetails.data.plan_type
            })
        }
    }

    React.useEffect(() => {
        if (props.cc) {
            let cc = ""
            props.cc.map((item, index) => {
                cc = cc + item.email + "; "
            })
            setCc(cc)
        }
    }, [props.cc])

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (infoModalRef.current && !infoModalRef.current.contains(event.target)) {
                setInfoModalVisibility(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [])

    return (
        <>
            <div className='flex items-center border border-[#E4E4E7] p-5 mb-5 rounded-[5px]'>
                <div className='text-lg font-semibold text-[#374151]'>Download Contract</div>
                <button onClick={generateDocx} className='ml-auto flex justify-center items-center bg-[#1C64F2] border border-[#1C64F2] rounded-[12px] py-[9px] px-[17px] hover:bg-blue-700'>
                    <div className='text-sm font-semibold text-[#FFFFFF]'>Download</div>
                </button>
            </div>
            <div className='border border-[#E4E4E7] p-5 mb-5 rounded-[5px]'>
                <div className='flex items-center text-lg font-semibold text-[#374151] pb-2'>
                    Email
                    <div className='ml-auto w-content mt-1 relative z-40'>
                        <svg onClick={() => setInfoModalVisibility(true)} className="ml-auto cursor-pointer" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.4685 13.8006C18.9019 12.7542 19.125 11.6327 19.125 10.5C19.125 8.21251 18.2163 6.01871 16.5988 4.4012C14.9813 2.7837 12.7875 1.875 10.5 1.875C8.21251 1.875 6.0187 2.7837 4.4012 4.4012C2.7837 6.01871 1.875 8.21251 1.875 10.5C1.875 11.6327 2.09809 12.7542 2.53154 13.8006C2.96499 14.8471 3.6003 15.7979 4.4012 16.5988C5.20211 17.3997 6.15292 18.035 7.19936 18.4685C8.24579 18.9019 9.36735 19.125 10.5 19.125C11.6327 19.125 12.7542 18.9019 13.8006 18.4685C14.8471 18.035 15.7979 17.3997 16.5988 16.5988C17.3997 15.7979 18.035 14.8471 18.4685 13.8006Z" fill="white" />
                            <path d="M11.4583 14.3333H10.5V10.5H9.54167M10.5 6.66667H10.5096M19.125 10.5C19.125 11.6327 18.9019 12.7542 18.4685 13.8006C18.035 14.8471 17.3997 15.7979 16.5988 16.5988C15.7979 17.3997 14.8471 18.035 13.8006 18.4685C12.7542 18.9019 11.6327 19.125 10.5 19.125C9.36735 19.125 8.24579 18.9019 7.19936 18.4685C6.15292 18.035 5.20211 17.3997 4.4012 16.5988C3.6003 15.7979 2.96499 14.8471 2.53154 13.8006C2.09809 12.7542 1.875 11.6327 1.875 10.5C1.875 8.21251 2.7837 6.01871 4.4012 4.4012C6.0187 2.7837 8.21251 1.875 10.5 1.875C12.7875 1.875 14.9813 2.7837 16.5988 4.4012C18.2163 6.01871 19.125 8.21251 19.125 10.5Z" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        {
                            infoModalVisibility &&
                            <div ref={infoModalRef} className="absolute right-[-25px] w-[28rem] bg-[white] h-52 top-0 z-50 border overflow-y-auto rounded-[5px] shadow-lg">
                                <div className="flex items-center justify-between px-4 py-2">
                                    <div className="text-sm font-medium text-[#374151]">Email</div>
                                    <button onClick={() => setInfoModalVisibility(false)} className="focus:outline-none">
                                        <svg className="w-4 h-4 text-[#374151]" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L15 15M15 1L1 15" stroke="#374151" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="px-4 pb-2">
                                    <div className="text-xs font-medium text-[#374151]" >
                                        <p className="mb-1.5">In the previous step, you submitted email addresses for the contract recipient (i.e., the new “owner” of the contract) and/or any viewers.</p>
                                        <p className="mb-1.5">Once you send the contract draft, the email recipient(s) will receive an invitation via email to participate in this Standard Draft contract workflow.</p>
                                        <p className="mb-1.5">We strongly encourage you to include a descriptive message, which will be included in the emails received by the recipients. Please see here for an example:</p>
                                        <p className="mb-1.5"><i>Hey Tom, I just used Standard Draft to prepare an initial version of our NDA. Please review at your earliest convenience.</i></p>
                                        <p className="mb-1.5"><i>I have added Susan from my side and Rahul from your side as viewers.</i></p>
                                        <p className="mb-1.5"><i>If you need to communicate with me directly, please email me at linda@newco.net. I look forward to working with you on this!</i></p>
                                        <p className="mb-1.5"><i>Linda</i></p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className='mb-3'>
                    <div className='text-[15px] font-normal text-[#6B7280] py-2 mr-2'>
                        {props.shareWithContractRecipient ? "Counterparty Recipient:" : "Recipient:"}
                    </div>
                    <input disabled value={props.to} className='text-base font-normal text-[#6B7280] py-[8px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[8px] w-full' />
                </div>
                {
                    props.cc && props.cc.length > 0 &&
                    <div className='mb-3'>
                        <div className='text-[15px] font-normal text-[#6B7280] py-2 mr-2'>View Access:</div>
                        <input disabled value={cc} className='text-base font-normal text-[#6B7280] py-[12px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[8px] w-full ' />
                    </div>
                }
                <div className='flex items-center mb-3'>
                    <div className='text-[15px] font-normal text-[#6B7280] py-2 mr-2'>Attachment:</div>
                    <div className='text-[15px] font-[500] text-[#6B7280] py-2 mr-2'>{props.contractName}.doc</div>
                </div>
                <div className='text-[15px] font-normal text-[#6B7280] py-2 mr-2'>Message:</div>
                <textarea value={props.message} onChange={(e) => { props.setMessage(e.target.value); localStorage.setItem('stagingMessage', e.target.value) }} className='text-base font-normal text-[#6B7280] py-[12px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[8px] w-full h-[200px] mb-3'
                    placeholder={`Hey Linda, I just prepared an ${props.phase === "Drafting " ? "initial" : "updated"} version of our NDA using Standard Draft. Please take a look when you have a moment. If you need to communicate with me directly, please reach me at: ryan@gmail.com.`} />
            </div>
        </>
    )
}

export default Message
