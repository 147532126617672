import instance from "../auth/Axios"
import React from 'react'

const Status = ({
    contractId, contractName, yourNewSigner, yourViewers, setYourViewers, contractRecipientSigner, contractRecipientViewers, setContractRecipientViewers,
    yourSideSigned, contractRecipientSideSigned
}) => {
    const handleSign = async () => {
        const data = {
            contractId: contractId,
            signerName: yourNewSigner.firstName + ' ' + yourNewSigner.lastName,
            signerEmail: yourNewSigner.email,
        }
        const resp = await instance.post('/contract/sign', data)
        window.open(resp.data.signingUrl, '_self')
    }

    const downloadSignedContract = async () => {
        const response = await instance.post("/contract/download", {
            contractId: contractId,
        }, {
            responseType: 'blob',
        })
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        let link = document.createElement('a')
        link.href = href;
        link.setAttribute('download', contractName + '.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    return (
        <div>
            <div className='text-lg font-semibold text-[#374151] pb-1.5'>Your Side</div>
            <div className='ml-3'>
                <div className='flex gap-[10px]'>
                    {
                        yourSideSigned ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="#3e83f8" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                            : null
                    }
                    <p className='text-sm font-normal text-[#6B7280] pb-3 mt-3'>
                        {
                            localStorage.getItem("email") === yourNewSigner.email ? "You" : yourNewSigner.firstName + ' ' + yourNewSigner.lastName
                        }
                        {
                            yourSideSigned ? " signed this contract on " +
                                new Date(yourSideSigned).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
                                :
                                localStorage.getItem("email") === yourNewSigner.email ?
                                    " have not signed this contract. Please note, if you just signed this contract via DocuSign, it may take a couple minutes for the status to be updated."
                                    :
                                    " has not signed this contract."
                        }
                    </p>
                </div>
                {
                    yourSideSigned || yourNewSigner.email !== localStorage.getItem("email") ? null :
                        <button onClick={handleSign} className='bg-[#1C64F2] text-white rounded-md py-2 px-4 font-semibold text-sm'>
                            Sign Contract
                        </button>
                }
                {
                    yourViewers.length === 0 ? null :
                        <>
                            <p className='text-sm font-normal text-[#6B7280] mt-3'>
                                Your side’s viewers:
                            </p>
                            {
                                yourViewers.map((viewer, index) => {
                                    return (
                                        <>
                                            <div className='flex gap-[10px]'>
                                                <div>
                                                    <div className='text-sm font-normal text-[#6B7280] py-2'>Email *</div>
                                                    <input placeholder='ryan@standarddraft.com' className='text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.email} onChange={(e) => {
                                                        const temp = [...yourViewers]
                                                        temp[index].email = e.target.value
                                                        setYourViewers(temp)
                                                    }} />
                                                </div>
                                                <div>
                                                    <div className='text-sm font-normal text-[#6B7280] py-2'>First Name</div>
                                                    <input placeholder='Ryan' className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.firstName} onChange={(e) => {
                                                        const temp = [...yourViewers]
                                                        temp[index].firstName = e.target.value
                                                        setYourViewers(temp)
                                                    }
                                                    } />
                                                </div>
                                                <div>
                                                    <div className='text-sm font-normal text-[#6B7280] py-2'>Last Name</div>
                                                    <input placeholder='Sammi' className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.lastName} onChange={(e) => {
                                                        const temp = [...yourViewers]
                                                        temp[index].lastName = e.target.value
                                                        setYourViewers(temp)
                                                    }
                                                    } />
                                                </div>
                                            </div>

                                            <div className='flex items-center'>
                                                <div onClick={() => {
                                                    const temp = [...yourViewers]
                                                    temp.splice(index, 1)
                                                    setYourViewers(temp)
                                                }
                                                } className='text-sm font-medium text-[#1C64F2] hover:cursor-pointer'>Remove</div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </>
                }
            </div>
            <div className='text-lg font-semibold text-[#374151] pb-1.5 mt-3'>Counterparty</div>
            <div className='ml-3'>
                <p className='text-sm font-normal text-[#6B7280]'>
                    <span className='flex gap-[10px]'>
                        {
                            contractRecipientSideSigned ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="#3e83f8" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg>
                                : null
                        }
                        <span className='text-sm font-normal text-[#6B7280] pb-3 mt-3'>
                            {contractRecipientSigner.firstName}
                            {
                                contractRecipientSideSigned ? " signed this contract on " +
                                    new Date(contractRecipientSideSigned).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
                                    : " has not signed this contract."
                            }
                        </span>
                    </span>
                </p>
                {/* {
                    contractRecipientSideSigned ? null :
                        <button className='bg-[gray] text-white rounded-md py-2 px-4 font-semibold text-sm'>
                            Resend for Signature
                        </button>
                } */}
                {
                    contractRecipientViewers.length === 0 ? null :
                        <>
                            <p className='text-sm font-normal text-[#6B7280] mt-3'>
                                Counterparty’s viewers:
                            </p>
                            {
                                contractRecipientViewers.map((viewer, index) => {
                                    return (
                                        <>
                                            <div className='flex gap-[10px]'>
                                                <div>
                                                    <div className='text-sm font-normal text-[#6B7280] py-2'>Email *</div>
                                                    <input placeholder='ryan@standarddraft.com' className='text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.email} onChange={(e) => {
                                                        const temp = [...contractRecipientViewers]
                                                        temp[index].email = e.target.value
                                                        setContractRecipientViewers(temp)
                                                    }} />
                                                </div>
                                                <div>
                                                    <div className='text-sm font-normal text-[#6B7280] py-2'>First Name</div>
                                                    <input placeholder='Ryan' className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.firstName} onChange={(e) => {
                                                        const temp = [...contractRecipientViewers]
                                                        temp[index].firstName = e.target.value
                                                        setContractRecipientViewers(temp)
                                                    }
                                                    } />
                                                </div>
                                                <div>
                                                    <div className='text-sm font-normal text-[#6B7280] py-2'>Last Name</div>
                                                    <input placeholder='Sammi' className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.lastName} onChange={(e) => {
                                                        const temp = [...contractRecipientViewers]
                                                        temp[index].lastName = e.target.value
                                                        setContractRecipientViewers(temp)
                                                    }
                                                    } />
                                                </div>
                                            </div>

                                            <div className='flex items-center'>
                                                <div onClick={() => {
                                                    const temp = [...contractRecipientViewers]
                                                    temp.splice(index, 1)
                                                    setContractRecipientViewers(temp)
                                                }
                                                } className='text-sm font-medium text-[#1C64F2] hover:cursor-pointer'>Remove</div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </>
                }
            </div>
            {yourSideSigned && contractRecipientSideSigned ?
                <button onClick={downloadSignedContract} className="block ml-auto bg-[#1C64F2] rounded-[12px] py-[9px] px-[17px] text-sm font-semibold text-[#FFFFFF]">Download</button>
                : null}
        </div>
    )
}

export default Status
