import PostSigninNavbar from '../components/PostSigninNavbar'
import PreSigninNavbar from '../components/PreSigninNavbar'
import Sidebar from '../components/Sidebar'
import React from 'react'

const TermsOfUse = () => {
    const [sidebar, setSidebar] = React.useState(false);

    return (
        <div>
            <div className='flex'>
                {window.localStorage.getItem('accessToken') && <Sidebar value={sidebar} setSidebar={setSidebar} />}
                <div className='w-full'>
                    {window.localStorage.getItem('accessToken') ?
                        <PostSigninNavbar value={sidebar} onChange={setSidebar} />
                        :
                        <PreSigninNavbar />
                    }
                    <div className='py-5 px-8 text-justify'>
                        <b>Standard Draft, Inc. Terms of Use</b>
                        <p className='mb-3'>Last Modified: June 1, 2023</p>
                        <p className='mb-3'>Standard Draft, Inc. (“Standard Draft”, “we”, “us”, or “our”) provides and makes available this website and platform for creating, negotiating, signing, reviewing, analyzing, and otherwise working with contracts (collectively, our “Services”).</p>
                        <p className='mb-3'>These Terms of Use, along with all other agreements, disclaimers, and disclosures displayed on our website (these “Terms”) state the terms and conditions under which you may use our Services.</p>
                        <p className='mb-3'>By accessing or using our website or our Services in any way (including using the data, text, reports, contract forms, and other materials made available or enabled via our website or our Services), clicking on any button(s) in connection with your acceptance of these Terms, and/or browsing our website, you agree to follow and be bound by these Terms and our Privacy Policy. If you are accessing or using the Service on behalf of your company or firm, you represent that you are authorized to accept these Terms on behalf of such entity, and all references to “you” mean such entity.</p>
                        <p className='mb-3'><b>Please note, these Terms of Use contain a binding arbitration clause, class action waiver and other provisions that limit your rights and remedies. Please review them carefully.</b></p>
                        <p className='mb-3'><b><u>1. Important Disclaimers</u></b></p>
                        <p className='mb-3'><b>As explained in further detail below, Standard Draft is not a law firm and does not provide legal advice. Standard Draft’s Services do not create an attorney-client relationship between you and Standard Draft. You should review all contracts carefully before using them and consult with an attorney about drafting choices you make and questions you have.</b></p>
                        <p className='mb-3'>Standard Draft is not a law firm. Any communications between you and Standard Draft may not be protected under the attorney-client privilege doctrine.  Standard Draft does not provide legal, tax, and/or accounting advice or in no case should be considered a substitute for advice from qualified legal counsel and other tax or accounting professionals.</p>
                        <p className='mb-3'>Any opinions, insights, or information expressed via our Services (including in connection with our self-service contract forms (our “Forms”) and the configurable options and information modals relating to the Forms) (collectively, our “Contextual Materials”) are not a substitute for legal advice and are provided solely as a tool to make the process for drafting and negotiating contracts more efficient. Because our Services (including our Contextual Materials) are general in nature and may not pertain to your specific circumstances, you should not act or refrain from acting based on any of our Contextual Materials without first obtaining advice from a licensed attorney.</p>
                        <p className='mb-3'>Without limiting the foregoing, our Contextual Materials may not reflect recent developments in the law, be complete or be accurate in or applicable to your jurisdiction. We make no representation as to the suitability of using our Services in any given circumstance or as to the enforceability of our Forms in any specific jurisdiction.</p>
                        <p className='mb-3'>We do not review any information you provide us for legal accuracy or sufficiency, draw legal conclusions, provide opinions about your selection of forms, or apply the law to the facts of your situation. If you need advice for a specific problem, you should consult with a licensed attorney.</p>
                        <p className='mb-3'>Our Services do not create an attorney-client relationship between you and Standard Draft or any individual attorney or law firm.</p>
                        <p className='mb-3'><b><u>2. Access to and Usage of Our Services</u></b></p>
                        <p className='mb-3'><b>2(a) General</b></p>
                        <p className='mb-3'>You acknowledge that our Services (including our Forms and Contextual Materials) are protected by copyrights, trademarks (whether registered or unregistered), trade secrets, patents or other proprietary rights, and these worldwide rights are valid and protected in all forms, media and technologies now existing or later developed. You agree to comply with all intellectual property laws, and you shall not encumber any interest in, or assert any rights to, our intellectual property.</p>
                        <p className='mb-3'><b>2(b) Grant of License</b></p>
                        <p className='mb-3'>Standard Draft grants you a limited, non-transferable, non-sublicensable and revocable license to access and use our Services (including our Forms and Contextual Materials) solely for the purpose of participating in the contract workflow (consisting of drafting, reviewing, negotiating, and signing a contract with a counterparty) for which one of our Forms was initially selected by you or your counterparty (the “Permitted Purpose”).</p>
                        <p>Such license is subject to these Terms and does not include:</p>
                        <ul className='list-disc ml-8 mb-3'>
                            <li>any resale of our Forms;</li>
                            <li>any commercial use of our Forms (other than for the Permitted Purpose);</li>
                            <li>any distribution, public performance, public display, inclusion in artificial intelligence or large language model training data or other exploitation of any of our Forms (other than as expressly permitted by Standard Draft in writing);</li>
                            <li>modifying or otherwise making any derivative use of any of our Forms, except for modifications in drafting and negotiating a Form for your authorized use within the Permitted Purpose;</li>
                            <li>downloading (other than the page caching) and printing of any part of our Forms, except as expressly permitted on our platform in connection with the Permitted Purpose;</li>
                            <li>making any portion of our Forms publicly available through any service, the internet, or any other technology;</li>
                            <li>using any automatic or manual process to harvest information from our platform; or</li>
                            <li>any use of our Forms or Contextual Materials for other than the Permitted Purpose.</li>
                        </ul>
                        <p className='mb-3'>Unless expressly stated herein, nothing in these Terms confers any license to intellectual property rights, whether by estoppel, implication or otherwise. This license is revocable at any time.</p>
                        <p className='mb-3'>This license terminates automatically without notice if you breach any of the provisions of these Terms.</p>
                        <p className='mb-3'><b>2(c) No Interference</b></p>
                        <p>You agree not to:</p>
                        <ul className='list-disc ml-8 mb-3'>
                            <li>take any action that imposes an unreasonable load on our website’s infrastructure;</li>
                            <li>attempt to decipher, decompile, disassemble or reverse engineer any of the software underlying our Services;</li>
                            <li>use any device, software or routine to interfere or attempt to interfere with the proper working of our website; or</li>
                            <li>frame or link to any of the materials or information available on our website.</li>
                        </ul>
                        <p className='mb-3'><b>2(d) Changes and Interruptions to our Services</b></p>
                        <p className='mb-3'>From time to time we may add, remove or change our Forms or Contextual Materials  or other aspects of our Services. You acknowledge that temporary interruptions in the availability of our Services may occur from time to time as normal events.</p>
                        <p className='mb-3'><b><u>3. User Content and Feedback</u></b></p>
                        <p className='mb-3'>As part of our Services, users may draft and negotiate contracts by selecting among the options we provide in our Forms, incorporating custom text modifications into our Forms or otherwise interacting with our Contextual Materials (“User Content”). You agree not to share any User Content that is inappropriate, illegal, offensive or for which you do not control all intellectual property rights. You agree that we may use User Content for any purpose that is within the scope of our Privacy Policy.</p>
                        <p className='mb-3'>Without limiting the above, if you provide us with any feedback or suggestions regarding our Services (“Feedback”), you hereby assign to us all rights in such Feedback and agree that we will have the right to use and fully exploit such Feedback and related information in any manner we deem appropriate. We will treat any Feedback you provide to Company as non-confidential and non-proprietary.</p>
                        <p className='mb-3'>You may encounter content produced by other users of our Services (e.g., user comments made about our Forms and Contextual Materials). Although we reserve the right to promote, edit and/or delete any user comments posted on our platform, please note that we may not monitor or review such content, we do not endorse any such content, and we do not support any views, opinions, recommendations, or advice that may be in such content. You agree not to hold us responsible in any way for your interactions with other users or use of any such content.</p>
                        <p className='mb-3'><b><u>4. Accounts</u></b></p>
                        <p className='mb-3'>In order to use our Services, you must register for an account (“Account”) and provide certain information about yourself as prompted by the account registration process. You represent and warrant that all required registration information you submit is truthful and accurate and you will maintain the accuracy of such information.</p>
                        <p className='mb-3'>You may delete your Account at any time, for any reason, by reaching out to us at info@standarddraft.com or following the instructions on our website. We may suspend or terminate your Account in accordance with Section 8 (Term and Termination).</p>
                        <p className='mb-3'>You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account. You agree to immediately notify us of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security. We cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.</p>
                        <p className='mb-3'><b><u>5. Disclaimer of Warranties</u></b></p>
                        <p className='mb-3'>OUR SERVICES ARE PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, AND STANDARD DRAFT AND ITS PARTNERS, EMPLOYEES, AGENTS AND SUPPLIERS (COLLECTIVELY, THE “STANDARD DRAFT PARTIES”) EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY OR NON-INFRINGEMENT. THE STANDARD DRAFT PARTIES MAKE NO WARRANTY THAT OUR SERVICES WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL OR SAFE.  IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO OUR WEBSITE, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO 90 DAYS FROM THE DATE OF FIRST USE.</p>
                        <p className='mb-3'>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.</p>
                        <p className='mb-3'><b><u>6. Limitation of Liability</u></b></p>
                        <p className='mb-3'>TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL THE STANDARD DRAFT PARTIES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, OUR SERVICES, EVEN IF THE STANDARD DRAFT PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, OUR SERVICES IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING FROM SUCH ACCESS OR USE.</p>
                        <p className='mb-3'>TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED IN THESE TERMS, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THESE TERMS (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF THE GREATER OF TOTAL FEES PAID BY YOU TO US IN THE TWELVE MONTHS PRIOR TO WHEN THE CLAIM AROSE OR ONE HUNDRED U.S. DOLLARS. YOU AGREE THAT OUR PARTNERS, EMPLOYEES, AGENTS, AND SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THESE TERMS.</p>
                        <p className='mb-3'>SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>
                        <p className='mb-3'><b><u>7. Indemnification</u></b></p>
                        <p className='mb-3'>You agree to defend (if requested), indemnify, and hold the Standard Draft Parties harmless, including costs and attorneys’ fees, from any claim or demand due to or arising out of (a) your access to or use of our Services, (b) your violation of these Terms, (c) your violation of applicable laws or regulations, or (d) your User Content. Standard Draft reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of Standard Draft. Standard Draft will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it. Your indemnification obligation will survive the termination of these Terms and your access to or use of our Service.</p>
                        <p className='mb-3'><b><u>8. Term and Termination</u></b></p>
                        <p className='mb-3'>These Terms will remain in full force and effect while you use our Services. We may suspend or terminate your rights to use our Services (including your Account) at any time for any reason at our sole discretion, including for any use of our Services in violation of these Terms. Upon termination of your rights under these Terms, your Account and right to access and use our Services will terminate immediately. You understand that any termination of your Account may involve deletion of your User Content associated with your Account from our live databases. We will not have any liability whatsoever to you for any termination of your rights under these Terms, including for termination of your Account or deletion of your User Content. Even after your rights under these Terms are terminated, the provisions of these Terms relating to your usage (including Section 2 (Access to and Usage of Our Services) and Section 3 (User Content and Feedback)) will remain in effect.</p>
                        <p className='mb-3'><b><u>9. Dispute Resolution</u></b></p>
                        <p className='mb-3'><b>9(a) Disputes Generally</b></p>
                        <p className='mb-3'>You agree that any dispute between you and any of the Standard Draft Parties relating in any way to our Services or these Terms will be resolved by binding arbitration, rather than in court, except that: (1) you and the Standard Draft Parties may assert individualized claims in small claims court if the claims qualify, remain in such court and advance solely on an individual, non-class basis; (2) you or the Standard Draft Parties may seek an injunction or other equitable relief in court for infringement or other misuse of intellectual property rights (such as trademarks, trade dress, domain names, trade secrets, copyrights, and patents); and (3) you agree that before you commence arbitration against a Standard Draft Party (or initiate an action in small claims court if you so elect), you will confer via email, telephonically or via videoconference with the Standard Draft Party in question, in a good faith effort to resolve informally any dispute covered by this Section 9.</p>
                        <p className='mb-3'><b>9(b) Arbitration Rules, Process, and Procedure</b></p>
                        <p className='mb-3'>The arbitration to resolve a dispute will be administered by the American Arbitration Association (“AAA”) in accordance with the Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (the “AAA Rules”) then in effect, except as modified by this Section 9. The Federal Arbitration Act will govern the interpretation and enforcement of this Section 9.</p>
                        <p className='mb-3'>A party that desires to initiate arbitration must provide the other party with a written Demand for Arbitration as specified in the AAA Rules. The arbitrator will be either a retired judge or an attorney licensed to practice law in the state of California and will be selected by the parties from the AAA’s roster of consumer dispute arbitrators. If the parties are unable to agree upon an arbitrator within seven (7) days of delivery of the Demand for Arbitration, then the AAA will appoint the arbitrator in accordance with the AAA Rules.</p>
                        <p className='mb-3'>Unless the parties otherwise agree, the arbitration will be conducted in Los Angeles, California. If your claim does not exceed $10,000, then the arbitration will be conducted solely on the basis of documents the parties submit to the arbitrator, unless you request a hearing or the arbitrator determines that a hearing is necessary. If your claim exceeds $10,000, your right to a hearing will be determined by the AAA Rules. Subject to the AAA Rules, the arbitrator will have the discretion to direct a reasonable exchange of information by the parties, consistent with the expedited nature of the arbitration.</p>
                        <p className='mb-3'>The arbitrator will render an award within the time frame specified in the AAA Rules. The arbitrator’s decision will include the essential findings and conclusions upon which the arbitrator based the award. Judgment on the arbitration award may be entered in any court having jurisdiction. The arbitrator’s award damages must be consistent with the terms of Section 6 (Limitation of Liability) above as to the types and the amounts of damages for which a party may be held liable. The arbitrator may award declaratory or injunctive relief only in favor of the claimant and only to the extent necessary to provide relief warranted by the claimant’s individual claim.</p>
                        <p className='mb-3'><b>9(c) Class Action Waiver</b></p>
                        <p className='mb-3'><b>You and Standard Draft acknowledge and agree that, to the fullest extent permitted by law, we are each waiving the right to participate as a plaintiff or class member in any purported class action lawsuit, class-wide arbitration, private attorney general action or any other representative or consolidated proceeding.</b> Unless we agree in writing, the arbitrator may not consolidate more than one party’s claims and may not otherwise preside over any form of any class or representative proceeding. If there is a final judicial determination that applicable law precludes enforcement of the waiver contained in this paragraph as to any claim, cause of action or requested remedy, then that claim, cause of action or requested remedy, and only that claim, cause of action or requested remedy, will be severed from this agreement to arbitrate and will be brought in a court of competent jurisdiction. In the event that a claim, cause of action or requested remedy is severed pursuant to this paragraph, then you and we agree that the claims, causes of action or requested remedies that are not subject to arbitration will be stayed until all arbitrable claims, causes of action and requested remedies are resolved by the arbitrator.</p>
                        <p className='mb-3'><b><u>10. General</u></b></p>
                        <p className='mb-3'><b>10(a) Third Party Information</b></p>
                        <p className='mb-3'>We may hyperlink to or otherwise make third party information available on our website. This is done solely for the purposes of convenience. We do not endorse or approve of any such third party information or such third parties. If you decide to access linked third party websites, you do so at your own risk.</p>
                        <p className='mb-3'><b>10(b) Governing Law</b></p>
                        <p className='mb-3'>These Terms are governed by the laws of the state of California, without respect to its conflict of laws principles. If the arbitration provision in Section 9 (Dispute Resolution) is found to be unenforceable, the sole jurisdiction and venue for any dispute shall be the state and federal courts located in Los Angeles, California, and each party consents to the exclusive jurisdiction and venue of such courts.</p>
                        <p className='mb-3'><b>10(c) Notices</b></p>
                        <p className='mb-3'>We may provide you with notices under these Terms via email or via the platform where we offer our Services. Any notices you provide under these Terms will be deemed given only when personally delivered, delivered by reputable courier requiring signature for receipt, or three (3) business days after being deposited in the mail (first class and postage prepaid) in each case to: Standard Draft, Inc., 2219 Main St., Unit 670, Santa Monica, CA 90405, Attn: Legal (with a copy to legal@standarddraft.com).</p>
                        <p className='mb-3'><b>10(d) Changes to these Terms</b></p>
                        <p className='mb-3'>Standard Draft may modify these Terms from time to time. If we make any changes to these Terms, we will change the “Last Updated” date above and we will post the updated Terms on this page. Any modifications to these Terms will be effective upon our posting of the modified version (or as otherwise indicated at the time of posting). In all cases, your use of our Services after the effective date of any modified Terms indicates your acceptance of the modified Terms (except that any changes to Section 9 or Section 10(b) will not apply to any disputes for which the parties have actual notice on or before the date the change is posted).</p>
                        <p className='mb-3'><b>10(e) Waivers</b></p>
                        <p className='mb-3'>Any waiver or failure to enforce any provision of these Terms on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion.</p>
                        <p className='mb-3'><b>10(f) Severability</b></p>
                        <p className='mb-3'>If any provision of these Terms is found to be invalid or unenforceable by a court of competent jurisdiction, the invalid or unenforceable provision will be severed from these Terms and the remaining provisions will remain in full force and effect.</p>
                        <p className='mb-3'><b>10(g) Entire Agreement</b></p>
                        <p className='mb-3'>These Terms constitute the entire agreement between the parties regarding the subject matter of these Terms.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsOfUse
