import { useDispatch, useSelector } from 'react-redux'
import React, { useState } from 'react'

const CommentHistory = ({ heading, setCommentModalVisibility, readOnly }) => {
    const versionsComments = useSelector(state => state.contract.versionsComments)
    const dispatch = useDispatch()

    const [versionHistoryData, setVersionHistoryData] = useState([])
    const [comment, setComment] = useState('')

    const handleSave = () => {
        setCommentModalVisibility(false)
        if (comment.length === 0) {
            dispatch({
                type: 'DELETE_COMMENT',
                payload: heading
            })
        }
        else {
            dispatch({
                type: 'UPDATE_COMMENT',
                payload: {
                    heading,
                    comment
                }
            })
        }
    }

    React.useEffect(() => {
        setComment(versionsComments[0]?.comments?.hasOwnProperty(heading) ? versionsComments[0].comments[heading] : '')
        setVersionHistoryData(versionsComments.slice(1).map((history) => {
            if (history.author && history.comments[heading]) {
                return {
                    ...history,
                    comment: history.comments[heading]
                }
            }
            else {
                return null
            }
        }))
    }, [versionsComments])

    return (
        <div id="defaultModal" tabIndex={-1} className="fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full scrollbar-none overflow-auto">
            <div className="flex items-center justify-center w-full h-full">
                <div className="relative bg-white  w-full max-w-md  rounded-[20px]">
                    <div className="flex justify-between items-center p-4 rounded-t border-b">
                        <h3 className="text-lg font-semibold text-[#111928]">Comment History - {heading}</h3>
                        <button onClick={() => { setCommentModalVisibility(false) }}>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.292787 0.793081C0.480314 0.60561 0.734622 0.500295 0.999786 0.500295C1.26495 0.500295 1.51926 0.60561 1.70679 0.793081L5.99979 5.08608L10.2928 0.793081C10.385 0.697571 10.4954 0.621389 10.6174 0.56898C10.7394 0.516571 10.8706 0.488985 11.0034 0.487831C11.1362 0.486677 11.2678 0.511978 11.3907 0.562259C11.5136 0.61254 11.6253 0.686793 11.7192 0.780686C11.8131 0.874579 11.8873 0.986231 11.9376 1.10913C11.9879 1.23202 12.0132 1.3637 12.012 1.49648C12.0109 1.62926 11.9833 1.76048 11.9309 1.88249C11.8785 2.00449 11.8023 2.11483 11.7068 2.20708L7.41379 6.50008L11.7068 10.7931C11.8889 10.9817 11.9897 11.2343 11.9875 11.4965C11.9852 11.7587 11.88 12.0095 11.6946 12.1949C11.5092 12.3803 11.2584 12.4855 10.9962 12.4878C10.734 12.49 10.4814 12.3892 10.2928 12.2071L5.99979 7.91408L1.70679 12.2071C1.51818 12.3892 1.26558 12.49 1.00339 12.4878C0.741188 12.4855 0.490376 12.3803 0.304968 12.1949C0.11956 12.0095 0.0143906 11.7587 0.0121121 11.4965C0.00983372 11.2343 0.110629 10.9817 0.292787 10.7931L4.58579 6.50008L0.292787 2.20708C0.105316 2.01955 0 1.76525 0 1.50008C0 1.23492 0.105316 0.980609 0.292787 0.793081Z" fill="#111928" />
                            </svg>
                        </button>
                    </div>
                    <div className="text-sm text-[#6B7280] px-4 py-2">
                        You may add comments to provide explanations or context for your drafting decisions. Your counterparty will be able to review your comments.
                    </div>
                    <div className=" py-3 px-8 h-[400px] overflow-auto scrollbar-none w-full ">
                        {
                            !readOnly &&
                            <div className="flex">
                                <div className="flex flex-col items-center mr-4">
                                    <div className='bg-blue-500 text-white font-semibold w-8 h-8 pt-1 rounded-full text-center'>v{versionHistoryData.length + 1}</div>
                                    {versionHistoryData.length !== 0 && <div className="w-px h-[70%] bg-gray-300" />}
                                </div>
                                <div className="pt-1 pb-8 w-full">
                                    <p className="text-base text-[#4B5563] mb-1">Current Draft</p>
                                    <textarea className="w-full h-[50px] border border-[#D1D5DB] rounded-[12px] text-sm text-[#111928] px-4 py-2" placeholder="Add a comment" value={comment} onChange={(e) => { setComment(e.target.value) }} />
                                </div>
                            </div>
                        }
                        {
                            versionHistoryData.map((item, index) => (
                                <div key={index} className="flex">
                                    <div className="flex flex-col items-center mr-4">
                                        <div className={`bg-blue-500 text-white font-semibold w-8 ${item || versionHistoryData.length - index === 1 ? 'h-8' : 'h-10'} pt-1 rounded-full text-center`}>
                                            v{versionHistoryData.length - index}
                                        </div>
                                        {index !== versionHistoryData.length - 1 && <div className="w-px h-[65%] bg-gray-300" />}
                                    </div>
                                    <div className="pt-1 pb-8">
                                        <p className="text-base text-[#6B7280] mb-1">
                                            {item ? <> Proposed by <span className='font-[600]'>{item.author}</span> </> : 'No comments'}
                                        </p>
                                        {item && <p className="text-sm text-[#4B5563] mb-1 font-[600]">{item.comment}</p>}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="flex justify-end items-center p-4 rounded-b gap-2">
                        <button className='block mx-auto w-[15vw] py-[9px] px-[17px] border border-[#D1D5DB] rounded-[12px] text-sm font-semibold text-white bg-[#1E86FF]' onClick={handleSave}>Save</button>
                        <button onClick={() => { setCommentModalVisibility(false) }} className='block mx-auto w-[15vw] py-[9px] px-[17px] border border-[#D1D5DB] rounded-[12px] text-sm font-semibold text-[#111928]'>Close</button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CommentHistory