import React from 'react'

const ChatInput = ({ inputValue, setInputValue, isBotResponding, handleSendMessage, setHeight }) => {
    const [rows, setRows] = React.useState(1)

    React.useEffect(() => {
        const textarea = document.getElementById('chatbot-input-bar')
        if (inputValue.indexOf('\n') !== -1) {
            setRows(inputValue.split('\n').length)
        }
        else if (textarea.scrollHeight - textarea.clientHeight !== 0) {
            setRows((e) => e + 1)
        }
        else if (inputValue.length === 0) {
            setRows(1)
        }
    }, [inputValue])

    React.useEffect(() => {
        let height = ((window.innerHeight * 75) / 100) - 50
        if (height - (rows * 20) > height - 120) {
            setHeight(height - rows * 20)
        }
        else {
            setHeight(height - 120)
        }
    }, [rows])

    return (
        <div
            className={`bg-white z-30 relative shadow-md  px-3 py-0.5 ${rows > 1 ? 'border-primary border-2 rounded-lg' : 'border border-gray-300 rounded-full flex'
                }`}>
            <textarea
                className={`text-input px-1 py-2 text-black text-md w-full resize-none border-transparent focus:border-transparent focus:ring-0 focus:outline-none text-justify`}
                id="chatbot-input-bar"
                name="searchbox"
                type="text"
                maxLength="2000"
                autoCapitalize="off"
                autoComplete="off"
                aria-autocomplete="both"
                spellCheck="false"
                autoCorrect="off"
                disabled={isBotResponding}
                placeholder={'Type your message...'}
                value={inputValue}
                onChange={(e) => {
                    if (!inputValue.length && !e.target.value.trim().length) return
                    setInputValue(e.target.value)
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSendMessage(e.target.value)
                    }
                }}
                style={{
                    maxHeight: '150px',
                    height: 'auto',
                    minHeight: '25px',
                    lineHeight: '1.5',
                    resize: 'none'
                }}
                rows={rows}
            />
            <svg xmlns="http://www.w3.org/2000/svg" onClick={handleSendMessage} className={`z-10 ${isBotResponding ? '' : 'cursor-pointer'} ${inputValue.length > 0 ? 'hidden' : 'mt-2.5'}`} height={20} width={20} viewBox="0 0 2048 2048">
                <path fill="#615AA7" d="M64 1920q-28 0-46-18t-18-47q0-7 2-17l189-658q5-17 19-30t32-16l878-139q14-2 22-11t8-24q0-14-8-23t-22-12L242 786q-18-3-32-16t-19-30L2 82Q0 72 0 65q0-28 18-46T64 0q15 0 27 6l1920 896q17 8 27 23t10 35q0 19-10 34t-27 24L91 1914q-12 6-27 6z"></path>
            </svg>
            <div className={`absolute bottom-2 left-1 w-full px-4 ${inputValue.length > 0 ? '' : 'hidden'} `} >
                <svg xmlns="http://www.w3.org/2000/svg" onClick={handleSendMessage} className='z-10 cursor-pointer ml-auto' height={20} width={20} viewBox="0 0 2048 2048">
                    <path fill="#615AA7" d="M64 1920q-28 0-46-18t-18-47q0-7 2-17l189-658q5-17 19-30t32-16l878-139q14-2 22-11t8-24q0-14-8-23t-22-12L242 786q-18-3-32-16t-19-30L2 82Q0 72 0 65q0-28 18-46T64 0q15 0 27 6l1920 896q17 8 27 23t10 35q0 19-10 34t-27 24L91 1914q-12 6-27 6z"></path>
                </svg>
            </div>

        </div>
    )
}

export default ChatInput