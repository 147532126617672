import React from 'react'

const Stepper = ({ steps, activeStep, hoverStep, lastStepDisabled, currentCollaborator, handleStepChange }) => {
    return (
        <>
            <div className="bg-[white] border-b border-[#E4E4E7]">
                <div className="mx-4 p-4">
                    <div className="flex items-center">
                        {steps.map((step, index) => {
                            if (index < activeStep) {
                                if (activeStep === steps.length) {
                                    return (
                                        <>
                                            <div onClick={() => handleStepChange(step)} className='flex items-center hover:cursor-pointer'>
                                                <div style={{ border: "2px solid #3F83F8" }} className="mr-1 bg-[#FFFFFF] rounded-full h-8 w-8 py-3 flex items-center justify-center text-xs text-[#A1A1AA] font-normal">
                                                    {index === 0 ?
                                                        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.4375 6.9375L5.8125 11.3125L14.5625 1.9375" stroke="#3F83F8" strokeWidth="2.34375" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        :
                                                        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.4375 6.9375L5.8125 11.3125L14.5625 1.9375" stroke="#3F83F8" strokeWidth="2.34375" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    }
                                                </div>
                                                <span className='hidden 2xl:block text-xs font-[400] text-[#52525B] hover:text-[#3F83F8]'>
                                                    {step}
                                                </span>
                                            </div>
                                            <div className="mx-2 flex-auto border-t-2 border-[#3F83F8]" />
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            <div onClick={() => handleStepChange(step)} className='group/stepperNextStates flex items-center hover:cursor-pointer'>
                                                <div className={`border ${hoverStep === index ? 'border-[#3F83F8] text-[#3F83F8]' : 'border-[#D4D4D8] text-[#A1A1AA]'} group-hover/stepperNextStates:border-[#3F83F8] group-hover/stepperNextStates:text-[#3F83F8] mr-1 bg-[#FFFFFF] rounded-full h-8 w-8 py-3 flex items-center justify-center text-xs font-normal`}>{index === 0 ? `` : '§' + index}</div>
                                                <span className={`hidden 2xl:block text-xs font-[400] ${hoverStep === index ? 'text-[#3F83F8]' : 'text-[#52525B]'} group-hover/stepperNextStates:text-[#3F83F8]`}>{step}</span>
                                            </div>
                                            <div className="mx-2 flex-auto border-t-2 border-[#E4E4E7]" />
                                        </>
                                    )
                                }
                            }
                        })}

                        <div onClick={() => handleStepChange(steps[activeStep])} className="flex items-center relative hover:cursor-pointer">
                            <div className='flex items-center'>
                                <div style={{ border: "2px solid #3F83F8" }} className="flex items-center justify-center mr-1 rounded-full h-8 w-8">
                                    {
                                        activeStep === steps.length ?
                                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.5 0.5C1.10218 0.5 0.720644 0.658035 0.43934 0.93934C0.158035 1.22064 0 1.60218 0 2L0 8.0075C0.00198264 8.40402 0.160893 8.78363 0.441983 9.06331C0.723073 9.343 1.10347 9.5 1.5 9.5H5.625C5.72446 9.5 5.81984 9.46049 5.89016 9.39017C5.96049 9.31984 6 9.22446 6 9.125C6 9.02554 5.96049 8.93016 5.89016 8.85983C5.81984 8.78951 5.72446 8.75 5.625 8.75H1.5C1.33491 8.75002 1.17442 8.69557 1.04343 8.59509C0.912437 8.4946 0.818259 8.35371 0.7755 8.19425L5.0055 5.591L6 6.18725L11.25 3.03725V5.375C11.25 5.47446 11.2895 5.56984 11.3598 5.64017C11.4302 5.71049 11.5255 5.75 11.625 5.75C11.7245 5.75 11.8198 5.71049 11.8902 5.64017C11.9605 5.56984 12 5.47446 12 5.375V2C12 1.60218 11.842 1.22064 11.5607 0.93934C11.2794 0.658035 10.8978 0.5 10.5 0.5H1.5ZM4.281 5.156L0.75 7.32875V3.03725L4.281 5.156ZM0.75 2.16275V2C0.75 1.80109 0.829018 1.61032 0.96967 1.46967C1.11032 1.32902 1.30109 1.25 1.5 1.25H10.5C10.6989 1.25 10.8897 1.32902 11.0303 1.46967C11.171 1.61032 11.25 1.80109 11.25 2V2.16275L6 5.31275L0.75 2.16275Z" fill="#3F83F8" />
                                                <path d="M12 8.375C12 9.07119 11.7234 9.73887 11.2312 10.2312C10.7389 10.7234 10.0712 11 9.375 11C8.67881 11 8.01113 10.7234 7.51884 10.2312C7.02656 9.73887 6.75 9.07119 6.75 8.375C6.75 7.67881 7.02656 7.01113 7.51884 6.51884C8.01113 6.02656 8.67881 5.75 9.375 5.75C10.0712 5.75 10.7389 6.02656 11.2312 6.51884C11.7234 7.01113 12 7.67881 12 8.375ZM10.5052 7.11575C10.463 7.09043 10.4162 7.07368 10.3674 7.06646C10.3187 7.05924 10.2691 7.06169 10.2213 7.07367C10.1735 7.08565 10.1285 7.10692 10.089 7.13628C10.0494 7.16563 10.0161 7.20249 9.99075 7.24475L9.11325 8.70725L8.703 8.297C8.63259 8.22658 8.53708 8.18703 8.4375 8.18703C8.33792 8.18703 8.24241 8.22658 8.172 8.297C8.10158 8.36741 8.06203 8.46292 8.06203 8.5625C8.06203 8.66208 8.10158 8.75759 8.172 8.828L8.7525 9.40775C8.81292 9.46829 8.88635 9.51425 8.96721 9.54214C9.04807 9.57003 9.13422 9.57912 9.21912 9.56871C9.30401 9.55829 9.38541 9.52866 9.45713 9.48205C9.52885 9.43544 9.589 9.3731 9.633 9.29975L10.6342 7.63025C10.6596 7.588 10.6763 7.54117 10.6835 7.49245C10.6908 7.44372 10.6883 7.39405 10.6763 7.34628C10.6644 7.2985 10.6431 7.25355 10.6137 7.21399C10.5844 7.17444 10.5475 7.14106 10.5052 7.11575Z" fill="#3F83F8" />
                                            </svg>
                                            :
                                            <div className='flex items-center justify-center h-6 w-6 rounded-full bg-[#3F83F8] text-xs font-bold text-[#FAFAFA]'>
                                                {activeStep !== 0 ? '§' + activeStep : null}
                                            </div>
                                    }
                                </div>
                                <span className={`text-xs font-[500] ${hoverStep === activeStep ? 'text-[#3F83F8]' : 'text-[#52525B]'} hover:text-[#3F83F8]`}>{steps[activeStep]}</span>
                            </div>
                        </div>
                        {
                            activeStep < steps.length ?
                                <div className="mx-2 flex-auto border-t-2 border-[#E4E4E7]" />
                                :
                                null
                        }

                        {steps.map(
                            (step, index) => {
                                if (activeStep + index + 1 < steps.length) {
                                    return (
                                        <>
                                            <div onClick={() => handleStepChange(steps[index + activeStep + 1])} className='group/stepperNextStates flex items-center hover:cursor-pointer'>
                                                <div className={`border ${hoverStep === index + activeStep + 1 ? 'border-[#3F83F8] text-[#3F83F8]' : 'border-[#D4D4D8] text-[#A1A1AA]'} group-hover/stepperNextStates:border-[#3F83F8] group-hover/stepperNextStates:text-[#3F83F8] mr-1 bg-[#FFFFFF] rounded-full h-8 w-8 py-3 flex items-center justify-center text-xs font-normal`}>§{activeStep + index + 1}</div>
                                                <span className={`hidden 2xl:block text-xs font-[400] ${hoverStep === index + activeStep + 1 ? 'text-[#3F83F8]' : 'text-[#52525B]'} group-hover/stepperNextStates:text-[#3F83F8]`}>{steps[index + activeStep + 1]}</span>
                                            </div>
                                            <div className="mx-2 flex-auto border-t-2 border-[#E4E4E7]" />
                                        </>
                                    )
                                }
                            }
                        )}
                        {
                            activeStep < steps.length ?
                                lastStepDisabled || currentCollaborator.role === "viewer" ?
                                    <div className='relative group hover:cursor-pointer flex items-center hover:cursor-pointer'>
                                        <span className="text-center z-50 absolute top-[35px] right-[0px] w-80 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100">
                                            {currentCollaborator.role === "viewer" ?
                                                "You are reviewing this contract as a viewer. Any changes you make will not be saved or shared." :
                                                "This contract has been sent to your counterparty. Any changes you make now will not be saved or shared."}
                                        </span>
                                        <div className="border border-[#D4D4D8] hover:border-[#3F83F8] mr-1 bg-[#FFFFFF] rounded-full h-8 w-8 py-3 flex items-center justify-center text-xs text-[#A1A1AA] font-normal">
                                            <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.5 0.5C1.10218 0.5 0.720644 0.658035 0.43934 0.93934C0.158035 1.22064 0 1.60218 0 2L0 8.0075C0.00198264 8.40402 0.160893 8.78363 0.441983 9.06331C0.723073 9.343 1.10347 9.5 1.5 9.5H5.625C5.72446 9.5 5.81984 9.46049 5.89016 9.39017C5.96049 9.31984 6 9.22446 6 9.125C6 9.02554 5.96049 8.93016 5.89016 8.85983C5.81984 8.78951 5.72446 8.75 5.625 8.75H1.5C1.33491 8.75002 1.17442 8.69557 1.04343 8.59509C0.912437 8.4946 0.818259 8.35371 0.7755 8.19425L5.0055 5.591L6 6.18725L11.25 3.03725V5.375C11.25 5.47446 11.2895 5.56984 11.3598 5.64017C11.4302 5.71049 11.5255 5.75 11.625 5.75C11.7245 5.75 11.8198 5.71049 11.8902 5.64017C11.9605 5.56984 12 5.47446 12 5.375V2C12 1.60218 11.842 1.22064 11.5607 0.93934C11.2794 0.658035 10.8978 0.5 10.5 0.5H1.5ZM4.281 5.156L0.75 7.32875V3.03725L4.281 5.156ZM0.75 2.16275V2C0.75 1.80109 0.829018 1.61032 0.96967 1.46967C1.11032 1.32902 1.30109 1.25 1.5 1.25H10.5C10.6989 1.25 10.8897 1.32902 11.0303 1.46967C11.171 1.61032 11.25 1.80109 11.25 2V2.16275L6 5.31275L0.75 2.16275Z" fill="#A1A1AA" />
                                            </svg>
                                            <svg className='absolute mt-[3px] ml-[4px]' width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 3.375C6 4.07119 5.72344 4.73887 5.23116 5.23116C4.73887 5.72344 4.07119 6 3.375 6C2.67881 6 2.01113 5.72344 1.51884 5.23116C1.02656 4.73887 0.75 4.07119 0.75 3.375C0.75 2.67881 1.02656 2.01113 1.51884 1.51884C2.01113 1.02656 2.67881 0.75 3.375 0.75C4.07119 0.75 4.73887 1.02656 5.23116 1.51884C5.72344 2.01113 6 2.67881 6 3.375ZM4.50525 2.11575C4.463 2.09043 4.41617 2.07368 4.36745 2.06646C4.31872 2.05924 4.26905 2.06169 4.22128 2.07367C4.1735 2.08565 4.12855 2.10692 4.08899 2.13628C4.04944 2.16563 4.01606 2.20249 3.99075 2.24475L3.11325 3.70725L2.703 3.297C2.63259 3.22658 2.53708 3.18703 2.4375 3.18703C2.33792 3.18703 2.24241 3.22658 2.172 3.297C2.10158 3.36741 2.06203 3.46292 2.06203 3.5625C2.06203 3.66208 2.10158 3.75759 2.172 3.828L2.7525 4.40775C2.81292 4.46829 2.88635 4.51425 2.96721 4.54214C3.04807 4.57003 3.13422 4.57912 3.21912 4.56871C3.30401 4.55829 3.38541 4.52866 3.45713 4.48205C3.52885 4.43544 3.589 4.3731 3.633 4.29975L4.63425 2.63025C4.65957 2.588 4.67632 2.54117 4.68354 2.49245C4.69076 2.44372 4.68831 2.39405 4.67633 2.34628C4.66435 2.2985 4.64308 2.25355 4.61372 2.21399C4.58437 2.17444 4.54751 2.14106 4.50525 2.11575Z" fill="#A1A1AA" />
                                            </svg>
                                        </div>
                                    </div>
                                    :
                                    <div onClick={() => handleStepChange('', steps.length)} className='flex items-center hover:cursor-pointer'>
                                        <div className="border border-[#D4D4D8] hover:border-[#3F83F8] mr-1 bg-[#FFFFFF] rounded-full h-8 w-8 py-3 flex items-center justify-center text-xs text-[#A1A1AA] font-normal">
                                            <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.5 0.5C1.10218 0.5 0.720644 0.658035 0.43934 0.93934C0.158035 1.22064 0 1.60218 0 2L0 8.0075C0.00198264 8.40402 0.160893 8.78363 0.441983 9.06331C0.723073 9.343 1.10347 9.5 1.5 9.5H5.625C5.72446 9.5 5.81984 9.46049 5.89016 9.39017C5.96049 9.31984 6 9.22446 6 9.125C6 9.02554 5.96049 8.93016 5.89016 8.85983C5.81984 8.78951 5.72446 8.75 5.625 8.75H1.5C1.33491 8.75002 1.17442 8.69557 1.04343 8.59509C0.912437 8.4946 0.818259 8.35371 0.7755 8.19425L5.0055 5.591L6 6.18725L11.25 3.03725V5.375C11.25 5.47446 11.2895 5.56984 11.3598 5.64017C11.4302 5.71049 11.5255 5.75 11.625 5.75C11.7245 5.75 11.8198 5.71049 11.8902 5.64017C11.9605 5.56984 12 5.47446 12 5.375V2C12 1.60218 11.842 1.22064 11.5607 0.93934C11.2794 0.658035 10.8978 0.5 10.5 0.5H1.5ZM4.281 5.156L0.75 7.32875V3.03725L4.281 5.156ZM0.75 2.16275V2C0.75 1.80109 0.829018 1.61032 0.96967 1.46967C1.11032 1.32902 1.30109 1.25 1.5 1.25H10.5C10.6989 1.25 10.8897 1.32902 11.0303 1.46967C11.171 1.61032 11.25 1.80109 11.25 2V2.16275L6 5.31275L0.75 2.16275Z" fill="#A1A1AA" />
                                            </svg>
                                            <svg className='absolute mt-[3px] ml-[4px]' width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 3.375C6 4.07119 5.72344 4.73887 5.23116 5.23116C4.73887 5.72344 4.07119 6 3.375 6C2.67881 6 2.01113 5.72344 1.51884 5.23116C1.02656 4.73887 0.75 4.07119 0.75 3.375C0.75 2.67881 1.02656 2.01113 1.51884 1.51884C2.01113 1.02656 2.67881 0.75 3.375 0.75C4.07119 0.75 4.73887 1.02656 5.23116 1.51884C5.72344 2.01113 6 2.67881 6 3.375ZM4.50525 2.11575C4.463 2.09043 4.41617 2.07368 4.36745 2.06646C4.31872 2.05924 4.26905 2.06169 4.22128 2.07367C4.1735 2.08565 4.12855 2.10692 4.08899 2.13628C4.04944 2.16563 4.01606 2.20249 3.99075 2.24475L3.11325 3.70725L2.703 3.297C2.63259 3.22658 2.53708 3.18703 2.4375 3.18703C2.33792 3.18703 2.24241 3.22658 2.172 3.297C2.10158 3.36741 2.06203 3.46292 2.06203 3.5625C2.06203 3.66208 2.10158 3.75759 2.172 3.828L2.7525 4.40775C2.81292 4.46829 2.88635 4.51425 2.96721 4.54214C3.04807 4.57003 3.13422 4.57912 3.21912 4.56871C3.30401 4.55829 3.38541 4.52866 3.45713 4.48205C3.52885 4.43544 3.589 4.3731 3.633 4.29975L4.63425 2.63025C4.65957 2.588 4.67632 2.54117 4.68354 2.49245C4.69076 2.44372 4.68831 2.39405 4.67633 2.34628C4.66435 2.2985 4.64308 2.25355 4.61372 2.21399C4.58437 2.17444 4.54751 2.14106 4.50525 2.11575Z" fill="#A1A1AA" />
                                            </svg>
                                        </div>
                                    </div>
                                :
                                null
                        }

                    </div>
                </div>
            </div>

        </>
    )
}

export default Stepper