// Children + Arrow + Disabled

import React from 'react'
import { useSelector } from "react-redux";
import { triggerSubsectionClick } from "./utils/triggerSubsectionClick"

function Input({ obj, dispatch_handler, disabled, collapsibleHandler }) {
    const ans = useSelector((state) => state.contract.ans);

    const [placeholderValue, setPlaceholderValue] = React.useState('');
    const [value, setValue] = React.useState('');

    React.useEffect(() => {
        if (ans[obj.var_name]) {
            setValue(ans[obj.var_name].value);
        }
    }, [ans])

    function handleFocus() {
        dispatch_handler([{ type: 'CHANGE_TOGGLE' }])
        if (value.startsWith('[') && value.endsWith(']')) {
            setPlaceholderValue(value);
            setValue('');
        }
    }

    function handleBlur() {
        if (value.trim() === '') {
            setValue(placeholderValue);
            let t = {}
            t[obj.var_name] = {
                "id": obj.id,
                "value": placeholderValue
            }
            dispatch_handler([{ "payload": t, "type": "ADD" }]);
        }
        else {
            setValue(value);
        }
    }
    return (
        <>
            {
                disabled ?
                    <>
                        <label className=" text-[#71717A] text-[12px] font-[400]">{obj.label}:</label>{' '}
                        <div className="relative group hover:cursor-pointer w-full">
                            <span className="absolute top-[-15px] w-40 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100">Selection unavailable</span>
                            <input id={obj.id} type="text"
                                value={value}
                                className="w-full max-w-xs h-[30px] border-[1px] border-[#71717A] rounded-[5px] text-[#71717A] text-[12px] font-[400]"
                                disabled />
                        </div>
                    </>
                    :
                    <>
                        <label className=" text-[#71717A] text-[12px] font-[400]">{obj.label}:</label>{' '}
                        <input id={obj.id} type="text"
                            value={value}
                            className="w-full max-w-xs h-[30px] border-[1px] border-[#71717A] rounded-[5px] text-[#71717A] text-[12px] font-[400]"
                            onFocus={() => { handleFocus(); triggerSubsectionClick(obj.id) }}
                            onBlur={handleBlur}
                            onChange={(e) => {
                                let t = {}
                                t[obj.var_name] = {
                                    "id": obj.id,
                                    "value": e.target.value.replace(/{/g, '').replace(/}/g, '')
                                }
                                dispatch_handler([{ "payload": t, "type": "ADD" }]);
                                triggerSubsectionClick(obj.id)
                            }} />
                    </>
            }
        </>
    )
}

export default Input