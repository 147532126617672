import PreSigninNavbar from "../components/PreSigninNavbar.js";

const Contract = () => {
    return (
        <>
            <PreSigninNavbar />
            <div className="mt-8 text-5xl font-bold text-center text-[#111928]">Create contracts the <span className="italic">Standard</span> way</div>
            <div className="text-lg font-normal text-[#6B7280] text-center mb-7">Standard Draft allows you to create contracts quickly & easily.</div>
            <nav className="flex justify-center align-center" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-16">
                    {["NDAs", "M&A", "Finance", "Employment", "IP", "️Others"].map((item, index) => {
                        return (
                            <li>
                                <span className="text-base font-medium text-[#52525B] cursor-pointer hover:text-gray-800">{item}</span>
                                {index === 0 ? <hr color="#1C64F2" /> : null}
                            </li>
                        )
                    })}
                </ol>
            </nav>
            <hr color="#E5E7EB" className="mb-7" style={{ width: "35rem", marginLeft: "auto", marginRight: "auto" }} />
            <div className="flex mx-11 mb-7">
                <div className="bg-[#FFFFFF] mr-5 basis-1/4" style={{
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px"
                }}>
                    <div className="p-4 pb-3" style={{ minHeight: "94%" }}>
                        <div className="text-2xl font-semibold text-[#6B7280] text-center mb-2">Unilateral NDA</div>
                        <div className="flex justify-center">
                            <img src="Images/Contract/Contract.png" />
                        </div>
                        <p className="text-lg font-normal text-[#6B7280] mb-8">One party agrees to confidentiality obligations. Generally appropriate if only one party is expected to disclose confidential information.</p>
                        <div className="flex">
                            <div className="flex justify-center items-center mr-1">
                                <img src="Images/Contract/Certified.png" className="mr-2" />
                            </div>
                            <p className="text-sm font-normal">Trusted by <span className="italic">leading law firms, companies & private equity firms</span></p>
                        </div>
                    </div>
                    <button style={{ borderBottomLeftRadius: "12px", borderBottomRightRadius: "12px" }} className="bg-[#1C64F2] text-white text-sm font-semibold w-full py-3 hover:bg-blue-700">SELECT</button>
                </div>
                <div className="bg-[#FFFFFF] mr-5 basis-1/4" style={{
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px"
                }}>
                    <div className="p-4 pb-3" style={{ minHeight: "94%" }}>
                        <div className="text-2xl font-semibold text-[#6B7280] text-center mb-2">Mutual NDA</div>
                        <div className="flex justify-center">
                            <img src="Images/Contract/Contract.png" />
                        </div>
                        <p className="text-lg font-normal text-[#6B7280] mb-8">Both parties agree to confidentiality obligations. Generally appropriate if both parties expect to disclose confidential information. Also known as a “Bilateral NDA.” </p>
                        <div className="flex">
                            <div className="flex justify-center items-center mr-1">
                                <img src="Images/Contract/Certified.png" className="mr-2" />
                            </div>
                            <p className="text-sm font-normal">Trusted by <span className="italic">leading law firms, companies & private equity firms</span></p>
                        </div>
                    </div>
                    <button style={{ borderBottomLeftRadius: "12px", borderBottomRightRadius: "12px" }} className="bg-[#1C64F2] text-white text-sm font-semibold w-full py-3 hover:bg-blue-700">SELECT</button>
                </div>
                <div className="bg-[#FFFFFF] mr-5 basis-1/4" style={{
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.64)"
                }}>
                    <div className="p-4 pb-3" style={{ minHeight: "94%" }}>
                        <div className="text-2xl font-semibold text-[#374151] text-center mb-2">Multilateral NDA</div>
                        <div className="flex justify-center" style={{
                            backgroundImage: "url(Images/Contract/Contract.png)",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            height: "240px",
                            width: "240px"
                        }}>
                            <div style={{
                                backdropFilter: "blur(1.5px)",
                                backgroundColor: "rgba(0, 0, 0, 0.64)",
                                height: "240px",
                                width: "240px",
                                display: "flex",
                                flexFlow: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <svg className="mt-[125px]" width="41" height="50" viewBox="0 0 41 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.5 0C13.6075 0 8 5.6075 8 12.5V20H5.5C4.17392 20 2.90215 20.5268 1.96447 21.4645C1.02678 22.4021 0.5 23.6739 0.5 25V45C0.5 46.3261 1.02678 47.5978 1.96447 48.5355C2.90215 49.4732 4.17392 50 5.5 50H35.5C36.8261 50 38.0979 49.4732 39.0355 48.5355C39.9732 47.5978 40.5 46.3261 40.5 45V25C40.5 23.6739 39.9732 22.4021 39.0355 21.4645C38.0979 20.5268 36.8261 20 35.5 20H33V12.5C33 5.6075 27.3925 0 20.5 0ZM13 12.5C13 8.365 16.365 5 20.5 5C24.635 5 28 8.365 28 12.5V20H13V12.5ZM23 39.3075V45H18V39.3075C17.126 38.807 16.4241 38.0533 15.9871 37.1459C15.55 36.2385 15.3982 35.2199 15.5517 34.2244C15.7052 33.229 16.1568 32.3034 16.8469 31.5698C17.537 30.8362 18.4333 30.329 19.4175 30.115C20.1485 29.9534 20.9065 29.9579 21.6356 30.1282C22.3647 30.2985 23.0462 30.6304 23.63 31.0992C24.2137 31.568 24.6848 32.1619 25.0084 32.837C25.3321 33.5121 25.5001 34.2513 25.5 35C25.4986 35.8744 25.2667 36.7329 24.8278 37.4891C24.3889 38.2453 23.7585 38.8725 23 39.3075Z" fill="#FAFAFA" />
                                </svg>
                                <div className="text-sm font-semibold text-[#FAFAFA] mt-[30px]">COMING SOON</div>
                            </div>
                        </div>
                        <p className="text-lg font-normal text-[#6B7280]">Multiple parties agree to confidentiality obligations</p>
                    </div>
                    <button style={{ borderBottomLeftRadius: "12px", borderBottomRightRadius: "12px" }} className="bg-[#D4D4D8] text-white text-sm font-semibold w-full py-3">COMING SOON</button>
                </div>
                <div className="bg-[#FFFFFF] mr-5 basis-1/4" style={{
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.64)"
                }}>
                    <div className="p-4 pb-3" style={{ minHeight: "94%" }}>
                        <div className="text-2xl font-semibold text-[#374151] text-center mb-2">Joinder</div>
                        <div className="flex justify-center" style={{
                            backgroundImage: "url(Images/Contract/Contract.png)",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            height: "240px",
                            width: "240px"
                        }}>
                            <div style={{
                                backdropFilter: "blur(1.5px)",
                                backgroundColor: "rgba(0, 0, 0, 0.64)",
                                height: "240px",
                                width: "240px",
                                display: "flex",
                                flexFlow: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <svg className="mt-[125px]" width="41" height="50" viewBox="0 0 41 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.5 0C13.6075 0 8 5.6075 8 12.5V20H5.5C4.17392 20 2.90215 20.5268 1.96447 21.4645C1.02678 22.4021 0.5 23.6739 0.5 25V45C0.5 46.3261 1.02678 47.5978 1.96447 48.5355C2.90215 49.4732 4.17392 50 5.5 50H35.5C36.8261 50 38.0979 49.4732 39.0355 48.5355C39.9732 47.5978 40.5 46.3261 40.5 45V25C40.5 23.6739 39.9732 22.4021 39.0355 21.4645C38.0979 20.5268 36.8261 20 35.5 20H33V12.5C33 5.6075 27.3925 0 20.5 0ZM13 12.5C13 8.365 16.365 5 20.5 5C24.635 5 28 8.365 28 12.5V20H13V12.5ZM23 39.3075V45H18V39.3075C17.126 38.807 16.4241 38.0533 15.9871 37.1459C15.55 36.2385 15.3982 35.2199 15.5517 34.2244C15.7052 33.229 16.1568 32.3034 16.8469 31.5698C17.537 30.8362 18.4333 30.329 19.4175 30.115C20.1485 29.9534 20.9065 29.9579 21.6356 30.1282C22.3647 30.2985 23.0462 30.6304 23.63 31.0992C24.2137 31.568 24.6848 32.1619 25.0084 32.837C25.3321 33.5121 25.5001 34.2513 25.5 35C25.4986 35.8744 25.2667 36.7329 24.8278 37.4891C24.3889 38.2453 23.7585 38.8725 23 39.3075Z" fill="#FAFAFA" />
                                </svg>
                                <div className="text-sm font-semibold text-[#FAFAFA] mt-[30px]">COMING SOON</div>
                            </div>
                        </div>
                        <p className="text-lg font-normal text-[#6B7280]">Allows a new party to be added to the original NDA</p>
                    </div>
                    <button style={{ borderBottomLeftRadius: "12px", borderBottomRightRadius: "12px" }} className="bg-[#D4D4D8] text-white text-sm font-semibold w-full py-3">COMING SOON</button>
                </div>
            </div>
            <div className="text-center text-lg font-normal text-[#6B7280] mb-20">more coming soon</div>
        </>
    )
}

export default Contract;