import React from "react";

export default function Introduction(props) {
  const [active, setActive] = React.useState(0);

  React.useEffect(() => {
    setActive(props.active ? props.active : 0);
  }, []);

  return (
    <div
      tabIndex={-1}
      className="flex justify-center items-center fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full overflow-auto"
    >
      <div
        className=" pb-6 w-full max-w-4xl bg-[#FFFFFF] rounded-[10px] max-h-[700px] overflow-y-auto relative"
        style={{
          boxShadow:
            "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <section className="text-gray-600 body-font  max-h-[700px]">
          <div className="flex item-center justify-between py-5 border-b-[1px] border-gray-200 text-lg">
            <div className="font-bold pl-12">Welcome to Standard Draft!</div>
            <div className="flex mx-auto pr-28">
              <p
                className={`mr-2 cursor-pointer ${
                  active === 0 ? "text-blue-600" : "text-gray-400"
                }`}
                onClick={() => setActive(0)}
              >
                General Instructions
              </p>
              <p className="mr-2">/</p>
              <p
                className={`mr-2 cursor-pointer ${
                  active === 1 ? "text-blue-600" : "text-gray-400"
                }`}
                onClick={() => setActive(1)}
              >
                Contract Composer
              </p>
              <p className="mr-2">/</p>
              <p
                className={`cursor-pointer ${
                  active === 2 ? "text-blue-600" : "text-gray-400"
                }`}
                onClick={() => setActive(2)}
              >
                NDA
              </p>
            </div>
            <svg
              onClick={() => {
                props.setInstructionsModal(false);
              }}
              className="mt-2 mr-12 cursor-pointer"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.292787 0.793081C0.480314 0.60561 0.734622 0.500295 0.999786 0.500295C1.26495 0.500295 1.51926 0.60561 1.70679 0.793081L5.99979 5.08608L10.2928 0.793081C10.385 0.697571 10.4954 0.621389 10.6174 0.56898C10.7394 0.516571 10.8706 0.488985 11.0034 0.487831C11.1362 0.486677 11.2678 0.511978 11.3907 0.562259C11.5136 0.61254 11.6253 0.686793 11.7192 0.780686C11.8131 0.874579 11.8873 0.986231 11.9376 1.10913C11.9879 1.23202 12.0132 1.3637 12.012 1.49648C12.0109 1.62926 11.9833 1.76048 11.9309 1.88249C11.8785 2.00449 11.8023 2.11483 11.7068 2.20708L7.41379 6.50008L11.7068 10.7931C11.8889 10.9817 11.9897 11.2343 11.9875 11.4965C11.9852 11.7587 11.88 12.0095 11.6946 12.1949C11.5092 12.3803 11.2584 12.4855 10.9962 12.4878C10.734 12.49 10.4814 12.3892 10.2928 12.2071L5.99979 7.91408L1.70679 12.2071C1.51818 12.3892 1.26558 12.49 1.00339 12.4878C0.741188 12.4855 0.490376 12.3803 0.304968 12.1949C0.11956 12.0095 0.0143906 11.7587 0.0121121 11.4965C0.00983372 11.2343 0.110629 10.9817 0.292787 10.7931L4.58579 6.50008L0.292787 2.20708C0.105316 2.01955 0 1.76525 0 1.50008C0 1.23492 0.105316 0.980609 0.292787 0.793081Z"
                fill="#111928"
              />
            </svg>
          </div>
          <div className="container max-h-[450px] px-12 mx-auto overflow-scroll">
            <div className="flex justify-center mx-auto my-5">
              {active == 0 ? (
                <iframe
                  className="rounded-lg shadow-lg"
                  src="https://www.loom.com/embed/3fe7f040cef34ccdbc50f854897e49af"
                  width="600px"
                  height="400px"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              ) : active == 1 ? (
                <iframe
                  className="rounded-lg shadow-lg"
                  src="https://www.loom.com/embed/8b365a8ad5a9423daa87e5aa0a6227a9"
                  width="600px"
                  height="400px"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              ) : (
                <iframe
                  className="rounded-lg shadow-lg"
                  src="https://www.loom.com/embed/e4cb618ea3fa41fca0dd22a053ff1981"
                  width="600px"
                  height="400px"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              )}
            </div>
            <div className="mx-3 my-3 text-justify">
              {active == 0 ? (
                <>
                  <p className="mb-3">
                    <b>
                      <i>What is Standard Draft? What am I able to do?</i>
                    </b>
                  </p>
                  <p className="ml-5">
                    <p className="mb-3">
                      Standard Draft accelerates the process of reaching
                      agreement. Our solution combines (1) worldclass, highly
                      configurable contract forms and (2) a two-sided platform
                      for seamless drafting and negotiating.
                    </p>
                    <p className="mb-3">
                      As a private beta user, you are able to draft, negotiate,
                      and sign NDAs in a two-sided workflow with your
                      counterparty.
                    </p>
                    <p className="mb-3">
                      <b>
                        We depend on your feedback to help shape our platform.
                      </b>{" "}
                      Please send all feedback (in whatever format is most
                      convenient) to{" "}
                      <span className="text-blue-600">
                        beta@standarddraft.com
                      </span>{" "}
                      so we can directly respond to you.
                    </p>
                    <p className="mb-3">
                      This iteration of Standard Draft is just the tip of the
                      iceberg – we look forward to working with our early users
                      as we expand our contract library and build a platform
                      that transforms the contract process.
                    </p>
                  </p>
                  <p className="mb-3">
                    <b>
                      <i>How does Standard Draft work?</i>
                    </b>
                  </p>
                  <p className="ml-5">
                    <p className="mb-3">
                      There are three key stages in the Standard Draft workflow:
                      Drafting, Negotiating, Signing.
                    </p>
                    <p className="mb-3">
                      <b>Drafting | Phase 1</b> – The process kicks off when a
                      user creates a contract from the dashboard. This user (who
                      we refer to as the <b>Proposer</b>) then accesses the{" "}
                      <b>Contract Composer</b>, where he or she reviews the
                      contract and makes any necessary configurations. Once
                      satisfied, the Proposer sends the contract to his or her
                      counterparty (the <b>Responder</b>).
                    </p>
                    <p className="mb-3">
                      <b>Negotiating | Stage 2</b> – The <b>Responder</b> now
                      accesses the <b>Contract Composer</b>, where he or she
                      reviews <b>Proposer</b>’s initial draft (including the
                      comparison of that draft against the baseline Standard
                      Draft form). The Responder makes any necessary
                      configurations and then sends the contract back to the
                      Proposer. The Proposer and Responder continue the
                      negotiation process until all contract configurations are
                      agreed upon by both sides.
                    </p>
                    <p className="mb-3">
                      <b>Signing | Stage 3</b> – Once all configurations are
                      agreed upon, the parties sign the contract using Standard
                      Draft’s integration with DocuSign!
                    </p>
                  </p>
                  <p className="mb-3">
                    <b>
                      <i>Great! How do I get started?</i>
                    </b>
                  </p>
                  <p className="ml-5">
                    <p className="mb-3">
                      If you are visiting Standard Draft for the first time,
                      then we encourage you to create a contract by pressing
                      +Create Contract in the dashboard.
                    </p>
                    <p className="mb-3">
                      If you were invited into Standard Draft as a counterparty
                      to a contract workflow (i.e., you are the <b>Responder</b>{" "}
                      of the contract workflow), then you should see the
                      contract name within your dashboard. Please click the
                      contract to review it in the Contract Composer.
                    </p>
                    <p className="mb-3">
                      In either case, we encourage you to review the Contract
                      Composer instructions (at the top of this window) to learn
                      how to use the Contract Composer.
                    </p>
                  </p>
                </>
              ) : active == 1 ? (
                <>
                  <p className="mb-3">
                    <b>
                      <i>What is the Contract Composer?</i>
                    </b>
                  </p>
                  <p className="ml-5">
                    <p className="mb-3">
                      The Contract Composer is where the full Standard Draft
                      process plays out – Drafting, Negotiating, and Signing.
                    </p>
                    <p className="mb-3">
                      There are a few key elements of the Contract Composer:
                    </p>
                    <p className="mb-3">
                      <b>Navigation Bar</b> – This clickable navigator
                      corresponds to the main sections of the contract that you
                      are working on. When you click a section in the navigator,
                      the Preview Panel and the Configurations Panel will direct
                      you to that section.
                    </p>
                    <p className="mb-3">
                      <b>Preview Panel</b> – On the right side of the screen,
                      you will see the full contract. Configurations to the
                      contract will appear as you make them.
                    </p>
                    <p className="mb-3">
                      <b>Configurations Panel</b> – On the left side of the
                      screen, you can make configurations to the contract by
                      clicking among the various options.
                    </p>
                  </p>
                  <p className="mb-3">
                    <b>
                      <i>How does the Preview Panel work?</i>
                    </b>
                  </p>
                  <p className="ml-5">
                    <p className="mb-3">
                      The Preview Panel on the right side of the screen allows
                      you to view various iterations of the contract via the
                      toggle:
                    </p>
                    <p className="mb-3">
                      <b>Clean</b> – A clean version of the contract where all
                      of your configurations appear within the contract.
                    </p>
                    <p className="mb-3">
                      <b>Your Changes</b> – A version of the contract where you
                      can see your side’s current changes to the contract (with
                      additions shown in{" "}
                      <span className="text-blue-700">
                        <u>blue</u>
                      </span>{" "}
                      and deletions shown in{" "}
                      <span className="text-red-500">
                        <s>red</s>
                      </span>
                      ).
                    </p>
                    <p className="mb-3">
                      <b>Counterparty</b> – This toggle does not appear until
                      the Negotiating phase begins. This toggle shows the
                      counterparty’s changes against the prior version of the
                      contract. Lawyers generally refer to this as a redline,
                      blackline, or comparison version.
                    </p>
                  </p>
                  <p className="mb-3">
                    <b>
                      <i>How does the Configurations Panel work?</i>
                    </b>
                  </p>
                  <p className="ml-5">
                    <p className="mb-3">
                      In general, the Configurations Panel is made up of
                      expandable boxes that correspond to each sub-section of
                      the contract. For example, when you are in Section 2 of
                      the contract, you will see boxes corresponding to the
                      Section 2 sub-section (2(a), 2(b), etc.).
                    </p>
                    <p className="mb-3">
                      Once you expand the box, you will see the possible
                      configurations for that sub-section. Nearly all
                      checkboxes, radio buttons, etc. are configurable – feel
                      free to click around to see how contract language is added
                      or removed!
                    </p>
                    <p className="mb-3">
                      You will notice various colors among the configurations:
                    </p>
                    <p className="mb-3">
                      <span className="text-grey-600 font-[600]">Grey</span> –
                      If you see a grey box or button, this means that the
                      selection is part of the Standard Draft default version
                      for your contract or that the selection has not been
                      changed since your prior version of the contract.
                    </p>
                    <p className="mb-3">
                      <span className="text-blue-700 font-[700]">Blue</span> – A
                      blue box or button corresponds to a configuration that you
                      have incorporated into the contract that did not appear in
                      the prior version of the contract.
                    </p>
                    <p className="mb-3">
                      <span className="text-red-500 font-[700]">Red</span> – A
                      red box or button corresponds to a configuration that you
                      have removed from the contract that appeared in the prior
                      version of the contract.
                    </p>
                    <p className="mb-3">
                      <span className="text-purple-700 font-[600]">Purple</span>{" "}
                      – A purple box or button corresponds to a change that your
                      counterparty made.
                    </p>
                  </p>
                  <p className="mb-3">
                    <b>
                      <i>
                        How do I download the contract or send it to others?
                      </i>
                    </b>
                  </p>
                  <p className="ml-5">
                    <p className="mb-3">
                      Once you have reviewed the contract and made any necessary
                      configurations, please press the Finish & Review button.
                    </p>
                    <p className="mb-3">
                      Within the Finish & Review workflow, you will be able to
                      download the contract, send the contract to a collaborator
                      from your side, or send the contract to your counterparty.
                    </p>
                  </p>
                  <p className="mb-3">
                    <b>
                      <i>
                        Are new versions of the contract created during the
                        two-sided workflow?
                      </i>
                    </b>
                  </p>
                  <p className="ml-5">
                    <p className="mb-3">
                      Yes, new versions of the contract are created as part of
                      your contract workflow.
                    </p>
                    <p className="mb-3">
                      The process begins with the Standard Draft default
                      version. This is essentially Version 0 of the contract
                      workflow.
                    </p>
                    <p className="mb-3">
                      During the Drafting phase, the Proposer creates Version 1
                      of the contract by making any necessary configurations and
                      then sends the contract to the Responder.
                    </p>
                    <p className="mb-3">
                      Once the Responder visits the Contract Composer, he or she
                      reviews Version 1 of the contract (along with the
                      comparison of Version 1 against Version 0, the Standard
                      Draft default version).
                    </p>
                    <p className="mb-3">
                      The Responder then creates Version 2 of the contract and
                      sends it to the Proposer.
                    </p>
                    <p className="mb-3">
                      As you can likely tell, each time one side of the contract
                      workflow sends the contract to the other side, a new
                      version of the contract is created. This continues until
                      the parties are ready to sign the contract.
                    </p>
                    <p className="mb-3">
                      During this process, users can access the version history
                      of the contract by pressing the “Last version” link above
                      the navigation bar or the “Section History” link within
                      individual sections.
                    </p>
                  </p>
                  <p className="mb-3">
                    <b>
                      <i>Am I able to include custom text in the contract?</i>
                    </b>
                  </p>
                  <p className="ml-5">
                    <p className="mb-3">
                      Yes, our platform accommodates custom text modifications.
                    </p>
                    <p className="mb-3">
                      We encourage you to review the configurable options for
                      each sub-section before adding custom text. Your contract
                      drafting preference may already be available via a
                      configurable option.
                    </p>
                    <p className="mb-3">
                      If you still need to include custom text, you may do so by
                      clicking “Convert to Custom Text” at the bottom right of
                      the sub-section box or by clicking “+Add custom
                      sub-section”.
                    </p>
                    <p className="mb-3">
                      Your custom text will be flagged to your counterparty so
                      the text can be accepted or rejected.
                    </p>
                  </p>
                </>
              ) : (
                <>
                  <p className="mb-3">
                    Please watch the video above to learn more about the
                    Standard Draft NDA.
                  </p>
                  <p className="mb-3">
                    We are continuing to refine the Standard Draft NDA. If you
                    have any feedback or comments, please reach out to us at
                    <span className="text-blue-600">
                      {" "}
                      beta@standarddraft.com
                    </span>
                    .
                  </p>
                </>
              )}
            </div>
            <div className="flex justify-center mx-auto my-5">
              {active === 0 ? null : active === 1 ? (
                <iframe
                  className="rounded-lg shadow-lg"
                  src="https://www.loom.com/embed/4df166851aa74ce887558eff4df896f9"
                  width="600px"
                  height="400px"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              ) : null}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
