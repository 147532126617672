import { alertError, alertSuccess } from "./Alerts";
import { useNavigate } from 'react-router-dom';
import instance from '../auth/Axios';
import React from 'react'

const UserTemplates = (props) => {
    const navigate = useNavigate();

    const [step1Visibility, setStep1Visibility] = React.useState(props.step1SelectedOption ? false : true);
    const [step2Visibility, setStep2Visibility] = React.useState(props.step1SelectedOption ? true : false);
    const [confirmModalVisibility, setConfirmModalVisibility] = React.useState(false);

    const [step1Options, setStep1Options] = React.useState([
        {
            "name": "Re-use a template",
            "description": "If you have previously created a template (based on this specific type of contract), you may choose to re-use the selections from your prior template."
        },
        {
            "name": "Save draft as template",
            "description": "Save the selections from your current draft for future use as a template."
        }
    ]);
    const [step1SelectedOption, setStep1SelectedOption] = React.useState(props.step1SelectedOption ? props.step1SelectedOption : null);

    const [step2Options, setStep2Options] = React.useState([]);
    const [step2SelectedOption, setStep2SelectedOption] = React.useState(null);
    const [templateName, setTemplateName] = React.useState("");

    React.useEffect(() => {
        instance.post('/userTemplates/getAll', {
            contractId: props.contractId
        }).then((response) => {
            setStep2Options(response.data.data);
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    const updateContractFormsBorder = (e) => {
        if (step1SelectedOption) {
            document.getElementById(step1SelectedOption.name).style.border = "1px solid #E4E4E7";
        }
        if (e.length) {
            document.getElementById(e.name).style.border = "1px solid blue";
        } setStep1SelectedOption(e)
    }

    const handleSubmit = async () => {
        if (step1SelectedOption.name === "Re-use a template") {
            await instance.post('/userTemplates/apply', {
                templateId: step2SelectedOption.id,
                contractId: props.contractId
            });
            if (props.step1SelectedOption) {
                navigate(props.redirect);
            }
            else {
                window.location.reload();
            }
        }
        else if (step1SelectedOption.name === "Save draft as template") {
            if (step2SelectedOption) {
                try {
                    await instance.post('/userTemplates/update', {
                        name: templateName,
                        templateId: step2SelectedOption.id
                    });
                    alertSuccess("Template updated successfully!");
                    if (props.step1SelectedOption) {
                        navigate(props.redirect);
                    }
                } catch (error) {
                    alertError(error.response.data.message);
                    console.log(error);
                }
            }
            else {
                try {
                    await instance.post('/userTemplates/create', {
                        name: templateName,
                        contractId: props.contractId
                    });
                    alertSuccess("Template created successfully!");
                    if (props.step1SelectedOption) {
                        navigate(props.redirect);
                    }
                } catch (error) {
                    alertError(error.response.data.message);
                    console.log(error);
                }
            }
        }
        props.onChange(false);
    }

    return (
        <div
            tabIndex={-1}
            className="fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full overflow-auto"
        >
            {
                step1Visibility &&
                <div className='p-8 pr-4 pt-0 sm:mx-[23vw] sm:my-[7vh] bg-[#FFFFFF] rounded-[10px]' style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                    <div className="flex mb-5">
                        <div className="mt-4 cursor-pointer" style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                            <svg onClick={() => { props.onChange(false) }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                            </svg>
                        </div>
                    </div>
                    <div className="mb-6">
                        <div className="font-semibold text-3xl text-[#111928] mb-2">User Templates</div>
                        <div className="font-normal text-[#6B7280]">Re-use or save a contract template.</div>
                    </div>
                    {
                        step1Options.map((option, index) => {
                            return (
                                <div
                                    onClick={() => {
                                        updateContractFormsBorder(option);
                                        document.getElementById(option.name).getElementsByTagName("input")[0].checked = true;
                                    }}
                                    className="custom-radio flex p-3 mt-3" style={{
                                        border: "1px solid #E4E4E7",
                                        borderRadius: "10px"
                                    }} id={option.name} key={index}>
                                    <div className="flex items-center justify-center">
                                        <input type="radio" value={option.name} name="options" className="mr-3" style={{ border: "1px solid #E4E4E7" }}></input>
                                        <i className="fa fa-check-circle hidden mr-3"></i>
                                    </div>
                                    <div>
                                        <label className="font-normal text-base text-[#4B5563]">{option.name}</label>
                                        <div className="text-xs font-normal text-[#6B7280]">{option.description}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="flex justify-end mt-10 gap-3">
                        <button className={`${step1SelectedOption ? "" : "opacity-50 cursor-not-allowed"}`}
                            onClick={() => {
                                if (step1SelectedOption.name === "Re-use a template" && step2Options.length === 0) {
                                    alertError("You do not have any templates to re-use");
                                    return;
                                }
                                setStep1Visibility(false)
                                setStep2Visibility(true)
                            }} style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #1C64F2", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1C64F2", width: "100px" }}>
                            <svg className="mr-3" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.7931 7.50003L12.9396 8.35358L8.65013 12.643C8.56064 12.737 8.51118 12.8622 8.51231 12.9921C8.51345 13.1232 8.56603 13.2486 8.65874 13.3413C8.75144 13.434 8.87685 13.4866 9.00794 13.4877C9.13783 13.4889 9.26301 13.4394 9.35703 13.3499L15.3534 7.35353C15.3534 7.35351 15.3534 7.35349 15.3534 7.35348C15.4471 7.25972 15.4998 7.13259 15.4998 7.00003C15.4998 6.86747 15.4471 6.74034 15.3534 6.64658L13.7931 7.50003ZM13.7931 7.50003H12.586H1C0.867392 7.50003 0.740215 7.44735 0.646446 7.35358C0.552678 7.25982 0.5 7.13264 0.5 7.00003C0.5 6.86742 0.552678 6.74025 0.646446 6.64648C0.740215 6.55271 0.867392 6.50003 1 6.50003H12.586H13.7931L12.9396 5.64648L8.64661 1.35353C8.64659 1.35351 8.64657 1.35349 8.64655 1.35348C8.55285 1.25972 8.50021 1.13259 8.50021 1.00003C8.50021 0.867498 8.55283 0.740391 8.6465 0.646637C8.74026 0.552902 8.86742 0.500244 9 0.500244C9.13256 0.500244 9.25969 0.552882 9.35345 0.646584C9.35346 0.646602 9.35348 0.646619 9.3535 0.646637L15.3534 6.64653L13.7931 7.50003Z" fill="white" stroke="white" />
                            </svg>
                            <div className="text-sm font-semibold text-[white]">Next</div>
                        </button>
                    </div>
                </div>
            }
            {
                step2Visibility &&
                <div className={`p-8 pr-4 ${!props.step1SelectedOption && "pt-0"} sm:mx-[23vw] sm:my-[7vh] bg-[#FFFFFF] rounded-[10px]`} style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                    {
                        !props.step1SelectedOption &&
                        <div className="flex mb-5">
                            <div className="mt-4 cursor-pointer" style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                                <svg onClick={() => { props.onChange(false) }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                </svg>
                            </div>
                        </div>
                    }
                    <div className="font-semibold text-3xl text-[#111928] mb-2">{step1SelectedOption.name === "Re-use a template" ? "Re-use a Template" : "Save Draft as Template"}</div>
                    <div className="font-normal text-[#6B7280]">{step1SelectedOption.name === "Re-use a template" ? "To re-use the selections from one of your prior templates, please select the template below." : "Provide a name for this template. Or, if you would like to replace a prior template, please select the template from the options below."}</div>
                    {step1SelectedOption.name === "Save draft as template" && <input value={templateName} onChange={(e) => { setTemplateName(e.target.value) }} id="templateName" className='my-5 block w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border border-[#E5E7EB] rounded-[16px]' placeholder='Acme, Inc. Mutual NDA' />}
                    <div className='grid grid-cols-2 gap-x-5 gap-y-4 mt-4'>
                        {step2Options.sort((a, b) => (a.name > b.name) ? 1 : -1).map((item, index) => (
                            <div key={index} onClick={() => {
                                if (item.name === step2SelectedOption?.name) {
                                    setStep2SelectedOption(null)
                                } else {
                                    setStep2SelectedOption(item)
                                }
                            }} className={`px-6 border-2 select-none py-4 rounded-lg shadow-sm text-base font-semibold text-[#1F2A37] flex ${step2SelectedOption?.name === item.name ? "border-[#1C64F2]" : "border-[#e4e4e7]"} `}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" fill="#9CA3AF" class="bi bi-file-earmark-fill" viewBox="0 0 16 16">
                                    <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3z" />
                                </svg>
                                <span className='ml-2'>{item.name}</span>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-end mt-10 gap-3">
                        <button onClick={() => {
                            if (!props.step1SelectedOption) {
                                setStep2SelectedOption(null)
                                setStep2Visibility(false)
                                setStep1Visibility(true)
                            }
                            else {
                                props.onChange(false)
                                navigate(props.redirect)
                            }
                        }} style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #D1D5DB", display: "flex", alignItems: "center", justifyContent: "center", width: "100px" }}>
                            <svg className="mr-3" width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.20703 13.7069C8.0195 13.8944 7.76519 13.9997 7.50003 13.9997C7.23487 13.9997 6.98056 13.8944 6.79303 13.7069L0.793031 7.70692C0.605559 7.51939 0.500244 7.26508 0.500244 6.99992C0.500244 6.73475 0.605559 6.48045 0.793031 6.29292L6.79303 0.292919C6.98163 0.110761 7.23423 0.00996641 7.49643 0.0122448C7.75863 0.0145233 8.00944 0.119692 8.19485 0.3051C8.38026 0.490508 8.48543 0.741321 8.4877 1.00352C8.48998 1.26571 8.38919 1.51832 8.20703 1.70692L3.91403 5.99992H15.5C15.7652 5.99992 16.0196 6.10528 16.2071 6.29281C16.3947 6.48035 16.5 6.7347 16.5 6.99992C16.5 7.26514 16.3947 7.51949 16.2071 7.70703C16.0196 7.89456 15.7652 7.99992 15.5 7.99992H3.91403L8.20703 12.2929C8.3945 12.4804 8.49982 12.7348 8.49982 12.9999C8.49982 13.2651 8.3945 13.5194 8.20703 13.7069Z" fill="#111928" />
                            </svg>
                            <div className="text-sm font-semibold text-[#111928]">Back</div>
                        </button>
                        <button className={`${step1SelectedOption.name === "Re-use a template" && !step2SelectedOption && "opacity-50 cursor-not-allowed disabled"}`}
                            onClick={() => {
                                const templateName = document.getElementById("templateName");
                                if (templateName && !templateName.value.trim().length) {
                                    alertError("Template name cannot be empty");
                                    return;
                                }
                                setStep2Visibility(false);
                                if ((step1SelectedOption.name === "Save draft as template" && !step2SelectedOption) || (props.step1SelectedOption && props.step1SelectedOption.name === "Re-use a template")) {
                                    handleSubmit();
                                }
                                else {
                                    setConfirmModalVisibility(true);
                                }
                            }} style={{ borderRadius: "12px", padding: "9px 10px 9px 10px", border: "1px solid #1C64F2", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1C64F2", width: "100px" }}>
                            <svg className="mr-3" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.7931 7.50003L12.9396 8.35358L8.65013 12.643C8.56064 12.737 8.51118 12.8622 8.51231 12.9921C8.51345 13.1232 8.56603 13.2486 8.65874 13.3413C8.75144 13.434 8.87685 13.4866 9.00794 13.4877C9.13783 13.4889 9.26301 13.4394 9.35703 13.3499L15.3534 7.35353C15.3534 7.35351 15.3534 7.35349 15.3534 7.35348C15.4471 7.25972 15.4998 7.13259 15.4998 7.00003C15.4998 6.86747 15.4471 6.74034 15.3534 6.64658L13.7931 7.50003ZM13.7931 7.50003H12.586H1C0.867392 7.50003 0.740215 7.44735 0.646446 7.35358C0.552678 7.25982 0.5 7.13264 0.5 7.00003C0.5 6.86742 0.552678 6.74025 0.646446 6.64648C0.740215 6.55271 0.867392 6.50003 1 6.50003H12.586H13.7931L12.9396 5.64648L8.64661 1.35353C8.64659 1.35351 8.64657 1.35349 8.64655 1.35348C8.55285 1.25972 8.50021 1.13259 8.50021 1.00003C8.50021 0.867498 8.55283 0.740391 8.6465 0.646637C8.74026 0.552902 8.86742 0.500244 9 0.500244C9.13256 0.500244 9.25969 0.552882 9.35345 0.646584C9.35346 0.646602 9.35348 0.646619 9.3535 0.646637L15.3534 6.64653L13.7931 7.50003Z" fill="white" stroke="white" />
                            </svg>
                            <div className="text-sm font-semibold text-[white] mb-0.5">{step1SelectedOption.name === "Re-use a template" ? "Next" : step2SelectedOption ? "Replace" : "Save"}</div>
                        </button>
                    </div>
                </div>
            }
            {
                confirmModalVisibility &&
                <div className='p-8 pr-4 pt-0 sm:mx-[23vw] sm:my-[7vh] bg-[#FFFFFF] rounded-[10px]' style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                    <div className="flex mb-5">
                        <div className="mt-4 cursor-pointer" style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                            <svg onClick={() => { props.onChange(false) }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                            </svg>
                        </div>
                    </div>
                    <div className="mb-6">
                        <div className="font-semibold text-3xl text-[#111928] mb-2">Are you sure?</div>
                        <div className="font-normal text-[#6B7280]">
                            {
                                step1SelectedOption.name === "Re-use a template" ?
                                    "By selecting this template, then any configurations you’ve made in the current draft will be updated to reflect the configurations from the selected template."
                                    :
                                    "By replacing a prior template, you will no longer be able to use or retrieve the prior template."
                            }
                        </div>
                    </div>
                    <div className="flex justify-end mt-10 gap-3">
                        <button onClick={() => {
                            props.onChange(false)
                        }} style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #D1D5DB", display: "flex", alignItems: "center", justifyContent: "center", width: "100px" }}>
                            <div className="text-sm font-semibold text-[#111928]">No</div>
                        </button>
                        <button onClick={() => {
                            handleSubmit();
                        }} style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #1C64F2", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1C64F2", width: "100px" }}>
                            <div className="text-sm font-semibold text-[white]">Yes</div>
                        </button>
                    </div>
                </div >
            }
        </div >
    )
}

export default UserTemplates
