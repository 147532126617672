import React from 'react'

const Signing = () => {
    return (
        <div>
            <nav className="bg-white p-3" style={{ borderBottom: "1px solid #E5E7EB" }}>
                <div className="mx-auto px-2 sm:px-4">
                    <div className="relative flex items-center justify-between">
                        <div className="absolute mt-1 inset-y-0 left-0 flex items-center sm:hidden">
                            <button type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                                <span className="sr-only">Open main menu</span>
                                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                                <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div className="m-1 flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                            <div className="hidden flex-shrink-0 items-center sm:flex">
                                <img src="/Images/Navbar/SD-Logo.png" className="w-[45%] h-[30px]" />
                                <div className='text-xs font-normal text-[#6B7280] w-60 ml-3'>Create contracts the Standard Draft way. 243+ in California are already using.</div>
                            </div>
                            <button className='flex ml-auto'>
                                <svg className='mt-2 mr-1.5' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.83333 12.3333H9V9H8.16667M9 5.66667H9.00833M16.5 9C16.5 9.98491 16.306 10.9602 15.9291 11.8701C15.5522 12.7801 14.9997 13.6069 14.3033 14.3033C13.6069 14.9997 12.7801 15.5522 11.8701 15.9291C10.9602 16.306 9.98491 16.5 9 16.5C8.01509 16.5 7.03982 16.306 6.12987 15.9291C5.21993 15.5522 4.39314 14.9997 3.6967 14.3033C3.00026 13.6069 2.44781 12.7801 2.0709 11.8701C1.69399 10.9602 1.5 9.98491 1.5 9C1.5 7.01088 2.29018 5.10322 3.6967 3.6967C5.10322 2.29018 7.01088 1.5 9 1.5C10.9891 1.5 12.8968 2.29018 14.3033 3.6967C15.7098 5.10322 16.5 7.01088 16.5 9Z" stroke="#52525B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <div className='text-sm font-semibold mt-1.5'>Know More</div>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="sm:hidden" id="mobile-menu">
                    <div className="px-2 pt-2 pb-3 space-y-1">
                        <a href="#" className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium" aria-current="page">Dashboard</a>

                        <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Team</a>

                        <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Projects</a>

                        <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Calendar</a>
                    </div>
                </div>
            </nav>
            <div className='sm:mx-[13vw] sm:my-[4vh] bg-[#FFFFFF] rounded-[20px]' style={{ boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)" }}>
                <div className='flex p-4 bg-[#F4F4F5] border border-[#D4D4D8] w-full' style={{ borderRadius: "20px 20px 0px 0px" }}>
                    <div className='mt-3 ml-1'>
                        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.5 5.10938C4.40609 5.10938 5.14062 4.37484 5.14062 3.46875C5.14062 2.56266 4.40609 1.82812 3.5 1.82812C2.59391 1.82812 1.85938 2.56266 1.85938 3.46875C1.85938 4.37484 2.59391 5.10938 3.5 5.10938Z" stroke="#52525B" strokeWidth="2.34375" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.5 5.10938C4.40609 5.10938 5.14062 4.37484 5.14062 3.46875C5.14062 2.56266 4.40609 1.82812 3.5 1.82812C2.59391 1.82812 1.85938 2.56266 1.85938 3.46875C1.85938 4.37484 2.59391 5.10938 3.5 5.10938Z" stroke="#52525B" strokeWidth="2.34375" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.5 5.10938C4.40609 5.10938 5.14062 4.37484 5.14062 3.46875C5.14062 2.56266 4.40609 1.82812 3.5 1.82812C2.59391 1.82812 1.85938 2.56266 1.85938 3.46875C1.85938 4.37484 2.59391 5.10938 3.5 5.10938Z" stroke="#52525B" strokeWidth="2.34375" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <div className='ml-auto flex'>
                        <button className='mr-12 mb-[3.5px]'>
                            <div className='text-sm font-semibold text-[#1C64F2] hover:text-blue-700'>Download As Word</div>
                            <div className='text-xs font-normal text-[#374151]'>(review time ~3-5 hours)</div>
                        </button>
                        <button className='flex mr-7 bg-[#FFFFFF] border border-[#1C64F2] rounded-[30px] pl-3 pr-5 pb-1 hover:bg-gray-100'>
                            <div className='flex mt-4 mr-2'>
                                <svg className='relative bottom-[2px] left-[4px]' width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.91612 5.50003C5.91612 6.1316 5.66478 6.73642 5.21849 7.18262L1.97498 10.4255C1.92051 10.48 1.86508 10.5316 1.80583 10.5793C1.66439 10.6969 1.51053 10.7953 1.34998 10.8746C1.12349 10.7628 0.912285 10.6128 0.72402 10.4245C-0.204882 9.49583 -0.204882 7.98905 0.72402 7.05937L2.28366 5.50003L0.724976 3.93974C-0.203926 3.01102 -0.203926 1.50424 0.724976 0.574561C0.913241 0.386333 1.12444 0.237279 1.34998 0.125488C1.51244 0.205748 1.66726 0.305117 1.81061 0.423596C1.86699 0.47137 1.92242 0.521055 1.97498 0.573606L2.1059 0.704506L5.21849 3.81649C5.66574 4.26365 5.91612 4.86942 5.91612 5.50003Z" fill="#1C64F2" />
                                </svg>
                                <svg className='relative bottom-[5px]' width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.6683 8.49999C14.6683 13.1082 10.9192 16.8556 6.31105 16.8556H2.32021C1.78313 16.8556 1.29766 16.6435 0.939286 16.2986C0.933552 16.2403 0.931641 16.1811 0.931641 16.1199C0.931641 14.8061 1.9972 13.7398 3.31219 13.7398H8.08667C10.9766 13.7398 13.3285 11.3893 13.3285 8.49904C13.3285 5.60968 10.9766 3.25826 8.08667 3.25826H3.31315C1.99911 3.25826 0.932596 2.1929 0.932596 0.878171C0.932596 0.817977 0.934508 0.757782 0.940242 0.699498C1.29861 0.354571 1.78409 0.142456 2.32117 0.142456H6.31105C10.9192 0.144367 14.6683 3.89174 14.6683 8.49999Z" fill="#1C64F2" />
                                </svg>
                            </div>
                            <div>
                                <div className='text-sm font-semibold text-[#1C64F2]'>Review + Edit Standard Draft</div>
                                <div className='text-xs font-normal text-[#374151] text-left'>(review time ~15 mins)</div>
                            </div>
                        </button>
                        <button className='flex justify-center bg-[#1C64F2] rounded-[30px] px-4 pb-1 hover:bg-blue-700'>
                            <svg className='block mt-3 mr-2' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.0606 7.06055L12.0606 7.06061L12.0666 7.05436C12.3399 6.77145 12.4911 6.39255 12.4877 5.99925C12.4842 5.60596 12.3265 5.22974 12.0484 4.95163C11.7703 4.67352 11.394 4.51576 11.0007 4.51234C10.6074 4.50893 10.2285 4.66012 9.94564 4.93336L9.94559 4.9333L9.93945 4.93945L7 7.87889L6.06055 6.93945L6.06061 6.93939L6.05436 6.93336C5.77145 6.66012 5.39255 6.50893 4.99925 6.51235C4.60596 6.51576 4.22974 6.67352 3.95163 6.95163C3.67352 7.22974 3.51576 7.60596 3.51234 7.99925C3.50893 8.39255 3.66012 8.77145 3.93336 9.05436L3.9333 9.05441L3.93945 9.06055L5.93945 11.0606L5.9395 11.0606C6.22079 11.3418 6.60225 11.4998 7 11.4998C7.39775 11.4998 7.77921 11.3418 8.0605 11.0606L8.06055 11.0606L12.0606 7.06055ZM13.3033 13.3033C11.8968 14.7098 9.98912 15.5 8 15.5C6.01088 15.5 4.10322 14.7098 2.6967 13.3033C1.29018 11.8968 0.5 9.98912 0.5 8C0.5 6.01088 1.29018 4.10322 2.6967 2.6967C4.10322 1.29018 6.01088 0.5 8 0.5C9.98912 0.5 11.8968 1.29018 13.3033 2.6967C14.7098 4.10322 15.5 6.01088 15.5 8C15.5 9.98912 14.7098 11.8968 13.3033 13.3033Z" fill="white" stroke="white" />
                            </svg>
                            <div>
                                <div className='text-sm font-semibold text-[#FFFFFF] text-left'>Start Signing</div>
                                <div className='text-xs font-normal text-[#FFFFFF]'>(no log in needed)</div>
                            </div>
                        </button>
                    </div>
                </div>
                <div className='bg-[#1C64F2] w-full h-[4px]'></div>
                <div className='flex align-center justify-center'>
                    <img src='Images/Signing/PDF.png' className='w-7/12 h-7/12 mt-6' />
                </div>
                <div className='bg-[#F4F4F5] text-[#F4F4F5] h-[60px] rounded-[20px]'>A</div>
            </div>
        </div>
    )
}

export default Signing
