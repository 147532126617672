import Swal from 'sweetalert2'

export const alertSuccess = (text) => {
    Swal.fire({
        title: "Success",
        text,
        icon: 'success',
        confirmButtonText: 'OK',
        width: 450,
        confirmButtonColor: '#2563EB',
    })
}

export const alertError = (text) => {
    Swal.fire({
        title: "Warning",
        text,
        icon: 'error',
        confirmButtonText: 'OK',
        width: 450,
        confirmButtonColor: '#2563EB',
    })
}

export const alertInfo = (title, text) => {
    Swal.fire({
        title: title,
        text,
        icon: 'info',
        confirmButtonText: 'OK',
        width: 450,
        confirmButtonColor: '#2563EB',
    })
}