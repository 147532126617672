import { useNavigate, useLocation } from 'react-router-dom'
import { BsFillInfoCircleFill } from 'react-icons/bs'
import Introduction from './Introduction';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react'
import '../index.css'

const Sidebar = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const [filpIcon, setFilpIcon] = useState(false)
    const [instructionsModal, setInstructionsModal] = useState(false)

    React.useEffect(() => {
        setInstructionsModal(props.instructionsModalVisibility)
    }, [props.instructionsModalVisibility])

    return (
        <>
            <div className={` min-h-[100vh] border-r border-[#e4e4e738] customSidebarBG`} >
                <aside className={`duration-100 ease-in-out ${props.value ? "w-60" : "w-[60px]"}`} aria-label="Sidebar">
                    {
                        props.value ?
                            <div className="overflow-y-auto py-4 px-3 rounded">
                                <div className="flex flex-shrink-0 items-center justify-between" onMouseEnter={() => setFilpIcon(true)} onMouseLeave={() => setFilpIcon(false)}>
                                    <img src="/Images/Navbar/SD-Logo.png" className="w-auto h-[30px] pl-2.5" />
                                    <button className={`${filpIcon ? `w-auto visible opacity-100` : `invisible opacity-0`} duration-200 ease-in-out active:bg-gray-500 bg-gray-200 active:bg-opacity-30 ml-auto h-8 w-8  rounded-full`}
                                        onClick={() => { props.setSidebar(!props.value) }}>
                                        <svg className='mx-auto' viewBox="0 0 24 24" height={"22px"} fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5 4a1 1 0 00-1 1v14a1 1 0 001 1h3V4H5zm4-2H5a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3V5a3 3 0 00-3-3H9zm1 2v16h9a1 1 0 001-1V5a1 1 0 00-1-1h-9zm6.707 10.707a1 1 0 000-1.414L15.414 12l1.293-1.293a1 1 0 00-1.414-1.414l-2 2a1 1 0 000 1.414l2 2a1 1 0 001.414 0z"
                                                fill="#333333"></path></svg>

                                        {/* <label  className='absolute top-4 left-60 text-xs font-semibold bg-gray-900 bg-opacity-95 text-slate-100 rounded-3xl py-2 px-2.5'>Hide navigation</label> */}
                                    </button>
                                </div>
                                <ul className="space-y-2 mt-4">
                                    <li onClick={() => {
                                        if (props.currentCollaborator && props.currentCollaborator.role !== "viewer" && props.currentCollaborator.team === props.contract.currentOwnerTeam) {
                                            props.setCloseModal(true);
                                        }
                                        else {
                                            dispatch({ type: 'RESET' });
                                            navigate('/dashboard')
                                        }
                                    }} className='rounded-xl cursor-pointer'>
                                        <p className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg">
                                            {
                                                location.pathname === '/dashboard' ?
                                                    <svg className='ml-1' width="16" height="20" viewBox="0 0 16 20" fill="#1C64F2" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.799988 2.7999C0.799988 2.16338 1.05284 1.55293 1.50293 1.10285C1.95302 0.652759 2.56347 0.399902 3.19999 0.399902H8.70319C9.33966 0.400038 9.95001 0.652984 10.4 1.1031L14.4968 5.1999C14.9469 5.64989 15.1999 6.26024 15.2 6.8967V17.1999C15.2 17.8364 14.9471 18.4469 14.497 18.897C14.047 19.347 13.4365 19.5999 12.8 19.5999H3.19999C2.56347 19.5999 1.95302 19.347 1.50293 18.897C1.05284 18.4469 0.799988 17.8364 0.799988 17.1999V2.7999ZM3.19999 9.9999C3.19999 9.68164 3.32642 9.37642 3.55146 9.15137C3.7765 8.92633 4.08173 8.7999 4.39999 8.7999H11.6C11.9182 8.7999 12.2235 8.92633 12.4485 9.15137C12.6736 9.37642 12.8 9.68164 12.8 9.9999C12.8 10.3182 12.6736 10.6234 12.4485 10.8484C12.2235 11.0735 11.9182 11.1999 11.6 11.1999H4.39999C4.08173 11.1999 3.7765 11.0735 3.55146 10.8484C3.32642 10.6234 3.19999 10.3182 3.19999 9.9999ZM4.39999 13.5999C4.08173 13.5999 3.7765 13.7263 3.55146 13.9514C3.32642 14.1764 3.19999 14.4816 3.19999 14.7999C3.19999 15.1182 3.32642 15.4234 3.55146 15.6484C3.7765 15.8735 4.08173 15.9999 4.39999 15.9999H11.6C11.9182 15.9999 12.2235 15.8735 12.4485 15.6484C12.6736 15.4234 12.8 15.1182 12.8 14.7999C12.8 14.4816 12.6736 14.1764 12.4485 13.9514C12.2235 13.7263 11.9182 13.5999 11.6 13.5999H4.39999Z" fill="#111928" />
                                                    </svg>
                                                    :
                                                    <svg className='ml-1' width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.799988 2.7999C0.799988 2.16338 1.05284 1.55293 1.50293 1.10285C1.95302 0.652759 2.56347 0.399902 3.19999 0.399902H8.70319C9.33966 0.400038 9.95001 0.652984 10.4 1.1031L14.4968 5.1999C14.9469 5.64989 15.1999 6.26024 15.2 6.8967V17.1999C15.2 17.8364 14.9471 18.4469 14.497 18.897C14.047 19.347 13.4365 19.5999 12.8 19.5999H3.19999C2.56347 19.5999 1.95302 19.347 1.50293 18.897C1.05284 18.4469 0.799988 17.8364 0.799988 17.1999V2.7999ZM3.19999 9.9999C3.19999 9.68164 3.32642 9.37642 3.55146 9.15137C3.7765 8.92633 4.08173 8.7999 4.39999 8.7999H11.6C11.9182 8.7999 12.2235 8.92633 12.4485 9.15137C12.6736 9.37642 12.8 9.68164 12.8 9.9999C12.8 10.3182 12.6736 10.6234 12.4485 10.8484C12.2235 11.0735 11.9182 11.1999 11.6 11.1999H4.39999C4.08173 11.1999 3.7765 11.0735 3.55146 10.8484C3.32642 10.6234 3.19999 10.3182 3.19999 9.9999ZM4.39999 13.5999C4.08173 13.5999 3.7765 13.7263 3.55146 13.9514C3.32642 14.1764 3.19999 14.4816 3.19999 14.7999C3.19999 15.1182 3.32642 15.4234 3.55146 15.6484C3.7765 15.8735 4.08173 15.9999 4.39999 15.9999H11.6C11.9182 15.9999 12.2235 15.8735 12.4485 15.6484C12.6736 15.4234 12.8 15.1182 12.8 14.7999C12.8 14.4816 12.6736 14.1764 12.4485 13.9514C12.2235 13.7263 11.9182 13.5999 11.6 13.5999H4.39999Z" fill="#111928" />
                                                    </svg>
                                            }
                                            <span className={`ml-4 text-base font-medium ${location.pathname === '/dashboard' ? `text-[#1C64F2]` : `text-gray-900 `} `}>Dashboard</span>
                                        </p>
                                    </li>
                                    <li>
                                        <p onClick={() => {
                                            setInstructionsModal(true)
                                        }} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg cursor-pointer">
                                            < BsFillInfoCircleFill className='ml-1' />
                                            <span className={`ml-4 text-base font-medium text-gray-900`}>Instructions</span>
                                        </p>

                                    </li>
                                </ul>
                                <ul className="pt-6 mt-4 space-y-2 border-t border-[#E5E7EB]"></ul>
                            </div>
                            :
                            <div className="overflow-y-auto py-4 px-3 rounded">
                                <div className="relative h-10">
                                    <img src="/Images/Navbar/justLogo.png" onMouseEnter={() => setFilpIcon(true)} className={`${!filpIcon ? `w-auto visible opacity-100` : `invisible opacity-0`} absolute h-[30px] ml-2 duration-200 ease-in-out`} />
                                    <button className={` ${filpIcon ? `w-auto visible opacity-100` : `invisible opacity-0`} duration-200 ease-in-out absolute active:bg-gray-500 bg-gray-200 active:bg-opacity-30 h-8 w-8 ml-2 rounded-full`} onMouseLeave={() => setFilpIcon(false)} onClick={() => { props.setSidebar(!props.value); setFilpIcon(false) }}>
                                        <svg viewBox="0 0 24 24" height={"22px"} fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5 4a1 1 0 00-1 1v14a1 1 0 001 1h3V4H5zm4-2H5a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3V5a3 3 0 00-3-3H9zm1 2v16h9a1 1 0 001-1V5a1 1 0 00-1-1h-9zm3.293 5.293a1 1 0 000 1.414L14.586 12l-1.293 1.293a1 1 0 001.414 1.414l2-2a1 1 0 000-1.414l-2-2a1 1 0 00-1.414 0z" fill="#333333"></path></svg>
                                    </button>
                                </div>
                                <ul className="space-y-2 mt-2">
                                    <li onClick={() => {
                                        if (props.currentCollaborator && props.currentCollaborator.role !== "viewer" && props.currentCollaborator.team === props.contract.currentOwnerTeam) {
                                            props.setCloseModal(true);
                                        }
                                        else {
                                            dispatch({ type: 'RESET' });
                                            navigate('/dashboard')
                                        }
                                    }} className='rounded-xl'>
                                        <p className="flex items-center ml-1.5 text-base font-normal text-gray-900 rounded-lg">
                                            {
                                                location.pathname === '/dashboard' ?
                                                    <svg className='ml-1 cursor-pointer' width="16" height="20" viewBox="0 0 16 20" fill="#1C64F2" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.799988 2.7999C0.799988 2.16338 1.05284 1.55293 1.50293 1.10285C1.95302 0.652759 2.56347 0.399902 3.19999 0.399902H8.70319C9.33966 0.400038 9.95001 0.652984 10.4 1.1031L14.4968 5.1999C14.9469 5.64989 15.1999 6.26024 15.2 6.8967V17.1999C15.2 17.8364 14.9471 18.4469 14.497 18.897C14.047 19.347 13.4365 19.5999 12.8 19.5999H3.19999C2.56347 19.5999 1.95302 19.347 1.50293 18.897C1.05284 18.4469 0.799988 17.8364 0.799988 17.1999V2.7999ZM3.19999 9.9999C3.19999 9.68164 3.32642 9.37642 3.55146 9.15137C3.7765 8.92633 4.08173 8.7999 4.39999 8.7999H11.6C11.9182 8.7999 12.2235 8.92633 12.4485 9.15137C12.6736 9.37642 12.8 9.68164 12.8 9.9999C12.8 10.3182 12.6736 10.6234 12.4485 10.8484C12.2235 11.0735 11.9182 11.1999 11.6 11.1999H4.39999C4.08173 11.1999 3.7765 11.0735 3.55146 10.8484C3.32642 10.6234 3.19999 10.3182 3.19999 9.9999ZM4.39999 13.5999C4.08173 13.5999 3.7765 13.7263 3.55146 13.9514C3.32642 14.1764 3.19999 14.4816 3.19999 14.7999C3.19999 15.1182 3.32642 15.4234 3.55146 15.6484C3.7765 15.8735 4.08173 15.9999 4.39999 15.9999H11.6C11.9182 15.9999 12.2235 15.8735 12.4485 15.6484C12.6736 15.4234 12.8 15.1182 12.8 14.7999C12.8 14.4816 12.6736 14.1764 12.4485 13.9514C12.2235 13.7263 11.9182 13.5999 11.6 13.5999H4.39999Z" fill="#111928" />
                                                    </svg>
                                                    :
                                                    <svg className='ml-1 cursor-pointer' width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.799988 2.7999C0.799988 2.16338 1.05284 1.55293 1.50293 1.10285C1.95302 0.652759 2.56347 0.399902 3.19999 0.399902H8.70319C9.33966 0.400038 9.95001 0.652984 10.4 1.1031L14.4968 5.1999C14.9469 5.64989 15.1999 6.26024 15.2 6.8967V17.1999C15.2 17.8364 14.9471 18.4469 14.497 18.897C14.047 19.347 13.4365 19.5999 12.8 19.5999H3.19999C2.56347 19.5999 1.95302 19.347 1.50293 18.897C1.05284 18.4469 0.799988 17.8364 0.799988 17.1999V2.7999ZM3.19999 9.9999C3.19999 9.68164 3.32642 9.37642 3.55146 9.15137C3.7765 8.92633 4.08173 8.7999 4.39999 8.7999H11.6C11.9182 8.7999 12.2235 8.92633 12.4485 9.15137C12.6736 9.37642 12.8 9.68164 12.8 9.9999C12.8 10.3182 12.6736 10.6234 12.4485 10.8484C12.2235 11.0735 11.9182 11.1999 11.6 11.1999H4.39999C4.08173 11.1999 3.7765 11.0735 3.55146 10.8484C3.32642 10.6234 3.19999 10.3182 3.19999 9.9999ZM4.39999 13.5999C4.08173 13.5999 3.7765 13.7263 3.55146 13.9514C3.32642 14.1764 3.19999 14.4816 3.19999 14.7999C3.19999 15.1182 3.32642 15.4234 3.55146 15.6484C3.7765 15.8735 4.08173 15.9999 4.39999 15.9999H11.6C11.9182 15.9999 12.2235 15.8735 12.4485 15.6484C12.6736 15.4234 12.8 15.1182 12.8 14.7999C12.8 14.4816 12.6736 14.1764 12.4485 13.9514C12.2235 13.7263 11.9182 13.5999 11.6 13.5999H4.39999Z" fill="#111928" />
                                                    </svg>
                                            }
                                        </p>
                                    </li>
                                    <li>
                                        < BsFillInfoCircleFill onClick={() => {
                                            setInstructionsModal(true)
                                        }} className='mx-auto mt-5 cursor-pointer' />
                                    </li>
                                </ul>
                                <ul className="pt-6 mt-4 space-y-2 border-t border-[#E5E7EB]"></ul>
                            </div>
                    }
                </aside>
            </div>
            {instructionsModal && <Introduction setInstructionsModal={setInstructionsModal} active={props.instructionsModalState} />}
        </>
    )
}

export default Sidebar
