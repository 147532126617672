import { alertError, alertSuccess } from "../components/Alerts";
import PostSigninNavbar from "../components/PostSigninNavbar";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import { connect } from "react-redux";
import instance from "../auth/Axios";

export const PricingTable = (props) => {
  const navigate = useNavigate();
  const {
    payment_status,
    remaining_contract,
    planId,
    selectedContractId,
    plan_type,
  } = useSelector((state) => state.payment);

  const [freeTrialLoading, setFreeTrialLoading] = useState(false);
  const [billingPeriod, setbillingPeriod] = useState("monthly");
  const [sidebar, setSidebar] = React.useState(false);
  const [loading, setLoading] = useState(null);

  let stripePromise;
  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEYS);
    }
    return stripePromise;
  };

  const handleSubscription = async (planId, type, index, freeTrial) => {
    try {
      setLoading(index);
      const res = await instance.post(`/payment/create-checkout-session`, {
        planId,
        type,
        selectedContractId,
        freeTrial,
      });
      if (res.status) {
        const sessionId = res.data.sessionId;
        const stripe = await getStripe();
        stripe.redirectToCheckout({ sessionId: sessionId });
        setLoading(null);
        setFreeTrialLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(null);
      setFreeTrialLoading(false);
      alertError("Something went wrong when creating checkout from stripe");
    }
  };

  const handleFreetrial = async () => {
    try {
      const res = await instance.get(`/payment/free-trial`);
      if (res.status) {
        const getUserDetails = await instance.get("/user/get-user-details");
        if (getUserDetails.data.status) {
          alertSuccess("Free Trial Activated");
          if (selectedContractId) {
            navigate(`/${selectedContractId}?freeTrial=true`);
          } else {
            navigate(`/dashboard`);
          }
        }
      }
    } catch (error) {
      alertError("Payment Failed");
    }
  };

  const handleEarlyAccess = async () => {
    try {
      if (
        !document.getElementById("earlyAccessCode").value ||
        document.getElementById("earlyAccessCode").value.trim() === ""
      ) {
        alertError("Please enter early access code");
        return;
      }

      const res = await instance.post(`/payment/early-access`, {
        accessCode: document.getElementById("earlyAccessCode").value,
      });
      const getUserDetails = await instance.get("/user/get-user-details");
      if (getUserDetails.data.status) {
        alertSuccess("Free Trial Activated");
        if (selectedContractId) {
          navigate(`/${selectedContractId}?freeTrial=true`);
        } else {
          navigate(`/dashboard`);
        }
      }
    } catch (error) {
      console.log(error);
      alertError(error.response.data);
    }
  };

  const paymentData = [
    {
      title: "3 NDAs Monthly",
      price: "129",
      period: "per month",
      features: [
        "Savings of 25%",
        "Access to all NDA drafting choices",
        "Two-sided negotiation access",
        "eSigning integration",
      ],
      buyButtonId: process.env.REACT_APP_STRIPE_3_NDA_PLAN,
      buyButtonIdWithFreeTrail:
        process.env.REACT_APP_STRIPE_3_NDA_PLAN_FREE_TRIAL,
      supportButtontitle: "Try Free",
      supportButtonsubtitle: "First month trial",
    },
    {
      title: "5 NDAs Monthly",
      price: "159",
      period: "per month",
      features: [
        "Savings of 45%",
        "Access to all NDA drafting choices",
        "Two-sided negotiation access",
        "eSigning integration",
      ],
      buyButtonId: process.env.REACT_APP_STRIPE_5_NDA_PLAN,
      buyButtonIdWithFreeTrail:
        process.env.REACT_APP_STRIPE_5_NDA_PLAN_FREE_TRIAL,
      supportButtontitle: "Try Free",
      supportButtonsubtitle: "First month trial",
    },
    {
      title: "10 NDAs Monthly",
      price: "299",
      period: "per month",
      features: [
        "Savings of 50%",
        "Access to all NDA drafting choices",
        "Two-sided negotiation access",
        "eSigning integration",
      ],
      buyButtonId: process.env.REACT_APP_STRIPE_7_NDA_PLAN,
      supportButtontitle: "Contact Support",
    },
  ];

  const featureArray = [
    "One-time purchase",
    "Access to all NDA drafting choices",
    "Two-sided negotiation access",
    "eSigning integration",
  ];

  const handleSupportButtonClick = (item, index) => {
    if (item.supportButtontitle !== "Contact Support") {
      handleSubscription(item.buyButtonId, "subscription", index, true);
      setFreeTrialLoading(true);
    }
  };

  return (
    <div className="flex">
      <Sidebar value={sidebar} setSidebar={setSidebar} />
      <div className="w-full">
        <PostSigninNavbar value={sidebar} onChange={setSidebar} />
        <div className="w-full max-w-6xl mx-auto">
          <div className="relative h-full w-full overflow-y-auto px-5 py-8 pb-8  rounded-[10px]">
            <div className="text-center w-full pb-3">
              <p className="text-2xl font-bold text-blue-600 ">Pricing Guide</p>
              <p className="text-md text-gray-600 leading-tight mt-3">
                Our Single Use or Monthly plans allow you to draft and negotiate
                NDAs with ease. With any plan that you choose, you will be{" "}
                <br />
                able to configure the Standard Draft NDA to suit your
                transaction. Best of all, your counterparty will also get access
                into our <br />
                platform so the negotiation and signing process can be as
                simplified as possible. Try for free today!
              </p>
            </div>

            <div className="w-full mt-7  grid grid-cols-4 mx-auto gap-x-4">
              <div className="rounded-xl h-fit border-4 bg-blue-200 border-blue-300 bg-opacity-40 shadow-md px-2.5 py-4 text-center ">
                <p className="text-xl font-bold text-gray-800">Single Use</p>
                <p className="mt-4 text-3xl font-bold text-gray-800"> $59</p>
                <p className="text-sm text-gray-500 font-semibold">
                  per NDA workflow
                </p>
                <div className="border-[1px] w-4/6 mx-auto mt-3"></div>
                <ul className="mt-3 space-y-0.5">
                  {featureArray.map((feature) => (
                    <li className="px-5 grid grid-cols-6 gap-x-3 ">
                      <svg
                        className="w-6 h-5 mt-1 col-span-1 text-green-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <p className="col-span-5 text-left">{feature}</p>
                    </li>
                  ))}
                </ul>

                <div className="px-8">
                  <button
                    id={4}
                    onClick={() =>
                      handleSubscription(
                        process.env.REACT_APP_STRIPE_AL_A_CARTA_PLAN,
                        "payment",
                        4
                      )
                    }
                    type="button"
                    className="mt-5 bg-blue-600 w-full rounded-lg py-1.5 text-[0.95rem] font-[500] text-white"
                  >
                    {4 === loading ? (
                      <div
                        className="inline-block h-4 w-4 mt-1 animate-spin rounded-full border-2 border-solid border-current border-r-transparent"
                        role="status"
                      >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                          Loading...
                        </span>
                      </div>
                    ) : (
                      `Buy Now`
                    )}
                  </button>
                  {!payment_status && (
                    <button
                      onClick={() => handleFreetrial()}
                      className="w-full mt-2 mb-1 bg-gray-800 rounded-lg py-1.5 whitespace-nowrap text-white"
                    >
                      <p
                        className={`text-[1rem] font-[500] leading-[0px] pt-2 pb-1`}
                      >
                        {" "}
                        Try Free{" "}
                      </p>
                      <span className="text-xs leading-none text-semibold">
                        {" "}
                        No credit card required{" "}
                      </span>
                    </button>
                  )}
                </div>
              </div>
              {paymentData.map((item, index) => (
                <div className="rounded-xl h-fit bg-blue-200 shadow-md px-3 py-4 text-center border border-opacity-20">
                  <p className="text-xl font-bold text-gray-800">
                    {item.title}
                  </p>
                  <p className="mt-4 text-3xl font-bold text-gray-800">
                    {" "}
                    $
                    {Number(item.price) *
                      Number(billingPeriod == "monthly" ? 1 : 12)}
                  </p>
                  <p className="text-sm text-gray-500 font-semibold">
                    {" "}
                    {item.period}
                  </p>
                  <div className="border-[1px] w-4/6 mx-auto mt-3"></div>
                  <ul className="mt-3 space-y-0.5">
                    {item.features.map((feature) => (
                      <li className="px-5 grid grid-cols-6 gap-x-3 ">
                        <svg
                          className="w-6 h-5 mt-1 col-span-1 text-green-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <p className="col-span-5 text-left">{feature}</p>
                      </li>
                    ))}
                  </ul>

                  {item.buyButtonId === planId ? (
                    <button className="w-2/3 mt-5 text-blue-600 shadow-sm border-2 border-blue-700 px-5 rounded-lg py-1.5 text-[0.95rem] font-[500] bg-white">
                      Current Plan
                    </button>
                  ) : (
                    <button
                      id={index}
                      onClick={() =>
                        handleSubscription(
                          item.buyButtonId,
                          "subscription",
                          index,
                          false
                        )
                      }
                      className="w-2/3 mt-5 bg-blue-600 px-5 rounded-lg py-1.5 text-[0.95rem] font-[500] text-white"
                    >
                      {index === loading && !freeTrialLoading ? (
                        <div
                          className="inline-block h-4 w-4 mt-1 animate-spin rounded-full border-2 border-solid border-current border-r-transparent"
                          role="status"
                        >
                          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                          </span>
                        </div>
                      ) : (
                        `Buy Now`
                      )}
                    </button>
                  )}
                  <br />
                  {plan_type !== "subscription" && (
                    <button
                      onClick={() => {
                        handleSupportButtonClick(item, index);
                      }}
                      className="w-2/3 mt-2 mb-1 bg-gray-800 rounded-lg py-1.5 text-white"
                    >
                      {index === loading && freeTrialLoading ? (
                        <div
                          className="inline-block h-4 w-4 mt-1 animate-spin rounded-full border-2 border-solid border-current border-r-transparent"
                          role="status"
                        >
                          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                          </span>
                        </div>
                      ) : (
                        <>
                          <p
                            className={`text-[1rem] font-[500] leading-[0px]  ${
                              item.supportButtonsubtitle ? `pt-2 pb-1` : `py-4`
                            }`}
                          >
                            {" "}
                            {item.supportButtontitle}{" "}
                          </p>
                          <span className="text-xs leading-none text-semibold">
                            {" "}
                            {item.supportButtonsubtitle &&
                              item.supportButtonsubtitle}{" "}
                          </span>
                        </>
                      )}
                    </button>
                  )}
                </div>
              ))}
            </div>
            {!payment_status && (
              <div className="mt-10">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex flex-col items-center justify-center">
                    <p className="text-2xl font-bold text-gray-800">
                      {" "}
                      Have an early access code?{" "}
                    </p>
                  </div>
                  <div className="flex items-center justify-center mt-5 gap-[10px]">
                    <input
                      id="earlyAccessCode"
                      type="text"
                      placeholder="Enter early access code"
                      className="w-96 h-12 border border-gray-300 rounded-lg px-5"
                    />
                    <button
                      onClick={() => {
                        handleEarlyAccess();
                      }}
                      className="w-40 h-12 bg-blue-600 text-white rounded-lg"
                    >
                      Redeem
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PricingTable);
