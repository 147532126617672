import { triggerSubsectionClick } from "./utils/triggerSubsectionClick"
import { ObjtoHTML } from "../Composer";
import React from 'react'
import '../../index.css'

const RadioButtons = ({ item, obj, dispatch_handler, disabled, collapsibleHandler }) => {
    const [arrow, setArrow] = React.useState(false);

    return (
        <div className="flex items-center flex-wrap">
            {
                item.classes ?
                    arrow ?
                        <div onClick={(e) => {
                            collapsibleHandler(e.currentTarget)
                            setArrow(false)
                        }} className={"mr-1.5 mt-2 hover:cursor-pointer " + item.classes} >
                            <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 1.125L6.5 6.125L11.5 1.125" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        :
                        <div onClick={(e) => {
                            collapsibleHandler(e.currentTarget)
                            setArrow(true)
                        }} className={"mr-1.5 mt-1 hover:cursor-pointer " + item.classes}>
                            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.625 11L6.625 6L1.625 0.999999" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    :
                    null
            }
            {
                disabled ?
                    <div className="relative group hover:cursor-pointer flex gap-x-2 py-1" onClick={(e) => {
                        setArrow(!arrow)
                        collapsibleHandler(e.currentTarget, 1)
                    }}>
                        <span className="absolute top-[-20px] w-40 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100">Selection unavailable</span>
                        <input id={item.opt_id} type="radio" name={obj.name} className={'text-blue-600 focus:ring-[white]'} disabled />
                        <label className={`text-xs text-[#6B7280] font-normal ${item.classes && `cursor-pointer dropDownTitleCheckbox `}`}>{item.label}</label>
                    </div>
                    :
                    <div className="flex gap-x-2 py-1" onClick={(e) => {
                        setArrow(!arrow)
                        collapsibleHandler(e.currentTarget, 1)
                    }}>
                        <input id={item.opt_id} type="radio" name={obj.name}
                            className={'text-blue-600 focus:ring-[white]'}
                            onChange={() => { dispatch_handler([{ type: 'CHANGE_TOGGLE' }]) }}
                            onClick={(e) => { e.stopPropagation(); dispatch_handler(item.actions); triggerSubsectionClick(item.opt_id) }} />
                        <label className={`text-xs text-[#6B7280] font-normal ${item.classes && `cursor-pointer dropDownTitleCheckbox `}`}>{item.label}</label>
                    </div>
            }
            <div className="subHeadingAlignment " id={item.opt_id} style={{ flexBasis: "100%" }}>
                {item.children && item.children.map((item, index) => {
                    return (
                        <div key={index}>
                            {<ObjtoHTML obj={[item]} disabled={disabled} collapsibleHandler={collapsibleHandler} />}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default RadioButtons
