import { alertSuccess, alertError } from "../components/Alerts";
import unauthaxios from "../auth/UnAuthAxios";
import React from "react";

const ResetPassword = () => {
  const handleResetPassword = (e) => {
    e.preventDefault();
    const newPassword = document.getElementById("newPassword").value;
    const confirmNewPassword =
      document.getElementById("confirmNewPassword").value;

    if (newPassword.trim() === "" || confirmNewPassword.trim() === "") {
      alertError("Please fill out all fields");
      return;
    } else if (newPassword === confirmNewPassword) {
      unauthaxios
        .post("/user/reset-password", {
          token: window.location.search.split("=")[1],
          password: newPassword,
        })
        .then((res) => {
          alertSuccess("Password Reset Successful");
          window.location.href = "/signin";
        })
        .catch((err) => {
          alertError(err.response.data.message);
          console.log(err);
        });
    } else {
      alertError("Passwords do not match");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="w-full max-w-lg">
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <img
              src="/Images/Navbar/SD-Logo.png"
              alt="Standard Draft"
              height="300"
              width="300"
              className="mx-auto"
            />
          </div>
          <div className="mb-4">
            <p className="text-center text-gray-700 text-xl font-bold mb-2">
              Reset Password
            </p>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              New Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
              id="newPassword"
              type="password"
              placeholder="New Password"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Confirm New Password
            </label>
            <input
              className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight"
              id="confirmNewPassword"
              type="password"
              placeholder="Confirm New Password"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              onClick={handleResetPassword}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
