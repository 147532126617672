import { useSelector } from "react-redux";
import React from 'react'

const PreviewButtons = (props) => {
    const { versionHistoryData } = useSelector(state => state.contract)
    return (
        <div className='w-fit ml-auto'>
            <div className='flex items-center'>
                <div className='ml-auto flex mr-2 py-[9px] pr-[9px] pl-[19px]' style={{
                    filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1))"
                }}>
                    <button
                        onClick={async () => {
                            props.setPreviewButton('clean')
                        }}
                        className={`flex items-center relative group justify-center h-fit py-2 px-[10px] ${props.previewButton === 'clean' ? 'bg-[#F4F4F5]' : 'bg-[#FFFFFF]'} border-[0.5px] rounded-tl-[12px] rounded-bl-[12px] border-[#E5E7EB] text-sm text-[#111928] hover:bg-[#eeeeee] hover:cursor-pointer`}>
                        <svg className='mr-2' width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.8151 6.51966C14.0616 6.78169 14.0616 7.13542 13.8151 7.39702C13.0385 8.22071 11.1753 9.91668 9.00008 9.91668C6.82482 9.91668 4.96165 8.22071 4.18507 7.39702C4.06512 7.27156 4 7.11721 4 6.95834C4 6.79947 4.06512 6.64512 4.18507 6.51966C4.96165 5.69597 6.82482 4 9.00008 4C11.1753 4 13.0385 5.69597 13.8151 6.51966V6.51966Z" stroke="#52525B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.00039 8.22615C9.86219 8.22615 10.5608 7.65851 10.5608 6.95829C10.5608 6.25807 9.86219 5.69043 9.00039 5.69043C8.13858 5.69043 7.43994 6.25807 7.43994 6.95829C7.43994 7.65851 8.13858 8.22615 9.00039 8.22615Z" stroke="#52525B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        Clean
                        <div className={`scale-0 absolute top-[45px] ${props.phase === "Signed" ? "right-0" : props.phase === "Drafting" ? `left-[-40px]` : `left-[-5px]`} w-64 transition-all duration-200 ease-in-out z-10 group-hover:scale-100`}>
                            <p className="rounded bg-white border w-full border-gray-300 shadow-lg p-2 text-xs text-Inter text-gray-600 group-hover:opacity-100">
                                View the clean version of the contract (with all changes shown as accepted)
                            </p>
                        </div>
                    </button>
                    {
                        props.phase !== "Signed" &&
                        <button onClick={() => {
                            props.setPreviewButton('changes')
                        }} className={`flex items-center relative group justify-center h-fit py-2 px-[10px] ${props.previewButton === 'changes' ? "bg-[#F4F4F5]" : "bg-[#FFFFFF]"} border-[0.5px] border-[#E5E7EB] text-sm text-[#111928] hover:bg-[#eeeeee] hover:cursor-pointer ${props.phase === "Drafting" && 'rounded-tr-[12px] rounded-br-[12px]'}`}>
                            <svg className='mr-2' width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.5 0.75C3.05 0.75 0.25 3.55 0.25 7C0.25 10.45 3.05 13.25 6.5 13.25C9.95 13.25 12.75 10.45 12.75 7C12.75 3.55 9.95 0.75 6.5 0.75ZM6.5 12C3.74375 12 1.5 9.75625 1.5 7C1.5 4.24375 3.74375 2 6.5 2C9.25625 2 11.5 4.24375 11.5 7C11.5 9.75625 9.25625 12 6.5 12ZM9.10625 8.725L8.41875 8.0375C8.8625 7.20625 8.75 6.15625 8.05 5.45625C7.84711 5.25169 7.60563 5.08945 7.33956 4.97894C7.07348 4.86843 6.78811 4.81186 6.5 4.8125C6.48125 4.8125 6.4625 4.81875 6.44375 4.81875L7.125 5.5L6.4625 6.1625L4.69375 4.39375L6.4625 2.625L7.125 3.2875L6.525 3.8875C7.31875 3.89375 8.10625 4.1875 8.7125 4.7875C9.775 5.85625 9.90625 7.5125 9.10625 8.725ZM8.30625 9.60625L6.5375 11.375L5.875 10.7125L6.46875 10.1187C5.65198 10.1124 4.87058 9.78455 4.29375 9.20625C3.78502 8.69699 3.46746 8.02789 3.39459 7.31176C3.32173 6.59564 3.49802 5.87629 3.89375 5.275L4.58125 5.9625C4.1375 6.79375 4.25 7.84375 4.95 8.54375C5.3875 8.98125 5.96875 9.19375 6.55 9.175L5.875 8.5L6.5375 7.8375L8.30625 9.60625Z" fill="#52525B" />
                            </svg>
                            Your Changes
                            <div className={`scale-0 absolute top-[45px] ${props.phase === "Drafting" ? `left-[-130px]` : `left-[-25px]`} w-64 transition-all duration-200 ease-in-out z-10 group-hover:scale-100`}>
                                <p className=" rounded bg-white border w-full border-gray-300 shadow-lg p-2 text-xs text-Inter text-gray-600 group-hover:opacity-100">
                                    View your side’s current changes (with additions marked in <span style={{ color: "blue" }}><u>blue</u></span> and deletions marked in <span style={{ color: "#FE1E1F" }} className='line-through'>red</span>)
                                </p>
                            </div>
                        </button>
                    }
                    {
                        props.phase !== "Signed" && props.phase !== "Drafting" &&
                        <button onClick={() => {
                            props.setPreviewButton('markup')
                        }} className={`flex items-center relative group justify-center h-fit py-2 px-[10px] ${props.previewButton === 'markup' ? "bg-[#F4F4F5]" : "bg-[#FFFFFF]"} border-[0.5px] border-[#E5E7EB] ${versionHistoryData && versionHistoryData.length > 1 ? "" : "rounded-tr-[12px] rounded-br-[12px]"} text-sm text-[#111928] hover:bg-[#eeeeee] hover:cursor-pointer`}>
                            {versionHistoryData && versionHistoryData.length === 1 ?
                                <>
                                    <svg className='mr-2' width="11" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 560 600">
                                        <polygon points="67.63 362.46 1.89 414.84 231.19 598 558.11 337.55 492.37 285.03 231.19 493.11 67.63 362.46" />
                                        <polygon points="58.73 302.04 231.19 439.79 493.96 230.45 321.5 92.69 58.73 302.04" />
                                        <polygon points="505.38 221.35 550.81 185.16 321.51 2 1.89 256.63 47.32 292.92 321.51 74.47 505.38 221.35" />
                                    </svg>
                                    Compare
                                    <div className='scale-0 absolute top-[45px] right-0 w-64 transition-all duration-200 ease-in-out z-10 group-hover:scale-100'>
                                        <p className=" rounded bg-white border w-full border-gray-300 shadow-lg p-2 text-xs text-Inter text-gray-600 group-hover:opacity-100">
                                            View your counterparty’s changes against the Standard Draft default version
                                        </p>
                                    </div>
                                </>
                                :
                                <>
                                    <svg className='mr-2' width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.93614 0.496852L9.02926 0.403727C9.09935 0.333675 9.18256 0.278115 9.27412 0.240218C9.36569 0.202322 9.46382 0.182832 9.56292 0.182861C9.66202 0.18289 9.76014 0.202438 9.85168 0.240388C9.94322 0.278337 10.0264 0.333946 10.0964 0.404039C10.1665 0.474132 10.2221 0.557337 10.26 0.648902C10.2979 0.740467 10.3173 0.8386 10.3173 0.937698C10.3173 1.0368 10.2977 1.13492 10.2598 1.22646C10.2218 1.318 10.1662 1.40117 10.0961 1.47123L10.003 1.56373C10.1623 1.74239 10.2472 1.97522 10.2404 2.21449C10.2335 2.45377 10.1354 2.68135 9.96614 2.8506L3.53364 9.28373C3.49342 9.32373 3.44308 9.35203 3.38801 9.3656L0.88801 9.9906C0.835693 10.0036 0.780895 10.0029 0.728943 9.98848C0.676991 9.97407 0.629653 9.94646 0.591529 9.90833C0.553406 9.87021 0.525794 9.82287 0.511378 9.77092C0.496962 9.71897 0.496232 9.66417 0.50926 9.61185L1.13426 7.11185C1.14793 7.057 1.17623 7.00689 1.21614 6.96685L7.24238 0.940602C7.18212 0.898183 7.10879 0.878431 7.03537 0.884835C6.96195 0.89124 6.89315 0.923388 6.84114 0.975602L4.78364 3.03373C4.75458 3.06278 4.72009 3.08583 4.68212 3.10155C4.64416 3.11728 4.60348 3.12537 4.56239 3.12537C4.5213 3.12537 4.48061 3.11728 4.44265 3.10155C4.40468 3.08583 4.37019 3.06278 4.34114 3.03373C4.31208 3.00467 4.28903 2.97018 4.27331 2.93222C4.25758 2.89425 4.24949 2.85357 4.24949 2.81248C4.24949 2.77139 4.25758 2.7307 4.27331 2.69274C4.28903 2.65478 4.31208 2.62028 4.34114 2.59123L6.39988 0.533727C6.56924 0.364361 6.797 0.26624 7.03642 0.259499C7.27584 0.252758 7.50876 0.337909 7.68739 0.497477C7.85911 0.344001 8.08133 0.259109 8.31164 0.258994C8.54196 0.258879 8.76425 0.343548 8.93614 0.496852Z" fill="#52525B" />
                                    </svg>
                                    Counterparty
                                    <div className='scale-0 absolute top-[45px] right-0 w-64 transition-all duration-200 ease-in-out z-10 group-hover:scale-100'>
                                        <p className=" rounded bg-white border w-full border-gray-300 shadow-lg p-2 text-xs text-Inter text-gray-600 group-hover:opacity-100">
                                            View your counterparty’s changes against your prior version
                                        </p>
                                    </div>
                                </>
                            }
                        </button>
                    }
                    {
                        versionHistoryData && versionHistoryData.length > 1 &&
                        <button
                            onClick={async () => {
                                props.setPreviewButton('cumulative')
                            }}
                            className={`flex items-center relative group justify-center h-fit py-[13px] px-[10px] ${props.previewButton === 'cumulative' ? "bg-[#F4F4F5]" : "bg-[#FFFFFF]"} border-[0.5px] border-[#E5E7EB] rounded-tr-[12px] rounded-br-[12px] text-sm text-[#111928] hover:bg-[#eeeeee] hover:cursor-pointer`}>
                            <svg width="11" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 560 600">
                                <polygon points="67.63 362.46 1.89 414.84 231.19 598 558.11 337.55 492.37 285.03 231.19 493.11 67.63 362.46" />
                                <polygon points="58.73 302.04 231.19 439.79 493.96 230.45 321.5 92.69 58.73 302.04" />
                                <polygon points="505.38 221.35 550.81 185.16 321.51 2 1.89 256.63 47.32 292.92 321.51 74.47 505.38 221.35" />
                            </svg>
                            <div className='scale-0 absolute top-[45px] right-0 w-64 transition-all duration-200 ease-in-out z-10 group-hover:scale-100'>
                                <p className=" rounded bg-white border w-full border-gray-300 shadow-lg p-2 text-xs text-Inter text-gray-600 group-hover:opacity-100">
                                    View a ‘cumulative markup’ of the current version against the Standard Draft default version
                                </p>
                            </div>
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default PreviewButtons
