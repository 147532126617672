import React, { useState } from 'react'
import instance from '../auth/Axios'
import { alertError, alertSuccess } from './Alerts'
function FolderModal(props) {
    const { folderData, contract, handleMoveSuccessful, setFolderModaStatus, setNewFolderModal } = props
    const [selectedData, setSelectedData] = useState(null)

    const handleSubmit = async () => {
        try {
            const results = await instance.post("/contract/updateFolder", {
                id: contract.id,
                folderId: selectedData
            })
            if (results.status === 200) {
                handleMoveSuccessful()
                setFolderModaStatus({ status: false, id: null })
                alertSuccess("Contract moved to folder!");
            }
        } catch (error) {
            console.log(error)
            alertError("something went wrong!")
        }
    }

    return (
        <div
            tabIndex={-1}
            className="flex justify-center items-center fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full overflow-auto"
        >
            <div className='px-8 py-6 w-[50%] bg-[#FFFFFF] rounded-[10px]' style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                <div className='flex justify-between items-center'>
                    <p className='text-xl font-bold text-gray-700 '>Select a Folder</p>
                    {setNewFolderModal && <p onClick={() => { setNewFolderModal(true); setFolderModaStatus({ status: false, id: null }) }} className='text-md cursor-pointer font-semibold text-blue-700'>Create a New Folder</p>}
                </div>

                <div className='grid grid-cols-2 gap-x-5 gap-y-4 mt-4'>
                    {!setNewFolderModal &&
                        <div onClick={() => {
                            if ("null" === selectedData) {
                                setSelectedData(null)
                            } else {
                                setSelectedData("null")
                            }
                        }} className={`px-6 border-2 select-none py-4 rounded-lg shadow-sm text-base font-semibold text-[#1F2A37] flex ${selectedData === "null" ? "border-[#1C64F2]" : "border-[#e4e4e7]"} `}>
                            <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.7812 4.24219H12.2637L8.82715 0.955078C8.78342 0.914144 8.72591 0.891139 8.66602 0.890625H1.21875C0.700195 0.890625 0.28125 1.30957 0.28125 1.82812V19.1719C0.28125 19.6904 0.700195 20.1094 1.21875 20.1094H22.7812C23.2998 20.1094 23.7188 19.6904 23.7188 19.1719V5.17969C23.7188 4.66113 23.2998 4.24219 22.7812 4.24219Z" fill="#9CA3AF" />
                            </svg>
                            <span className='ml-2'>...</span>
                        </div>
                    }
                    {folderData.sort((a, b) => (a.name > b.name) ? 1 : -1).map((item, index) => (
                        <div onClick={() => {
                            if (item.id === selectedData) {
                                setSelectedData(null)
                            } else {
                                setSelectedData(item.id)
                            }
                        }} className={`px-6 border-2 select-none py-4 rounded-lg shadow-sm text-base font-semibold text-[#1F2A37] flex ${selectedData === item.id ? "border-[#1C64F2]" : "border-[#e4e4e7]"} `}>
                            <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.7812 4.24219H12.2637L8.82715 0.955078C8.78342 0.914144 8.72591 0.891139 8.66602 0.890625H1.21875C0.700195 0.890625 0.28125 1.30957 0.28125 1.82812V19.1719C0.28125 19.6904 0.700195 20.1094 1.21875 20.1094H22.7812C23.2998 20.1094 23.7188 19.6904 23.7188 19.1719V5.17969C23.7188 4.66113 23.2998 4.24219 22.7812 4.24219Z" fill="#9CA3AF" />
                            </svg>
                            <span className='ml-2'>{item.name}</span>
                        </div>
                    )
                    )}
                </div>



                <button onClick={() => setFolderModaStatus({ status: false, id: null })} className='text-gray-500 border border-gray-500 py-2 px-7 rounded-lg mt-4 float-left'>
                    Back
                </button>
                <button onClick={() => handleSubmit()} disabled={selectedData === null} className='bg-blue-500 text-white py-2 px-7 rounded-lg mt-4 float-right'>
                    Move
                </button>
            </div>
        </div>
    )
}

export default FolderModal