const initState = {
    remaining_contract: 0,
    payment_status: false,
    planId: '',
    plan_type: '',
    selectedContractId: null,
    email: '',
    firstName: '',
    lastName: '',
}

export default (state = initState, action) => {
    switch (action.type) {
        case 'SET_PAYMENT_DETAILS':
            return {
                ...state,
                remaining_contract: action.remaining_contract,
                payment_status: action.payment_status,
                planId: action.planId,
                plan_type: action.plan_type,
            }
        case 'SET_SELECTED_CONTRACT_ID':
            return {
                ...state,
                selectedContractId: action.contractId
            }
        case 'SET_EMAIL':
            return {
                ...state,
                email: action.email,
            }
        case 'SET_FIRST_NAME':
            return {
                ...state,
                firstName: action.firstName,
            }
        case 'SET_LAST_NAME':
            return {
                ...state,
                lastName: action.lastName,
            }

        default:
            return state;

    }
}
