import { alertSuccess, alertError } from '../components/Alerts';
import Breadcrumbs_1 from "../components/Breadcrumbs_1"
import UserTemplates from '../components/UserTemplates'
import Signature from "../components/Signature";
import { useNavigate } from 'react-router-dom';
import { validate } from 'email-validator';
import { useSelector } from "react-redux";
import PaymentModal from './PaymentModal';
import Recipients from "./Recipients"
import instance from "../auth/Axios"
import Signers from './Signers';
import Message from "./Message"
import Swal from 'sweetalert2'
import Status from "./Status"
import React from 'react'

const ContractReview = (props) => {

    const navigate = useNavigate();
    const ans = useSelector((state) => state.contract.ans);
    const versionsComments = useSelector((state) => state.contract.versionsComments);

    const [currentStage, setCurrentStage] = React.useState(0)
    const [signaturesData, setSignaturesData] = React.useState([])
    const [userTemplateModal, setUserTemplateModal] = React.useState(false)
    const [paymentModalStatus, setPaymentModalStatus] = React.useState(false)
    const [stages, setStages] = React.useState(["Email Recipients", "Download & Send"])
    const { payment_status, remaining_contract } = useSelector((state) => state.payment);
    const [userTemplateVisibilityModal, setUserTemplateVisibilityModal] = React.useState(false)

    const [shareWithContractRecipient, setShareWithContractRecipient] = React.useState(true)
    const [editFromYourSide, setEditFromYourSide] = React.useState(true)
    const [yourOwner, setYourOwner] = React.useState({
        email: '',
        firstName: '',
        lastName: '',
    })
    const [newDraftOwner, setNewDraftOwner] = React.useState({
        email: '',
        firstName: '',
        lastName: '',
    }) // contractRecipientOwner is in the props coming from the ComposerPage
    const [yourViewers, setYourViewers] = React.useState([])
    const [contractRecipientViewers, setContractRecipientViewers] = React.useState([])

    const [cc, setCc] = React.useState([])

    const [firstSignatureBlock, setFirstSignatureBlock] = React.useState(true)
    const [editYourSigner, setEditYourSigner] = React.useState(false)
    const [yourPreviousSigner, setYourPreviousSigner] = React.useState(false)
    const [yourNewSigner, setYourNewSigner] = React.useState({
        email: '',
        firstName: '',
        lastName: '',
    })
    const [editContractRecipientSigner, setEditContractRecipientSigner] = React.useState(true)
    const [contractRecipientPreviousSigner, setContractRecipientPreviousSigner] = React.useState(false)
    const [contractRecipientSigner, setContractRecipientSigner] = React.useState({
        email: '',
        firstName: '',
        lastName: '',
    })
    const [signersMessage, setSignersMessage] = React.useState('')

    const [yourSideSigned, setYourSideSigned] = React.useState(null)
    const [contractRecipientSideSigned, setContractRecipientSideSigned] = React.useState(null)

    const [signersButtonDisabled, setSignersButtonDisabled] = React.useState(false)

    const handleSigners = async () => {
        if (props.currentCollaborator.role === 'viewer') {
            alertError("You are not authorized to perform this action")
            return
        }
        if (editYourSigner) {
            if (validate(yourNewSigner.email) === false) {
                alertError(yourNewSigner.email + " is not a valid email address")
                return
            }
            else if (yourNewSigner.email === props.contractRecipientOwner.email) {
                alertError("You can't add the same email address as the contract recipient owner")
                return
            }
            else {
                for (let i = 0; i < cc.length; i++) {
                    if (cc[i].team !== props.currentCollaborator.team && yourNewSigner.email === cc[i].email) {
                        alertError("You can't add the same email address as the contract recipient viewers")
                        return
                    }
                }
            }
        }
        if (editContractRecipientSigner) {
            if (validate(contractRecipientSigner.email) === false) {
                alertError(contractRecipientSigner.email + " is not a valid email address")
                return
            }
            else if (contractRecipientSigner.email === yourOwner.email) {
                alertError("You can't add the same email address as the current owner")
                return
            }
            else {
                for (let i = 0; i < cc.length; i++) {
                    if (cc[i].team === props.currentCollaborator.team && contractRecipientSigner.email === cc[i].email) {
                        alertError("You can't add the same email address as the current viewers")
                        return
                    }
                }
            }
        }
        if (!signersMessage.trim().length) {
            alertError("Please enter a message")
            return
        }

        let finalAns = { ...ans };
        if (Object.keys(props.previousVersion).length) {
            for (let key in ans) {
                if (key.includes("OriginalText") && ans[key].removeWarning) {
                    finalAns[key].removeWarning = false;
                }
            }
            for (let key in ans) {
                if (key.includes("CustomText")) {
                    if (props.counterPartyId) {
                        let innerHTML = document.getElementById(key.replace("CustomText", "")).innerHTML
                        while (innerHTML.includes('<span style="color: blue"><u>')) {
                            let removalTextStartIndex = innerHTML.indexOf('<span style="color: blue"><u>')
                            let removalTextEndIndex = innerHTML.indexOf('</u></span>', removalTextStartIndex) + 11
                            innerHTML = innerHTML.replace(innerHTML.substring(removalTextStartIndex, removalTextEndIndex), "")
                        }
                        finalAns[key.replace("Custom", "Original")].value = innerHTML
                    }
                    if (!props.previousVersion.version.structured_options_answers[key]) {
                        finalAns[key.replace("Custom", "Original")].addWarning = true;
                        finalAns[key.replace("Custom", "Original")].changeWarning = false;
                        finalAns[key.replace("Custom", "Original")].removeWarning = false;
                    }
                    else {
                        finalAns[key.replace("Custom", "Original")].changeWarning = true;
                        finalAns[key.replace("Custom", "Original")].addWarning = false;
                        finalAns[key.replace("Custom", "Original")].removeWarning = false;
                    }
                }
            }

            for (let key in props.previousVersion.version.structured_options_answers) {
                if (key.includes("CustomText")) {
                    if (!ans[key]) {
                        if (props.counterPartyId) {
                            let innerHTML = document.getElementById(key.replace("CustomText", "")).innerHTML
                            while (innerHTML.includes('<span style="color: #FE1E1F"><s>')) {
                                let removalTextStartIndex = innerHTML.indexOf('<span style="color: #FE1E1F"><s>')
                                let removalTextEndIndex = innerHTML.indexOf('</s></span>', removalTextStartIndex) + 11
                                innerHTML = innerHTML.replace(innerHTML.substring(removalTextStartIndex, removalTextEndIndex), "")
                            }
                            finalAns[key.replace("Custom", "Original")].value = innerHTML
                        }
                        finalAns[key.replace("Custom", "Original")].removeWarning = true;
                        finalAns[key.replace("Custom", "Original")].changeWarning = false;
                        finalAns[key.replace("Custom", "Original")].addWarning = false;
                    }
                }
            }
        }
        await instance.post("/version/createMajorVersion", {
            contractId: props.contractId,
            structured_options_answers: finalAns,
            comments: versionsComments[0].comments
        });

        const data = {
            contractId: props.contractId,
            contractName: props.contractName,
            yourSigner: yourNewSigner,
            contractRecipientSigner: editContractRecipientSigner ? contractRecipientSigner : null,
            to: [
                {
                    email: yourNewSigner.email,
                    firstName: yourNewSigner.firstName,
                    lastName: yourNewSigner.lastName,
                    team: props.currentCollaborator.team,
                    role: 'signer',
                },
                editContractRecipientSigner ?
                    {
                        email: contractRecipientSigner.email,
                        firstName: contractRecipientSigner.firstName,
                        lastName: contractRecipientSigner.lastName,
                        team: props.currentCollaborator.team === "proposer" ? "counterParty" : "proposer",
                        role: 'signer',
                    }
                    :
                    null
                ,
                ...cc
            ].filter((item) => item !== null),
            message: signersMessage,
            data: props.cleanContract(),
            anchorString1:
            {
                anchorString: firstSignatureBlock ? ans["CONTACTINFORMATION1"].value : ans["CONTACTINFORMATION2"].value,
                xOffSet: ans["SIGNATORY1"] ? '15' : '3',
                yOffSet: ans["SIGNATORY1"] ? '-71' : '-58',
            },
            anchorString2: {
                anchorString: firstSignatureBlock ? ans["CONTACTINFORMATION2"].value : ans["CONTACTINFORMATION1"].value,
                xOffSet: ans["SIGNATORY2"] ? '15' : '3',
                yOffSet: ans["SIGNATORY2"] ? '-71' : '-58',
            }
        }

        const resp = await instance.post('/contract/createEnvelope', data)
        setSignersButtonDisabled(false)
        if (resp.status === 200) {
            window.location.reload()
        }
    }

    const handlePaymentModal = () => {
        if (props.currentCollaborator.team === "proposer" && !props.isPaid && remaining_contract <= 0) {
            setPaymentModalStatus(true)
            return false
        }
        return true
    }

    const handleSubmit = async () => {
        try {
            if (props.currentCollaborator.team === "proposer" && !props.isPaid && remaining_contract <= 0) {
                setPaymentModalStatus(true)
                return false
            }

            if (props.currentCollaborator.role !== 'owner') {
                alertError("You are not authorized to perform this action")
                return
            }

            if (shareWithContractRecipient) {
                if (validate(yourOwner.email) === false) {
                    alertError(yourOwner.email + " is not a valid email address")
                    return
                }
                if (validate(props.contractRecipientOwner.email) === false) {
                    alertError(props.contractRecipientOwner.email + " is not a valid email address")
                    return
                }
                if (cc.length > 0) {
                    for (let i = 0; i < cc.length; i++) {
                        if (validate(cc[i].email) === false) {
                            alertError(cc[i].email + " is not a valid email address")
                            return
                        }
                    }
                }
            }
            else if (editFromYourSide) {
                if (validate(newDraftOwner.email) === false) {
                    alertError(newDraftOwner.email + " is not a valid email address")
                    return
                }
                if (cc.length > 0) {
                    for (let i = 0; i < cc.length; i++) {
                        if (cc[i].team === props.currentCollaborator.team && validate(cc[i].email) === false) {
                            alertError(cc[i].email + " is not a valid email address")
                            return
                        }
                    }
                }
            }

            if (!props.message || !props.message.trim().length) {
                alertError("Please enter a message")
                return
            }

            const data = {
                contractId: props.contractId,
                contractName: props.contractName,
                contractRecipientOwnerEmail: shareWithContractRecipient ? props.contractRecipientOwner.email : null,
                newDraftOwnerEmail: !shareWithContractRecipient && editFromYourSide ? newDraftOwner.email : null,
                to: shareWithContractRecipient ?
                    [
                        {
                            email: yourOwner.email,
                            firstName: yourOwner.firstName,
                            lastName: yourOwner.lastName,
                            team: props.currentCollaborator.team,
                            role: 'owner',
                        },
                        {
                            email: props.contractRecipientOwner.email,
                            firstName: props.contractRecipientOwner.firstName,
                            lastName: props.contractRecipientOwner.lastName,
                            team: props.currentCollaborator.team === "proposer" ? "counterParty" : "proposer",
                            role: 'owner',
                        },
                        ...cc
                    ]
                    :
                    editFromYourSide ?
                        [
                            {
                                email: yourOwner.email,
                                firstName: yourOwner.firstName,
                                lastName: yourOwner.lastName,
                                team: props.currentCollaborator.team,
                                role: 'owner',
                            },
                            {
                                email: newDraftOwner.email,
                                firstName: newDraftOwner.firstName,
                                lastName: newDraftOwner.lastName,
                                team: props.currentCollaborator.team,
                                role: 'viewer',
                            },
                            ...(cc.map((item) => {
                                if (item.team === props.currentCollaborator.team) {
                                    return item
                                }
                            }).filter((item) => item !== undefined))
                        ]
                        :
                        [
                            {
                                email: yourOwner.email,
                                firstName: yourOwner.firstName,
                                lastName: yourOwner.lastName,
                                team: props.currentCollaborator.team,
                                role: 'owner',
                            },
                            ...(cc.map((item) => {
                                if (item.team === props.currentCollaborator.team) {
                                    return item
                                }
                            }).filter((item) => item !== undefined))
                        ],
                message: props.message,
                data: props.cleanContract(),
            }

            let emails = []
            for (let i = 0; i < data.to.length; i++) {
                if (emails.includes(data.to[i].email)) {
                    alertError(`You have entered ${data.to[i].email} more than once. Please remove the duplicate email address.`)
                    return
                }
                emails = [...emails, data.to[i].email]
            }

            let finalAns = { ...ans };
            if (Object.keys(props.previousVersion).length) {
                for (let key in ans) {
                    if (key.includes("OriginalText") && ans[key].removeWarning) {
                        finalAns[key].removeWarning = false;
                    }
                }
                for (let key in ans) {
                    if (key.includes("CustomText")) {
                        if (props.counterPartyId) {
                            let innerHTML = document.getElementById(key.replace("CustomText", "")).innerHTML
                            while (innerHTML.includes('<span style="color: blue"><u>')) {
                                let removalTextStartIndex = innerHTML.indexOf('<span style="color: blue"><u>')
                                let removalTextEndIndex = innerHTML.indexOf('</u></span>', removalTextStartIndex) + 11
                                innerHTML = innerHTML.replace(innerHTML.substring(removalTextStartIndex, removalTextEndIndex), "")
                            }
                            finalAns[key.replace("Custom", "Original")].value = innerHTML
                        }
                        if (!props.previousVersion.version.structured_options_answers[key]) {
                            finalAns[key.replace("Custom", "Original")].addWarning = true;
                            finalAns[key.replace("Custom", "Original")].changeWarning = false;
                            finalAns[key.replace("Custom", "Original")].removeWarning = false;
                        }
                        else {
                            finalAns[key.replace("Custom", "Original")].changeWarning = true;
                            finalAns[key.replace("Custom", "Original")].addWarning = false;
                            finalAns[key.replace("Custom", "Original")].removeWarning = false;
                        }
                    }
                }

                for (let key in props.previousVersion.version.structured_options_answers) {
                    if (key.includes("CustomText")) {
                        if (!ans[key]) {
                            if (props.counterPartyId) {
                                let innerHTML = document.getElementById(key.replace("CustomText", "")).innerHTML
                                while (innerHTML.includes('<span style="color: #FE1E1F"><s>')) {
                                    let removalTextStartIndex = innerHTML.indexOf('<span style="color: #FE1E1F"><s>')
                                    let removalTextEndIndex = innerHTML.indexOf('</s></span>', removalTextStartIndex) + 11
                                    innerHTML = innerHTML.replace(innerHTML.substring(removalTextStartIndex, removalTextEndIndex), "")
                                }
                                finalAns[key.replace("Custom", "Original")].value = innerHTML
                            }
                            finalAns[key.replace("Custom", "Original")].removeWarning = true;
                            finalAns[key.replace("Custom", "Original")].changeWarning = false;
                            finalAns[key.replace("Custom", "Original")].addWarning = false;
                        }
                    }
                }
            }
            if (shareWithContractRecipient) {
                await instance.post("/version/createMajorVersion", {
                    contractId: props.contractId,
                    structured_options_answers: finalAns,
                    comments: versionsComments[0].comments
                });
            }

            const resp = await instance.post('/contract/send', data)
            if (resp.status === 200) {
                Swal.fire({
                    title: "Success",
                    text: 'Contract sent successfully',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    width: 450,
                    confirmButtonColor: '#2563EB',
                }).then(() => {
                    if (props.phase === "Drafting") {
                        setUserTemplateVisibilityModal(true)
                    }
                    else {
                        navigate(`/dashboard`)
                    }
                })
            } else if (resp.status === 400) {
                alertSuccess(resp.data.message)
            }
        } catch (error) {
            console.log(error)
            alertError(error);
        }
    }

    React.useEffect(() => {
        let tempCc = []
        if (yourViewers.length) {
            let temp = []
            for (let i = 0; i < yourViewers.length; i++) {
                temp = [...temp, {
                    email: yourViewers[i].email,
                    firstName: yourViewers[i].firstName,
                    lastName: yourViewers[i].lastName,
                    team: props.currentCollaborator.team,
                    role: "viewer"
                }]
            }
            tempCc = [
                ...tempCc,
                ...temp
            ]
        }
        if (contractRecipientViewers.length) {
            let temp = []
            for (let i = 0; i < contractRecipientViewers.length; i++) {
                temp = [...temp, {
                    email: contractRecipientViewers[i].email,
                    firstName: contractRecipientViewers[i].firstName,
                    lastName: contractRecipientViewers[i].lastName,
                    team: props.currentCollaborator.team === "proposer" ? "counterParty" : "proposer",
                    role: "viewer"
                }]
            }
            tempCc = [
                ...tempCc,
                ...temp
            ]
        }
        setCc(tempCc)
    }, [yourViewers, contractRecipientViewers])

    React.useEffect(() => {
        let yourViewers = []
        let yourOwner = null
        let yourSigner = null
        let contractRecipientViewers = []
        let contractRecipientOwner = null
        let contractRecipientSigner = null

        props.collaborators.forEach((collaborator) => {
            if (collaborator.team === props.currentCollaborator.team && collaborator.role === "owner") {
                yourOwner = collaborator.user
                setYourOwner(collaborator.user)
            }
            else if (collaborator.team === props.currentCollaborator.team && collaborator.role === "signer") {
                if (collaborator.signed) {
                    setYourSideSigned(collaborator.signed)
                }
                yourSigner = collaborator.user
                props.setInitiateSignWorkflow(true)
                setStages(["Date & Signatures", "Signers", "Status"])
                if (props.currentCollaborator.user.email === collaborator.user.email) {
                    setEditYourSigner(false)
                }
                else {
                    setEditYourSigner(true)
                }
                setYourPreviousSigner(true)
            }
            else if (collaborator.team === props.currentCollaborator.team && collaborator.role === "viewer") {
                yourViewers = [
                    ...yourViewers,
                    collaborator.user
                ]
            }
            else if (collaborator.team !== props.currentCollaborator.team && collaborator.role === "owner") {
                contractRecipientOwner = collaborator.user
                props.setContractRecipientOwner(collaborator.user)
            }
            else if (collaborator.team !== props.currentCollaborator.team && collaborator.role === "signer") {
                if (collaborator.signed) {
                    setContractRecipientSideSigned(collaborator.signed)
                }
                contractRecipientSigner = collaborator.user
                props.setInitiateSignWorkflow(true)
                setStages(["Date & Signatures", "Signers", "Status"])
                setEditContractRecipientSigner(true)
                setContractRecipientPreviousSigner(true)
            }
            else if (collaborator.team !== props.currentCollaborator.team && collaborator.role === "viewer") {
                contractRecipientViewers = [
                    ...contractRecipientViewers,
                    collaborator.user
                ]
            }
        })
        if (yourSigner) {
            setYourNewSigner(yourSigner)
        }
        else if (yourOwner) {
            setYourNewSigner(yourOwner)
        }
        if (contractRecipientSigner) {
            setContractRecipientSigner(contractRecipientSigner)
        }
        else if (contractRecipientOwner) {
            setContractRecipientSigner(contractRecipientOwner)
        }

        if (localStorage.getItem('stagingShareWith')) {
            if (!contractRecipientOwner && localStorage.getItem('stagingShareWith') === "Counter Party") {
                props.setContractRecipientOwner({
                    email: localStorage.getItem('stagingEmail'),
                    firstName: localStorage.getItem('stagingFirst'),
                    lastName: localStorage.getItem('stagingLast'),
                })
            }
            else if (localStorage.getItem('stagingShareWith') === "New Owner") {
                setShareWithContractRecipient(false)
                setNewDraftOwner({
                    email: localStorage.getItem('stagingEmail'),
                    firstName: localStorage.getItem('stagingFirst'),
                    lastName: localStorage.getItem('stagingLast'),
                })
            }
            props.setMessage(localStorage.getItem('stagingMessage'))
            setCurrentStage(1)
        }
        else if (props.phase === "Signing" || props.phase === "Signed") {
            if (yourSigner) {
                setStages(["Status"])
                setCurrentStage(0)
            }
            else {
                setCurrentStage(1)
            }
        }
        else {
            if (props.initiateSignWorkflow) {
                setStages(["Date & Signatures", "Signers", "Status"])
            }
            else {
                setStages(["Email Recipients", "Download & Send"])
            }
            setCurrentStage(0)
        }
        setYourViewers(
            localStorage.getItem('stagingYourViewers') ?
                [...JSON.parse(localStorage.getItem('stagingYourViewers')), ...yourViewers].filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.email === item.email
                    ))
                )
                : yourViewers
        )
        setContractRecipientViewers(
            localStorage.getItem('stagingCounterpartyViewers') ?
                [...JSON.parse(localStorage.getItem('stagingCounterpartyViewers')), ...contractRecipientViewers].filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.email === item.email
                    ))
                )
                : contractRecipientViewers
        )
    }, [props.initiateSignWorkflow])

    const handleSaveAndContinue = (index) => {

        if (shareWithContractRecipient && props.contractRecipientOwner.email.length === 0) {
            alertError("Please enter contract recipient email")
            return
        }

        // if (props.currentCollaborator.team === "proposer" && !props.isPaid && remaining_contract <= 0) {
        //     setPaymentModalStatus(true)
        // } else {
        if (stages[currentStage] === "Date & Signatures" && stages[index] !== "Status") {
            for (let i = 0; i < signaturesData.length; i++) {
                if (signaturesData[i].partyName === "" || signaturesData[i].contactInformation === "") {
                    alertError("Please fill out all fields")
                    return
                }
                if (signaturesData[i].contactInformation.trim().length < 3) {
                    alertError("Please enter valid contact information")
                    return
                }
                if (signaturesData[i].signatoryName === "" || signaturesData[i].signatoryTitle === "") {
                    if (signaturesData[i].partyType !== "Individual") {
                        alertError("Please fill out all fields")
                        return
                    }
                }
            }
        }
        else if (stages[index] === "Status") {
            return
        }
        if (index < stages.length) {
            setCurrentStage(index)
        }
        // }
    }


    return (
        <div>
            <div className='pt-3 text-xl font-bold text-center text-[#374151]'>{props.initiateSignWorkflow ? "Contract Signing" : "Contract Review"}</div>
            <div className='flex justify-center mt-3 relative left-[-20px]'>
                <Breadcrumbs_1 stages={stages} currentStage={currentStage} handleSaveAndContinue={handleSaveAndContinue} />
            </div>
            <div className='border border-[#E4E4E7] p-5 m-8 mt-5 rounded-[5px]'>
                {
                    props.initiateSignWorkflow ?
                        stages.length === 3 && currentStage === 0 ?
                            <Signature data={signaturesData} setData={setSignaturesData} />
                            :
                            stages.length === 3 && currentStage === 1 ?
                                <Signers
                                    yourViewers={yourViewers} setYourViewers={setYourViewers} contractRecipientViewers={contractRecipientViewers}
                                    setContractRecipientViewers={setContractRecipientViewers} firstSignatureBlock={firstSignatureBlock}
                                    setFirstSignatureBlock={setFirstSignatureBlock} editYourSigner={editYourSigner} setEditYourSigner={setEditYourSigner}
                                    yourNewSigner={yourNewSigner} setYourNewSigner={setYourNewSigner} editContractRecipientSigner={editContractRecipientSigner}
                                    signersMessage={signersMessage} setSignersMessage={setSignersMessage} contractRecipientSigner={contractRecipientSigner}
                                    setContractRecipientSigner={setContractRecipientSigner} yourPreviousSigner={yourPreviousSigner}
                                    contractRecipientPreviousSigner={contractRecipientPreviousSigner}
                                />
                                :
                                <Status contractId={props.contractId} contractName={props.contractName} yourNewSigner={yourNewSigner} yourViewers={yourViewers}
                                    setYourViewers={setYourViewers} contractRecipientSigner={contractRecipientSigner} contractRecipientViewers={contractRecipientViewers}
                                    setContractRecipientViewers={setContractRecipientViewers} yourSideSigned={yourSideSigned} contractRecipientSideSigned={contractRecipientSideSigned} />
                        :
                        currentStage === 0 ?
                            <Recipients
                                shareWithContractRecipient={shareWithContractRecipient} setShareWithContractRecipient={setShareWithContractRecipient}
                                editFromYourSide={editFromYourSide} setEditFromYourSide={setEditFromYourSide} yourOwner={yourOwner}
                                contractRecipientOwner={props.contractRecipientOwner} setContractRecipientOwner={props.setContractRecipientOwner}
                                yourViewers={yourViewers} setYourViewers={setYourViewers} contractRecipientViewers={contractRecipientViewers}
                                setContractRecipientViewers={setContractRecipientViewers} counterPartyId={props.counterPartyId}
                                newDraftOwner={newDraftOwner} setNewDraftOwner={setNewDraftOwner}
                            />
                            :
                            <>
                                <Message
                                    cleanContract={props.cleanContract} contractId={props.contractId}
                                    contractName={props.contractName} message={props.message} setMessage={props.setMessage}
                                    handlePaymentModal={handlePaymentModal} shareWithContractRecipient={shareWithContractRecipient}
                                    to={
                                        shareWithContractRecipient ? props.contractRecipientOwner.email : newDraftOwner.email
                                    }
                                    cc={shareWithContractRecipient ? [...cc]
                                        :
                                        cc.map((item) => {
                                            if (item.team === props.currentCollaborator.team) {
                                                return item
                                            }
                                        }).filter((item) => item !== undefined)} phase={props.phase} />
                            </>
                }
                <div className='flex'>
                    {
                        currentStage === 0 || stages[currentStage] === "Status" ? null :
                            <button onClick={() => {
                                if (currentStage > 0) {
                                    setCurrentStage(currentStage - 1)
                                }
                            }} className='flex justify-center items-center bg-[#FFFFFF] border border-[#D1D5DB] rounded-[12px] py-[9px] px-[17px] hover:bg-gray-100'>
                                <svg className='mr-3' width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.66683 12.8334L1.8335 7.00008L7.66683 1.16675" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <div className='text-sm font-semibold text-[#111928]'>Back</div>
                            </button>
                    }
                    {
                        stages[currentStage] === "Signers" ?
                            <button disabled={signersButtonDisabled} onClick={async () => {
                                document.body.style.cursor = 'wait';
                                setSignersButtonDisabled(true)
                                await handleSigners()
                                document.body.style.cursor = 'default';
                                setSignersButtonDisabled(false)
                            }} className='ml-auto flex justify-center items-center bg-[#1C64F2] border border-[#1C64F2] rounded-[12px] py-[9px] px-[17px] hover:bg-blue-700'>
                                <div className='text-sm font-semibold text-[#FFFFFF] mr-3'>
                                    {
                                        !editContractRecipientSigner ? "Request Counterparty Info" : "Request Signatures"
                                    }
                                </div>
                                <svg className='pt-[3px]' width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.875 1.9751L7.54167 7.64176L1.875 13.3084" stroke="white" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                            :
                            currentStage !== stages.length - 1 ?
                                <button onClick={() => {
                                    handleSaveAndContinue(currentStage + 1)
                                    document.getElementById("SelectionPane").scrollTo({ top: 0, behavior: "smooth" })
                                }} className='ml-auto flex justify-center items-center bg-[#1C64F2] border border-[#1C64F2] rounded-[12px] py-[9px] px-[17px] hover:bg-blue-700'>
                                    <div className='text-sm font-semibold text-[#FFFFFF] mr-3'>{stages[currentStage] === "Email Recipients" ? "Continue to Download & Send" : "Save & Continue"}</div>
                                    <svg className='pt-[3px]' width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.875 1.9751L7.54167 7.64176L1.875 13.3084" stroke="white" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                                :
                                stages[currentStage] === "Status" ?
                                    null
                                    :
                                    <button onClick={handleSubmit} className='ml-auto flex justify-center items-center bg-[#1C64F2] border border-[#1C64F2] rounded-[12px] py-[9px] px-[17px] hover:bg-blue-700'>
                                        <div className='text-sm font-semibold text-[#FFFFFF]'>Send Contract Draft</div>
                                    </button>
                    }

                </div>
            </div>

            {
                paymentModalStatus && <PaymentModal setPaymentModalStatus={setPaymentModalStatus} contractId={window.location.pathname.replace('/', '')} />
            }
            {
                userTemplateVisibilityModal &&
                <div
                    tabIndex={-1}
                    className="fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full overflow-auto"
                >
                    <div className='p-8 pr-4 sm:mx-[23vw] sm:my-[7vh] bg-[#FFFFFF] rounded-[10px]' style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                        <div className="mb-6">
                            <div className="font-semibold text-3xl text-[#111928] mb-2">Save draft as a template?</div>
                            <div className="font-normal text-[#6B7280]">By saving this draft as a template, you will be able to easily re-use the configurations from this draft in a future contract workflow. </div>
                        </div>
                        <div className="flex justify-end mt-10 gap-3">
                            <button onClick={() => {
                                setUserTemplateVisibilityModal(false)
                                navigate("/dashboard")
                            }} style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #D1D5DB", display: "flex", alignItems: "center", justifyContent: "center", width: "100px" }}>
                                <div className="text-sm font-semibold text-[#111928]">No</div>
                            </button>
                            <button onClick={() => {
                                setUserTemplateVisibilityModal(false)
                                setUserTemplateModal(true)
                            }} style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #1C64F2", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1C64F2", width: "100px" }}>
                                <div className="text-sm font-semibold text-[white]">Yes</div>
                            </button>
                        </div>
                    </div >
                </div>
            }
            {
                userTemplateModal && <UserTemplates onChange={setUserTemplateModal} contractId={props.contractId} step1SelectedOption={{ name: "Save draft as template" }} redirect={"/dashboard"} />
            }
        </div >
    )
}

export default ContractReview
