import React from "react";
import instance from "../auth/Axios";
import Sidebar from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PostSigninNavbar from "../components/PostSigninNavbar";
import { alertSuccess, alertError } from "../components/Alerts";

export const Setting = () => {
  const navigate = useNavigate();

  const [sidebar, setSidebar] = React.useState(false);
  const { payment_status, remaining_contract } = useSelector(
    (state) => state.payment
  );

  const [organization, setOrganization] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  const stripeCustomerPortal = async () => {
    try {
      if (payment_status != "paid") {
        navigate("/pricing");
      }
      const res = await instance.get(`/payment/customer-portal`);
      if (res.data.status) {
        return window.open(
          res.data.return_url,
          "_blank",
          "noopener,noreferrer"
        );
      }
    } catch (error) {
      navigate("/pricing");
      console.log(error);
    }
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await instance.put(`/user/update`, {
        organization,
        firstName,
        lastName,
      });
      if (res.data.status) {
        alertSuccess("User updated successfully!");
        localStorage.setItem("name", res.data.name);
        window.location.reload();
      }
    } catch (error) {
      alertError("Something went wrong when updating user!");
    }
  };

  React.useEffect(() => {
    let name = localStorage.getItem("name");

    setOrganization(name.split(" ").slice(2).join(" ").substring(1, name.split(" ").slice(2).join(" ").length - 1));
    setFirstName(name.split(" ")[0]);
    setLastName(name.split(" ")[1]);
  }, [localStorage.getItem("name")]);

  return (
    <div>
      <div className="flex">
        <Sidebar value={sidebar} setSidebar={setSidebar} />
        <div className="w-full">
          <PostSigninNavbar value={sidebar} onChange={setSidebar} />
          <div className="h-full">
            <div className="flex justify-between items-center px-12 mt-5">
              <p className="font-Inter font-semibold text-[#101828] text-sm">
                Remaining Contracts:
                <span className="text-[#1363DF]"> {remaining_contract} </span>
              </p>
              {/* <div>
                <button
                  onClick={() => {
                    navigate("/pricing");
                  }}
                  type="text"
                  className="inline-flex mr-4 cursor-pointer items-center gap-x-2 text-[14px] font-semibold font-Inter px-4 rounded-lg border-[1px] border-[#D0D5DD] py-2 shadow-leadButton hover:shadow-navButton"
                >
                  <svg
                    width="20"
                    height="16"
                    viewBox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.66602 5.33317H18.3327V3.83317C18.3327 2.89975 18.3327 2.43304 18.151 2.07652C17.9912 1.76292 17.7363 1.50795 17.4227 1.34816C17.0661 1.1665 16.5994 1.1665 15.666 1.1665H4.33268C3.39926 1.1665 2.93255 1.1665 2.57603 1.34816C2.26243 1.50795 2.00746 1.76292 1.84767 2.07652C1.66602 2.43304 1.66602 2.89975 1.66602 3.83317V10.1665C1.66602 11.0999 1.66602 11.5666 1.84767 11.9232C2.00746 12.2368 2.26243 12.4917 2.57603 12.6515C2.93255 12.8332 3.39926 12.8332 4.33268 12.8332H9.16602M12.0827 14.4998L13.7702 14.1623C13.9173 14.1329 13.9909 14.1182 14.0595 14.0913C14.1204 14.0674 14.1782 14.0364 14.2319 13.999C14.2923 13.9569 14.3454 13.9038 14.4515 13.7977L17.916 10.3332C18.3763 9.87293 18.3763 9.12674 17.916 8.6665C17.4558 8.20627 16.7096 8.20627 16.2493 8.66651L12.7848 12.131C12.6787 12.2371 12.6257 12.2902 12.5835 12.3506C12.5461 12.4043 12.5151 12.4622 12.4912 12.5231C12.4643 12.5917 12.4496 12.6652 12.4202 12.8124L12.0827 14.4998Z"
                      stroke="#475467"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className="text-sm font-semibold text-center text-[#344054]">
                    Pricing Table
                  </p>
                </button>
                <button
                  onClick={() => {
                    stripeCustomerPortal();
                  }}
                  type="text"
                  className="inline-flex cursor-pointer items-center gap-x-2 text-[14px] font-semibold font-Inter px-4 rounded-lg border-[1px] border-[#D0D5DD] py-2 shadow-leadButton hover:shadow-navButton"
                >
                  <svg
                    width="20"
                    height="16"
                    viewBox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.66602 5.33317H18.3327V3.83317C18.3327 2.89975 18.3327 2.43304 18.151 2.07652C17.9912 1.76292 17.7363 1.50795 17.4227 1.34816C17.0661 1.1665 16.5994 1.1665 15.666 1.1665H4.33268C3.39926 1.1665 2.93255 1.1665 2.57603 1.34816C2.26243 1.50795 2.00746 1.76292 1.84767 2.07652C1.66602 2.43304 1.66602 2.89975 1.66602 3.83317V10.1665C1.66602 11.0999 1.66602 11.5666 1.84767 11.9232C2.00746 12.2368 2.26243 12.4917 2.57603 12.6515C2.93255 12.8332 3.39926 12.8332 4.33268 12.8332H9.16602M12.0827 14.4998L13.7702 14.1623C13.9173 14.1329 13.9909 14.1182 14.0595 14.0913C14.1204 14.0674 14.1782 14.0364 14.2319 13.999C14.2923 13.9569 14.3454 13.9038 14.4515 13.7977L17.916 10.3332C18.3763 9.87293 18.3763 9.12674 17.916 8.6665C17.4558 8.20627 16.7096 8.20627 16.2493 8.66651L12.7848 12.131C12.6787 12.2371 12.6257 12.2902 12.5835 12.3506C12.5461 12.4043 12.5151 12.4622 12.4912 12.5231C12.4643 12.5917 12.4496 12.6652 12.4202 12.8124L12.0827 14.4998Z"
                      stroke="#475467"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className="text-sm font-semibold text-center text-[#344054]">
                    Manage Subscription
                  </p>
                </button>
              </div> */}
            </div>
            <div className="w-full bg-white rounded-lg mx-auto mt-8 flex overflow-hidden rounded-b-none h-[100%]">
              <div className="w-1/3 bg-gray-100 p-12 hidden md:inline-block">
                <h2 className="font-medium text-md text-gray-700 mb-4 tracking-wide">
                  Profile Info
                </h2>
                <p className="text-xs text-gray-500">
                  Update your profile information.
                </p>
              </div>
              <div className="md:w-2/3 w-full">
                <div className="py-4 px-16">
                  <label htmlFor="name" className="text-sm text-gray-600">
                    First Name
                  </label>
                  <input
                    className="mt-2 border-2 border-gray-200 px-3 py-2 block w-full rounded-lg text-base text-gray-900 focus:outline-none focus:border-indigo-500"
                    type="text"
                    defaultValue=""
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
                <div className="py-4 px-16">
                  <label htmlFor="name" className="text-sm text-gray-600">
                    Last Name
                  </label>
                  <input
                    className="mt-2 border-2 border-gray-200 px-3 py-2 block w-full rounded-lg text-base text-gray-900 focus:outline-none focus:border-indigo-500"
                    type="text"
                    defaultValue=""
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>
                <div className="py-4 px-16">
                  <label htmlFor="name" className="text-sm text-gray-600">
                    Organization
                  </label>
                  <input
                    className="mt-2 border-2 border-gray-200 px-3 py-2 block w-full rounded-lg text-base text-gray-900 focus:outline-none focus:border-indigo-500"
                    type="text"
                    defaultValue=""
                    value={organization}
                    onChange={(e) => {
                      setOrganization(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex p-16 py-8 bg-gray-300 clearfix rounded-b-lg border-t border-gray-200 w-full">
              <div className="text-xs text-gray-500 tracking-tight mt-2">
                Click on Save to update your Profile Info
              </div>
              <button
                onClick={handleSubmit}
                className="ml-auto bg-indigo-500 text-white text-sm font-medium px-6 py-2 rounded uppercase cursor-pointer"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
