import { Routes, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Introduction from './components/Introduction';
import ContractFolder from './pages/ContractFolder';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ResetPassword from "./pages/ResetPassword";
import PricingTable from './pages/PricingTable';
import ComposerPage from './pages/ComposerPage';
import TermsOfUse from "./pages/TermsOfUse";
import Dashboard from './pages/Dashboard';
import Contract from './pages/Contract';
import Signing from './pages/Signing';
import Setting from './pages/Setting';
import instance from './auth/Axios';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import React from 'react'

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { introductionStatus } = useSelector(state => state.payment)
  const currentRoute = location.pathname;

  const [showMobileWarning, setShowMobileWarning] = React.useState(false)

  React.useEffect(() => {
    async function fetchData() {
      if (currentRoute === '/signin' || currentRoute === '/') {
        dispatch({
          type: 'SET_PAYMENT_DETAILS', remaining_contract: 0,
          payment_status: '',
          planId: '',
          plan_type: ''
        })
        return;
      }
      const getUserDetails = await instance.get('/user/get-user-details')
      if (getUserDetails.data.status) {
        dispatch({
          type: 'SET_PAYMENT_DETAILS', remaining_contract: getUserDetails.data.remaining_contract,
          payment_status: getUserDetails.data.payment_status,
          planId: getUserDetails.data.planId,
          plan_type: getUserDetails.data.plan_type
        })
      }
    }

    if (window.innerWidth <= 600)
      setShowMobileWarning(true)
    else
      fetchData()
  }, [currentRoute])

  React.useEffect(() => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const newWidth = windowWidth * 1.25;
    const newHeight = windowHeight * 1.25;

    window.resizeBy(newWidth - windowWidth, newHeight - windowHeight);
  }, [])

  return (
    <>
      {
        showMobileWarning ?
          <div className="w-screen h-screen flex flex-col justify-center items-center">
            <h1>Sorry, this website is not available on mobile devices.</h1>
            <h2>Please visit on a desktop or laptop.</h2>
          </div>
          :
          <Routes>
            <Route path="/dashboard/:folderName" element={<ContractFolder />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/:contractUUID" element={<ComposerPage />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            {/* <Route path="/pricing" element={<PricingTable />} /> */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/contract" element={<Contract />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/signing" element={<Signing />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/" element={<SignUp />} />
          </Routes>
      }

      {introductionStatus && <Introduction />}
    </>
  );
}

export default App;