import React from 'react'
import instance from "../auth/Axios";
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { alertSuccess } from "../components/Alerts";

function PaymentModal(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    return (
        <div
            tabIndex={-1}
            className="flex justify-center items-center fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full overflow-auto"
        >
            <div className='px-8 py-6 w-[50%] bg-[#FFFFFF] rounded-[10px] relative' style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                <div className="absolute right-5 top-5">
                    <svg onClick={() => { props.setPaymentModalStatus(false) }} className="h-6 w-6 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
                <div className="modal-header flex justify-between items-center px-4 py-2">
                    {/* <h2 className="text-lg font-medium">Subscribe or upgrade!</h2> */}
                    <h2 className="text-lg font-medium">Private Beta Access</h2>
                    <button >
                        {/* <FaTimes className="text-gray-500" /> */}
                    </button>
                </div>
                <div className="modal-body px-4 py-2">
                    {/* <p className="mb-4">
                        In order to download or send this contract, please subscribe or upgrade your plan. Single-use contracts and free trials are also available.
                    </p> */}
                    <p className="mb-4">As a user in Standard Draft’s private beta, you will be provided with free contract workflows.</p>
                    <p className="mb-4">This will allow you to download contracts and to send contracts to others (e.g., your counterparty or a collaborator). Please note that when you send a contract to someone else, he or she will be able to use Standard Draft to work on the contract without needing to make any payment.</p>
                    <p className="mb-4">Our private beta offer is only available for a limited time. Please click on Redeem below to access your free Standard Draft contract workflows!</p>
                    <div className="flex justify-end">
                        {/* <button
                            onClick={() => {
                                dispatch({ type: 'SET_SELECTED_CONTRACT_ID', contractId: props.contractId })
                                navigate('/pricing')

                            }}
                            className="bg-blue-500 text-white py-2 px-7 rounded-lg mr-2"
                        >
                            Upgrade plan
                        </button> */}
                        {/* <button
                            onClick={() => {
                                dispatch({ type: 'SET_SELECTED_CONTRACT_ID', contractId: props.contractId })
                                navigate('/pricing')
                            }}
                            variant="outline"
                            className='bg-blue-500 text-white py-2 px-7 rounded-lg mr-2'
                        >
                            See All Plans
                        </button> */}
                        <button
                            onClick={async () => {
                                await instance.post(`/payment/early-access`, {
                                    accessCode: "sdbeta2023",
                                });
                                const getUserDetails = await instance.get("/user/get-user-details");
                                if (getUserDetails.data.status) {
                                    alertSuccess("Free Trial Activated");
                                    window.location.reload();
                                }
                            }}
                            className="bg-blue-500 text-white py-2 px-7 rounded-lg mr-2"
                        >
                            Redeem
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PaymentModal