import PostSigninNavbar from '../components/PostSigninNavbar'
import PreSigninNavbar from '../components/PreSigninNavbar'
import Sidebar from '../components/Sidebar'
import React from 'react'

const PrivacyPolicy = () => {
    const [sidebar, setSidebar] = React.useState(false);

    return (
        <div>
            <div className='flex'>
                {window.localStorage.getItem('accessToken') && <Sidebar value={sidebar} setSidebar={setSidebar} />}
                <div className='w-full'>
                    {window.localStorage.getItem('accessToken') ?
                        <PostSigninNavbar value={sidebar} onChange={setSidebar} />
                        :
                        <PreSigninNavbar />
                    }
                    <div className='py-5 px-8 text-justify'>
                        <b>Standard Draft, Inc. Privacy Policy</b>
                        <p className='mb-3'>Last Modified: June 1, 2023</p>
                        <p className='mb-3'>At Standard Draft, Inc. (“Standard Draft”, “we”, “us” or “our”), your privacy is a priority. This Privacy Policy explains how we collect, use, share, and protect your personal information when you use our website, platform and related services (collectively, our "Services").</p>
                        <b>1. Information We Collect</b>
                        <p className='mb-3'>We collect information from you both when you provide it voluntarily and automatically when you use our Services.</p>
                        <b>1(a) Information You Provide to Us</b>
                        <p className='mb-3'>We collect information you provide to us. For example, we collect information when you create an account, use the interactive areas and features of our Services, request customer support, or otherwise communicate with us.</p>
                        <p className='mb-3'>The types of information we may collect include your name, email address, postal address, professional title, organizational affiliation, phone number, your user-generated content (such as comments, questions, feedback, configurations to our contract forms, custom-generated language to our contract forms, information about counterparties and other content or information that you submit to, or use with, our Services) and any other information you choose to provide.</p>
                        <b>1(b) Information We Collect Automatically When You Use Our Services</b>
                        <p>When you access or use our Services, we automatically collect information about you, including:</p>
                        <ul className='list-disc ml-8 mb-3'>
                            <li>log information about your use of our Services, including your browser type and language, access times, pages viewed, your IP address and the website you visited before navigating to our Services;</li>
                            <li>information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, unique device identifiers, and mobile network information;</li>
                            <li>information about the location of your device each time you use our Services;</li>
                            <li>cookies (note, you may refuse to accept browser cookies by activating the appropriate setting on your browser; however, if you select this setting you may be unable to access certain parts of our website or Services);</li>
                            <li>local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our website; and</li>
                            <li>web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit us, for example, to count users who have visited specific pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).</li>
                        </ul>
                        <b>2. How We Use Your Information</b>
                        <p>We may use the information we collect from you in various ways, including to:</p>
                        <ul className='list-disc ml-8 mb-3'>
                            <li>provide, maintain, and improve our Services, including to operate features and functionality of our Services (for example, remembering your information and contract configurations so that you will not have to re-enter such information during this or subsequent visits);</li>
                            <li>provide and deliver the products and services you request, process transactions and send you related information;</li>
                            <li>send you technical notices, updates, security alerts, and support and administrative messages;</li>
                            <li>respond to your comments, questions, and requests, and provide customer service;</li>
                            <li>monitor and analyze trends, usage, and activities in connection with our Services (we use Google Analytics for certain purposes; if you prefer to prevent the use of Google Analytics relating to your use of our site, please visit: <a className='text-blue-700 font-[600]' href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a>);</li>
                            <li>personalize and improve our Services and provide content or features that match user profiles or interests;</li>
                            <li>link or combine with information we get from others to help understand your needs and provide you with better service;</li>
                            <li>communicate directly with you, including by sending you newsletters, promotions, or information about our products and services (your opt-out options for promotional communications are described below under the heading “Your Choices”);</li>
                            <li>to investigate or control potential unauthorized use or abuse of our Services and our products and services; and</li>
                            <li>carry out any other purpose for which the information was collected.</li>
                        </ul>
                        <b>3. Sharing of Information</b>
                        <p className='mb-3'>We may disclose aggregated information about our users and their behavior (for example, statistics and data related to how users configure contracts on our platform), and information that does not identify any individual, without restriction. If we are required under applicable law to treat such information as personally identifiable information, then we will only disclose it as described below.</p>
                        <p>We may disclose personal information that we collect or you provide as described in this Privacy Policy:</p>
                        <ul className='list-disc ml-8 mb-3'>
                            <li>to our subsidiaries and affiliates;</li>
                            <li>with vendors, consultants, contractors, and other service providers who need access to such information to carry out work on our behalf;</li>
                            <li>with other users and the public (for example, your comments to blog posts, articles or similar resources on our site can be seen, collected and used by others, including being cached, copied, screen captured or stored elsewhere by others (e.g., search engines), and we are not responsible for any such use of this information);</li>
                            <li>to fulfill the purpose for which you provide it (for example, if you submit an email address to invite someone to negotiate a contract using our Services, we will transmit the contents of that email, the proposed contract (along with any configurations you have made, including any custom text you have incorporated into the contract), any comments you have provided and your email address to the recipients);</li>
                            <li>in response to a request for information if we believe disclosure is in accordance with any applicable law, regulation, or legal process, or as otherwise required by any applicable law, rule, or regulation;</li>
                            <li>if we believe your actions are inconsistent with the spirit or language of our user agreements or policies, or to protect the rights, property, and safety of Standard Draft or others (including exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction);</li>
                            <li>in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company; or</li>
                            <li>with your consent or at your direction.</li>
                        </ul>
                        <b>4. Security</b>
                        <p className='mb-3'>Standard Draft takes reasonable measures to help protect all information about you from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our Services. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on our website.</p>
                        <b>5. Your Choices</b>
                        <p className='mb-3'>We provide you with choices regarding the information you provide to us. The mechanisms provided for you to control these functions may depend on your device settings or how you interact with our Services. You may opt-out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of the email, or by contacting us using the information provided at the end of this Privacy Policy under the heading “Contact Information”. Please note that if you choose to opt-out of marketing-related emails, you may continue to receive service-related and other non-marketing emails.</p>
                        <b>6. Children's Privacy</b>
                        <p className='mb-3'>Standard Draft’s Services are not directed to, and we do not knowingly collect personal information from, individuals under the age of 18. If you are under 18, you may not attempt to register for our Services or send any information about yourself to us, including your name, address, telephone number, or email address. If we learn that we have collected personal information through our Services from a child without the consent of the child’s parent or guardian as required by law, we will comply with applicable legal requirements to delete the information.</p>
                        <b>7. International Users and International Transfer of Data</b>
                        <p className='mb-3'>Our Services are intended for users in the United States. If you are a user accessing our Services from any other country with laws governing data collection and use that may differ from U.S. law, please note that your personal data may be transferred to the United States and processed there.</p>
                        <p className='mb-3'>We may from time to time transfer your personal information to other countries outside the United States and make it accessible to our affiliates and third party service providers internationally. By using our Services or otherwise providing personal information to us, you are consenting to have your personal information transferred to and processed in the United States or any other country in which we or our affiliates and service providers maintain facilities.</p>
                        <b>8. California Privacy Rights</b>
                        <p className='mb-3'>If you are a California resident, it is our policy pursuant to California’s Civil Code Section 1798.83 not to disclose your personally identifiable information to third parties for such third parties’ direct marketing purposes unless you have opted-in to such disclosures. Please note that even if you do not opt-in, we may still share your information with third-parties for purposes other than direct marketing, such as for completing a requested transaction or any other non-direct marketing purposes permitted under this Privacy Policy.</p>
                        <p className='mb-3'>Furthermore, California Civil Code Section 1798.83 permits customers in California to request certain details about how certain types of their information are shared with third parties and, in some cases, affiliates, for those third parties’ and affiliates’ own direct marketing purposes. You may make such a request by contacting us using the information provided at the end of this Privacy Policy under the heading “Contact Information” with a preference on how our response to your request should be sent.</p>
                        <b>9. Changes to this Privacy Policy</b>
                        <p className='mb-3'>Standard Draft may modify this Privacy Policy from time to time. If we make any material changes to this Privacy Policy, we will change the “Last Updated” date above and we will post the updated Privacy Policy on this page. Any modifications to this Privacy Policy will be effective upon our posting of the modified version (or as otherwise indicated at the time of posting). In all cases, your use of our Services after the effective date of any modified Privacy Policy indicates your acceptance of the modified Privacy Policy.</p>
                        <b>10. Contact Us</b>
                        <p className='mb-3'>Please contact us with any questions or comments you may have at legal@standarddraft.com.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy
