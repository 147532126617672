import React from "react";
import { useNavigate } from "react-router-dom";
import { SlMagnifierAdd } from "react-icons/sl";

const PostSigninNavbar = (props) => {
    let btnRef = React.useRef(null);
    const navigate = useNavigate()

    const [dropdown, setDropdown] = React.useState(false);

    React.useEffect(() => {
        const closeDropdown = (event) => {
            if (!btnRef.current.contains(event.target)) {
                setDropdown(false);
            }
        }
        document.addEventListener('click', closeDropdown);
        return () => { document.removeEventListener('click', closeDropdown) }
    }, []);
    return (
        <>
            <nav className="bg-white py-2" style={{ borderBottom: "1px solid #E5E7EB" }}>
                <div className="mx-auto px-2 sm:px-4">
                    <div className="relative flex items-center justify-between">
                        <div className="m-1 flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                            <div className="flex gap-x-4 item-center">
                                <div className='group relative z-50'>
                                    <span className='text-xs font-medium text-white py-[2px] px-[10px] bg-blue-200 rounded-[10px] cursor-pointer hover:bg-[#3F83F8]'>Beta</span>
                                    <span className="absolute top-[25px] left-[-5px] scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100 w-[300px]">
                                        <p className="mb-1.5">Welcome to the Standard Draft beta! You are working with the initial version of our product, which we continue to improve and refine.</p>
                                        <p className="mb-1.5">
                                            {props.contractTemplateName?.includes("Short-Form") ? <>This <b>Standard Draft Short-Form NDA v0.1</b> is optimized for commercial transactions. </> : <>This <b>Standard Draft Long-Form NDA v0.1</b> is optimized for complex transactions. </>}
                                            The Standard Draft NDA will continue to be updated before full release.
                                        </p>
                                        <p className="mb-1.5"><b>We depend on your feedback (on the tech product and contract substance) to help shape our platform.</b></p>
                                        <p className="">Please send all feedback (in whatever format is most convenient) to <span className="text-blue-600">beta@standarddraft.com</span> so we can directly respond to you. Your early support is extremely appreciated!</p>
                                    </span>
                                </div>
                                <div className='group relative z-50'>
                                    <SlMagnifierAdd className="mt-1.5 text-gray-600 cursor-pointer" />

                                    <span className="absolute top-[25px] left-[-5px] scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100 w-[300px]">
                                        <p className="mb-1.5">Based on screen sizes and default view settings, some users have reported a preference for a more zoomed-in interface.  </p>
                                        <p className="mb-1.5">If the interface below or any text appears too small, please zoom in using your browser’s settings. Alternatively, you may press ⌘ and + (on Mac) <span className="underline">or</span> Ctrl and + (on Windows).</p>
                                        <p className="mb-1.5">Please use the Google Chrome browser for best performance.</p>
                                    </span>
                                </div>
                            </div>
                            <div className="ml-auto flex justify-center items-center">
                                <>
                                    {/* <img onClick={() => setDropdown(!dropdown)} className="mt-1 w-[25px] h-[25px]" src="/Images/Navbar/Avatar Image 2.png" /> */}
                                    <div ref={btnRef} onClick={() => setDropdown(!dropdown)} className="flex items-center ml-2 text-base font-medium text-[#111928] hover:cursor-pointer">
                                        {localStorage.getItem("name")}
                                        <svg onClick={() => setDropdown(!dropdown)} className="ml-3 mt-1.5" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M0.410145 0.710241C0.672684 0.447781 1.02872 0.300339 1.39995 0.300339C1.77117 0.300339 2.12721 0.447781 2.38974 0.710241L6.99995 5.32044L11.6101 0.710241C11.7393 0.576526 11.8938 0.469871 12.0646 0.396499C12.2354 0.323126 12.4191 0.284505 12.605 0.28289C12.7909 0.281274 12.9752 0.316697 13.1473 0.38709C13.3193 0.457483 13.4756 0.561437 13.6071 0.692887C13.7385 0.824337 13.8425 0.98065 13.9129 1.1527C13.9833 1.32476 14.0187 1.50911 14.0171 1.695C14.0155 1.88089 13.9769 2.0646 13.9035 2.23541C13.8301 2.40621 13.7235 2.56069 13.5897 2.68984L7.98974 8.28984C7.72721 8.5523 7.37117 8.69974 6.99995 8.69974C6.62872 8.69974 6.27268 8.5523 6.01015 8.28984L0.410145 2.68984C0.147686 2.4273 0.000244141 2.07127 0.000244141 1.70004C0.000244141 1.32881 0.147686 0.972779 0.410145 0.710241Z" fill="#111928" />
                                        </svg>
                                    </div>
                                    <ul id="navbarDropdown" className={`origin-top-right z-50 absolute right-1 top-10 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${dropdown ? "block" : "hidden"}`}>
                                        <li><p onClick={() => navigate('/setting')} className="block px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">Settings</p></li>
                                        <li><p onClick={() => navigate('/signin')} className="block px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" >Logout</p></li>
                                    </ul>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default PostSigninNavbar;