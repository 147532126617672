import { scrollToElement } from "../components/composer-components/utils/scrollToElement";
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { alertError, alertSuccess, alertInfo } from '../components/Alerts'
import PostSigninNavbar from "../components/PostSigninNavbar";
import ContractReview from "../components/ContractReview";
import PreviewButtons from "../components/PreviewButtons";
import { useSelector, useDispatch } from "react-redux";
import PreviewPane from "../components/PreviewPane";
import { ObjtoHTML } from "../components/Composer";
import TopHeader from "../components/TopHeader";
import { useParams } from "react-router-dom";
import Stepper from "../components/Stepper";
import Sidebar from "../components/Sidebar";
import { useEffect, useState } from "react";
import GPTMode from "../components/GPTMode";
import instance from '../auth/Axios';
import Mustache from "mustache";
import React from "react";

const ComposerPage = () => {

    const { contractUUID } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const freeTrial = new URLSearchParams(location.search).get('freeTrial');
    const successId = new URLSearchParams(location.search).get('success');
    const failedId = new URLSearchParams(location.search).get('failed');

    const ds = useSelector((state) => state.contract.ds);
    const ans = useSelector((state) => state.contract.ans);
    const handleToggle = useSelector((state) => state.contract.handleToggle);
    const versionsComments = useSelector((state) => state.contract.versionsComments);

    const [contract, setContract] = useState({})
    const [contractId, setContractId] = useState(null)
    const [sidebar, setSidebar] = React.useState(false)
    const [saveVersion, setSaveVersion] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [hoverStep, setHoverStep] = React.useState(0);
    const [versionSavedTime, setVersionSavedTime] = useState(new Date());
    const [currentCollaborator, setCurrentCollaborator] = useState(null);
    const [previousVersion, setPreviousVersion] = React.useState({}) // Most recent major version
    const [secondPreviousVersion, setSecondPreviousVersion] = React.useState({}) // Second most recent major version
    const [signWorkflow, setSignWorkflow] = useState(false)
    const [hideDrafter, setHideDrafter] = useState(false)
    const [initiateSignWorkflow, setInitiateSignWorkflow] = React.useState(false)
    const [previewButton, setPreviewButton] = React.useState("changes")
    const [loaderVisibility, setLoaderVisibility] = React.useState(false)
    const [closeModal, setCloseModal] = React.useState(false)
    const [instructionsModalVisibility, setInstructionsModalVisibility] = React.useState(false)
    const [changeCounterpartyOwner, setChangeCounterpartyOwner] = React.useState(false)
    const [aiWorkflow, setAiWorkflow] = React.useState(false)
    const [chatMessages, setChatMessages] = React.useState([])

    const [contractRecipientOwner, setContractRecipientOwner] = React.useState({
        email: '',
        firstName: '',
        lastName: '',
    }) // ContractReview state
    const [message, setMessage] = React.useState('') // ContractReview state

    React.useEffect(() => {
        async function saveData() {
            if (successId !== null && successId !== undefined) {
                try {
                    setLoaderVisibility(true)
                    const res = await instance.post(`/payment/save-Retrieve-session`, { sessionId: successId })
                    if (res.data.status) {
                        const getUserDetails = await instance.get('/user/get-user-details')
                        if (getUserDetails.data.status) {
                            if (res.data.isFreeTrial) {
                                alertSuccess("Free Trial Activated!")
                            } else {
                                alertSuccess("Payment Successfull!")
                            }
                            dispatch({
                                type: 'SET_PAYMENT_DETAILS', remaining_contract: getUserDetails.data.remaining_contract,
                                payment_status: getUserDetails.data.payment_status,
                                planId: getUserDetails.data.planId,
                                plan_type: getUserDetails.data.plan_type
                            })
                            navigate(window.location.pathname)
                        }
                    }
                } catch (error) {
                    alertError("Payment Failed")
                } finally {
                    setLoaderVisibility(false)
                }
            }
        }
        saveData()
    }, [successId])

    React.useEffect(() => {
        if (failedId) {
            alertError("Payment Failed")
        }
    }, [failedId])

    React.useEffect(() => {
        if (freeTrial) {
            navigate(window.location.pathname)
            setActiveStep(9)
        }
    }, [freeTrial])

    useEffect(() => {
        setPreviewButton('changes')
    }, [handleToggle])

    // Selects the options in the Left Pane Accordings to the latest version - red/blue
    async function selectOptions(contractId) {
        const response = await instance.get(`/version/latestVersion?id=` + contractId)
        dispatch({ type: "ADD", payload: response.data.version.structured_options_answers })
        for (const key in response.data.version.structured_options_answers) {
            const ele = document.getElementById(response.data.version.structured_options_answers[key].id);
            if (ele) {
                if (ele.type === "checkbox" || ele.type === "radio") {
                    ele.checked = true;
                }
                else if (ele.type === "text") {
                    ele.value = response.data.version.structured_options_answers[key].value;
                }
            }
        }
    }

    const createOrUpdateMinorVersion = async () => {
        if (previousVersion === undefined || Object.keys(ans).length === 0 || currentCollaborator.role === "viewer" || contract.phase === "Signing" || contract.phase === "Signed" || loaderVisibility) return;
        const response = await instance.post("/version/createOrUpdateMinorVersion", {
            contractId: contractId,
            structured_options_answers: ans,
            comments: versionsComments[0].comments,
        });
        if (!response.data.version) {
            return
        }
        const time = new Date().getTime()
        setVersionSavedTime(new Date(time))
    }

    useEffect(() => {
        async function getContract() {
            try {
                const response = await instance.get(`/contract/id?uuid=` + contractUUID)
                if (!response.data.contract) {
                    alertError("You are not authorized to view this contract")
                    window.location.href = "/signin"
                }
                setContractId(response.data.contract.id)
                setContract(response.data.contract)
                setCurrentCollaborator(response.data.contract.currentCollaborator)

                const versionHistory = await instance.post("/version/getVersionHistory", { contractId: response.data.contract.id })
                if (versionHistory.data.length > 0) {
                    dispatch({ type: 'ADD_VERSION_HISTORY_MODAL', payload: versionHistory.data })
                }
                dispatch({ type: "UPDATE", payload: response.data.template })
                const versionsComments = await instance.post("/version/commentsHistory", { contractId: response.data.contract.id })
                dispatch({ type: "ADD_VERSIONS_COMMENTS", payload: versionsComments.data.versionsComments })

                await getPreviousMajorVersions(response.data.contract.id)

                // Auto expand the collapsible
                if (!response.data.template.contractTemplateName.includes("Short-Form")) {
                    let ids = ["164", "178", "181", "6", "165"]
                    let coll = document.getElementsByClassName("collapsible")
                    for (let i = 0; i < coll.length; i++) {
                        if (ids.indexOf(coll[i].parentElement.id) !== -1 || ids.indexOf(coll[i].parentElement.parentElement.id) !== -1 || ids.indexOf(coll[i].parentElement.parentElement.parentElement.id) !== -1) {
                            coll[i].click()
                        }
                    }
                }

                if (response.data.contract.phase === "Signing" || response.data.contract.phase === "Signed" || localStorage.getItem('stagingEmail')) {
                    setActiveStep(response.data.template.contractSections.length)
                }

                if (response.data.contract.phase === "Negotiating") {
                    if (versionHistory.data.length > 1) {
                        setPreviewButton('markup')
                    }
                    else {
                        setPreviewButton('clean')
                    }
                }
                else if (response.data.contract.phase === "Signed") {
                    setPreviewButton('clean')
                }

                const contractsResponse = await instance.get('/contract', {
                    params: {
                        id: "null"
                    }
                })
                if (contractsResponse.data.contracts.length <= 2) {
                    setInstructionsModalVisibility(true)
                }

                if (searchParams.get("prompt") === "true") {
                    setAiWorkflow(true)
                }
                setLoaderVisibility(false)

                if (response.data.contract.currentCollaborator.role === "viewer" || response.data.contract.currentCollaborator.team !== response.data.contract.currentOwnerTeam || response.data.contract.phase === "Signing" || response.data.contract.phase === "Signed") {
                    alertInfo("View Mode", "Please note, you are reviewing this contract in view mode. You may make changes to see how they impact the contract draft. However, any changes you make will not be saved or shared.")
                }
            } catch (error) {
                if (error.response.data.message) { alertError(error.response.data.message) }
                else if (error.response.data) { alertError(error.response.data) }
                navigate("/dashboard")
            }
        }
        async function getPreviousMajorVersions(contractId) {
            const response = await instance.get(`/version/latestTwoMajorVersions?id=` + contractId)
            if (response.data.versions[1]) {
                setPreviousVersion({ version: response.data.versions[0] })
                setSecondPreviousVersion({ version: response.data.versions[1] })
            }
            else {
                setPreviousVersion({ version: response.data.versions[0] })
                setSecondPreviousVersion({})
            }
            await selectOptions(contractId)
        }

        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
        if (loaderVisibility) {
            if (!localStorage.getItem("accessToken")) {
                navigate("/signin")
            }
            else if (searchParams.get("changeOwnership") === "true") {
                setChangeCounterpartyOwner(true)
                setLoaderVisibility(false)
            }
            else {
                getContract()
            }
        }
    }, [loaderVisibility])

    // Collapsible effect
    const addCollapsibleEffect = () => {
        if (contractId) {
            selectOptions(contractId);
        }

        // For all other collasable classes
        var coll = document.getElementsByClassName("collapsible");
        var i;

        for (i = 0; i < coll.length; i++) {
            coll[i].nextElementSibling.nextElementSibling.style.transition = "all 0.1s ease-out";
            coll[i].nextElementSibling.nextElementSibling.style.opacity = 0;
            coll[i].nextElementSibling.nextElementSibling.style.display = "none";
        }
    }
    useEffect(() => {
        addCollapsibleEffect()
    }, [hideDrafter, secondPreviousVersion, aiWorkflow]);

    // useEffect(() => {
    //     setTimeout(() => {
    //         var coll = document.getElementsByClassName("collapsible");
    //         for (let i = 0; i < coll.length; i++) {
    //             if (!document.getElementById(ds.contractSections[activeStep] + "-heading").parentElement.parentElement.contains(coll[i])) {
    //                 if (coll[i].nextElementSibling.nextElementSibling.style.display === "block") {
    //                     coll[i].click();
    //                 }
    //             }
    //         }
    //     }, 500);
    // }, [activeStep])

    // Left Side Coloring
    useEffect(() => {
        for (let [key, value] of Object.entries(ans)) {
            const ele = document.getElementById(value.id)
            if (ele) {
                if (ele.type === "checkbox" || ele.type === "radio") {
                    ele.checked = true;
                    ele.style.backgroundColor = "";
                    ele.style.borderColor = "";
                }
            }
        }
        if (previousVersion.version) {
            for (let [key, value] of Object.entries(previousVersion.version.structured_options_answers)) {
                const ele = document.getElementById(value.id)
                if (ele) {
                    if (ele.type === "checkbox" || ele.type === "radio") {
                        if (!ans[key]) {
                            ele.style.backgroundColor = "#FDF2F2";
                            ele.style.borderColor = "#F8B4B4";
                        }
                        else if (!secondPreviousVersion.version || secondPreviousVersion.version.structured_options_answers[key]?.id === value?.id) {
                            if (ele.checked) {
                                ele.style.backgroundColor = "#4B5563";
                                ele.style.borderColor = "#4B5563";
                            }
                            else {
                                ele.style.backgroundColor = "#80808045";
                                ele.style.borderColor = "#80808045";
                            }
                        }
                        else {
                            if (ele.checked) {
                                ele.style.backgroundColor = "#7E3AF2";
                                ele.style.borderColor = "#7E3AF2";
                            }
                            else {
                                ele.style.backgroundColor = "#DCD7FE";
                                ele.style.borderColor = "#7e3af29e";
                            }
                        }
                    }
                }
            }
        }
        if (secondPreviousVersion.version) {
            for (let [key, value] of Object.entries(secondPreviousVersion.version.structured_options_answers)) {
                const ele = document.getElementById(value.id)
                if (ele) {
                    if (ele.type === "checkbox" || ele.type === "radio") {
                        if (!ans[key] && !previousVersion.version.structured_options_answers[key]) {
                            if (ele.checked) {
                                ele.style.backgroundColor = "#7E3AF2";
                                ele.style.borderColor = "#7E3AF2";
                            }
                            else {
                                ele.style.backgroundColor = "#DCD7FE";
                                ele.style.borderColor = "#7e3af29e";
                            }
                        }
                        else if (ans[key]?.id !== value?.id && previousVersion.version.structured_options_answers[key]?.id !== value?.id) {
                            if (ele.checked) {
                                ele.style.backgroundColor = "#4B5563";
                                ele.style.borderColor = "#4B5563";
                            }
                            else {
                                ele.style.backgroundColor = "#80808045";
                                ele.style.borderColor = "#80808045";
                            }
                        }
                    }
                }
            }
        }
    }, [ans])

    useEffect(() => {
        createOrUpdateMinorVersion()
    }, [ans, versionsComments])

    useEffect(() => {
        if (saveVersion) {
            setSaveVersion(false)
            createOrUpdateMinorVersion();
        }
    }, [saveVersion])

    useEffect(() => {
        dispatch({ type: "RESET" })
        setLoaderVisibility(true)
    }, [])

    function numberToAlphabet(number) {
        let alphabet = " ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        return alphabet[number]
    }

    function open(text, ans) {
        if (!text) return text

        let temp = text
        while (temp.includes("{{")) {
            if (temp.includes("{{^")) {
                let tempText = temp.substring(temp.indexOf("{{^"))
                let variable = tempText.substring(tempText.indexOf("{{^") + 3, tempText.indexOf("}}"))
                if (ans[variable]) {
                    temp = temp.replace(tempText.substring(tempText.indexOf("{{^"), tempText.indexOf("{{/" + variable + "}}") + variable.length + 5), "")
                }
                else {
                    temp = temp.replace(tempText.substring(tempText.indexOf("{{^"), tempText.indexOf("{{/" + variable + "}}") + variable.length + 5), tempText.substring(tempText.indexOf("{{^") + variable.length + 5, tempText.indexOf("{{/" + variable + "}}")))
                }
            }
            else if (temp.includes("{{{")) {
                let variableName = temp.substring(temp.indexOf("{{{") + 3, temp.indexOf("}}}"))
                temp = temp.replace(temp.substring(temp.indexOf("{{{"), temp.indexOf("}}}") + 3), "{" + variableName + "}")
            }
            else {
                let variable = ans[temp.substring(temp.indexOf("{{") + 2, temp.indexOf("}}"))]
                if (variable) {
                    temp = temp.replace(temp.substring(temp.indexOf("{{"), temp.indexOf("}}") + 2), variable.value)
                }
                else {
                    temp = temp.replace(temp.substring(temp.indexOf("{{"), temp.indexOf("}}") + 2), "")
                }
            }
        }

        let colonOccured = false
        while (temp.includes("{")) {
            let counter = 1
            let variableName = temp.substring(temp.indexOf("{"), temp.indexOf("}") + 1)
            let variable = temp.substring(temp.indexOf("{") + 1, temp.indexOf("}")).split("|")
            while (temp.includes(variableName)) {
                let variableIndex = temp.indexOf(variableName)
                if (counter === 1) {
                    let symbol = colonOccured ? " (" : ": ("
                    if (variable[1] === "Number") {
                        temp = temp.substring(0, variableIndex).trim() + symbol + counter++ + ") " + temp.substring(temp.indexOf("}", variableIndex) + 1).trim() + " "
                    }
                    else if (variable[1] === "Capitalized") {
                        temp = temp.substring(0, variableIndex).trim() + symbol + numberToAlphabet(counter++) + ") " + temp.substring(temp.indexOf("}", variableIndex) + 1).trim() + " "
                    }
                    else if (variable[1] === "Lowercase") {
                        temp = temp.substring(0, variableIndex).trim() + symbol + numberToAlphabet(counter++).toLowerCase() + ") " + temp.substring(temp.indexOf("}", variableIndex) + 1).trim() + " "
                    }
                }
                else {
                    let symbol = colonOccured ? ", (" : "; ("
                    if (variable[1] === "Number") {
                        temp = temp.substring(0, variableIndex).trim() + symbol + counter++ + ") " + temp.substring(temp.indexOf("}", variableIndex) + 1).trim() + " "
                    }
                    else if (variable[1] === "Capitalized") {
                        temp = temp.substring(0, variableIndex).trim() + symbol + numberToAlphabet(counter++) + ") " + temp.substring(temp.indexOf("}", variableIndex) + 1).trim() + " "
                    }
                    else if (variable[1] === "Lowercase") {
                        temp = temp.substring(0, variableIndex).trim() + symbol + numberToAlphabet(counter++).toLowerCase() + ") " + temp.substring(temp.indexOf("}", variableIndex) + 1).trim() + " "
                    }
                }
            }
            if (counter === 2) {
                let symbol = colonOccured ? " (" : ": ("
                if (variable[1] === "Number") {
                    temp = temp.slice(0, temp.lastIndexOf(symbol + (counter - 1).toString() + ")")).trim() + " " + temp.slice(temp.lastIndexOf(symbol + (counter - 1).toString() + ")") + 5).trim() + " "
                }
                else if (variable[1] === "Capitalized") {
                    temp = temp.slice(0, temp.lastIndexOf(symbol + numberToAlphabet(counter - 1) + ")")).trim() + " " + temp.slice(temp.lastIndexOf(symbol + numberToAlphabet(counter - 1) + ")") + 5).trim() + " "
                }
                else if (variable[1] === "Lowercase") {
                    temp = temp.slice(0, temp.lastIndexOf(symbol + numberToAlphabet(counter - 1).toLowerCase() + ")")).trim() + " " + temp.slice(temp.lastIndexOf(symbol + numberToAlphabet(counter - 1).toLowerCase() + ")") + 5).trim() + " "
                }
            }
            else if (counter > 2) {
                let symbol = colonOccured ? ", (" : "; ("
                let andOr = ans[variable[0] + " AND/OR"] ? " " + ans[variable[0] + " AND/OR"].value.trim() + " " : ""
                if (variable[1] === "Number") {
                    temp = temp.slice(0, temp.lastIndexOf(symbol + (counter - 1).toString() + ")") + 2).trim() + andOr + temp.slice(temp.lastIndexOf(symbol + (counter - 1).toString() + ")") + 2).trim()
                }
                else if (variable[1] === "Capitalized") {
                    temp = temp.slice(0, temp.lastIndexOf(symbol + numberToAlphabet(counter - 1) + ")") + 2).trim() + andOr + temp.slice(temp.lastIndexOf(symbol + numberToAlphabet(counter - 1) + ")") + 2).trim()
                }
                else if (variable[1] === "Lowercase") {
                    temp = temp.slice(0, temp.lastIndexOf(symbol + numberToAlphabet(counter - 1).toLowerCase() + ")") + 2).trim() + andOr + temp.slice(temp.lastIndexOf(symbol + numberToAlphabet(counter - 1).toLowerCase() + ")") + 2).trim()
                }
            }
            colonOccured = true
        }

        let index = 0
        while (temp.includes(" a ", index)) {
            index = temp.indexOf(" a ", index)
            if (temp[index + 3].toLowerCase() === "a" || temp[index + 3].toLowerCase() === "e" || temp[index + 3].toLowerCase() === "i" || temp[index + 3].toLowerCase() === "o") {
                temp = temp.slice(0, index + 2) + "n" + temp.slice(index + 2)
            }
            index++
        }
        return temp
    }

    function cleanContract() {
        let json = { ...ans }
        for (const key in json) {
            // Hardcode Special Case
            if (key === "Other Limitations Subsections Included" && open(json[key].value, ans) === "") {
                delete json[key]
                continue
            }

            const element = json[key].value;
            json = {
                ...json,
                [key]: element?.includes("{{") ?
                    '<span style="color: black">' + open(element, ans) + '</span>' :
                    '<span style="color: black">' + element + '</span>'
            }
        }
        let contract = Mustache.render(ds.docContractTemplate, json)
        let symbols = ["( ", " )", " ;", " :", " ,", " .", ' ’', '“ ', ' ”']
        for (let i = 0; i < symbols.length; i++) {
            while (contract.includes(symbols[i])) {
                contract = contract.replace(symbols[i], symbols[i].trim())
            }
        }
        while (contract.includes("1 (")) {
            contract = contract.replace("1 (", "1(")
        }
        while (contract.includes("<p><br> </p>")) {
            contract = contract.replace("<p><br> </p>", "<br>")
        }
        while (contract.includes("</p></span>")) {
            contract = contract.replace("<p>", "").replace("</p></span>", "</span>")
        }
        while (contract.includes("</p>")) {
            contract = contract.replace("<p>", "").replace("</p>", "<br>")
        }

        let index = 0
        while (contract.includes(" a ", index)) {
            index = contract.indexOf(" a ", index)
            if (contract[index + 3].toLowerCase() === "a" || contract[index + 3].toLowerCase() === "e" || contract[index + 3].toLowerCase() === "i" || contract[index + 3].toLowerCase() === "o") {
                contract = contract.slice(0, index + 2) + "n" + contract.slice(index + 2)
            }
            index++
        }
        return contract
    }

    const finishAndReview = async () => {
        let openCTE = [];
        for (let key in ans) {
            if (key.includes('OriginalText') && (ans[key].changeWarning || ans[key].addWarning) && secondPreviousVersion.version && (
                !secondPreviousVersion.version.structured_options_answers[key.replace('OriginalText', 'CustomText')] ||
                secondPreviousVersion.version.structured_options_answers[key.replace('OriginalText', 'CustomText')].value.replace(/ /g, '') !== ans[key.replace('OriginalText', 'CustomText')].value.replace(/ /g, '')
            )) {
                openCTE.push(key.substring(12, key.length).replace("CustomSubSection", ""));
            }
        }
        if (openCTE.length > 0) {
            alertError(`
            The current draft cannot be finished until you have accepted or rejected your counterparty’s custom text edits in the following sections:
            ${openCTE.join(', ')}`);
            return
        }

        let signWorkflow = true;
        for (let key in ans) {
            if (!key.includes("OriginalText") && (!previousVersion.version.structured_options_answers[key] || ans[key]?.value !== previousVersion.version.structured_options_answers[key].value)) {
                signWorkflow = false;
                break
            }
        }
        for (let key in previousVersion.version.structured_options_answers) {
            if (!key.includes("OriginalText") && !ans[key]) {
                signWorkflow = false;
                break
            }
        }
        if (contract.counterPartyId && signWorkflow) {
            setSignWorkflow(signWorkflow);
        }
        else {
            setActiveStep(ds.contractSections.length)
        }
        document.getElementById("SelectionPane").scrollTo({ top: 0, behavior: "smooth" })
    }

    function collapsibleHandler(currentTarget, sibblings = 2) {
        for (let i = 0; i < sibblings; i++) {
            currentTarget = currentTarget.nextElementSibling;
        }
        if (currentTarget.style.display === "block") {
            currentTarget.style.display = "none";
            currentTarget.style.opacity = 0;
        } else {
            currentTarget.style.display = "block";
            currentTarget.style.opacity = 1;
        }
    }

    const handleStepChange = (step, index = ds.contractSections.indexOf(step)) => {
        const div = document.getElementById("SelectionPane");
        if (contract.phase === "Signing") {
            return
        }
        else if (index === ds.contractSections.length) {
            finishAndReview()
            div?.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
        else {
            // Stepper
            if (activeStep === ds.contractSections.length) {
                setActiveStep(index)
            }

            setTimeout(() => {
                if (activeStep === ds.contractSections.length) {
                    addCollapsibleEffect()
                }

                // Left Pane
                const element = document.getElementById(step + "-heading");
                div?.scrollTo({
                    top: element?.offsetTop - 80,
                    behavior: 'smooth',
                });

                // Right Pane
                if (step === "Term") {
                    scrollToElement("Term Subsection")
                }
                else {
                    scrollToElement(step)
                }
            }, 200);
        }
    }

    const handleScroll = (pane) => {
        const div = document.getElementById(pane);
        const pageYOffset = pane === "SelectionPane" ? div.scrollTop + 160 : div.scrollTop + 320
        ds.contractSections.forEach((step) => {
            const section = pane === "SelectionPane" ? document.getElementById(step + "-heading")?.parentElement.parentElement : document.getElementById(step)
            const sectionOffsetTop = section.offsetTop
            const sectionHeight = section.offsetHeight

            if (pageYOffset >= sectionOffsetTop && pageYOffset < sectionOffsetTop + sectionHeight) {
                if (pane === "SelectionPane") {
                    setActiveStep(ds.contractSections.indexOf(step))
                }
                else {
                    setHoverStep(ds.contractSections.indexOf(step))
                }
            }
        });
    }

    return (
        <>
            {
                Object.keys(contract).length !== 0 ?
                    <>
                        <div className="flex" onContextMenu={(e) => { e.preventDefault(); }} onCopy={(e) => { e.preventDefault(); }}>
                            <Sidebar value={sidebar} setSidebar={setSidebar} setCloseModal={setCloseModal} contract={contract} currentCollaborator={currentCollaborator} instructionsModalVisibility={instructionsModalVisibility} instructionsModalState={1} />
                            {
                                loaderVisibility ?
                                    <div className="flex justify-center items-center min-h-[100px] w-full">
                                        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
                                        {searchParams.get("prompt") === "true" ? <div className="ml-4 text-2xl font-semibold">Standard Draft AI is preparing your contract</div> : null}
                                    </div>
                                    :
                                    null
                            }
                            <div className={loaderVisibility ? "hidden" : "block"}>
                                <PostSigninNavbar value={sidebar} onChange={setSidebar} contractTemplateName={ds?.contractTemplateName} />
                                <TopHeader setInitiateSignWorkflow={setInitiateSignWorkflow} signWorkflow={signWorkflow} setSignWorkflow={setSignWorkflow} versionSavedTime={versionSavedTime} finishAndReview={finishAndReview} setCloseModal={setCloseModal}
                                    setSaveVersion={setSaveVersion} contractId={contractId} steps={ds.contractSections} setActiveStep={setActiveStep} contract={contract} cleanContract={cleanContract} currentCollaborator={currentCollaborator} />
                                <div>
                                    <Stepper steps={ds.contractSections} activeStep={activeStep} hoverStep={hoverStep} currentCollaborator={currentCollaborator} lastStepDisabled={contract.phase !== "Signed" && currentCollaborator.team !== contract.currentOwnerTeam} handleStepChange={handleStepChange} />
                                    <div className='flex flex-row'>
                                        <div id='SelectionPane' onScroll={() => handleScroll("SelectionPane")} className={`${aiWorkflow ? '' : 'pl-5'} pt-2 pb-12 bg-[#FFFEFE] border-r relative ${hideDrafter ? `invisible opacity-0 hidden` : `basis-1/2 visible opacity-100`} ${aiWorkflow ? 'overflow-y-hidden' : 'overflow-y-auto'}`} style={{ height: "75vh" }}>
                                            <div className="flex items-center gap-2">
                                                {/* <div className="ml-auto cursor-pointer" onClick={() => setAiWorkflow(!aiWorkflow)}>
                                                    {
                                                        aiWorkflow ?
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z" stroke="#575F68" />
                                                                <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0" stroke="#3B444B" />
                                                            </svg>
                                                            :
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707zM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1zM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707zM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0z" />
                                                            </svg>
                                                    }
                                                </div> */}
                                                <div className="cursor-pointer mr-3 ml-auto" onClick={() => setHideDrafter(!hideDrafter)}>
                                                    <svg viewBox="0 0 24 24" height={"20px"} fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5 4a1 1 0 00-1 1v14a1 1 0 001 1h3V4H5zm4-2H5a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3V5a3 3 0 00-3-3H9zm1 2v16h9a1 1 0 001-1V5a1 1 0 00-1-1h-9zm6.707 10.707a1 1 0 000-1.414L15.414 12l1.293-1.293a1 1 0 00-1.414-1.414l-2 2a1 1 0 000 1.414l2 2a1 1 0 001.414 0z" fill="#3B444B"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            {
                                                !hideDrafter ?
                                                    aiWorkflow ?
                                                        <GPTMode chatMessages={chatMessages} setChatMessages={setChatMessages} contractId={contractId} contractStructuredOptions={ds.contractStructuredOptions} />
                                                        :
                                                        activeStep === ds.contractSections.length ?
                                                            <ContractReview initiateSignWorkflow={initiateSignWorkflow} isPaid={contract.isPaid} setInitiateSignWorkflow={setInitiateSignWorkflow} signWorkflow={signWorkflow} setSignWorkflow={setSignWorkflow} previousVersion={previousVersion} counterPartyId={contract.counterPartyId} collaborators={contract.collaborators}
                                                                currentCollaborator={currentCollaborator} contractName={contract.name} contractId={contractId} phase={contract.phase} cleanContract={cleanContract} contractRecipientOwner={contractRecipientOwner} setContractRecipientOwner={setContractRecipientOwner}
                                                                message={message} setMessage={setMessage} />
                                                            :
                                                            <div className={`${ds?.contractTemplateName?.includes("Short-Form") ? "pb-10" : ""}`}>
                                                                <ObjtoHTML obj={ds.contractStructuredOptions.map((section) => section[0])} setPreviewButton={setPreviewButton} secondPreviousVersion={secondPreviousVersion} previousVersion={previousVersion} contract={contract} collapsibleHandler={collapsibleHandler} steps={ds.contractSections} setActiveStep={setActiveStep} readOnly={currentCollaborator.role === "viewer" || currentCollaborator.team !== contract.currentOwnerTeam} />
                                                                <div className="flex mt-6">
                                                                    {
                                                                        activeStep === ds.contractStructuredOptions.length - 1 && contract.phase !== "Signed" && (currentCollaborator.role === "viewer" || currentCollaborator.team !== contract.currentOwnerTeam) ?
                                                                            <button className="relative group hover:cursor-pointer ml-[auto] mr-6 flex justify-center items-center bg-[#1C64F2] py-[9px] px-[17px] border border-[#D1D5DB] rounded-[12px]">
                                                                                <span className="z-50 absolute top-[-70px] right-[0px] w-60 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100">
                                                                                    {currentCollaborator.role === "viewer" ?
                                                                                        "You are reviewing this contract as a viewer. Any changes you make will not be saved or shared." :
                                                                                        "This contract has been sent to your counterparty. Any changes you make now will not be saved or shared."}
                                                                                </span>
                                                                                <span className="text-sm font-semibold text-[#FFFFFF] mr-3">Continue</span>
                                                                                <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M1.375 1.9751L7.04167 7.64176L1.375 13.3084" stroke="white" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round" />
                                                                                </svg>
                                                                            </button>
                                                                            :
                                                                            <button onClick={() => { handleStepChange("", ds.contractSections.length) }} className="ml-[auto] mr-6 flex justify-center items-center bg-[#1C64F2] py-[9px] px-[17px] border border-[#D1D5DB] rounded-[12px]">
                                                                                <span className="text-sm font-semibold text-[#FFFFFF] mr-3">Continue</span>
                                                                                <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M1.375 1.9751L7.04167 7.64176L1.375 13.3084" stroke="white" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round" />
                                                                                </svg>
                                                                            </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                        <div id='some-id' className={`pl-5 pt-2 pb-12 bg-[#FFFEFE] border-r relative ${hideDrafter ? `w-[65px] visible opacity-100` : `invisible w-0 opacity-0`}`} style={{ overflowY: 'scroll' }}>
                                            <div className="float-right px-3 cursor-pointer" onClick={() => setHideDrafter(!hideDrafter)}>
                                                <svg viewBox="0 0 24 24" height={"20px"} fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5 4a1 1 0 00-1 1v14a1 1 0 001 1h3V4H5zm4-2H5a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3V5a3 3 0 00-3-3H9zm1 2v16h9a1 1 0 001-1V5a1 1 0 00-1-1h-9zm3.293 5.293a1 1 0 000 1.414L14.586 12l-1.293 1.293a1 1 0 001.414 1.414l2-2a1 1 0 000-1.414l-2-2a1 1 0 00-1.414 0z" fill="currentColor"></path></svg>
                                            </div>
                                        </div>
                                        <div className={`${hideDrafter ? `w-full` : `basis-1/2`}`}>
                                            <div className="">
                                                <PreviewButtons previewButton={previewButton} setPreviewButton={setPreviewButton} phase={contract.phase} />
                                            </div>
                                            <div onScroll={() => handleScroll("PreviewPane")} id="PreviewPane" style={{ height: "67vh" }} className={`${hideDrafter ? `px-20 pt-2 pb-12 font-serif overflow-scroll` : `pr-5 pl-5 pt-2 pb-12 font-serif overflow-scroll`}`}>
                                                <PreviewPane previousVersion={previousVersion} secondPreviousVersion={secondPreviousVersion} steps={ds.contractSections} setActiveStep={setActiveStep} activeStep={activeStep} hoverStep={hoverStep} versionSavedTime={versionSavedTime} previewButton={previewButton} setPreviewButton={setPreviewButton} open={open} phase={contract.phase} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    : null
            }
            {
                closeModal ?
                    <div
                        tabIndex={-1}
                        className="flex justify-center items-center fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full overflow-auto"
                    >
                        <div className='px-8 py-6 w-[50%] bg-[#FFFFFF] rounded-[10px]' style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                            <div>
                                <div className="font-semibold text-3xl text-[#111928] mb-1">
                                    Warning
                                </div>
                                <div className="font-normal text-[#6B7280]">
                                    Are you sure you want to exit this contract? Your changes have been saved.
                                </div>
                            </div>
                            <div className="flex justify-end mt-11 gap-3">
                                <button onClick={() => {
                                    setCloseModal(false);
                                }} style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #D1D5DB", display: "flex", alignItems: "center", justifyContent: "center", width: "100px" }}>
                                    <div className="text-sm font-semibold text-[#111928]">No</div>
                                </button>
                                <button onClick={() => {
                                    setCloseModal(false);
                                    dispatch({ type: 'RESET' });
                                    // navigate(-1)
                                    navigate('/dashboard')
                                }} style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #1C64F2", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1C64F2", width: "100px" }}>
                                    <div className="text-sm font-semibold text-[white]">Yes</div>
                                </button>
                            </div>
                        </div >
                    </div>
                    : null
            }
            {
                changeCounterpartyOwner ?
                    <div
                        tabIndex={-1}
                        className="flex justify-center items-center fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full overflow-auto"
                    >
                        <div className='px-8 py-6 w-[50%] bg-[#FFFFFF] rounded-[10px]' style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                            <div>
                                <div className="font-semibold text-3xl text-[#111928] mb-1">
                                    Warning
                                </div>
                                <div className="font-normal text-[#6B7280]">
                                    You are now becoming an owner and the original recipient will now be a viewer. Are you sure?
                                </div>
                            </div>
                            <input id="code" type="text" className="mt-4 border border-[#D1D5DB] rounded-[12px] w-full px-4 py-2" placeholder="Enter code" />
                            <div className="flex justify-end mt-11 gap-3">
                                <button onClick={() => {
                                    setChangeCounterpartyOwner(false);
                                    navigate('/dashboard')
                                }} style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #D1D5DB", display: "flex", alignItems: "center", justifyContent: "center", width: "100px" }}>
                                    <div className="text-sm font-semibold text-[#111928]">No</div>
                                </button>
                                <button onClick={async () => {
                                    let contractUUID = window.location.pathname.split("/")[1]
                                    let ownershipChangeCode = document.getElementById("code").value
                                    if (ownershipChangeCode.trim().length === 0) {
                                        alertError("Please enter code")
                                        return
                                    }
                                    try {
                                        await instance.post("/contract/changeCounterpartyOwner", { contractUUID, ownershipChangeCode })
                                        alertSuccess("Ownership changed successfully")
                                        setChangeCounterpartyOwner(false);
                                        navigate(window.location.pathname.split("?")[0])
                                        setTimeout(() => { window.location.reload() }, 1000);
                                    } catch (error) {
                                        alertError(error.response.data)
                                        setChangeCounterpartyOwner(false);
                                        navigate('/dashboard')
                                    }
                                }} style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #1C64F2", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1C64F2", width: "100px" }}>
                                    <div className="text-sm font-semibold text-[white]">Yes</div>
                                </button>
                            </div>
                        </div >
                    </div>
                    : null
            }
        </>
    );
}

export default ComposerPage;