import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
const Recipients = ({
    shareWithContractRecipient, setShareWithContractRecipient, editFromYourSide, setEditFromYourSide,
    yourOwner, contractRecipientOwner, setContractRecipientOwner, yourViewers, setYourViewers,
    contractRecipientViewers, setContractRecipientViewers, counterPartyId, newDraftOwner, setNewDraftOwner
}) => {
    const dispatch = useDispatch();
    let infoModalRef = React.useRef();

    const { email, firstName, lastName } = useSelector(state => state.payment);
    const [infoModalVisibility, setInfoModalVisibility] = React.useState(false);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (infoModalRef.current && !infoModalRef.current.contains(event.target)) {
                setInfoModalVisibility(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [])

    React.useEffect(() => {
        if (contractRecipientViewers.length !== 0) {
            localStorage.setItem('stagingCounterpartyViewers', JSON.stringify(contractRecipientViewers))
        }
    }, [contractRecipientViewers])

    React.useEffect(() => {
        if (yourViewers.length !== 0) {
            localStorage.setItem('stagingYourViewers', JSON.stringify(yourViewers))
        }
    }, [yourViewers])

    return (
        <div>
            <div className='flex items-center text-lg font-semibold text-[#374151] pb-2'>
                Email Recipients
                <div className='relative ml-auto w-content mt-1 z-40'>
                    <svg onClick={() => setInfoModalVisibility(true)} className="ml-auto cursor-pointer" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.4685 13.8006C18.9019 12.7542 19.125 11.6327 19.125 10.5C19.125 8.21251 18.2163 6.01871 16.5988 4.4012C14.9813 2.7837 12.7875 1.875 10.5 1.875C8.21251 1.875 6.0187 2.7837 4.4012 4.4012C2.7837 6.01871 1.875 8.21251 1.875 10.5C1.875 11.6327 2.09809 12.7542 2.53154 13.8006C2.96499 14.8471 3.6003 15.7979 4.4012 16.5988C5.20211 17.3997 6.15292 18.035 7.19936 18.4685C8.24579 18.9019 9.36735 19.125 10.5 19.125C11.6327 19.125 12.7542 18.9019 13.8006 18.4685C14.8471 18.035 15.7979 17.3997 16.5988 16.5988C17.3997 15.7979 18.035 14.8471 18.4685 13.8006Z" fill="white" />
                        <path d="M11.4583 14.3333H10.5V10.5H9.54167M10.5 6.66667H10.5096M19.125 10.5C19.125 11.6327 18.9019 12.7542 18.4685 13.8006C18.035 14.8471 17.3997 15.7979 16.5988 16.5988C15.7979 17.3997 14.8471 18.035 13.8006 18.4685C12.7542 18.9019 11.6327 19.125 10.5 19.125C9.36735 19.125 8.24579 18.9019 7.19936 18.4685C6.15292 18.035 5.20211 17.3997 4.4012 16.5988C3.6003 15.7979 2.96499 14.8471 2.53154 13.8006C2.09809 12.7542 1.875 11.6327 1.875 10.5C1.875 8.21251 2.7837 6.01871 4.4012 4.4012C6.0187 2.7837 8.21251 1.875 10.5 1.875C12.7875 1.875 14.9813 2.7837 16.5988 4.4012C18.2163 6.01871 19.125 8.21251 19.125 10.5Z" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {
                        infoModalVisibility &&
                        <div ref={infoModalRef} className="absolute right-[-25px] w-[28rem] bg-[white] h-52 top-0 z-50 border overflow-y-auto rounded-[5px] shadow-lg">
                            <div className="flex items-center justify-between px-4 py-2">
                                <div className="text-sm font-medium text-[#374151]">Email Recipients</div>
                                <button onClick={() => setInfoModalVisibility(false)} className="focus:outline-none">
                                    <svg className="w-4 h-4 text-[#374151]" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L15 15M15 1L1 15" stroke="#374151" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </div>
                            <div className="px-4 pb-2">
                                <div className="text-xs font-medium text-[#374151]" >
                                    <p className="mb-1.5">You may choose to share this contract with your counterparty or just members of your side.</p>
                                    <p className="mb-1.5"><b><i>Share with Counterparty</i></b></p>
                                    <p className="mb-1.5">When you share with the counterparty, the contract draft will be sent to the counterparty and you will no longer be able to edit it until it is sent back to you.</p>
                                    <p className="mb-1.5">The counterparty (i.e., the email address that you submit under the Counterparty Side header) will now be able to edit the contract as the new contract “owner”. He or she may choose to provide view access or editing ability to someone else from his or her side.</p>
                                    <p className="mb-1.5">When sharing with the counterparty, you may choose to add viewers from your side or the counterparty side.</p>
                                    <p className="mb-1.5"><b><i>Share with My Side</i></b></p>
                                    <p className="mb-1.5">When you share with your own side, the contract draft will be sent to individuals from your side that you choose to include. The contract draft will not be sent to your counterparty.</p>
                                    <p className="mb-1.5">If you choose to allow someone else from your side to edit the draft, then you will no longer be able to edit it (until “ownership” of the contract is passed back to you).</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <p className='text-sm font-normal text-[#6B7280] pb-1.5'>
                Are you ready to share this contract with the counterparty?
                Or do you want to share with just your side first?
            </p>
            <p className='text-xs font-normal text-[#6B7280] pb-4'>
                Note: If you share with the counterparty, you can still copy members of your side to the email
            </p>
            <div className='ml-4 pb-5'>
                <div className='flex items-center pb-2.5'>
                    <input defaultChecked={shareWithContractRecipient} type='radio' name='shareWithCounterparty' id='shareWithCounterparty' className='w-[17px] h-[17px]  focus:ring-[white]' onClick={() => setShareWithContractRecipient(true)} />
                    <label htmlFor='shareWithCounterparty' className='text-sm font-normal text-[#6B7280] ml-2'>Share with Counterparty</label>
                </div>
                <div className='flex items-center'>
                    <input defaultChecked={!shareWithContractRecipient} type='radio' name='shareWithCounterparty' id='shareWithCounterparty' className='w-[17px] h-[17px]  focus:ring-[white]' onClick={() => setShareWithContractRecipient(false)} />
                    <label htmlFor='shareWithCounterparty' className='text-sm font-normal text-[#6B7280] ml-2'>Share with My Side</label>
                </div>
            </div>
            {
                shareWithContractRecipient ?
                    <>
                        <div className='text-base font-medium text-[#374151]'>Counterparty Side</div>
                        {/* <div className='text-sm font-normal text-[#6B7280] mb-2'>Optional - Counterparty signer & their legal counsel.</div> */}
                        <div className="ml-4 mb-4">
                            <div className='flex gap-[10px]'>
                                <div>
                                    <div className='text-sm font-normal text-[#6B7280] py-2'>Email *</div>
                                    <input
                                        disabled={counterPartyId ? true : false}
                                        value={contractRecipientOwner.email ? contractRecipientOwner.email : email}
                                        onChange={(e) => {
                                            setContractRecipientOwner({
                                                ...contractRecipientOwner,
                                                email: e.target.value
                                            })
                                            localStorage.setItem('stagingShareWith', 'Counter Party')
                                            localStorage.setItem('stagingEmail', e.target.value)
                                            dispatch({ type: 'SET_EMAIL', payload: e.target.value })
                                        }} className='text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' />
                                </div>
                                <div>
                                    <div className='text-sm font-normal text-[#6B7280] py-2'>First Name</div>
                                    <input
                                        disabled={counterPartyId ? true : false}
                                        value={contractRecipientOwner.firstName ? contractRecipientOwner.firstName : firstName}
                                        onChange={(e) => {
                                            setContractRecipientOwner(
                                                {
                                                    ...contractRecipientOwner,
                                                    firstName: e.target.value
                                                })
                                            dispatch({ type: 'SET_FIRST_NAME', payload: e.target.value })
                                            localStorage.setItem('stagingFirst', e.target.value)
                                        }} className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' />
                                </div>
                                <div>
                                    <div className='text-sm font-normal text-[#6B7280] py-2'>Last Name</div>
                                    <input
                                        disabled={counterPartyId ? true : false}
                                        value={contractRecipientOwner.lastName ? contractRecipientOwner.lastName : lastName}
                                        onChange={(e) => {
                                            setContractRecipientOwner({
                                                ...contractRecipientOwner,
                                                lastName: e.target.value
                                            })
                                            dispatch({ type: 'SET_LAST_NAME', payload: e.target.value })
                                            localStorage.setItem('stagingLast', e.target.value)
                                        }} className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' />
                                </div>
                            </div>
                            {
                                contractRecipientViewers.length > 0 ?
                                    <div className='text-base font-medium text-[#374151] mt-4'>Viewers</div>
                                    :
                                    null
                            }
                            {
                                contractRecipientViewers.map((viewer, index) => {
                                    return (
                                        <>
                                            <div className='flex gap-[10px]'>
                                                <div>
                                                    <div className='text-sm font-normal text-[#6B7280] py-2'>Email *</div>
                                                    <input className='text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.email} onChange={(e) => {
                                                        const temp = [...contractRecipientViewers]
                                                        temp[index].email = e.target.value
                                                        setContractRecipientViewers(temp)
                                                    }} />
                                                </div>
                                                <div>
                                                    <div className='text-sm font-normal text-[#6B7280] py-2'>First Name</div>
                                                    <input className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.firstName} onChange={(e) => {
                                                        const temp = [...contractRecipientViewers]
                                                        temp[index].firstName = e.target.value
                                                        setContractRecipientViewers(temp)
                                                    }
                                                    } />
                                                </div>
                                                <div>
                                                    <div className='text-sm font-normal text-[#6B7280] py-2'>Last Name</div>
                                                    <input className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.lastName} onChange={(e) => {
                                                        const temp = [...contractRecipientViewers]
                                                        temp[index].lastName = e.target.value
                                                        setContractRecipientViewers(temp)
                                                    }
                                                    } />
                                                </div>
                                            </div>

                                            <div className='flex items-center'>
                                                <div onClick={() => {
                                                    const temp = [...contractRecipientViewers]
                                                    temp.splice(index, 1)
                                                    setContractRecipientViewers(temp)
                                                }
                                                } className='text-sm font-medium text-[#1C64F2] hover:cursor-pointer'>Remove</div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                            <div className='flex mt-3'>
                                <div onClick={() => setContractRecipientViewers([
                                    ...contractRecipientViewers,
                                    {
                                        email: '',
                                        firstName: '',
                                        lastName: ''
                                    }
                                ])} className='text-sm font-medium text-[#1C64F2] hover:cursor-pointer'>+ Add viewers from the counterparty side</div>
                                {/* <div className='ml-auto flex justify-center items-center'>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.0004 6H13.0004C12.3248 6 11.7141 5.73368 11.2659 5.30049L11.0114 5.05449L10.6948 5.21279L5.75481 7.68279L5.43901 7.84069L5.48217 8.19112C5.50743 8.39627 5.50743 8.60374 5.48217 8.80889L5.43901 9.15932L5.75481 9.31722L10.6948 11.7872L11.0109 11.9453L11.2654 11.7C11.69 11.2907 12.2456 11.0447 12.834 11.0055C13.4224 10.9662 14.0058 11.1363 14.481 11.4855C14.9562 11.8348 15.2926 12.3408 15.4308 12.9141C15.569 13.4874 15.5001 14.0911 15.2362 14.6185C14.9724 15.1459 14.5306 15.563 13.9889 15.7962C13.4472 16.0294 12.8406 16.0636 12.2761 15.8927C11.7117 15.7219 11.2258 15.357 10.9044 14.8626C10.583 14.3682 10.4467 13.776 10.5196 13.1908L10.5633 12.8399L10.247 12.6818L5.30702 10.2118L4.99082 10.0537L4.73634 10.2991C4.3832 10.6396 3.93767 10.8687 3.45525 10.9579C2.97284 11.047 2.47485 10.9923 2.02333 10.8004C1.57181 10.6086 1.18672 10.2881 0.916017 9.87897C0.645317 9.46983 0.500977 8.99009 0.500977 8.4995C0.500977 8.00892 0.645317 7.52917 0.916017 7.12003C1.18672 6.71089 1.57181 6.39044 2.02333 6.19859C2.47485 6.00674 2.97284 5.95198 3.45526 6.04112C3.93767 6.13027 4.3832 6.35938 4.73634 6.69992L4.99082 6.94532L5.30702 6.78722L10.247 4.31722L10.5632 4.15913L10.5196 3.80834C10.457 3.30458 10.5492 2.79371 10.784 2.34365C11.0188 1.89359 11.3851 1.52567 11.834 1.28881C12.283 1.05196 12.7935 0.957401 13.2975 1.01771C13.8015 1.07801 14.2753 1.29033 14.6557 1.62642C15.0361 1.96251 15.3053 2.40645 15.4272 2.8992C15.5492 3.39195 15.5183 3.91016 15.3387 4.38492C15.159 4.85969 14.839 5.2685 14.4213 5.557C14.0037 5.8455 13.5081 6.00002 13.0004 6Z" fill="#374151" stroke="#374151" />
                            </svg>
                            <div className='ml-2 text-xs font-medium text-[#374151] hover:cursor-pointer'>More Sharing</div>
                        </div> */}
                            </div>
                        </div>
                    </>
                    : null
            }
            <div className='text-base font-medium text-[#374151]'>My Side</div>
            {/* <div className='text-sm font-normal text-[#6B7280] mb-2'>The person drafting the contract</div> */}
            <div className='ml-4 mb-6'>
                <div className='flex gap-[10px]'>
                    <div>
                        <div className='text-sm font-normal text-[#6B7280] py-2'>Email *</div>
                        <input disabled value={yourOwner.email} className='text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' />
                    </div>
                    <div>
                        <div className='text-sm font-normal text-[#6B7280] py-2'>First Name</div>
                        <input disabled value={yourOwner.firstName} className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' />
                    </div>
                    <div>
                        <div className='text-sm font-normal text-[#6B7280] py-2'>Last Name</div>
                        <input disabled value={yourOwner.lastName} className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' />
                    </div>
                </div>
                {
                    shareWithContractRecipient ? null :
                        <>
                            <div className='text-base font-normal text-[#6B7280] pb-1.5 mt-4'>Do you want to allow someone from your side to edit the draft?</div>
                            <div className='flex gap-[10px] mb-3'>
                                <div className='flex items-center'>
                                    <input defaultChecked={editFromYourSide} type='radio' name='editFromYourSide' id='editFromYourSide' className='w-[17px] h-[17px] focus:ring-[white]' onClick={() => {
                                        setEditFromYourSide(true);
                                        for (let i = 0; i < yourViewers.length; i++) {
                                            if (yourViewers[i].email === '' && yourViewers[i].firstName === '' && yourViewers[i].lastName === '') {
                                                yourViewers.splice(i, 1);
                                                i--;
                                            }
                                        }
                                    }} />
                                    <label htmlFor='editFromYourSide' className='text-sm font-normal text-[#6B7280] ml-2'>Yes</label>
                                </div>
                                <div className='flex items-center'>
                                    <input defaultChecked={!editFromYourSide} type='radio' name='editFromYourSide' id='editFromYourSide' className='w-[17px] h-[17px] focus:ring-[white]' onClick={() => {
                                        setEditFromYourSide(false);
                                        if (yourViewers.length === 0) {
                                            setYourViewers([{
                                                email: '',
                                                firstName: '',
                                                lastName: '',
                                            }])
                                        }
                                    }} />
                                    <label htmlFor='editFromYourSide' className='text-sm font-normal text-[#6B7280] ml-2'>No</label>
                                </div>
                            </div>
                            {
                                editFromYourSide ?
                                    <>
                                        <div className='text-base font-medium text-[#374151] mt-4'>New Draft Owner</div>
                                        <div className='flex gap-[10px]'>
                                            <div>
                                                <div className='text-sm font-normal text-[#6B7280] py-2'>Email *</div>
                                                <input value={newDraftOwner.email} onChange={(e) => {
                                                    setNewDraftOwner({
                                                        ...newDraftOwner,
                                                        email: e.target.value
                                                    })
                                                    localStorage.setItem('stagingShareWith', 'New Owner')
                                                    localStorage.setItem('stagingEmail', e.target.value)
                                                }} className='text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' />
                                            </div>
                                            <div>
                                                <div className='text-sm font-normal text-[#6B7280] py-2'>First Name</div>
                                                <input value={newDraftOwner.firstName} onChange={(e) => {
                                                    setNewDraftOwner({
                                                        ...newDraftOwner,
                                                        firstName: e.target.value
                                                    })
                                                    localStorage.setItem('stagingFirst', e.target.value)
                                                }} className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' />
                                            </div>
                                            <div>
                                                <div className='text-sm font-normal text-[#6B7280] py-2'>Last Name</div>
                                                <input value={newDraftOwner.lastName} onChange={(e) => {
                                                    setNewDraftOwner({
                                                        ...newDraftOwner,
                                                        lastName: e.target.value
                                                    })
                                                    localStorage.setItem('stagingLast', e.target.value)
                                                }} className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' />
                                            </div>
                                        </div>
                                    </>
                                    : null
                            }
                        </>
                }
                {
                    yourViewers.length > 0 ?
                        <div className='mt-3 text-base font-medium text-[#374151]'>Viewers</div>
                        : null
                }
                {
                    yourViewers.map((viewer, index) => {
                        return (
                            <>
                                <div className='flex gap-[10px]'>
                                    <div>
                                        <div className='text-sm font-normal text-[#6B7280] py-2'>Email *</div>
                                        <input className='text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.email} onChange={(e) => {
                                            const temp = [...yourViewers]
                                            temp[index].email = e.target.value
                                            setYourViewers(temp)
                                        }} />
                                    </div>
                                    <div>
                                        <div className='text-sm font-normal text-[#6B7280] py-2'>First Name</div>
                                        <input className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.firstName} onChange={(e) => {
                                            const temp = [...yourViewers]
                                            temp[index].firstName = e.target.value
                                            setYourViewers(temp)
                                        }
                                        } />
                                    </div>
                                    <div>
                                        <div className='text-sm font-normal text-[#6B7280] py-2'>Last Name</div>
                                        <input className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.lastName} onChange={(e) => {
                                            const temp = [...yourViewers]
                                            temp[index].lastName = e.target.value
                                            setYourViewers(temp)
                                        }
                                        } />
                                    </div>
                                </div>

                                <div className='flex items-center'>
                                    <div onClick={() => {
                                        const temp = [...yourViewers]
                                        temp.splice(index, 1)
                                        setYourViewers(temp)
                                    }
                                    } className='text-sm font-medium text-[#1C64F2] hover:cursor-pointer'>Remove</div>
                                </div>
                            </>
                        )
                    })
                }
                <button onClick={() => {
                    setYourViewers([
                        ...yourViewers,
                        {
                            email: '',
                            firstName: '',
                            lastName: '',
                        }
                    ])
                }} className='text-sm font-medium text-[#1C64F2] hover:cursor-pointer mt-3'>+ Add viewers from your side</button>
            </div>
        </div>
    )
}

export default Recipients
