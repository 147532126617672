import { ObjtoHTML } from "../Composer";
import React from 'react'
import '../../index.css'

const Text = ({ obj, disabled, collapsibleHandler }) => {
    const [arrow, setArrow] = React.useState(false)
    return (
        <div className="flex flex-wrap items-center" id={obj.id}>
            {
                obj.classes ?
                    arrow ?
                        <div onClick={(e) => {
                            collapsibleHandler(e.currentTarget)
                            setArrow(false)
                        }} className={"mr-2 mt-2 hover:cursor-pointer " + obj.classes}>
                            <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 1.125L6.5 6.125L11.5 1.125" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        :
                        <div onClick={(e) => {
                            collapsibleHandler(e.currentTarget)
                            setArrow(true)
                        }} className={"mr-2 mt-1 hover:cursor-pointer " + obj.classes} >
                            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.625 11L6.625 6L1.625 0.999999" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    :
                    <div className=""></div>
            }
            <div
                className={`mt-2 text-xs font-normal text-[#6B7280] ${obj.classes && `cursor-pointer`}`}
                onClick={(e) => {
                    collapsibleHandler(e.currentTarget, 1)
                    setArrow(!arrow)
                }}
                dangerouslySetInnerHTML={{ __html: obj.html }} />
            <div className="basis-full subHeadingAlignment">
                {obj.children ? obj.children.map((item) => {
                    return (
                        <ObjtoHTML obj={[item]} disabled={disabled} collapsibleHandler={collapsibleHandler} />
                    )
                }) : null}
            </div>
        </div>
    )
}

export default Text
