const initialState = {
  ds: {
    "contractSections": [],
    "contract": [[]]
  },
  ans: {},
  t_val: 0,
  handleToggle: false,
  versionsComments: [{ comments: {} }],
  versionHistoryData: []
}

export const contractReducer = (state = initialState, action) => {
  let obj = {}
  switch (action.type) {

    case 'ADD':
      obj = {}
      for (let i in action.payload) {
        obj[i] = action.payload[i]
      }
      return {
        ...state,
        ans: {
          ...state.ans,
          ...obj
        }
      }

    case 'ALTER':
      obj = { ...state.ans }
      for (let i in action.payload) {
        if (state.ans[i] === undefined) {
          obj[i] = action.payload[i]
        }
        else {
          // obj = {...state.ans}
          delete obj[i]
        }
      }
      return {
        ...state,
        ans: obj
      }

    case "UPDATE":
      return {
        ...state,
        ds: action.payload
      };

    case "DELETE":
      obj = { ...state.ans }
      delete obj[action.payload]
      return {
        ...state,
        ans: obj
      }

    case "SELECT":
      console.log("SELECTEDooo", action.payload);
      let t = state.ans.find((i) => i.id === action.payload.id);
      //update if t already exists, else add
      if (t !== undefined) {
        console.log("SELECT IF");
        //create copy of ans
        let ans_copy = [...state.ans];
        let index = state.ans.indexOf(t);
        ans_copy[index] = action.payload;

        return {
          ...state,
          ans: ans_copy,
        };

      } else {
        console.log("SELECT ELSE");
        return {
          ...state,
          ans: [...state.ans, action.payload],
        };
      }

    case "INPUT":
      console.log("INPUT", action.payload);
      let t_val = state.ans.find((i) => i.id === action.payload.id);
      //update if t already exists, else add
      if (t_val !== undefined) {
        console.log("INPUT IF");
        //create copy of ans
        let ans_copy = [...state.ans];
        let index = state.ans.indexOf(t_val);
        ans_copy[index] = action.payload;
        return {
          ...state,
          ans: ans_copy,
        };
      }
      else {
        console.log("INPUT ELSE");
        return {
          ...state,
          ans: [...state.ans, action.payload],
        };
      }

    case "CHANGE_TOGGLE":
      return {
        ...state,
        handleToggle: !state.handleToggle,
      };

    // case "INPUT":
    //   console.log("INPUT", action.payload);
    //   let t_val = state.ans.find((i) => i.id === action.payload.id);

    case "UPDATE_T":
      return {
        ...state,
        t_val: action.payload
      };

    case 'ADD_VERSION_HISTORY_MODAL':
      return {
        ...state,
        versionHistoryData: action.payload
      };

    case 'RESET':
      return { ...initialState };

    case 'ADD_VERSIONS_COMMENTS':
      return {
        ...state,
        versionsComments: action.payload
      };

    case 'UPDATE_COMMENT':
      return {
        ...state,
        versionsComments: [{
          ...state.versionsComments[0],
          comments: {
            ...state.versionsComments[0].comments,
            [action.payload.heading]: action.payload.comment
          }
        }, ...state.versionsComments.slice(1)]
      };

    case 'DELETE_COMMENT':
      obj = [...state.versionsComments]
      delete obj[0].comments[action.payload]
      return {
        ...state,
        versionsComments: obj
      }

    default:
      return state;
  }
}

export default contractReducer