import React from 'react';
import { alertError } from '../components/Alerts';
import unauthaxios from '../auth/UnAuthAxios';
import { Link, useNavigate } from 'react-router-dom';
import PreSigninNavbar from '../components/PreSigninNavbar';

const SignUp = () => {
    const navigate = useNavigate();

    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [association, setAssociation] = React.useState('');
    const [betaAccessCode, setBetaAccessCode] = React.useState('');
    const [failedToValidate, setFailedToValidate] = React.useState(false);
    const [invitationCode, setInvitationCode] = React.useState('');
    function handleSubmit(e) {
        e.preventDefault();
        if (email.length !== 0 && password.length !== 0 && confirmPassword.length !== 0 && password === confirmPassword && betaAccessCode.length > 0 && document.getElementById('t&c').checked) {
            unauthaxios.post('/user/signup', {
                firstname: firstName,
                lastname: lastName,
                email: email,
                password: password,
                invitationCode: invitationCode,
                association: association,
                betaAccessCode: betaAccessCode.toLowerCase()
            }).then(res => {
                if (res.status === 200) {
                    navigate('/signin');
                }
            }).catch(function (error) {
                setFailedToValidate(true);
                alertError(error.response.data.message);
            });
        }
        else if (email.length === 0 || password.length === 0 || confirmPassword.length === 0) {
            setFailedToValidate(true);
            alertError("Please fill in all the fields");
        }
        else if (password !== confirmPassword) {
            setFailedToValidate(true);
            alertError("Passwords do not match");
        }
        else if (!document.getElementById('t&c').checked) {
            setFailedToValidate(true);
            alertError("Please accept the terms and conditions");
        }
    }

    React.useEffect(() => {
        localStorage.clear();
    }, [])

    React.useEffect(() => {
        const invitationUrl = new URLSearchParams(window.location.search).get("invitationCode")
        if (invitationUrl !== null && invitationUrl !== undefined) {
            setInvitationCode(invitationUrl);
            unauthaxios.get(`/user/invitation?invitationCode=${invitationUrl}`).then(res => {
                if (res.status === 200) {
                    setFirstName(res.data.firstName);
                    setLastName(res.data.lastName);
                    setEmail(res.data.email);
                }
            }
            ).catch(function (error) {
                alertError(error.response.data.message);
            }
            );
        }
    }, []);

    return (
        <>
            <PreSigninNavbar />
            <div className='flex p-8 sm:mx-[17vw] sm:my-[9vh] bg-[#FFFFFF] rounded-[16px]' style={{ boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)" }}>
                <div className='flex items-center justify-center pr-12'>
                    <img width={"400px"} height={"400px"} src="/Images/Navbar/SD-Logo.png"></img>
                </div>
                <form>
                    <div className="text-3xl font-bold text-[#111928] font-bold mb-5">Create your free account</div>
                    <div className="mb-4">
                        <h4 className="text-base font-medium text-[#111928] font-medium">First Name<span className='text-red-500'>*</span></h4>
                        <input
                            id="firstname"
                            name="firstname"
                            type="text"
                            autoComplete="firstname"
                            required
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            className="relative block w-full rounded-t-md sm:text-sm sm:w-[29vw]"
                            placeholder="John"
                            style={{
                                padding: "13px 20px",
                                gap: "10px",
                                height: "40px",
                                background: "#F9FAFB",
                                border: "1px solid #E5E7EB",
                                borderRadius: "16px"
                            }}
                        />
                    </div>
                    <div className="mb-4">
                        <h4 className="text-base font-medium text-[#111928] font-medium">Last Name<span className='text-red-500'>*</span></h4>
                        <input
                            id="lastname"
                            name="lastname"
                            type="text"
                            autoComplete="lastname"
                            required
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            className="relative block w-full rounded-t-md sm:text-sm sm:w-[29vw]"
                            placeholder="Doe"
                            style={{
                                padding: "13px 20px",
                                gap: "10px",
                                height: "40px",
                                background: "#F9FAFB",
                                border: "1px solid #E5E7EB",
                                borderRadius: "16px"
                            }}
                        />
                    </div>
                    <div className="mb-4 relative">
                        <h4 className="text-base font-medium text-[#111928] font-medium">Organization</h4>
                        <input
                            id="lastname"
                            name="lastname"
                            type="text"
                            autoComplete="lastname"
                            required
                            value={association}
                            onChange={(e) => setAssociation(e.target.value)}
                            className="relative block w-full rounded-t-md sm:text-sm sm:w-[29vw]"
                            placeholder="Acme"
                            style={{
                                padding: "13px 20px",
                                gap: "10px",
                                height: "40px",
                                background: "#F9FAFB",
                                border: "1px solid #E5E7EB",
                                borderRadius: "16px"
                            }}
                        />
                        <svg
                            onMouseEnter={() => {
                                document.getElementById(`Association`).style.display = "block"
                            }}
                            onMouseLeave={() => {
                                document.getElementById(`Association`).style.display = "none"
                            }}
                            width="21" height="20" className='absolute bottom-2 right-3' viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.4685 13.3006C18.9019 12.2542 19.125 11.1327 19.125 10C19.125 7.71251 18.2163 5.51871 16.5988 3.9012C14.9813 2.2837 12.7875 1.375 10.5 1.375C8.21251 1.375 6.0187 2.2837 4.4012 3.9012C2.7837 5.51871 1.875 7.71251 1.875 10C1.875 11.1327 2.09809 12.2542 2.53154 13.3006C2.96499 14.3471 3.6003 15.2979 4.4012 16.0988C5.20211 16.8997 6.15292 17.535 7.19936 17.9685C8.24579 18.4019 9.36735 18.625 10.5 18.625C11.6327 18.625 12.7542 18.4019 13.8006 17.9685C14.8471 17.535 15.7979 16.8997 16.5988 16.0988C17.3997 15.2979 18.035 14.3471 18.4685 13.3006Z" fill="white" />
                            <path d="M11.4583 13.8333H10.5V10H9.54167M10.5 6.16667H10.5096M19.125 10C19.125 11.1327 18.9019 12.2542 18.4685 13.3006C18.035 14.3471 17.3997 15.2979 16.5988 16.0988C15.7979 16.8997 14.8471 17.535 13.8006 17.9685C12.7542 18.4019 11.6327 18.625 10.5 18.625C9.36735 18.625 8.24579 18.4019 7.19936 17.9685C6.15292 17.535 5.20211 16.8997 4.4012 16.0988C3.6003 15.2979 2.96499 14.3471 2.53154 13.3006C2.09809 12.2542 1.875 11.1327 1.875 10C1.875 7.71251 2.7837 5.51871 4.4012 3.9012C6.0187 2.2837 8.21251 1.375 10.5 1.375C12.7875 1.375 14.9813 2.2837 16.5988 3.9012C18.2163 5.51871 19.125 7.71251 19.125 10Z" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <div id={`Association`} className='absolute hidden top-16 right-2  w-64 transition-all duration-200 ease-in-out z-10'>
                            <div className='rounded bg-white border w-full border-gray-300 shadow-lg p-2'>
                                <p className="text-xs text-Inter text-gray-600">
                                    Adding your organization provides useful context when you send contracts and work with others on our platform. For example:
                                </p>
                                <p className='text-xs text-Inter text-gray-600 italic mt-1.5'>You just received a contract draft from John Doe (Acme) </p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <h4 className="text-base font-medium text-[#111928] font-medium">Email<span className='text-red-500'>*</span></h4>
                        <input
                            id="email-address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="relative block w-full rounded-t-md sm:text-sm sm:w-[29vw]"
                            placeholder="name@example.com"
                            style={{
                                padding: "13px 20px",
                                gap: "10px",
                                height: "40px",
                                background: "#F9FAFB",
                                border: "1px solid #E5E7EB",
                                borderRadius: "16px"
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: "1rem" }}>
                        <h4 className="text-base font-medium text-[#111928] font-medium">Password<span className='text-red-500'>*</span></h4>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="relative block w-full sm:text-sm sm:w-[29vw]"
                            placeholder="**********"
                            style={{
                                padding: "13px 20px",
                                gap: "10px",
                                height: "40px",
                                background: "#F9FAFB",
                                border: "1px solid #E5E7EB",
                                borderRadius: "16px"
                            }}
                        />
                    </div>
                    <div className='mb-4'>
                        <h4 className="text-base font-medium text-[#111928] font-medium">Confirm Password<span className='text-red-500'>*</span></h4>
                        <input
                            id="confirm_password"
                            name="confirm_password"
                            type="password"
                            autoComplete="current-password"
                            required
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="relative block w-full sm:text-sm sm:w-[29vw]"
                            placeholder="**********"
                            style={{
                                padding: "13px 20px",
                                gap: "10px",
                                height: "40px",
                                background: "#F9FAFB",
                                border: "1px solid #E5E7EB",
                                borderRadius: "16px"
                            }}
                        />
                    </div>
                    <div className="mb-4">
                        <h4 className="text-base text-[#111928] font-medium">Beta Access Code<span className='text-red-500'>*</span></h4>
                        <input
                            id="beta-access-code"
                            name="beta-access-code"
                            type="text"
                            required
                            value={betaAccessCode}
                            onChange={(e) => setBetaAccessCode(e.target.value)}
                            className="relative block w-full rounded-t-md sm:text-sm sm:w-[29vw]"
                            style={{
                                padding: "13px 20px",
                                gap: "10px",
                                height: "40px",
                                background: "#F9FAFB",
                                border: "1px solid #E5E7EB",
                                borderRadius: "16px"
                            }}
                        />
                    </div>

                    <div className="flex items-center" style={{ marginTop: "1rem" }}>
                        <div className="flex items-center">
                            <input
                                id="t&c"
                                name="t&c"
                                type="checkbox"
                                required
                                className="border-[#D1D5DB] text-[#D1D5DB] focus:ring-[white]"
                            />
                            <label htmlFor="t&c" className="ml-2 text-xs font-normal text-[#6B7280]">
                                I accept the
                                <a target='_blank' href="https://www.standarddraft.com/terms-of-use" className="font-semibold text-[#1C64F2] hover:text-blue-700 px-1">Terms of Use</a>
                                and
                                <a target='_blank' href="https://www.standarddraft.com/privacy-policy" className="font-semibold text-[#1C64F2] hover:text-blue-700 px-1">Privacy Policy</a>
                            </label>
                        </div>
                    </div>
                    <div style={{ marginTop: "1rem" }}>
                        <button
                            type="submit"
                            style={{
                                border: "1px solid #1C64F2",
                                borderRadius: "12px"
                            }}
                            onClick={handleSubmit}
                            className="flex w-full justify-center bg-[#1C64F2] py-2 px-4 text-base font-semibold text-[#FFFFFF] hover:bg-blue-700"
                        >Create Account</button>
                    </div>
                    <hr style={{ width: "50%", marginLeft: "25%", borderColor: "#3F83F8", marginTop: "1rem" }} />
                    <div className="flex items-center justify-center mr-6" style={{ marginTop: "0.7rem" }}>
                        <Link to="/signin" className="text-base font-semibold text-[#1C64F2] ml-7 hover:text-blue-700">Sign In</Link>
                    </div>
                </form>
            </div>
        </>
    )
}

export default SignUp;