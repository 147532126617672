import { scrollToElement } from "./utils/scrollToElement.js";
import CustomTextEditor from "../CustomTextEditor.js";
import CommentHistory from "../CommentHistory.js";
import { useSelector } from "react-redux";
import { ObjtoHTML } from "../Composer";
import { alertError } from '../Alerts';
import React from 'react'

const SubSection = ({ obj, dispatch_handler, previousVersion, secondPreviousVersion, collapsibleHandler, setPreviewButton, steps, setActiveStep, readOnly }) => {

    const versionsComments = useSelector(state => state.contract.versionsComments)
    const { contractTemplateName } = useSelector(state => state.contract.ds)
    const ans = useSelector(state => state.contract.ans)
    let infoModalRef = React.useRef();

    const [arrow, setArrow] = React.useState(false);
    const [visibility, setVisibility] = React.useState(false)
    const [customTextEditor, setCustomTextEditor] = React.useState(false)
    const [childrenExpanded, setChildrenExpanded] = React.useState(false);
    const [overlayVisibility, setOverlayVisibility] = React.useState(true);
    const [infoModalVisibility, setInfoModalVisibility] = React.useState(false);
    const [addModalVisibility, setAddModalVisibility] = React.useState(false);
    const [changeModalVisibility, setChangeModalVisibility] = React.useState(false);
    const [removeModalVisibility, setRemoveModalVisibility] = React.useState(false);
    const [commentModalVisibility, setCommentModalVisibility] = React.useState(false);
    const [customSubSectionRemoveModalVisibility, setCustomSubSectionRemoveModalVisibility] = React.useState(false);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (infoModalRef.current && !infoModalRef.current.contains(event.target)) {
                setInfoModalVisibility(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [])

    React.useEffect(() => {
        if (!obj.actions || (obj.actions && (ans[obj.customTextId] || ans["CustomText" + obj.customTextId]))) {
            setVisibility(true)
        }

        if (ans["OriginalText" + obj.customTextId]) {
            if (ans["OriginalText" + obj.customTextId].addWarning) {
                setAddModalVisibility(true)
            }
            else if (ans["OriginalText" + obj.customTextId].changeWarning) {
                setChangeModalVisibility(true)
            }
        }

        if (Object.keys(secondPreviousVersion).length && Object.keys(previousVersion).length) {
            if (secondPreviousVersion.version.structured_options_answers["CustomText" + obj.customTextId] && !previousVersion.version.structured_options_answers["CustomText" + obj.customTextId] && ans["OriginalText" + obj.customTextId].removeWarning) {
                setCustomTextEditor(true);
                setOverlayVisibility(false);
                setRemoveModalVisibility(true);
            }
        }
        if (obj.customSubSection) {
            if (Object.keys(ans).length && !ans["OriginalText" + obj.customTextId]) {
                dispatch_handler(
                    [
                        {
                            type: "ADD",
                            payload: {
                                ["OriginalText" + obj.customTextId]: {
                                    "value": "",
                                }
                            }
                        }
                    ])
            }
        }
        if (ans["CustomText" + obj.customTextId]) {
            setCustomTextEditor(true);
            setOverlayVisibility(false);
        }
    }, [ans])

    return (
        <>
            <div onClick={() => setTimeout(() => scrollToElement(obj.customTextId), 1)} className={`subsection flex mr-5 mb-3 select-none ${obj.customSubSection && (!ans["CustomText" + obj.customTextId] && !ans["OriginalText" + obj.customTextId]?.removeWarning) ? "hidden" : "block"}`} id={obj.id}>
                {
                    !contractTemplateName.includes("Short-Form") ?
                        obj.actions ?
                            <div className="mt-3" onClick={() => {
                                if (ans["OriginalText" + obj.customTextId]?.removeWarning || (ans["OriginalText" + obj.customTextId]?.changeWarning && ans["CustomText" + obj.customTextId]?.value.replace(/\s/g, '') !== secondPreviousVersion?.version?.structured_options_answers["CustomText" + obj.customTextId]?.value.replace(/\s/g, '')) || ans["OriginalText" + obj.customTextId]?.addWarning) {
                                    alertError("Please expand this sub-section to accept or reject the custom edits")
                                    return;
                                }
                                if (obj.customSubSection) {
                                    setCustomSubSectionRemoveModalVisibility(true)
                                }
                                else {
                                    dispatch_handler([{ type: 'CHANGE_TOGGLE' }])
                                    dispatch_handler(obj.actions);
                                    dispatch_handler([{
                                        type: "DELETE",
                                        payload: "CustomText" + obj.customTextId
                                    }])
                                    setVisibility(!visibility);
                                }
                            }}>
                                {visibility ?
                                    previousVersion.version?.structured_options_answers[obj.customTextId] || previousVersion.version?.structured_options_answers["CustomText" + obj.customTextId] ?
                                        !secondPreviousVersion.version || secondPreviousVersion.version.structured_options_answers[obj.customTextId] || secondPreviousVersion.version.structured_options_answers["CustomText" + obj.customTextId] ?
                                            <svg width="22" height="22" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect y="0.5" width="16" height="16" rx="4" fill="#4B5563" />
                                                <path d="M6.5498 10.8903C6.72559 11.0551 7.02441 11.0551 7.2002 10.8903L12.3682 6.04535C12.5439 5.88055 12.5439 5.6004 12.3682 5.43561L11.7354 4.84235C11.5596 4.67755 11.2783 4.67755 11.1025 4.84235L6.88379 8.79742L4.89746 6.95172C4.72168 6.78693 4.44043 6.78693 4.26465 6.95172L3.63184 7.54498C3.45605 7.70978 3.45605 7.98993 3.63184 8.15472L6.5498 10.8903Z" fill="white" />
                                            </svg>
                                            :
                                            <svg width="22" height="22" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect y="0.5" width="16" height="16" rx="4" fill="#7E3AF2" />
                                                <path d="M6.5498 10.8903C6.72559 11.0551 7.02441 11.0551 7.2002 10.8903L12.3682 6.04535C12.5439 5.88055 12.5439 5.6004 12.3682 5.43561L11.7354 4.84235C11.5596 4.67755 11.2783 4.67755 11.1025 4.84235L6.88379 8.79742L4.89746 6.95172C4.72168 6.78693 4.44043 6.78693 4.26465 6.95172L3.63184 7.54498C3.45605 7.70978 3.45605 7.98993 3.63184 8.15472L6.5498 10.8903Z" fill="white" />
                                            </svg>
                                        :
                                        <svg width="22" height="22" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.5" width="16" height="16" rx="4" fill="#1C64F2" />
                                            <path d="M6.5498 10.8903C6.72559 11.0551 7.02441 11.0551 7.2002 10.8903L12.3682 6.04535C12.5439 5.88055 12.5439 5.6004 12.3682 5.43561L11.7354 4.84235C11.5596 4.67755 11.2783 4.67755 11.1025 4.84235L6.88379 8.79742L4.89746 6.95172C4.72168 6.78693 4.44043 6.78693 4.26465 6.95172L3.63184 7.54498C3.45605 7.70978 3.45605 7.98993 3.63184 8.15472L6.5498 10.8903Z" fill="white" />
                                        </svg>
                                    :
                                    !previousVersion.version?.structured_options_answers[obj.customTextId] && !previousVersion.version?.structured_options_answers["CustomText" + obj.customTextId] ?
                                        !secondPreviousVersion.version || !secondPreviousVersion.version.structured_options_answers[obj.customTextId] && !secondPreviousVersion.version.structured_options_answers["CustomText" + obj.customTextId] ?
                                            <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="1" y="1" width="16" height="16" rx="4" fill="#F9FAFB" />
                                                <rect x="1" y="1" width="16" height="16" rx="4" stroke="#E4E4E7" />
                                            </svg>
                                            :
                                            <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="1" y="1" width="16" height="16" rx="4" fill="#DCD7FE" />
                                                <rect x="1" y="1" width="16" height="16" rx="4" stroke="#7e3af29e" />
                                            </svg>
                                        :
                                        <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="1" y="1" width="16" height="16" rx="4" fill="#FDF2F2" />
                                            <rect x="1" y="1" width="16" height="16" rx="4" stroke="#F8B4B4" />
                                        </svg>
                                }
                            </div>
                            :
                            <div className="relative mt-3.5">
                                <span className="group hover:cursor-pointer">
                                    <span className="absolute top-[-40px] w-40 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100">You can’t remove this sub-section</span>
                                    <svg width="22" height="22" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="0.5" width="16" height="16" rx="4" fill="#4B5563" />
                                        <path d="M6.5498 10.8903C6.72559 11.0551 7.02441 11.0551 7.2002 10.8903L12.3682 6.04535C12.5439 5.88055 12.5439 5.6004 12.3682 5.43561L11.7354 4.84235C11.5596 4.67755 11.2783 4.67755 11.1025 4.84235L6.88379 8.79742L4.89746 6.95172C4.72168 6.78693 4.44043 6.78693 4.26465 6.95172L3.63184 7.54498C3.45605 7.70978 3.45605 7.98993 3.63184 8.15472L6.5498 10.8903Z" fill="white" />
                                    </svg>
                                </span>
                            </div>
                        :
                        null
                }
                <div className="mx-3 w-full" key={obj.id}>
                    <div className={"border border-[#E4E4E7] rounded-[5px] p-3"}>
                        <div id={obj.customTextId + "-expand"} className={obj.classes + " flex items-center hover:cursor-pointer"} onClick={(e) => {
                            e.stopPropagation()
                            if (e.isTrusted) {
                                for (let i = 0; i < steps.length; i++) {
                                    if (document.getElementById(steps[i] + "-heading").parentElement.parentElement.contains(e.currentTarget)) {
                                        setActiveStep(steps.indexOf(steps[i]))
                                        break
                                    }
                                }
                                scrollToElement(obj.customTextId)
                                collapsibleHandler(e.currentTarget);
                                setArrow(!arrow)
                            }
                            else {
                                if (!arrow) {
                                    collapsibleHandler(e.currentTarget);
                                    setArrow(!arrow)
                                }
                                if (childrenExpanded) {
                                    Array.from(document.getElementsByClassName("collapsible")).forEach((item) => {
                                        if (document.getElementById(obj.id).contains(item) && !item.id) {
                                            if (item.firstChild.tagName === "DIV") {
                                                item.firstChild.click()
                                            }
                                            else {
                                                item.click()
                                            }
                                        }
                                    })
                                    setChildrenExpanded(false)
                                }
                            }
                            const div = document.getElementById("SelectionPane");
                            div?.scrollTo({
                                top: document.getElementById(obj.customTextId + "-expand")?.offsetTop - div.clientHeight / 5,
                                behavior: 'smooth',
                            });
                        }}>
                            {
                                arrow ?
                                    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.3335 2L8.00016 8.66667L14.6668 2" stroke="#A1A1AA" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    :
                                    <svg width="16" height="16" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.625 11L6.625 6L1.625 0.999999" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                            }
                            <p className="text-[#374151] text-base font-normal px-2 w-56">{obj.heading}</p>
                            <div className="flex gap-5 items-center">
                                {
                                    visibility && previousVersion.version ?
                                        !obj.customTextId || previousVersion.version?.structured_options_answers[obj.customTextId] || previousVersion.version?.structured_options_answers["CustomText" + obj.customTextId] ?
                                            Object.keys(ans).every((item) => {
                                                if (document.getElementById(obj.id)?.contains(document.getElementById(ans[item].id)) || (item.includes("CustomText") && item.replace("CustomText", "") === obj.customTextId)) {
                                                    if (!previousVersion.version.structured_options_answers[item] || ans[item].value?.replace(/\s/g, '') !== previousVersion.version.structured_options_answers[item].value?.replace(/\s/g, ''))
                                                        return false;
                                                }
                                                return true;
                                            }) &&
                                                Object.keys(previousVersion.version.structured_options_answers).every((item) => {
                                                    if (!ans[item]) {
                                                        if (document.getElementById(obj.id)?.contains(document.getElementById(previousVersion.version.structured_options_answers[item].id)) || (item.includes("CustomText") && item.replace("CustomText", "") === obj.customTextId)) {
                                                            return false;
                                                        }
                                                    }
                                                    return true;
                                                }) ?
                                                Object.keys(secondPreviousVersion).length ?
                                                    !obj.customTextId || secondPreviousVersion.version.structured_options_answers[obj.customTextId] || secondPreviousVersion.version.structured_options_answers["CustomText" + obj.customTextId] ?
                                                        Object.keys(previousVersion.version.structured_options_answers).every((item) => {
                                                            if (document.getElementById(obj.id)?.contains(document.getElementById(previousVersion.version.structured_options_answers[item].id)) || (item.includes("CustomText") && item.replace("CustomText", "") === obj.customTextId)) {
                                                                if (!secondPreviousVersion.version.structured_options_answers[item] || secondPreviousVersion.version.structured_options_answers[item].value?.replace(/\s/g, '') !== previousVersion.version.structured_options_answers[item].value?.replace(/\s/g, ''))
                                                                    return false;
                                                            }
                                                            return true;
                                                        }) &&
                                                            Object.keys(secondPreviousVersion.version.structured_options_answers).every((item) => {
                                                                if (!previousVersion.version.structured_options_answers[item]) {
                                                                    if (document.getElementById(obj.id)?.contains(document.getElementById(secondPreviousVersion.version.structured_options_answers[item].id)) || (item.includes("CustomText") && item.replace("CustomText", "") === obj.customTextId)) {
                                                                        return false;
                                                                    }
                                                                }
                                                                return true;
                                                            }) ?
                                                            ""
                                                            :
                                                            <div className="flex gap-5 text-[#71717A] text-[12px] font-[400]">
                                                                Edited by counterparty
                                                                {
                                                                    (addModalVisibility || changeModalVisibility) &&
                                                                    <div className='relative group hover:cursor-pointer bg-[#FDECDB] text-xs text-[#52525B] border border-[#FDECDB] rounded-[10px] py-[2px] px-[10px] cursor-pointer hover:bg-[#FDECDB]'>
                                                                        <span className="absolute top-[24px] w-44 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100 z-10">Please accept or reject your counterparty’s proposed custom text</span>
                                                                        <div className="flex gap-2">
                                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-[#F59E0B]">
                                                                                <path d="M8 0.666626C3.80461 0.666626 0.666992 3.80424 0.666992 8.00063C0.666992 12.1969 3.80461 15.3345 8 15.3345C12.1954 15.3345 15.333 12.1969 15.333 8.00063C15.333 3.80424 12.1954 0.666626 8 0.666626ZM8 13.3345C4.68629 13.3345 2.00033 10.6485 2.00033 7.33479C2.00033 4.02108 4.68629 1.33512 8 1.33512C11.3137 1.33512 14 4.02108 14 7.33479C14 10.6485 11.3137 13.3345 8 13.3345Z" fill="currentColor" />
                                                                                <path d="M8.66699 4.66663H7.33366V8.00063H8.66699V4.66663ZM8.66699 8.99996H7.33366V10.3333H8.66699V8.99996Z" fill="currentColor" />
                                                                            </svg>
                                                                            <span className='font-medium'>Custom text</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        :
                                                        <div className="flex gap-5 text-[#71717A] text-[12px] font-[400]">
                                                            Added by counterparty
                                                            {
                                                                (addModalVisibility || changeModalVisibility) &&
                                                                <div className='relative group hover:cursor-pointer bg-[#FDECDB] text-xs text-[#52525B] border border-[#FDECDB] rounded-[10px] py-[2px] px-[10px] cursor-pointer hover:bg-[#FDECDB]'>
                                                                    <span className="absolute top-[24px] w-44 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100 z-10">Please accept or reject your counterparty’s proposed custom text</span>
                                                                    <div className="flex gap-2">
                                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-[#F59E0B]">
                                                                            <path d="M8 0.666626C3.80461 0.666626 0.666992 3.80424 0.666992 8.00063C0.666992 12.1969 3.80461 15.3345 8 15.3345C12.1954 15.3345 15.333 12.1969 15.333 8.00063C15.333 3.80424 12.1954 0.666626 8 0.666626ZM8 13.3345C4.68629 13.3345 2.00033 10.6485 2.00033 7.33479C2.00033 4.02108 4.68629 1.33512 8 1.33512C11.3137 1.33512 14 4.02108 14 7.33479C14 10.6485 11.3137 13.3345 8 13.3345Z" fill="currentColor" />
                                                                            <path d="M8.66699 4.66663H7.33366V8.00063H8.66699V4.66663ZM8.66699 8.99996H7.33366V10.3333H8.66699V8.99996Z" fill="currentColor" />
                                                                        </svg>
                                                                        <span className='font-medium'>Custom text</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    : ""
                                                :
                                                <div className="flex gap-5 text-[#71717A] text-[12px] font-[400]">
                                                    Edited by you
                                                    {
                                                        customTextEditor &&
                                                        <div className='relative group hover:cursor-pointer bg-[#F4F4F5] text-xs text-[#52525B] border rounded-[10px] py-[2px] px-[10px] cursor-pointer hover:bg-[#eeeeee]'>
                                                            <span className="absolute top-[24px] w-44 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100">You have proposed custom text in this sub-section</span>
                                                            <span className='font-medium'>Custom text</span>
                                                        </div>
                                                    }
                                                </div>
                                            :
                                            obj.actions ?
                                                <div className="flex gap-5 text-[#71717A] text-[12px] font-[400]">
                                                    Added by you
                                                    {
                                                        customTextEditor &&
                                                        <div className='relative group hover:cursor-pointer bg-[#F4F4F5] text-xs text-[#52525B] border rounded-[10px] py-[2px] px-[10px] cursor-pointer hover:bg-[#eeeeee]'>
                                                            <span className="absolute top-[24px] w-44 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100">You have proposed custom text in this sub-section</span>
                                                            <span className='font-medium'>Custom text</span>
                                                        </div>
                                                    }
                                                </div>
                                                : ""
                                        :
                                        !previousVersion.version?.structured_options_answers[obj.customTextId] && !previousVersion.version?.structured_options_answers["CustomText" + obj.customTextId] ?
                                            !Object.keys(secondPreviousVersion).length || (!secondPreviousVersion.version.structured_options_answers[obj.customTextId] && !secondPreviousVersion.version.structured_options_answers["CustomText" + obj.customTextId]) ?
                                                "" :
                                                <div className="flex gap-5 text-[#71717A] text-[12px] font-[400]">Removed by counterparty</div>
                                            :
                                            obj.actions ?
                                                <div className="flex gap-5 text-[#71717A] text-[12px] font-[400]">Removed by you</div>
                                                : ""
                                }
                                {
                                    (versionsComments[0]?.comments?.hasOwnProperty(obj.heading) || versionsComments[1]?.comments?.hasOwnProperty(obj.heading)) &&
                                    <div className='relative group hover:cursor-pointer text-xs text-[#52525B] py-[2px] cursor-pointer'>
                                        <span className="absolute top-[24px] w-48 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100 z-10">{
                                            versionsComments[0]?.comments?.hasOwnProperty(obj.heading) ? "You have added a comment in this sub-section" : "Your counterparty added a comment in this sub-section"
                                        }</span>
                                        <svg onClick={(e) => {
                                            e.stopPropagation()
                                            setCommentModalVisibility(true)
                                        }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat" viewBox="0 0 16 16">
                                            <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894m-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" fill="#374151d9" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                }
                            </div>
                            {
                                !obj.customSubSection &&
                                <div className="flex items-center gap-2 ml-auto mr-3">
                                    {
                                        arrow && Array.from(document.getElementsByClassName("collapsible")).some((item) => {
                                            return document.getElementById(obj.id).contains(item) && !item.id
                                        }) &&
                                        <div id={obj.customTextId + "-childrenexpand"} onClick={(e) => {
                                            e.stopPropagation();
                                            Array.from(document.getElementsByClassName("collapsible")).forEach((item) => {
                                                if (document.getElementById(obj.id).contains(item) && !item.id && ((!childrenExpanded && item.nextElementSibling.nextElementSibling.style.display === "none") || (childrenExpanded && item.nextElementSibling.nextElementSibling.style.display === "block"))) {
                                                    if (item.firstChild.tagName === "DIV") {
                                                        item.firstChild.click()
                                                    }
                                                    else {
                                                        item.click()
                                                    }
                                                }
                                            })
                                            if (childrenExpanded) {
                                                setChildrenExpanded(false)
                                            }
                                            else {
                                                setChildrenExpanded(true)
                                            }
                                        }} className="text-[#949BA9] text-xl mb-1">
                                            {childrenExpanded ?
                                                <div className='relative group cursor-pointer'>
                                                    <span className="absolute top-[25px] right-[-80px] w-40 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100">Minimize all configurable options below</span>
                                                    <span className='font-medium'>-</span>
                                                </div>
                                                :
                                                <div className='relative group cursor-pointer'>
                                                    <span className="absolute top-[25px] right-[-80px] w-40 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100">Expand all configurable options below</span>
                                                    <span className='font-medium'>+</span>
                                                </div>
                                            }
                                        </div>
                                    }
                                    <svg onClick={(e) => { e.stopPropagation(); setInfoModalVisibility(true) }} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.4685 13.8006C18.9019 12.7542 19.125 11.6327 19.125 10.5C19.125 8.21251 18.2163 6.01871 16.5988 4.4012C14.9813 2.7837 12.7875 1.875 10.5 1.875C8.21251 1.875 6.0187 2.7837 4.4012 4.4012C2.7837 6.01871 1.875 8.21251 1.875 10.5C1.875 11.6327 2.09809 12.7542 2.53154 13.8006C2.96499 14.8471 3.6003 15.7979 4.4012 16.5988C5.20211 17.3997 6.15292 18.035 7.19936 18.4685C8.24579 18.9019 9.36735 19.125 10.5 19.125C11.6327 19.125 12.7542 18.9019 13.8006 18.4685C14.8471 18.035 15.7979 17.3997 16.5988 16.5988C17.3997 15.7979 18.035 14.8471 18.4685 13.8006Z" fill="white" />
                                        <path d="M11.4583 14.3333H10.5V10.5H9.54167M10.5 6.66667H10.5096M19.125 10.5C19.125 11.6327 18.9019 12.7542 18.4685 13.8006C18.035 14.8471 17.3997 15.7979 16.5988 16.5988C15.7979 17.3997 14.8471 18.035 13.8006 18.4685C12.7542 18.9019 11.6327 19.125 10.5 19.125C9.36735 19.125 8.24579 18.9019 7.19936 18.4685C6.15292 18.035 5.20211 17.3997 4.4012 16.5988C3.6003 15.7979 2.96499 14.8471 2.53154 13.8006C2.09809 12.7542 1.875 11.6327 1.875 10.5C1.875 8.21251 2.7837 6.01871 4.4012 4.4012C6.0187 2.7837 8.21251 1.875 10.5 1.875C12.7875 1.875 14.9813 2.7837 16.5988 4.4012C18.2163 6.01871 19.125 8.21251 19.125 10.5Z" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            }
                        </div>
                        <div></div>
                        <div>
                            {obj.text && <div className="my-2 text-xs font-normal text-[#6B7280]" dangerouslySetInnerHTML={{ __html: obj.text }}></div>}
                            {!obj.children.length && !ans["CustomText" + obj.customTextId] ? <div className="mt-2 text-xs font-normal text-[#6B7280]">This sub-section does not have any configurable options. If necessary, you may convert this sub-section to custom text.</div> : null}
                            {
                                customTextEditor ?
                                    <>
                                        {
                                            !obj.customSubSection ?
                                                <div className="text-base font-semibold text-[#374151] mt-3">Custom Text Edits</div>
                                                :
                                                null
                                        }
                                        {
                                            ans["OriginalText" + obj.customTextId]?.addWarning ?
                                                !obj.customSubSection ?
                                                    <>
                                                        <p className="text-xs font-medium text-[#6B7280] mt-2">
                                                            Your counterparty converted this sub-section to custom text. You can review your counterparty’s edits in the preview panel to the right.
                                                        </p>
                                                        <p className="text-xs font-medium text-[#6B7280] mt-1">
                                                            Select Reject to reject your counterparty’s custom edits and return to the configurable drafting options.
                                                        </p>
                                                        <p className="text-xs font-medium text-[#6B7280] mt-1">
                                                            Select Accept to accept your counterparty’s custom edits and, if necessary, to propose your additional custom edits.
                                                        </p>
                                                    </>
                                                    :
                                                    <>
                                                        <p className="text-xs font-medium text-[#6B7280] mt-2">
                                                            Your counterparty proposed a custom sub-section.
                                                        </p>
                                                        <p className="text-xs font-medium text-[#6B7280] mt-1">
                                                            Select Reject to reject your counterparty’s custom sub-section.
                                                        </p>
                                                        <p className="text-xs font-medium text-[#6B7280] mt-1">
                                                            Select Accept to accept your counterparty’s custom sub-section and, if necessary, to propose additional custom edits to it.
                                                        </p>
                                                    </>
                                                :
                                                ans["OriginalText" + obj.customTextId]?.changeWarning && ans["CustomText" + obj.customTextId]?.value.replace(/\s/g, '') === secondPreviousVersion?.version.structured_options_answers["CustomText" + obj.customTextId]?.value.replace(/\s/g, '') ?
                                                    <p className="text-xs font-medium text-[#6B7280] mt-2">
                                                        Your counterparty accepted the custom text that you proposed. Would you like to propose additional edits?
                                                    </p>
                                                    :
                                                    ans["OriginalText" + obj.customTextId]?.changeWarning && ans["CustomText" + obj.customTextId]?.value.replace(/\s/g, '') !== secondPreviousVersion?.version.structured_options_answers["CustomText" + obj.customTextId]?.value.replace(/\s/g, '') ?
                                                        <>
                                                            <p className="text-xs font-medium text-[#6B7280] mt-2">
                                                                Your counterparty proposed additional custom edits.
                                                            </p>
                                                            <p className="text-xs font-medium text-[#6B7280] mt-1">
                                                                Select Reject to reject your counterparty’s custom edits and to restore your prior version.
                                                            </p>
                                                            <p className="text-xs font-medium text-[#6B7280] mt-1">
                                                                Select Accept to accept your counterparty’s custom edits and, if necessary, to propose additional custom edits.
                                                            </p>
                                                        </>
                                                        :
                                                        removeModalVisibility ?
                                                            <p className="text-xs font-medium text-[#6B7280] mt-2">
                                                                Your counterparty rejected the custom text that you proposed. Would you like to revert this section to your prior version?
                                                            </p>
                                                            :
                                                            !overlayVisibility && (previousVersion?.version.structured_options_answers["OriginalText" + obj.customTextId]?.changeWarning || previousVersion?.version.structured_options_answers["OriginalText" + obj.customTextId]?.addWarning) ?
                                                                ""
                                                                :
                                                                !overlayVisibility && previousVersion?.version.structured_options_answers["OriginalText" + obj.customTextId]?.removeWarning ?
                                                                    <p className="text-xs font-medium text-[#6B7280] mt-2">
                                                                        You reverted this sub-section to your prior version.
                                                                    </p>
                                                                    :
                                                                    <p className="text-xs font-medium text-[#6B7280] mt-2">
                                                                        {
                                                                            !obj.customSubSection ?
                                                                                "By converting this sub-section to custom text edits, you will no longer be able to modify the configurable drafting options. Click the lock icon below to convert to custom text edits."
                                                                                :
                                                                                "Please use the editor below to prepare your custom sub-section.  De-select this subsection in order to remove it."
                                                                        }
                                                                    </p>
                                        }
                                        <CustomTextEditor id={obj.id} visibility={visibility} setPreviewButton={setPreviewButton} customSubSection={obj.customSubSection} secondPreviousVersion={secondPreviousVersion} dispatch_handler={dispatch_handler} sectionId={obj.id} setCustomTextEditor={setCustomTextEditor} customTextId={obj.customTextId} overlayVisibility={overlayVisibility} setOverlayVisibility={setOverlayVisibility} addModalVisibility={addModalVisibility} setAddModalVisibility={setAddModalVisibility} changeModalVisibility={changeModalVisibility} setChangeModalVisibility={setChangeModalVisibility} removeModalVisibility={removeModalVisibility} setRemoveModalVisibility={setRemoveModalVisibility} setCommentModalVisibility={setCommentModalVisibility} />
                                    </>
                                    :
                                    null
                            }
                            <div className={obj.customSubSection || customTextEditor ? "hidden" : "block"} onClick={(e) => { e.stopPropagation() }}>
                                {obj.children.map((item, index) => {
                                    if (item.hide_if_true) {
                                        return (
                                            <div key={index} className={
                                                ans[item.hide_if_true[0]] && ans[item.hide_if_true[0]].state === "true" ? "hidden" : "block"
                                            }>
                                                <ObjtoHTML obj={[item]} disabled={!visibility} collapsibleHandler={collapsibleHandler} />
                                            </div>
                                        )
                                    }
                                    return <ObjtoHTML key={index} obj={[item]} disabled={!visibility} collapsibleHandler={collapsibleHandler} />;
                                })}
                                <div className="mt-8 flex">
                                    <button className="flex items-center gap-1" onClick={() => setCommentModalVisibility(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat" viewBox="0 0 16 16">
                                            <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894m-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" fill="#374151d9" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <div className="text-xs font-medium text-[#374151]">Comment</div>
                                    </button>
                                    {
                                        obj.customTextId && !contractTemplateName.includes("Short-Form") &&
                                        <button onClick={() => {
                                            let innerHTML = document.getElementById(obj.customTextId).innerHTML.trim()
                                            while (innerHTML.includes('<span style="color: blue">')) {
                                                let removalTextStartIndex = innerHTML.indexOf('<span style="color: blue">')
                                                let removalTextEndIndex = innerHTML.indexOf('</span>', removalTextStartIndex) + 7
                                                let otherSpan = innerHTML.indexOf('<span', removalTextStartIndex + 1)
                                                while (otherSpan !== -1) {
                                                    if (otherSpan < removalTextEndIndex) {
                                                        removalTextEndIndex = innerHTML.indexOf('</span>', removalTextEndIndex + 1) + 7
                                                        otherSpan = innerHTML.indexOf('<span', removalTextEndIndex + 1)
                                                    }
                                                    else {
                                                        otherSpan = -1
                                                    }
                                                }
                                                innerHTML = innerHTML.replace(innerHTML.substring(removalTextStartIndex, removalTextEndIndex), "")
                                            }
                                            dispatch_handler([
                                                {
                                                    type: "ADD",
                                                    payload: {
                                                        ["OriginalText" + obj.customTextId]: {
                                                            "value": innerHTML,
                                                        }
                                                    }
                                                }
                                            ])
                                            setCustomTextEditor(true);
                                        }} className="ml-[auto] flex items-center">
                                            <svg className="mr-3" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.0721 1.1148C12.8688 1.20215 12.6849 1.32912 12.5311 1.48831L5.37614 8.64331V11H7.73281L14.8878 3.84497C15.047 3.69123 15.174 3.50732 15.2613 3.30398C15.3487 3.10064 15.3946 2.88194 15.3966 2.66064C15.3985 2.43934 15.3563 2.21988 15.2725 2.01505C15.1887 1.81022 15.065 1.62414 14.9085 1.46765C14.752 1.31116 14.5659 1.1874 14.3611 1.1036C14.1562 1.0198 13.9368 0.977632 13.7155 0.979555C13.4942 0.981478 13.2755 1.02746 13.0721 1.1148Z" fill="#374151" />
                                                <path d="M7.04281 2.66664H2.87614C2.43411 2.66664 2.01019 2.84223 1.69763 3.15479C1.38507 3.46736 1.20947 3.89128 1.20947 4.33331V13.5C1.20947 13.942 1.38507 14.3659 1.69763 14.6785C2.01019 14.991 2.43411 15.1666 2.87614 15.1666H12.0428C12.4848 15.1666 12.9088 14.991 13.2213 14.6785C13.5339 14.3659 13.7095 13.942 13.7095 13.5V9.33331M12.5311 1.48831C12.6849 1.32912 12.8688 1.20215 13.0721 1.1148C13.2755 1.02746 13.4942 0.981478 13.7155 0.979555C13.9368 0.977632 14.1562 1.0198 14.3611 1.1036C14.5659 1.1874 14.752 1.31116 14.9085 1.46765C15.065 1.62414 15.1887 1.81022 15.2725 2.01505C15.3563 2.21988 15.3985 2.43934 15.3966 2.66064C15.3946 2.88194 15.3487 3.10064 15.2613 3.30398C15.174 3.50732 15.047 3.69123 14.8878 3.84497L7.73281 11H5.37614V8.64331L12.5311 1.48831Z" stroke="#374151" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <div className="text-xs font-medium text-[#374151]">Convert to Custom Text</div>
                                        </button>
                                    }
                                </div>
                            </div>
                        </div >
                    </div >
                </div >
                {
                    customSubSectionRemoveModalVisibility &&
                    <>
                        <div
                            tabIndex={-1}
                            className="flex justify-center items-center fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full overflow-auto"
                        >
                            <div className='px-8 py-6 w-[38%] bg-[#FFFFFF] rounded-[10px]' style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                                <div className="mb-3">
                                    <div className="font-semibold text-3xl text-[#111928] mb-1">Are you sure?</div>
                                </div>
                                <p className='text-sm font-normal text-[#6B7280]'>
                                    By removing this sub-section, any custom text edits made to this sub-section will be lost.
                                </p>
                                <div className='flex'>
                                    <button className='block mr-3 ml-auto text-[#111928] text-sm font-semibold border border-[#D1D5DB] rounded-[12px] px-[17px] py-[9px]'
                                        onClick={() => setCustomSubSectionRemoveModalVisibility(false)} >No</button>
                                    <button className='text-sm font-semibold bg-[#1C64F2] text-[white] border border-[#D1D5DB] rounded-[12px] px-[17px] py-[9px]'
                                        onClick={() => {
                                            setCustomSubSectionRemoveModalVisibility(false)
                                            dispatch_handler([{ type: 'CHANGE_TOGGLE' }])
                                            dispatch_handler(obj.actions);
                                            dispatch_handler([{
                                                type: "DELETE",
                                                payload: "CustomText" + obj.customTextId
                                            }])
                                            setVisibility(!visibility);
                                        }}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {
                    infoModalVisibility ?
                        <div className="relative" ref={infoModalRef}>
                            <div className="absolute right-12 w-[28rem] bg-[white] h-52 top-6 z-50 border overflow-y-auto rounded-[5px] shadow-lg">
                                <div className="flex items-center justify-between px-4 py-2 sticky top-0 bg-white">
                                    <div className="text-sm text-[#374151]" dangerouslySetInnerHTML={{ __html: obj.infoModal ? obj.infoModal.title : "Section" }}></div>
                                    <button onClick={() => setInfoModalVisibility(false)} className="focus:outline-none">
                                        <svg className="w-4 h-4 text-[#374151]" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L15 15M15 1L1 15" stroke="#374151" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="px-4 pb-2">
                                    <div className="text-xs text-[#374151]" dangerouslySetInnerHTML={{ __html: obj.infoModal ? obj.infoModal.description : "Information and insights coming soon." }}></div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                {commentModalVisibility && <CommentHistory heading={obj.heading} setCommentModalVisibility={setCommentModalVisibility} readOnly={readOnly} />}
            </div >
        </>
    )
}

export default SubSection
