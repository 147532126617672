import { useNavigate } from "react-router-dom";
import UserTemplates from "./UserTemplates";
import { useState, useEffect } from "react";
import { alertError } from "./Alerts";
import instance from "../auth/Axios"

const CreateContract = (props) => {
    const navigate = useNavigate();

    const [contractQuestionsModalVisibility, setContractQuestionsModalVisibility] = useState(false);
    const [questions, setQuestions] = useState([
        {
            heading: "Acceptance of Terms",
            description: "Please note, Standard Draft is a legal technology platform offering configurable contract forms for ease of drafting and negotiating. We are not a law firm and do not provide legal advice or services. <p style='margin-top:10px'> Standard Draft makes no assurances about the appropriateness or legality of the forms provided for any specific use. Each user is solely responsible for his or her own drafting decisions and should seek independent legal advice. By using the Standard Draft platform and/or forms, you agree to our <a href='https://www.standarddraft.com/terms-of-use' target='_blank'><u>Terms of Use</u></a> and <a href='https://www.standarddraft.com/privacy-policy' target='_blank'><u>Privacy Policy</u></a>. </p>",
            options: [["Yes, I understand this disclaimer and agree to the Terms of Use and Privacy Policy"]],
            acceptableAnswers: ["Yes, I understand this disclaimer and agree to the Terms of Use and Privacy Policy"]
        }
    ])
    const [currentQuestion, setCurrentQuestion] = useState(0)

    const [contractFormsModalVisibility, setContractFormsModalVisibility] = useState(false);
    const [contractForms, setContractForms] = useState([
        {
            name: "Other Standard Draft Forms",
        }
    ])
    const [contractFormsOption, setContractFormsOption] = useState(null)

    const [contractNameModalVisibility, setContractNameModalVisibility] = useState(false);

    const [userTemplateVisibilityModal, setUserTemplateVisibilityModal] = useState(false)
    const [userTemplateModal, setUserTemplateModal] = useState(false);
    const [contract, setContract] = useState(null);

    const [contractJSON, setContractJSON] = useState({})
    const [option, setOption] = useState('')

    const [submitClicked, setSubmitClicked] = useState(false)

    const updateContractFormsBorder = (e) => {
        if (contractFormsOption) {
            document.getElementById(contractFormsOption.id).style.border = "1px solid #E4E4E7";
        }
        if (e.length) {
            document.getElementById(e.id).style.border = "1px solid blue";
        } setContractFormsOption(e)
    }

    const updateBorder = (e) => {
        if (option.length !== 0) {
            document.getElementById(option).style.border = "1px solid #E4E4E7";
        }
        if (e.length !== 0) {
            document.getElementById(e).style.border = "1px solid blue";
        }
        setOption(e)
    }

    const handleSubmit = async () => {
        setSubmitClicked(true)
        const response = await instance.post("/contract/create", {
            contractJSON: {
                // ...contractJSON,
                contractFormId: contractJSON.contractFormId,
                contractName: document.getElementById("contractName").value,
                folderId: props.folderId
            },
            remainingContract: props.remainingContract
        })
        const userTemplates = await instance.post('/userTemplates/getAll', { contractId: response.data.id })

        setSubmitClicked(false)
        if (userTemplates.data.data.length !== 0) {
            setContract(response.data)
            setUserTemplateVisibilityModal(true)
        }
        else {
            navigate(`/${response.data.contractUUID}`)
        }
    }

    useEffect(() => {
        const getContractForms = async () => {
            const response = await instance.get("/contract/getContractForms")
            setContractForms([...response.data.contractForms, ...contractForms])
        }

        getContractForms()
        setContractQuestionsModalVisibility(props.value)
    }, [])

    return (
        <div>
            {contractQuestionsModalVisibility ?
                <div
                    tabIndex={-1}
                    className="fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full overflow-auto"
                >
                    <div className='p-8 pr-4 pt-0 sm:mx-[23vw] sm:my-[7vh] bg-[#FFFFFF] rounded-[10px]' style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                        <div className="flex mb-4">
                            <div style={{ marginRight: "auto" }} className="text-lg text-[#6B7280] font-normal mt-5">Step 1 of 3</div>
                            <div className="mt-4 cursor-pointer" style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                                <svg onClick={() => { props.onChange(false) }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                </svg>
                            </div>
                        </div>
                        <div className="mb-10">
                            <div className="font-semibold text-3xl text-[#111928] mb-2">{questions[currentQuestion].heading}</div>
                            <div className="font-normal text-[#6B7280]" dangerouslySetInnerHTML={{ __html: questions[currentQuestion].description }} />
                        </div>
                        {questions[currentQuestion].options.map((option, index) => {
                            return (
                                option[1] ?
                                    <div
                                        onClick={() => {
                                            updateBorder(option[0]);
                                            document.getElementById(option[0]).getElementsByTagName("input")[0].checked = true;
                                        }}
                                        className="custom-radio flex p-3 mt-3" style={{
                                            border: "1px solid #E4E4E7",
                                            borderRadius: "10px"
                                        }} id={option[0]} key={index}>
                                        <div className="flex items-center justify-center">
                                            <input type="radio" value={option[0]} name="options" className="mr-3" style={{ border: "1px solid #E4E4E7" }}></input>
                                            <i className="fa fa-check-circle hidden mr-3"></i>
                                        </div>
                                        <div>
                                            <label className="font-normal text-base text-[#4B5563]">{option[0]}</label>
                                            <div className="text-sm font-normal text-[#6B7280]">{option[1]}</div>
                                        </div>
                                    </div> :
                                    <div
                                        onClick={() => {
                                            updateBorder(option[0]);
                                            document.getElementById(option[0]).getElementsByTagName("input")[0].checked = true;
                                        }}
                                        className="custom-radio p-3 mt-3" style={{
                                            border: "1px solid #E4E4E7",
                                            borderRadius: "10px"
                                        }} id={option[0]} key={index}>
                                        <input type="radio" value={option[0]} name="options" className="mr-3" style={{ border: "1px solid #E4E4E7" }}></input>
                                        <i className="fa fa-check-circle hidden mr-3"></i>
                                        <label className="font-normal text-base text-[#4B5563]">{option[0]}</label>
                                    </div>
                            )
                        })}
                        <div className="flex justify-end mt-20 gap-3">
                            <button onClick={() => {
                                if (currentQuestion === 0) {
                                    props.onChange(false);
                                }
                                else {
                                    if (option.length !== 0) {
                                        document.getElementById(option).getElementsByTagName("input")[0].checked = false;
                                    }
                                    updateBorder("");
                                    setCurrentQuestion(currentQuestion - 1)
                                }
                            }} style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #D1D5DB", display: "flex", alignItems: "center", justifyContent: "center", width: "100px" }}>
                                {
                                    currentQuestion === 0 ?
                                        <div className="text-sm font-semibold text-[#111928]">Cancel</div>
                                        :
                                        <>
                                            <svg className="mr-3" width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.20703 13.7069C8.0195 13.8944 7.76519 13.9997 7.50003 13.9997C7.23487 13.9997 6.98056 13.8944 6.79303 13.7069L0.793031 7.70692C0.605559 7.51939 0.500244 7.26508 0.500244 6.99992C0.500244 6.73475 0.605559 6.48045 0.793031 6.29292L6.79303 0.292919C6.98163 0.110761 7.23423 0.00996641 7.49643 0.0122448C7.75863 0.0145233 8.00944 0.119692 8.19485 0.3051C8.38026 0.490508 8.48543 0.741321 8.4877 1.00352C8.48998 1.26571 8.38919 1.51832 8.20703 1.70692L3.91403 5.99992H15.5C15.7652 5.99992 16.0196 6.10528 16.2071 6.29281C16.3947 6.48035 16.5 6.7347 16.5 6.99992C16.5 7.26514 16.3947 7.51949 16.2071 7.70703C16.0196 7.89456 15.7652 7.99992 15.5 7.99992H3.91403L8.20703 12.2929C8.3945 12.4804 8.49982 12.7348 8.49982 12.9999C8.49982 13.2651 8.3945 13.5194 8.20703 13.7069Z" fill="#111928" />
                                            </svg>
                                            <div className="text-sm font-semibold text-[#111928]">Back</div>
                                        </>
                                }
                            </button>
                            <button onClick={() => {
                                if (option.length === 0 || !questions[currentQuestion].acceptableAnswers.includes(option)) {
                                    return
                                }
                                // let questionNumber = currentQuestion + 1
                                // setContractJSON(
                                //     {
                                //         ...contractJSON,
                                //         ["question " + questionNumber]: option
                                //     }
                                // )
                                document.getElementById(option).getElementsByTagName("input")[0].checked = false;
                                updateBorder("");
                                if (currentQuestion === questions.length - 1) {
                                    setContractQuestionsModalVisibility(false)
                                    setContractFormsModalVisibility(true)
                                }
                                else {
                                    setCurrentQuestion(currentQuestion + 1)
                                }
                            }} style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #1C64F2", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1C64F2", width: "100px" }}
                                className={`${!questions[currentQuestion].acceptableAnswers || questions[currentQuestion].acceptableAnswers.includes(option) ? "" : "opacity-50 cursor-not-allowed"}`}>
                                <svg className="mr-3" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.7931 7.50003L12.9396 8.35358L8.65013 12.643C8.56064 12.737 8.51118 12.8622 8.51231 12.9921C8.51345 13.1232 8.56603 13.2486 8.65874 13.3413C8.75144 13.434 8.87685 13.4866 9.00794 13.4877C9.13783 13.4889 9.26301 13.4394 9.35703 13.3499L15.3534 7.35353C15.3534 7.35351 15.3534 7.35349 15.3534 7.35348C15.4471 7.25972 15.4998 7.13259 15.4998 7.00003C15.4998 6.86747 15.4471 6.74034 15.3534 6.64658L13.7931 7.50003ZM13.7931 7.50003H12.586H1C0.867392 7.50003 0.740215 7.44735 0.646446 7.35358C0.552678 7.25982 0.5 7.13264 0.5 7.00003C0.5 6.86742 0.552678 6.74025 0.646446 6.64648C0.740215 6.55271 0.867392 6.50003 1 6.50003H12.586H13.7931L12.9396 5.64648L8.64661 1.35353C8.64659 1.35351 8.64657 1.35349 8.64655 1.35348C8.55285 1.25972 8.50021 1.13259 8.50021 1.00003C8.50021 0.867498 8.55283 0.740391 8.6465 0.646637C8.74026 0.552902 8.86742 0.500244 9 0.500244C9.13256 0.500244 9.25969 0.552882 9.35345 0.646584C9.35346 0.646602 9.35348 0.646619 9.3535 0.646637L15.3534 6.64653L13.7931 7.50003Z" fill="white" stroke="white" />
                                </svg>
                                <div className="text-sm font-semibold text-[white]">Next</div>
                            </button>
                        </div>
                    </div >
                </div>
                : null
            }

            {contractFormsModalVisibility ?
                <div
                    tabIndex={-1}
                    className="fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full overflow-auto"
                >
                    <div className='p-8 pr-4 pt-0 sm:mx-[23vw] sm:my-[7vh] bg-[#FFFFFF] rounded-[10px]' style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                        <div className="flex mb-5">
                            <div style={{ marginRight: "auto" }} className="text-lg text-[#6B7280] font-normal mt-5">Step 2 of 3</div>
                            <div className="mt-4 cursor-pointer" style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                                <svg onClick={() => { props.onChange(false) }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                </svg>
                            </div>
                        </div>
                        <div className="mb-6">
                            <div className="font-semibold text-3xl text-[#111928] mb-2">Contract Form Selection</div>
                            <div className="font-normal text-[#6B7280]">Please select a contract form below.</div>
                        </div>
                        {contractForms.map((option, index) => {
                            return (
                                <>
                                    {
                                        option.name === "Other Standard Draft Forms" ?
                                            <div className="custom-radio flex p-3 mt-3" style={{
                                                border: "1px solid #E4E4E7",
                                                borderRadius: "10px"
                                            }} key={index}>
                                                <div className="ml-6">
                                                    <label className="font-normal text-base text-[#4B5563] flex">
                                                        {option.name}
                                                        <div className="ml-3 px-[10px] bg-[#C3DDFD] rounded-[10px] flex justify-center items-center">
                                                            <span className="text-xs font-medium text-[#1C64F2]">Coming Soon</span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            :
                                            <div
                                                onClick={() => {
                                                    updateContractFormsBorder(option);
                                                    document.getElementById(option.id).getElementsByTagName("input")[0].checked = true;
                                                }}
                                                className="custom-radio flex p-3 mt-3" style={{
                                                    border: "1px solid #E4E4E7",
                                                    borderRadius: "10px"
                                                }} id={option.id} key={index}>
                                                <div className="flex items-center justify-center">
                                                    <input type="radio" value={option.name} name="options" className="mr-3" style={{ border: "1px solid #E4E4E7" }}></input>
                                                    <i className="fa fa-check-circle hidden mr-3"></i>
                                                </div>
                                                <div>
                                                    <label className="font-normal text-base text-[#4B5563]">{option.name}</label>
                                                    <div className="text-xs font-normal text-[#6B7280]">{option.description}</div>
                                                </div>
                                            </div>
                                    }
                                </>
                            )
                        })}
                        <div className="flex justify-end mt-10 gap-3">
                            <button onClick={() => {
                                setContractFormsModalVisibility(false)
                                setContractQuestionsModalVisibility(true)
                            }} style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #D1D5DB", display: "flex", alignItems: "center", justifyContent: "center", width: "100px" }}>
                                <svg className="mr-3" width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.20703 13.7069C8.0195 13.8944 7.76519 13.9997 7.50003 13.9997C7.23487 13.9997 6.98056 13.8944 6.79303 13.7069L0.793031 7.70692C0.605559 7.51939 0.500244 7.26508 0.500244 6.99992C0.500244 6.73475 0.605559 6.48045 0.793031 6.29292L6.79303 0.292919C6.98163 0.110761 7.23423 0.00996641 7.49643 0.0122448C7.75863 0.0145233 8.00944 0.119692 8.19485 0.3051C8.38026 0.490508 8.48543 0.741321 8.4877 1.00352C8.48998 1.26571 8.38919 1.51832 8.20703 1.70692L3.91403 5.99992H15.5C15.7652 5.99992 16.0196 6.10528 16.2071 6.29281C16.3947 6.48035 16.5 6.7347 16.5 6.99992C16.5 7.26514 16.3947 7.51949 16.2071 7.70703C16.0196 7.89456 15.7652 7.99992 15.5 7.99992H3.91403L8.20703 12.2929C8.3945 12.4804 8.49982 12.7348 8.49982 12.9999C8.49982 13.2651 8.3945 13.5194 8.20703 13.7069Z" fill="#111928" />
                                </svg>
                                <div className="text-sm font-semibold text-[#111928]">Back</div>
                            </button>
                            <button className={`${contractFormsOption ? "" : "opacity-50 cursor-not-allowed"}`}
                                onClick={
                                    () => {
                                        setContractJSON(
                                            {
                                                ...contractJSON,
                                                "contractFormId": contractFormsOption.id
                                            }
                                        )
                                        document.getElementById(contractFormsOption.id).getElementsByTagName("input")[0].checked = false;
                                        updateContractFormsBorder("");
                                        setContractFormsModalVisibility(false)
                                        setContractNameModalVisibility(true)
                                    }
                                } style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #1C64F2", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1C64F2", width: "100px" }}>
                                <svg className="mr-3" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.7931 7.50003L12.9396 8.35358L8.65013 12.643C8.56064 12.737 8.51118 12.8622 8.51231 12.9921C8.51345 13.1232 8.56603 13.2486 8.65874 13.3413C8.75144 13.434 8.87685 13.4866 9.00794 13.4877C9.13783 13.4889 9.26301 13.4394 9.35703 13.3499L15.3534 7.35353C15.3534 7.35351 15.3534 7.35349 15.3534 7.35348C15.4471 7.25972 15.4998 7.13259 15.4998 7.00003C15.4998 6.86747 15.4471 6.74034 15.3534 6.64658L13.7931 7.50003ZM13.7931 7.50003H12.586H1C0.867392 7.50003 0.740215 7.44735 0.646446 7.35358C0.552678 7.25982 0.5 7.13264 0.5 7.00003C0.5 6.86742 0.552678 6.74025 0.646446 6.64648C0.740215 6.55271 0.867392 6.50003 1 6.50003H12.586H13.7931L12.9396 5.64648L8.64661 1.35353C8.64659 1.35351 8.64657 1.35349 8.64655 1.35348C8.55285 1.25972 8.50021 1.13259 8.50021 1.00003C8.50021 0.867498 8.55283 0.740391 8.6465 0.646637C8.74026 0.552902 8.86742 0.500244 9 0.500244C9.13256 0.500244 9.25969 0.552882 9.35345 0.646584C9.35346 0.646602 9.35348 0.646619 9.3535 0.646637L15.3534 6.64653L13.7931 7.50003Z" fill="white" stroke="white" />
                                </svg>
                                <div className="text-sm font-semibold text-[white]">Next</div>
                            </button>
                        </div>
                    </div >
                </div>
                : null}

            {
                contractNameModalVisibility ?
                    <>
                        <div
                            tabIndex={-1}
                            className="fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full overflow-auto">
                            <div className='p-8 pr-4 pt-0 sm:mx-[23vw] sm:my-[7vh] bg-[#FFFFFF] rounded-[10px]' style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                                <div className="flex mb-5">
                                    <div style={{ marginRight: "auto" }} className="text-lg text-[#6B7280] font-normal mt-5">Step 3 of 3</div>
                                    <div className="mt-4 cursor-pointer" style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                                        <svg onClick={() => { props.onChange(false) }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="font-semibold text-3xl text-[#111928] mb-1">Enter Document Name</div>
                                    <div className="font-normal text-[#6B7280]">This name will be used when displaying the contract to anyone involved in the workflow (including your contract counterparty). The name may be changed later if necessary.</div>
                                </div>
                                <input id="contractName" className='block w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border border-[#E5E7EB] rounded-[16px]' placeholder='Acme - eWidgets NDA' />
                                <div className="flex justify-end gap-3 mt-10">
                                    <button onClick={() => {
                                        setContractNameModalVisibility(false);
                                        setContractFormsModalVisibility(true);
                                    }} style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #D1D5DB", display: "flex", alignItems: "center", justifyContent: "center", width: "100px" }}>
                                        <svg className="mr-3" width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.20703 13.7069C8.0195 13.8944 7.76519 13.9997 7.50003 13.9997C7.23487 13.9997 6.98056 13.8944 6.79303 13.7069L0.793031 7.70692C0.605559 7.51939 0.500244 7.26508 0.500244 6.99992C0.500244 6.73475 0.605559 6.48045 0.793031 6.29292L6.79303 0.292919C6.98163 0.110761 7.23423 0.00996641 7.49643 0.0122448C7.75863 0.0145233 8.00944 0.119692 8.19485 0.3051C8.38026 0.490508 8.48543 0.741321 8.4877 1.00352C8.48998 1.26571 8.38919 1.51832 8.20703 1.70692L3.91403 5.99992H15.5C15.7652 5.99992 16.0196 6.10528 16.2071 6.29281C16.3947 6.48035 16.5 6.7347 16.5 6.99992C16.5 7.26514 16.3947 7.51949 16.2071 7.70703C16.0196 7.89456 15.7652 7.99992 15.5 7.99992H3.91403L8.20703 12.2929C8.3945 12.4804 8.49982 12.7348 8.49982 12.9999C8.49982 13.2651 8.3945 13.5194 8.20703 13.7069Z" fill="#111928" />
                                        </svg>
                                        <div className="text-sm font-semibold text-[#111928]">Back</div>
                                    </button>
                                    <button disabled={submitClicked} onClick={async () => {
                                        if (document.getElementById("contractName").value === "") {
                                            alertError("Please enter a contract name");
                                            return;
                                        }
                                        await handleSubmit()
                                        setContractNameModalVisibility(false);
                                    }} style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #1C64F2", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1C64F2", width: "100px" }}>
                                        <svg className="mr-2" width="18" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.7931 7.50003L12.9396 8.35358L8.65013 12.643C8.56064 12.737 8.51118 12.8622 8.51231 12.9921C8.51345 13.1232 8.56603 13.2486 8.65874 13.3413C8.75144 13.434 8.87685 13.4866 9.00794 13.4877C9.13783 13.4889 9.26301 13.4394 9.35703 13.3499L15.3534 7.35353C15.3534 7.35351 15.3534 7.35349 15.3534 7.35348C15.4471 7.25972 15.4998 7.13259 15.4998 7.00003C15.4998 6.86747 15.4471 6.74034 15.3534 6.64658L13.7931 7.50003ZM13.7931 7.50003H12.586H1C0.867392 7.50003 0.740215 7.44735 0.646446 7.35358C0.552678 7.25982 0.5 7.13264 0.5 7.00003C0.5 6.86742 0.552678 6.74025 0.646446 6.64648C0.740215 6.55271 0.867392 6.50003 1 6.50003H12.586H13.7931L12.9396 5.64648L8.64661 1.35353C8.64659 1.35351 8.64657 1.35349 8.64655 1.35348C8.55285 1.25972 8.50021 1.13259 8.50021 1.00003C8.50021 0.867498 8.55283 0.740391 8.6465 0.646637C8.74026 0.552902 8.86742 0.500244 9 0.500244C9.13256 0.500244 9.25969 0.552882 9.35345 0.646584C9.35346 0.646602 9.35348 0.646619 9.3535 0.646637L15.3534 6.64653L13.7931 7.50003Z" fill="white" stroke="white" />
                                        </svg>
                                        <div className="text-sm font-semibold text-[white]">Create</div>
                                    </button>
                                </div>
                            </div >
                        </div>
                    </>
                    : null
            }
            {
                userTemplateVisibilityModal &&
                <div
                    tabIndex={-1}
                    className="fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full overflow-auto"
                >
                    <div className='p-8 pr-4 sm:mx-[23vw] sm:my-[7vh] bg-[#FFFFFF] rounded-[10px]' style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                        <div className="mb-6">
                            <div className="font-semibold text-3xl text-[#111928] mb-2">Re-use a prior template?</div>
                            <div className="font-normal text-[#6B7280]">By re-using a prior template, the configurations from that template will be automatically incorporated into your new contract draft.</div>
                        </div>
                        <div className="flex justify-end mt-10 gap-3">
                            <button onClick={() => {
                                setUserTemplateVisibilityModal(false)
                                navigate(`/${contract.contractUUID}`)
                            }} style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #D1D5DB", display: "flex", alignItems: "center", justifyContent: "center", width: "100px" }}>
                                <div className="text-sm font-semibold text-[#111928]">No</div>
                            </button>
                            <button onClick={() => {
                                setUserTemplateVisibilityModal(false)
                                setUserTemplateModal(true)
                            }} style={{ borderRadius: "12px", padding: "9px 17px 9px 17px", border: "1px solid #1C64F2", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1C64F2", width: "100px" }}>
                                <div className="text-sm font-semibold text-[white]">Yes</div>
                            </button>
                        </div>
                    </div >
                </div>
            }
            {
                userTemplateModal && <UserTemplates onChange={setUserTemplateModal} contractId={contract.id} step1SelectedOption={{ name: "Re-use a template" }} redirect={`/${contract.contractUUID}`} />
            }
        </div >
    )
}

export default CreateContract
