import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import unauthaxios from '../auth/UnAuthAxios';
import { useNavigate } from 'react-router-dom';
import PreSigninNavbar from '../components/PreSigninNavbar';
import { alertSuccess, alertError } from '../components/Alerts';

const SignIn = () => {

    const navigate = useNavigate();

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [failedToValidate, setFailedToValidate] = React.useState(false);
    const [forgotPasswordModal, setForgotPasswordModal] = React.useState(false);

    const handleForgotPassword = (e) => {
        e.preventDefault();
        if (document.getElementById('forgot-password-email').value.trim().length !== 0) {
            unauthaxios.post('/user/forgot-password', {
                email: document.getElementById('forgot-password-email').value,
            }).then(res => {
                if (res.status === 200) {
                    alertSuccess(res.data.message);
                    setForgotPasswordModal(false);
                }
            }).catch(function (error) {
                console.log(error);
                alertError(error.response.data.message);
            });
        }
        else {
            alertError('Please enter a valid email');
        }
    }

    useEffect(() => {
        localStorage.clear();
    }, [])

    function handleSubmit(e) {
        e.preventDefault();
        if (email.length !== 0 && password.length !== 0) {
            unauthaxios.post('/user/login', {
                email: email,
                password: password,
            }).then(res => {
                if (res.status === 200) {
                    localStorage.setItem('name', res.data.name);
                    localStorage.setItem('email', email);
                    localStorage.setItem('accessToken', res.data.accessToken);
                    localStorage.setItem('refreshToken', res.data.refreshToken);
                    setFailedToValidate(false);
                    navigate('/dashboard');
                }
            }).catch(function (error) {
                console.log(error);
                setFailedToValidate(true);
                alertError(error.response.data.message);
            });
        }
        else {
            setFailedToValidate(true);
            alertError('Please enter a valid email and password');
        }
    }

    return (
        <>
            <PreSigninNavbar />
            <div className='h-[100vh] flex justify-center items-center'>
                <div className='flex p-8 bg-[#FFFFFF] rounded-[16px]' style={{ boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)" }}>
                    <div className='flex items-center justify-center pr-12'>
                        <img width={"300px"} height={"300px"} src="/Images/Navbar/SD-Logo.png"></img>
                    </div>
                    <form>
                        <div className="text-3xl text-[#111928] font-bold mb-5">Sign In</div>
                        <div className="mb-4">
                            <h4 className="text-base text-[#111928] font-medium">Your email</h4>
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                value={email}
                                className="relative block w-full rounded-t-md sm:text-sm sm:w-[29vw]"
                                placeholder="name@example.com"
                                style={{
                                    padding: "13px 20px",
                                    gap: "10px",
                                    height: "40px",
                                    background: "#F9FAFB",
                                    border: "1px solid #E5E7EB",
                                    borderRadius: "16px"
                                }}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div>
                            <h4 className="text-base text-[#111928] font-medium">Password</h4>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                value={password}
                                className="relative block w-full sm:text-sm sm:w-[29vw]"
                                placeholder="**********"
                                style={{
                                    padding: "13px 20px",
                                    gap: "10px",
                                    height: "40px",
                                    background: "#F9FAFB",
                                    border: "1px solid #E5E7EB",
                                    borderRadius: "16px"
                                }}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div onClick={() => { setForgotPasswordModal(true) }} className='text-xs font-semibold text-[#6B7280] mt-2 cursor-pointer'>Forgot Password?</div>
                        <div style={{ marginTop: "1rem" }}>
                            <button
                                type="submit"
                                style={{
                                    border: "1px solid #1C64F2",
                                    borderRadius: "12px"
                                }}
                                className="flex w-full justify-center bg-[#1C64F2] py-2 px-4 text-base font-semibold text-[#FFFFFF] hover:bg-blue-700"
                                onClick={handleSubmit}
                            >Sign In</button>
                        </div>
                        <hr style={{ width: "50%", marginLeft: "25%", borderColor: "#3F83F8", marginTop: "1rem" }} />
                        <div className="flex items-center justify-center" style={{ marginTop: "0.7rem" }}>
                            <Link to="/" className="text-base font-semibold text-[#1C64F2] ml-3 hover:text-blue-700">Sign Up</Link>
                        </div>
                    </form>
                </div >
            </div>
            {forgotPasswordModal && (
                <div className='fixed z-10 inset-0 overflow-y-auto'>
                    <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                        <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
                            <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
                        </div>
                        <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
                            &#8203;
                        </span>
                        <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
                            <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                                <div className='sm:flex sm:items-start'>
                                    <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                                        <h3 className='text-lg leading-6 font-medium text-gray-900' id='modal-headline'>
                                            Forgot Password
                                        </h3>
                                        <div className='mt-2'>
                                            <p className='text-sm text-gray-500'>
                                                Enter your email address and we will send you a link to reset your password.
                                            </p>
                                        </div>
                                        <div className='mt-2'>
                                            <input
                                                id='forgot-password-email'
                                                name='email'
                                                type='email'
                                                autoComplete='email'
                                                defaultValue={email}
                                                required
                                                className='relative block w-full rounded-md sm:text-sm sm:w-[29vw]'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
                                <button
                                    type='button'
                                    className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#1C64F2] text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm'
                                    onClick={handleForgotPassword}
                                >
                                    Send
                                </button>
                                <button
                                    type='button'
                                    className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                                    onClick={() => { setForgotPasswordModal(false) }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default SignIn;