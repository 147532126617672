import { useSelector } from "react-redux";
import { diffWords } from "diff";
import Mustache from "mustache"
import React from "react";

import "../contract.css"

const PreviewPane = (props) => {

    let contract = useSelector((state) => state.contract.ds.contractTemplate) || ""
    let ds = useSelector((state) => state.contract.ds)
    let ans = useSelector((state) => state.contract.ans);

    const [renderJSON, setRenderJSON] = React.useState({})

    function diffColoring(prevString, newString) {
        if (!prevString) prevString = ""
        if (!newString) newString = ""

        let symbols = [')', ';', '.', ':', ',', '’']
        let diffString = ''
        let added = ''
        let removed = ''

        let diff = diffWords(prevString.replace(/<[^>]+>/g, ''), newString.replace(/<[^>]+>/g, ''))

        diff.forEach((part, index) => {
            let value = part.value.trim()
            if (part.added && value.length) {
                if (added[added.length - 1] === '(' || symbols.includes(value[0])) {
                    added += value
                }
                else {
                    added += ' ' + value
                }
            }
            else if (part.removed && value.length) {
                if (removed[removed.length - 1] === '(' || symbols.includes(value[0])) {
                    removed += value
                }
                else {
                    removed += ' ' + value
                }
            }
            else {
                if (value.length) {
                    if (index > 1 && value.split(' ').length === 1) {
                        if (added[added.length - 1] === '(' || symbols.includes(value[0])) {
                            added += value
                        }
                        else {
                            added += ' ' + value
                        }
                        if (removed[removed.length - 1] === '(' || symbols.includes(value[0])) {
                            removed += value
                        }
                        else {
                            removed += ' ' + value
                        }
                    }
                    else {
                        added = added.trim()
                        removed = removed.trim()
                        diffString = diffString.trim()
                        let addedArray = added.split(/([ )\.;:,(])/).filter((word) => word.trim() !== '')
                        let removedArray = removed.split(/([ )\.;:,(])/).filter((word) => word.trim() !== '')

                        let count = 0
                        let duplicate = false
                        if (removedArray.includes(addedArray[addedArray.length - 1])) {
                            let addedIndex = addedArray.length - 1
                            let removedIndex = removedArray.indexOf(addedArray[addedArray.length - 1])
                            while (removedIndex > 0 && addedIndex > 0 && removedArray[removedIndex] === addedArray[addedIndex]) {
                                count++
                                addedIndex--
                                removedIndex--
                            }
                            if (removedIndex === 0) {
                                duplicate = true
                            }
                        }

                        if (duplicate) {
                            count++
                            if (diffString[diffString.length - 1] === '(' || symbols.includes(addedArray.slice(0, addedArray.length - count).join(' ')[0]))
                                diffString += '<span style="color: blue"><u>' + addedArray.slice(0, addedArray.length - count).join(' ') + '</u></span>'
                            else
                                diffString += ' <span style="color: blue"><u>' + addedArray.slice(0, addedArray.length - count).join(' ') + '</u></span>'
                            if (diffString[diffString.length - 1] === '(' || symbols.includes(addedArray.slice(addedArray.length - count).join(' ')[0]))
                                diffString += addedArray.slice(addedArray.length - count).join(' ')
                            else
                                diffString += ' ' + addedArray.slice(addedArray.length - count).join(' ')
                            if (diffString[diffString.length - 1] === '(' || symbols.includes(removedArray.slice(count, removedArray.length).join(' ')[0]))
                                diffString += '<span style="color: #FE1E1F"><s>' + removedArray.slice(count, removedArray.length).join(' ') + "</s></span>"
                            else
                                diffString += ' <span style="color: #FE1E1F"><s>' + removedArray.slice(count, removedArray.length).join(' ') + "</s></span>"
                        }
                        else {
                            if (added.length)
                                if (diffString[diffString.length - 1] === '(' || symbols.includes(added[0]))
                                    diffString += '<span style="color: blue"><u>' + added + '</u></span>'
                                else
                                    diffString += ' <span style="color: blue"><u>' + added + '</u></span>'
                            if (removed.length)
                                if (diffString[diffString.length - 1] === '(' || symbols.includes(removed[0]))
                                    diffString += '<span style="color: #FE1E1F"><s>' + removed + "</s></span>"
                                else
                                    diffString += ' <span style="color: #FE1E1F"><s>' + removed + "</s></span>"
                        }
                        if (diffString[diffString.length - 1] === '(' || symbols.includes(value[0]))
                            diffString += value
                        else
                            diffString += ' ' + value
                        added = ''
                        removed = ''
                    }
                }
                else {
                    diffString += part.value
                }
            }
        });
        added = added.trim()
        removed = removed.trim()
        diffString = diffString.trim()
        let addedArray = added.split(/([ )\.;:,(])/).filter((word) => word.trim() !== '')
        let removedArray = removed.split(/([ )\.;:,(])/).filter((word) => word.trim() !== '')

        let count = 0
        let duplicate = false
        if (removedArray.includes(addedArray[addedArray.length - 1])) {
            let addedIndex = addedArray.length - 1
            let removedIndex = removedArray.indexOf(addedArray[addedArray.length - 1])
            while (removedIndex > 0 && addedIndex > 0 && removedArray[removedIndex] === addedArray[addedIndex]) {
                count++
                addedIndex--
                removedIndex--
            }
            if (removedIndex === 0) {
                duplicate = true
            }
        }

        if (duplicate) {
            count++
            if (diffString[diffString.length - 1] === '(' || symbols.includes(addedArray.slice(0, addedArray.length - count).join(' ')[0]))
                diffString += '<span style="color: blue"><u>' + addedArray.slice(0, addedArray.length - count).join(' ') + '</u></span>'
            else
                diffString += ' <span style="color: blue"><u>' + addedArray.slice(0, addedArray.length - count).join(' ') + '</u></span>'
            if (diffString[diffString.length - 1] === '(' || symbols.includes(addedArray.slice(addedArray.length - count).join(' ')[0]))
                diffString += addedArray.slice(addedArray.length - count).join(' ')
            else
                diffString += ' ' + addedArray.slice(addedArray.length - count).join(' ')
            if (diffString[diffString.length - 1] === '(' || symbols.includes(removedArray.slice(count, removedArray.length).join(' ')[0]))
                diffString += '<span style="color: #FE1E1F"><s>' + removedArray.slice(count, removedArray.length).join(' ') + "</s></span>"
            else
                diffString += ' <span style="color: #FE1E1F"><s>' + removedArray.slice(count, removedArray.length).join(' ') + "</s></span>"
        }
        else {
            if (added.length) {
                if (diffString[diffString.length - 1] === '(' || symbols.includes(added[0])) {
                    diffString += '<span style="color: blue"><u>' + added + '</u></span>'
                }
                else {
                    diffString += ' <span style="color: blue"><u>' + added + '</u></span>'
                }
            }
            if (removed.length) {
                if (diffString[diffString.length - 1] === '(' || symbols.includes(removed[0])) {
                    diffString += '<span style="color: #FE1E1F"><s>' + removed + "</s></span>"
                }
                else {
                    diffString += ' <span style="color: #FE1E1F"><s>' + removed + "</s></span>"
                }
            }
        }

        symbols = ["( ", " )", " ;", " :", " ,", " .", ' ’', '“ ', ' ”']
        for (let i = 0; i < symbols.length; i++) {
            while (diffString.includes(symbols[i])) {
                diffString = diffString.replace(symbols[i], symbols[i].trim())
            }
        }
        while (diffString.includes("1 (")) {
            diffString = diffString.replace("1 (", "1(")
        }
        diffString = diffString.trim()

        let finalString = newString
        let diffStringIndex = 0
        let finalStringIndex = 0
        while (finalString.includes("<p><br> </p>")) {
            finalString = finalString.replace("<p><br> </p>", "<br>")
        }

        while (finalStringIndex < finalString.length && diffStringIndex < diffString.length) {
            if (diffString[diffStringIndex] === " " || diffString[diffStringIndex] === "\n" || diffString[diffStringIndex] === "\t") {
                diffStringIndex++
            }
            else if (finalString[finalStringIndex] === " " || finalString[finalStringIndex] === "\n" || finalString[finalStringIndex] === "\t") {
                finalStringIndex++
            }
            else if (finalString[finalStringIndex] === "<" && (finalString[finalStringIndex + 1] === "/" || diffString[diffStringIndex] !== "<")) {
                let closeTagIndex = finalString.indexOf(">", finalStringIndex)
                let tag = finalString.substring(finalStringIndex, closeTagIndex + 1)
                if (tag === "<u>" || tag.includes("<u ") || tag === "</u>" ||
                    tag === "<b>" || tag.includes("<b ") || tag === "</b>" ||
                    tag === "<s>" || tag.includes("<s ") || tag === "</s>" ||
                    tag === "<em>" || tag.includes("<em ") || tag === "</em>" ||
                    tag === "<br>" || tag.includes("<br ") || tag === "<br/>" ||
                    tag === "<strong>" || tag.includes("<strong ") || tag === "</strong>" ||
                    tag === '<span style="color: #FE1E1F">' || tag === '<span style="color: blue"><u>' || tag === "</span>") {
                    diffString = diffString.substring(0, diffStringIndex) + tag + diffString.substring(diffStringIndex)
                    diffStringIndex += tag.length
                }
                else if (tag === "</p>" && finalString.substring(finalStringIndex + 4).replace(/<[^>]+>/g, '').length) {
                    diffString = diffString.substring(0, diffStringIndex) + "<br>" + diffString.substring(diffStringIndex)
                    diffStringIndex += 4
                }
                finalStringIndex += tag.length
            }
            else if (diffString[diffStringIndex] === "<") {
                let closeTagIndex = diffString.indexOf(">", diffStringIndex)
                let tag = diffString.substring(diffStringIndex, closeTagIndex + 1)
                if (tag === '<span style="color: #FE1E1F">') {
                    let endTag = diffString.indexOf("</span>", diffStringIndex)
                    diffStringIndex = endTag + 7
                }
                else {
                    diffStringIndex += tag.length
                }
            }
            else if (diffString[diffStringIndex] === finalString[finalStringIndex]) {
                finalStringIndex++
                diffStringIndex++
            }
            else {
                diffStringIndex++
                // console.log(diffString.substring(diffStringIndex))
                // console.log(finalString.substring(finalStringIndex))
                // break
            }
        }

        return diffString
    }

    React.useEffect(() => {
        setRenderJSON(
            () => {
                let json = { ...ans }
                if (props.previewButton === "clean" || (props.previewButton === "changes" && props.phase === "Signed")) {
                    for (const key in json) {
                        let element = json[key].value?.includes("{{") ? props.open(json[key].value, ans) : json[key].value
                        if (element) {
                            let symbols = ["( ", " )", " ;", " :", " ,", " .", ' ’']
                            for (let i = 0; i < symbols.length; i++) {
                                while (element.includes(symbols[i])) {
                                    element = element.replace(symbols[i], symbols[i].trim())
                                }
                            }
                            while (element.includes("1 (")) {
                                element = element.replace("1 (", "1(")
                            }
                        }
                        json = {
                            ...json,
                            [key]: '<span style="color: black">' + element + '</span>'
                        }
                    }
                }
                else if (props.previewButton === "markup" && Object.keys(props.previousVersion).length && Object.keys(props.secondPreviousVersion).length) {
                    json = {}
                    for (const key in props.previousVersion.version.structured_options_answers) {
                        const element = props.previousVersion.version.structured_options_answers[key].value;
                        if (props.secondPreviousVersion.version.structured_options_answers[key] !== undefined) {
                            json = {
                                ...json,
                                [key]: diffColoring(props.open(props.secondPreviousVersion.version.structured_options_answers[key].value, props.secondPreviousVersion.version.structured_options_answers), props.open(element, props.previousVersion.version.structured_options_answers))
                            }
                        }
                        else {
                            if (key.includes("CustomText")) {
                                json = {
                                    ...json,
                                    [key]: diffColoring(props.previousVersion.version.structured_options_answers[key.replace("Custom", "Original")].value, element)
                                }
                            }
                            else {
                                json = {
                                    ...json,
                                    [key]: '<span style="color: blue"><u>' + props.open(element, ans) + '</u></span>'
                                }
                            }
                        }
                    }

                    for (const key in props.secondPreviousVersion.version.structured_options_answers) {
                        const element = props.secondPreviousVersion.version.structured_options_answers[key].value;
                        if (json[key] === undefined) {
                            if (key.includes("CustomText") || key.includes("Check")) {
                                json = json
                            }
                            else {
                                json = {
                                    ...json,
                                    [key]: '<span style="color: #FE1E1F"><s>' + props.open(element, ans) + '</s></span>'
                                }
                            }
                        }
                    }
                }
                else if (props.previewButton === "markup") {
                    json = {}
                    for (const key in props.previousVersion.version.structured_options_answers) {
                        if (props.previousVersion.version.structured_options_answers[key].value)
                            json[key] = props.open(props.previousVersion.version.structured_options_answers[key].value, props.previousVersion.version.structured_options_answers)
                    }
                }
                else if (props.previewButton === "cumulative") {
                    let json = {}
                    for (const key in ans) {
                        if (ds.contractDefaultOptions[key]) {
                            json = {
                                ...json,
                                [key]: diffColoring(props.open(ds.contractDefaultOptions[key].value, ds.contractDefaultOptions), props.open(ans[key].value, ans))
                            }
                        }
                        else {
                            if (key.includes("CustomText")) {
                                json = {
                                    ...json,
                                    [key]: diffColoring(ans[key.replace("Custom", "Original")].value.trim(), ans[key].value)
                                }
                            }
                            else {
                                json = {
                                    ...json,
                                    [key]: '<span style="color: blue"><u>' + props.open(ans[key].value, ans) + '</u></span>'
                                }
                            }
                        }
                    }
                    for (const key in ds.contractDefaultOptions) {
                        if (!ans[key]) {
                            json = {
                                ...json,
                                [key]: '<span style="color: #FE1E1F"><s>' + props.open(ds.contractDefaultOptions[key].value, ds.contractDefaultOptions) + '</s></span>'
                            }
                        }
                    }
                    return json
                }
                else {
                    for (const key in json) {
                        let element = json[key].value;
                        if (Object.keys(props.previousVersion).length && props.previousVersion.version.structured_options_answers[key] !== undefined) {
                            if (element?.includes("{{")) {
                                json = {
                                    ...json,
                                    [key]: diffColoring(props.open(props.previousVersion.version.structured_options_answers[key].value, props.previousVersion.version.structured_options_answers), props.open(element, ans))
                                }
                            }
                            else if (element !== props.previousVersion.version.structured_options_answers[key].value) {
                                if (key.includes("CustomText")) {
                                    json = {
                                        ...json,
                                        [key]: diffColoring(props.previousVersion.version.structured_options_answers[key].value, element)
                                    }
                                }
                                else {
                                    json = {
                                        ...json,
                                        [key]: diffColoring(props.previousVersion.version.structured_options_answers[key].value, element)
                                    }
                                }
                            }
                            else {
                                json = {
                                    ...json,
                                    [key]: '<span style="color: black">' + element + '</span>'
                                }
                            }
                        }
                        else {
                            if (key.includes("CustomText")) {
                                json = {
                                    ...json,
                                    [key]: diffColoring(ans[key.replace("Custom", "Original")].value.trim(), element)
                                }
                            }
                            else {
                                json = {
                                    ...json,
                                    [key]: element?.includes("{{") ?
                                        '<span style="color: blue"><u>' + props.open(element, ans) + '</u></span>' :
                                        '<span style="color: blue"><u>' + element + '</u></span>'
                                }
                            }
                        }
                    }
                    if (Object.keys(props.previousVersion).length !== 0) {
                        for (const key in props.previousVersion.version.structured_options_answers) {
                            if (json.hasOwnProperty(key) === false) {
                                let element = props.previousVersion.version.structured_options_answers[key].value;
                                if (key.includes("CustomText") || key.includes("Check") || element === undefined) {
                                    json = json
                                }
                                else if (json[key] === undefined) {
                                    json = {
                                        ...json,
                                        [key]: element?.includes("{{") ?
                                            '<span style="color: #FE1E1F"><s>' + props.open(element, props.previousVersion.version.structured_options_answers) + '</s></span>' :
                                            '<span style="color: #FE1E1F"><s>' + element + '</s></span>'
                                    }
                                }
                            }
                        }
                    }
                }
                return json
            })
    }, [ans, props.previousVersion, props.previewButton, props.versionSavedTime, props.activeStep])

    React.useEffect(() => {
        if (props.previewButton === "markup" && Object.keys(props.secondPreviousVersion).length !== 0) {
            for (const key in props.secondPreviousVersion.version.structured_options_answers) {
                const element = props.secondPreviousVersion.version.structured_options_answers[key].value;
                if (renderJSON[key] === undefined) {
                    if (key.includes("CustomText")) {
                        let tempTag = document.createElement('div')
                        tempTag.innerHTML = Mustache.render(contract, renderJSON)
                        let tempTagTags = tempTag.getElementsByTagName('*')
                        for (let i = 0; i < tempTagTags.length; i++) {
                            if (tempTagTags[i].id === key.replace("CustomText", "")) {
                                let htmlText = tempTagTags[i].innerHTML
                                document.getElementById(key.replace("CustomText", "")).innerHTML = "<div>" + diffColoring(element, htmlText) + "</div>"
                            }
                        }
                    }
                }
            }
        }
        if (props.previewButton === "changes") {
            if (Object.keys(props.previousVersion).length !== 0) {
                for (const key in props.previousVersion.version.structured_options_answers) {
                    if (renderJSON.hasOwnProperty(key) === false) {
                        let element = props.previousVersion.version.structured_options_answers[key].value;
                        if (key.includes("CustomText") && renderJSON[key.replace("Custom", "Original")] !== undefined) {
                            let tempTag = document.createElement('div')
                            tempTag.innerHTML = Mustache.render(contract, renderJSON)
                            let tempTagTags = tempTag.getElementsByTagName('*')
                            for (let i = 0; i < tempTagTags.length; i++) {
                                if (tempTagTags[i].id === key.replace("CustomText", "")) {
                                    let htmlText = tempTagTags[i].innerHTML
                                    document.getElementById(key.replace("CustomText", "")).innerHTML = "<div>" + diffColoring(element, htmlText) + "</div>"
                                }
                            }
                        }
                    }
                }
            }
        }
    }, [renderJSON, props.hoverStep])

    return (
        <div>
            <div onClick={(e) => {
                if (props.phase === "Signing") return

                let section = e.target
                let subsection = e.target
                while (section.classList.contains('section') === false) {
                    section = section.parentElement
                }
                while (subsection !== null && subsection.classList.contains('subsection') === false) {
                    subsection = subsection.parentElement
                }

                let subsectionDropdownBox = subsection ? document.getElementById(subsection.id + "-expand") : null
                if (subsectionDropdownBox) {
                    subsectionDropdownBox?.click()
                    setTimeout(() => {
                        if (subsectionDropdownBox.innerText.includes("Edited by counterparty") || subsectionDropdownBox.innerText.includes("Edited by you") ||
                            subsectionDropdownBox.innerText.includes("Added by counterparty") || subsectionDropdownBox.innerText.includes("Added by you")) {
                            setTimeout(() => {
                                document.getElementById(subsection.id + "-childrenexpand")?.click()
                            }, 500)
                        }
                    }, 1500)
                }
                else {
                    let sectionId = section.id === "Term Subsection" ? "Term" : section.id === "Return or Destruction of Confidential Information" ? "Return or Destruction" : section.id
                    document.getElementById("SelectionPane").scrollTo({
                        top: document.getElementById(sectionId + "-heading").offsetTop - 80,
                        behavior: 'smooth'
                    })
                }
            }} id='contract' className="preview-contract p-6 text-[11pt]" key={Date()} dangerouslySetInnerHTML={{
                __html: Mustache.render(contract, renderJSON)
            }} />
        </div>
    )
}

export default PreviewPane