import React from 'react'

const Signers = ({
    yourViewers, setYourViewers, contractRecipientViewers, setContractRecipientViewers, firstSignatureBlock, setFirstSignatureBlock,
    editYourSigner, setEditYourSigner, yourNewSigner, setYourNewSigner, editContractRecipientSigner, signersMessage, setSignersMessage,
    contractRecipientSigner, setContractRecipientSigner, yourPreviousSigner, contractRecipientPreviousSigner
}) => {
    let infoModalRef = React.useRef();
    const [infoModalVisibility, setInfoModalVisibility] = React.useState(false);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (infoModalRef.current && !infoModalRef.current.contains(event.target)) {
                setInfoModalVisibility(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [])

    return (
        <div>
            <div className='flex items-center text-lg font-semibold text-[#374151] pb-1.5'>
                Your Side
                <div className='ml-auto mr-3 relative z-50'>
                    <svg onClick={() => setInfoModalVisibility(true)} className="ml-auto cursor-pointer" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.4685 13.8006C18.9019 12.7542 19.125 11.6327 19.125 10.5C19.125 8.21251 18.2163 6.01871 16.5988 4.4012C14.9813 2.7837 12.7875 1.875 10.5 1.875C8.21251 1.875 6.0187 2.7837 4.4012 4.4012C2.7837 6.01871 1.875 8.21251 1.875 10.5C1.875 11.6327 2.09809 12.7542 2.53154 13.8006C2.96499 14.8471 3.6003 15.7979 4.4012 16.5988C5.20211 17.3997 6.15292 18.035 7.19936 18.4685C8.24579 18.9019 9.36735 19.125 10.5 19.125C11.6327 19.125 12.7542 18.9019 13.8006 18.4685C14.8471 18.035 15.7979 17.3997 16.5988 16.5988C17.3997 15.7979 18.035 14.8471 18.4685 13.8006Z" fill="white" />
                        <path d="M11.4583 14.3333H10.5V10.5H9.54167M10.5 6.66667H10.5096M19.125 10.5C19.125 11.6327 18.9019 12.7542 18.4685 13.8006C18.035 14.8471 17.3997 15.7979 16.5988 16.5988C15.7979 17.3997 14.8471 18.035 13.8006 18.4685C12.7542 18.9019 11.6327 19.125 10.5 19.125C9.36735 19.125 8.24579 18.9019 7.19936 18.4685C6.15292 18.035 5.20211 17.3997 4.4012 16.5988C3.6003 15.7979 2.96499 14.8471 2.53154 13.8006C2.09809 12.7542 1.875 11.6327 1.875 10.5C1.875 8.21251 2.7837 6.01871 4.4012 4.4012C6.0187 2.7837 8.21251 1.875 10.5 1.875C12.7875 1.875 14.9813 2.7837 16.5988 4.4012C18.2163 6.01871 19.125 8.21251 19.125 10.5Z" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {
                        infoModalVisibility &&
                        <div ref={infoModalRef} className="absolute right-[-25px] w-[28rem] bg-[white] h-52 top-0 z-50 border overflow-y-auto rounded-[5px] shadow-lg">
                            <div className="flex items-center justify-between px-4 py-2">
                                <div className="text-sm font-medium text-[#374151]">Signers</div>
                                <button onClick={() => setInfoModalVisibility(false)} className="focus:outline-none">
                                    <svg className="w-4 h-4 text-[#374151]" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L15 15M15 1L1 15" stroke="#374151" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </div>
                            <div className="px-4 pb-2">
                                <div className="text-xs font-medium text-[#374151]" >
                                    <p className="mb-1.5">You will initially choose which signature block applies to your side. Please verify by reviewing the order of the signature blocks in the preview panel.</p>
                                    <p className="mb-1.5">You will next select whether you or someone else on your side will sign the contract. The person you select should be consistent with the signature block you prepared.</p>
                                    <p className="mb-1.5">Please also submit the information for the applicable counterparty signatory. The person you select should be consistent with the counterparty’s signature block.</p>
                                    <p className="mb-1.5">You may add viewers from both sides.</p>
                                    <p className="mb-1.5">Once you click the button to Request Signatures below, the signatories will be requested to sign via our DocuSign integration.</p>
                                    <p className="mb-1.5">We strongly encourage you to include a descriptive message, which will be included in the emails that are circulated. Please see here for an example:</p>
                                    <p className="mb-1.5"><i>Hi everyone, all points have been resolved and this contract is ready to be signed!</i></p>
                                    <p className="mb-1.5"><i>Susan and Rahul will receive invitations to sign shortly.</i></p>
                                    <p className="mb-1.5"><i>If you need to communicate with me directly, please email me at linda@newco.net.</i></p>
                                    <p className="mb-1.5"><i>Linda</i></p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className='ml-2'>
                <p className='text-sm font-normal text-[#6B7280] pb-3'>
                    Which signature block applies to your side?
                </p>
                <div className='flex gap-[10px] mb-3'>
                    <div className='flex items-center'>
                        <input defaultChecked={firstSignatureBlock} name="signatureBlock" type='radio' className='w-[17px] h-[17px] focus:ring-[white]' onClick={() => setFirstSignatureBlock(true)} />
                        <label className='text-sm font-normal text-[#6B7280] ml-2'>First block</label>
                    </div>
                    <div className='flex items-center'>
                        <input defaultChecked={!firstSignatureBlock} name="signatureBlock" type='radio' className='w-[17px] h-[17px] focus:ring-[white]' onClick={() => setFirstSignatureBlock(false)} />
                        <label className='text-sm font-normal text-[#6B7280] ml-2'>Second block</label>
                    </div>
                </div>
                <p className='text-sm font-normal text-[#6B7280] pb-3'>
                    Who’s signing on behalf of your side?
                </p>
                <div className='flex gap-[10px] mb-3'>
                    <div className='flex items-center'>
                        <input disabled={yourPreviousSigner} defaultChecked={!editYourSigner} name="editYourSigner" type='radio' className='w-[17px] h-[17px] focus:ring-[white]' onClick={() => setEditYourSigner(false)} />
                        <label htmlFor='shareWithCounterparty' className='text-sm font-normal text-[#6B7280] ml-2'>Me</label>
                    </div>
                    <div className='flex items-center'>
                        <input disabled={yourPreviousSigner} defaultChecked={editYourSigner} name="editYourSigner" type='radio' className='w-[17px] h-[17px] focus:ring-[white]' onClick={() => setEditYourSigner(true)} />
                        <label htmlFor='shareWithCounterparty' className='text-sm font-normal text-[#6B7280] ml-2'>Someone else</label>
                    </div>
                </div>
                {
                    editYourSigner ?
                        <>
                            <div className='text-sm font-normal text-[#6B7280] mb-2'>Your side’s signer</div>
                            <div className='flex gap-[10px]'>
                                <div>
                                    <div className='text-sm font-normal text-[#6B7280] py-2'>Email *</div>
                                    <input disabled={yourPreviousSigner} onChange={(e) => setYourNewSigner({
                                        ...yourNewSigner,
                                        email: e.target.value
                                    })} value={yourNewSigner.email} className='text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' />
                                </div>
                                <div>
                                    <div className='text-sm font-normal text-[#6B7280] py-2'>First Name</div>
                                    <input disabled={yourPreviousSigner} onChange={(e) => {
                                        setYourNewSigner({
                                            ...yourNewSigner,
                                            firstName: e.target.value
                                        })
                                    }} value={yourNewSigner.firstName} className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' />
                                </div>
                                <div>
                                    <div className='text-sm font-normal text-[#6B7280] py-2'>Last Name</div>
                                    <input disabled={yourPreviousSigner} onChange={(e) => {
                                        setYourNewSigner({
                                            ...yourNewSigner,
                                            lastName: e.target.value
                                        })
                                    }} value={yourNewSigner.lastName} className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' />
                                </div>
                            </div>
                        </>
                        :
                        null
                }
                <p className='text-sm font-normal text-[#6B7280] mt-3'>
                    Your side’s viewers:
                </p>
                {
                    yourViewers.map((viewer, index) => {
                        return (
                            <>
                                <div className='flex gap-[10px]'>
                                    <div>
                                        <div className='text-sm font-normal text-[#6B7280] py-2'>Email *</div>
                                        <input className='text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.email} onChange={(e) => {
                                            const temp = [...yourViewers]
                                            temp[index].email = e.target.value
                                            setYourViewers(temp)
                                        }} />
                                    </div>
                                    <div>
                                        <div className='text-sm font-normal text-[#6B7280] py-2'>First Name</div>
                                        <input className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.firstName} onChange={(e) => {
                                            const temp = [...yourViewers]
                                            temp[index].firstName = e.target.value
                                            setYourViewers(temp)
                                        }
                                        } />
                                    </div>
                                    <div>
                                        <div className='text-sm font-normal text-[#6B7280] py-2'>Last Name</div>
                                        <input className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.lastName} onChange={(e) => {
                                            const temp = [...yourViewers]
                                            temp[index].lastName = e.target.value
                                            setYourViewers(temp)
                                        }
                                        } />
                                    </div>
                                </div>

                                <div className='flex items-center'>
                                    <div onClick={() => {
                                        const temp = [...yourViewers]
                                        temp.splice(index, 1)
                                        setYourViewers(temp)
                                    }
                                    } className='text-sm font-medium text-[#1C64F2] hover:cursor-pointer'>Remove</div>
                                </div>
                            </>
                        )
                    })
                }
                <button onClick={() => {
                    setYourViewers([
                        ...yourViewers,
                        {
                            email: '',
                            firstName: '',
                            lastName: '',
                        }
                    ])
                }} className='text-sm font-medium text-[#1C64F2] hover:cursor-pointer mt-3'>+ Add viewers</button>
            </div>
            <div className='text-lg font-semibold text-[#374151] pb-1.5 mt-3'>Counterparty</div>
            {
                contractRecipientPreviousSigner ?
                    <div className='ml-2'>
                        <p className='text-sm font-normal text-[#6B7280] pb-3'>
                            Signature's Pending
                        </p>
                    </div>
                    :
                    <div className='ml-2'>
                        {/* <p className='text-sm font-normal text-[#6B7280] pb-3'>
                            Do you know who’s signing on behalf of your counterparty?
                        </p>
                        <div className='flex gap-[10px] mb-3'>
                            <div className='flex items-center'>
                                <input disabled={contractRecipientPreviousSigner} onClick={() => setEditContractRecipientSigner(true)} defaultChecked={editContractRecipientSigner} name="editContractRecipientSigner" type='radio' className='w-[17px] h-[17px] focus:ring-[white]' />
                                <label htmlFor='shareWithCounterparty' className='text-sm font-normal text-[#6B7280] ml-2'>Yes</label>
                            </div>
                            <div className='flex items-center'>
                                <input disabled={contractRecipientPreviousSigner} onClick={() => setEditContractRecipientSigner(false)} defaultChecked={!editContractRecipientSigner} name="editContractRecipientSigner" type='radio' className='w-[17px] h-[17px] focus:ring-[white]' />
                                <label htmlFor='shareWithCounterparty' className='text-sm font-normal text-[#6B7280] ml-2'>No</label>
                            </div>
                        </div> */}
                        {
                            editContractRecipientSigner ?
                                <>
                                    <div className='text-sm font-normal text-[#6B7280] mb-2'>Counterparty signatory:</div>
                                    <div className='flex gap-[10px]'>
                                        <div>
                                            <div className='text-sm font-normal text-[#6B7280] py-2'>Email *</div>
                                            <input disabled={contractRecipientPreviousSigner} onChange={(e) => setContractRecipientSigner({
                                                ...contractRecipientSigner,
                                                email: e.target.value
                                            })} value={contractRecipientSigner.email} className='text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' />
                                        </div>
                                        <div>
                                            <div className='text-sm font-normal text-[#6B7280] py-2'>First Name</div>
                                            <input disabled={contractRecipientPreviousSigner} onChange={(e) => {
                                                setContractRecipientSigner({
                                                    ...contractRecipientSigner,
                                                    firstName: e.target.value
                                                })
                                            }} value={contractRecipientSigner.firstName} className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' />
                                        </div>
                                        <div>
                                            <div className='text-sm font-normal text-[#6B7280] py-2'>Last Name</div>
                                            <input disabled={contractRecipientPreviousSigner} onChange={(e) => {
                                                setContractRecipientSigner({
                                                    ...contractRecipientSigner,
                                                    lastName: e.target.value
                                                })
                                            }} value={contractRecipientSigner.lastName} className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' />
                                        </div>
                                    </div>
                                </>
                                :
                                <div className='text-sm font-normal text-[#6B7280] mb-2'>Please click below to request your counterparty to select its signer.</div>
                        }
                        <p className='text-sm font-normal text-[#6B7280] mt-3'>
                            Counterparty’s viewers:
                        </p>
                        {
                            contractRecipientViewers.map((viewer, index) => {
                                return (
                                    <>
                                        <div className='flex gap-[10px]'>
                                            <div>
                                                <div className='text-sm font-normal text-[#6B7280] py-2'>Email *</div>
                                                <input className='text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.email} onChange={(e) => {
                                                    const temp = [...contractRecipientViewers]
                                                    temp[index].email = e.target.value
                                                    setContractRecipientViewers(temp)
                                                }} />
                                            </div>
                                            <div>
                                                <div className='text-sm font-normal text-[#6B7280] py-2'>First Name</div>
                                                <input className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.firstName} onChange={(e) => {
                                                    const temp = [...contractRecipientViewers]
                                                    temp[index].firstName = e.target.value
                                                    setContractRecipientViewers(temp)
                                                }
                                                } />
                                            </div>
                                            <div>
                                                <div className='text-sm font-normal text-[#6B7280] py-2'>Last Name</div>
                                                <input className='w-full text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[16px]' value={viewer.lastName} onChange={(e) => {
                                                    const temp = [...contractRecipientViewers]
                                                    temp[index].lastName = e.target.value
                                                    setContractRecipientViewers(temp)
                                                }
                                                } />
                                            </div>
                                        </div>

                                        <div className='flex items-center'>
                                            <div onClick={() => {
                                                const temp = [...contractRecipientViewers]
                                                temp.splice(index, 1)
                                                setContractRecipientViewers(temp)
                                            }
                                            } className='text-sm font-medium text-[#1C64F2] hover:cursor-pointer'>Remove</div>
                                        </div>
                                    </>
                                )
                            })
                        }
                        <div className='flex my-3'>
                            <div onClick={() => setContractRecipientViewers([
                                ...contractRecipientViewers,
                                {
                                    email: '',
                                    firstName: '',
                                    lastName: ''
                                }
                            ])} className='text-sm font-medium text-[#1C64F2] hover:cursor-pointer'>+ Add viewers</div>
                        </div>
                    </div>
            }
            <div className='flex items-center gap-1'>
                <div className='text-lg font-semibold text-[#374151] pb-1.5 mt-3'>Message</div>
                <div className='text-sm font-normal text-[#6B7280] py-2 mt-1'>*</div>
            </div>
            <textarea value={signersMessage} onChange={(e) => { setSignersMessage(e.target.value) }}
                placeholder="Hi all, this contract is now ready to be signed using Standard Draft’s integration with DocuSign! If you need to communicate with me directly about anything, please reach me at: ryan@gmail.com."
                className='text-base font-normal text-[#6B7280] py-[12px] px-[18px] bg-[#F9FAFB] border boder-[#E5E7EB] rounded-[8px] w-full h-[200px] mb-3' />
        </div>
    )
}

export default Signers
