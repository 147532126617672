import VersionHistoryViewMore from './VersionHistoryViewMore';
import { useDispatch, useSelector } from 'react-redux';
import { alertError } from '../components/Alerts';
import { useNavigate } from 'react-router-dom';
import VersionHistory from './VersionHistory';
import UserTemplates from './UserTemplates';
import PaymentModal from './PaymentModal';
import instance from '../auth/Axios';
import ListViwer from './ListViwer';
import React from 'react';

const TopHeader = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let btnRef = React.useRef(null);

    const versionHistoryData = useSelector((state) => state.contract.versionHistoryData);
    const { payment_status, remaining_contract } = useSelector((state) => state.payment);
    const { contractTemplateName } = useSelector(state => state.contract.ds)

    const [dropdown, setDropdown] = React.useState(false);
    const [paymentModalStatus, setPaymentModalStatus] = React.useState(false)
    const [userTemplatesModal, setUserTemplatesModal] = React.useState(false);
    const [time, setTime] = React.useState(timeSince(props.versionSavedTime));
    const [versionSavedTimeLoop, setVersionSavedTimeLoop] = React.useState(null);
    const [versionHistoryVisibility, setVersionHistoryVisibility] = React.useState(false);
    const [versionHistoryViewMoreVisibility, setVersionHistoryViewMoreVisibility] = React.useState({
        status: false,
        data: []
    })
    const [listViwerData, setListViwerData] = React.useState({
        status: false,
        data: props.contract.collaborators
    });

    function timeSince(date) {

        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + 1 + " years";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + 1 + " months";
        }
        interval = seconds / 604800;
        if (interval > 1) {
            return Math.floor(interval) + 1 + " weeks";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + 1 + " days";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + 1 + " hours";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) < 2 ? "2 minutes" :
                Math.floor(interval) < 5 ? "5 minutes" :
                    Math.floor(interval) < 10 ? "10 minutes" :
                        Math.floor(interval) < 30 ? "30 minutes" : "1 hour";
        }
        return Math.floor(seconds) === 0 ?
            "0 seconds" :
            Math.floor(seconds) < 30 ? "30 seconds" : "1 minute";
    }

    const generateDocx = async () => {
        if (props.currentCollaborator.team === "proposer" && !props.contract.isPaid && remaining_contract <= 0) {
            setPaymentModalStatus(true)
        } else {
            const response = await instance.post("/contract/getDocx", {
                data: props.cleanContract(),
                contractId: props.contractId,
            }, {
                responseType: 'blob',
            })
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', props.contract.name + '.docx'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);

            const getUserDetails = await instance.get('/user/get-user-details')
            if (getUserDetails.data.status) {
                dispatch({
                    type: 'SET_PAYMENT_DETAILS', remaining_contract: getUserDetails.data.remaining_contract,
                    payment_status: getUserDetails.data.payment_status,
                    planId: getUserDetails.data.planId,
                    plan_type: getUserDetails.data.plan_type
                })
            }
        }
    }

    React.useEffect(() => {
        const closeDropdown = (event) => {
            if (!btnRef.current.contains(event.target)) {
                setDropdown(false);
            }
        }
        document.addEventListener('click', closeDropdown);
        return () => { document.removeEventListener('click', closeDropdown) }
    }, []);

    React.useEffect(() => {
        clearInterval(versionSavedTimeLoop);
        setTime(timeSince(props.versionSavedTime));
        setVersionSavedTimeLoop(
            setInterval(() => {
                setTime(timeSince(props.versionSavedTime));
            }, 15 * 1000));
        return () => {
            clearInterval(versionSavedTimeLoop);
        };
    }, [props.versionSavedTime]);

    return (
        <>
            <div className='flex py-3 bg-[#FFFFFF] border-b border-[#E4E4E7]'>
                <div className='ml-11 flex justify-center items-center'>
                    <svg onClick={() => {
                        if (props.currentCollaborator.role !== "viewer" && props.currentCollaborator.team === props.contract.currentOwnerTeam) {
                            props.setCloseModal(true);
                        }
                        else {
                            dispatch({ type: 'RESET' });
                            navigate('/dashboard')
                        }
                    }} className='mr-3 cursor-pointer' width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.24853 12.1484C7.0235 12.3734 6.71833 12.4998 6.40013 12.4998C6.08194 12.4998 5.77677 12.3734 5.55173 12.1484L0.751734 7.34842C0.526769 7.12339 0.400391 6.81822 0.400391 6.50002C0.400391 6.18183 0.526769 5.87666 0.751734 5.65163L5.55173 0.851625C5.77806 0.633036 6.08118 0.512082 6.39582 0.514816C6.71045 0.51755 7.01143 0.643753 7.23392 0.866243C7.45641 1.08873 7.58261 1.38971 7.58534 1.70434C7.58808 2.01898 7.46712 2.3221 7.24853 2.54842L4.49693 5.30003H18.4001C18.7184 5.30003 19.0236 5.42645 19.2487 5.6515C19.4737 5.87654 19.6001 6.18177 19.6001 6.50002C19.6001 6.81828 19.4737 7.12351 19.2487 7.34855C19.0236 7.5736 18.7184 7.70003 18.4001 7.70003H4.49693L7.24853 10.4516C7.4735 10.6767 7.59988 10.9818 7.59988 11.3C7.59988 11.6182 7.4735 11.9234 7.24853 12.1484Z" fill="#111928" />
                    </svg>
                    <svg className='mr-3' width="2" height="21" viewBox="0 0 2 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 0.5L1 20.5" stroke="#E5E7EB" />
                    </svg>
                    <div className='text-xl font-semibold text-[#111928] mr-3'>{props.contract.name}</div>
                    {
                        props.contract.phase !== "Drafting" && props.contract.collaborators.length > 1 && (
                            <div className='text-sm font-medium text-[#6B7280] mr-3'>
                                <span onClick={() => { setVersionHistoryVisibility(true) }}><u className='hover:text-gray-600 cursor-pointer'>Last version</u></span> sent by {
                                    props.contract.currentOwnerTeam === "proposer" ?
                                        props.contract.collaborators.find(collaborator => collaborator.role === "owner" && collaborator.team !== "proposer")?.user.firstName :
                                        props.contract.collaborators.find(collaborator => collaborator.role === "owner" && collaborator.team === "proposer")?.user.firstName
                                }
                            </div>
                        )
                    }

                    <div className='relative group hover:cursor-pointer'>
                        <span className="absolute top-[25px] left-[-14px] scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100 w-[300px] z-50">
                            <p className='mb-1.5'><b>Drafting | Stage 1</b> – The Proposer prepares the initial draft of the contract by making any necessary configurations and then sends the draft to the counterparty (the Responder).</p>
                            <p className='mb-1.5'><b>Negotiating | Stage 2</b> – The Responder reviews Proposer’s initial draft (including the comparison of that draft against the baseline Standard Draft form) and makes any necessary configurations. The Proposer and Responder continue the negotiation process until all contract configurations are agreed upon.</p>
                            <p className='mb-1.5'><b>Signing | Stage 3</b> – The parties sign the contract using Standard Draft’s integration with DocuSign!</p>
                        </span>
                        <span className='text-xs font-medium text-[#057A55] py-[2px] px-[10px] bg-[#BCF0DA] rounded-[10px] cursor-pointer hover:bg-[#a7e6cbd9]'>
                            {props.contract.phase === "Signed" ? `${props.contract.phase}` :
                                `${props.contract.phase} | Stage${props.contract.phase === "Drafting" ? " 1 " : props.contract.phase === "Negotiating" ? " 2 " : props.contract.phase === "Signing" ? " 3 " : " 0 "} of 3`}
                        </span>
                    </div>
                </div>
                <div className='ml-auto mr-5 flex justify-center items-center'>
                    {
                        props.currentCollaborator.role === "viewer" || props.currentCollaborator.team !== props.contract.currentOwnerTeam || props.contract.phase === "Signing" || props.contract.phase === "Signed" ?
                            <>
                                <div className='relative group hover:cursor-pointer flex items-center justify-center bg-[#F4F4F5] text-xs text-[#52525B] border rounded-[10px] mr-3 py-[2px] px-[10px] cursor-pointer hover:bg-[#eeeeee]'>
                                    <span className="text-center z-50 absolute top-[35px] w-80 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100">
                                        {props.currentCollaborator.role === "viewer" ?
                                            "You are reviewing this contract as a viewer. Any changes you make will not be saved or shared." :
                                            props.contract.phase === "Signing" ?
                                                "This contract is now in the signing workflow." :
                                                props.contract.phase === "Signed" ?
                                                    "This contract has been signed. Any changes you make now will not be saved or shared." :
                                                    "This contract has been sent to your counterparty. Any changes you make now will not be saved or shared."}
                                    </span>
                                    <svg className="mr-2" width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0" fill="none" width="24" height="24" />
                                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2M12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20M11 7H13V13H11V7M11 15H13V17H11V15Z" />
                                    </svg>
                                    <div className='text-[#52525B] font-medium'>View Only</div>
                                </div>
                                <button className='relative group hover:cursor-pointer bg-[#1C64F2]/[0.5] hover:bg-[#1C64F2]/[0.5] hover:bg-blue-700 rounded-[12px] flex justify-center items-center mr-3 py-[9px] px-[17px]'>
                                    <span className="z-50 absolute top-[42px] right-[0px] w-80 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100">
                                        {props.currentCollaborator.role === "viewer" ?
                                            "You are reviewing this contract as a viewer. Any changes you make will not be saved or shared." :
                                            props.contract.phase === "Signing" ?
                                                "This contract is now in the signing workflow." :
                                                props.contract.phase === "Signed" ?
                                                    "This contract has been signed. Any changes you make now will not be saved or shared." :
                                                    "This contract has been sent to your counterparty. Any changes you make now will not be saved or shared."}
                                    </span>
                                    <svg className='mr-2 mt-0.5' width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0 15.4998C0 15.2346 0.105357 14.9802 0.292893 14.7927C0.48043 14.6051 0.734784 14.4998 1 14.4998H13C13.2652 14.4998 13.5196 14.6051 13.7071 14.7927C13.8946 14.9802 14 15.2346 14 15.4998C14 15.765 13.8946 16.0194 13.7071 16.2069C13.5196 16.3944 13.2652 16.4998 13 16.4998H1C0.734784 16.4998 0.48043 16.3944 0.292893 16.2069C0.105357 16.0194 0 15.765 0 15.4998ZM3.293 5.20679C3.10553 5.01926 3.00021 4.76495 3.00021 4.49979C3.00021 4.23462 3.10553 3.98031 3.293 3.79279L6.293 0.792786C6.48053 0.605315 6.73484 0.5 7 0.5C7.26516 0.5 7.51947 0.605315 7.707 0.792786L10.707 3.79279C10.8892 3.98139 10.99 4.23399 10.9877 4.49619C10.9854 4.75838 10.8802 5.0092 10.6948 5.1946C10.5094 5.38001 10.2586 5.48518 9.9964 5.48746C9.7342 5.48974 9.4816 5.38894 9.293 5.20679L8 3.91379V11.4998C8 11.765 7.89464 12.0194 7.70711 12.2069C7.51957 12.3944 7.26522 12.4998 7 12.4998C6.73478 12.4998 6.48043 12.3944 6.29289 12.2069C6.10536 12.0194 6 11.765 6 11.4998V3.91379L4.707 5.20679C4.51947 5.39426 4.26516 5.49957 4 5.49957C3.73484 5.49957 3.48053 5.39426 3.293 5.20679Z" fill="white" />
                                    </svg>
                                    <span className='text-sm font-semibold text-[#FFFFFF]'>Finish & Review</span>
                                </button>
                            </>
                            :
                            <>
                                {time === "0 seconds" ?
                                    <>
                                        <div className='hidden'>
                                            {setTimeout(() => {
                                                setTime(timeSince(props.versionSavedTime));
                                            }, 2000)}
                                        </div>
                                        <div className='flex items-center justify-center bg-[#F4F4F5] text-xs font-medium text-[#52525B] border rounded-[10px] mr-3 py-[2px] px-[10px] cursor-pointer hover:bg-[#eeeeee]'>
                                            <svg className="rotate mr-2" width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="0" fill="none" width="24" height="24" />
                                                <g>
                                                    <path d="M23.5 13.5l-3.086 3.086L19 18l-4.5-4.5 1.414-1.414L18 14.172V12c0-3.308-2.692-6-6-6V4c4.418 0 8 3.582 8 8v2.172l2.086-2.086L23.5 13.5zM6 12V9.828l2.086 2.086L9.5 10.5 5 6 3.586 7.414.5 10.5l1.414 1.414L4 9.828V12c0 4.418 3.582 8 8 8v-2c-3.308 0-6-2.692-6-6z" />
                                                </g>
                                            </svg>
                                            Saving...
                                        </div>
                                    </>
                                    :
                                    <div className='relative group hover:cursor-pointer bg-[#F4F4F5] text-xs text-[#52525B] border rounded-[10px] mr-3 py-[2px] px-[10px] cursor-pointer hover:bg-[#eeeeee]'>
                                        <span className="absolute top-[24px] left-[-40px] w-36 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100">{"Saved <" + time + " ago"}</span>
                                        <span className='font-medium'>Saved</span>
                                    </div>
                                }
                                {props.contract.phase === "Drafting" && <button onClick={() => setUserTemplatesModal(true)} className='text-sm font-semibold text-[#1C64F2]/[0.8] mr-3 hover:text-[#1C64F2] hover:cursor-pointer'>User Templates</button>}
                                <button onClick={props.finishAndReview} className='bg-[#1C64F2] hover:bg-blue-700 rounded-[12px] flex justify-center items-center mr-3 py-[9px] px-[17px]'>
                                    <svg className='mr-2 mt-0.5' width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0 15.4998C0 15.2346 0.105357 14.9802 0.292893 14.7927C0.48043 14.6051 0.734784 14.4998 1 14.4998H13C13.2652 14.4998 13.5196 14.6051 13.7071 14.7927C13.8946 14.9802 14 15.2346 14 15.4998C14 15.765 13.8946 16.0194 13.7071 16.2069C13.5196 16.3944 13.2652 16.4998 13 16.4998H1C0.734784 16.4998 0.48043 16.3944 0.292893 16.2069C0.105357 16.0194 0 15.765 0 15.4998ZM3.293 5.20679C3.10553 5.01926 3.00021 4.76495 3.00021 4.49979C3.00021 4.23462 3.10553 3.98031 3.293 3.79279L6.293 0.792786C6.48053 0.605315 6.73484 0.5 7 0.5C7.26516 0.5 7.51947 0.605315 7.707 0.792786L10.707 3.79279C10.8892 3.98139 10.99 4.23399 10.9877 4.49619C10.9854 4.75838 10.8802 5.0092 10.6948 5.1946C10.5094 5.38001 10.2586 5.48518 9.9964 5.48746C9.7342 5.48974 9.4816 5.38894 9.293 5.20679L8 3.91379V11.4998C8 11.765 7.89464 12.0194 7.70711 12.2069C7.51957 12.3944 7.26522 12.4998 7 12.4998C6.73478 12.4998 6.48043 12.3944 6.29289 12.2069C6.10536 12.0194 6 11.765 6 11.4998V3.91379L4.707 5.20679C4.51947 5.39426 4.26516 5.49957 4 5.49957C3.73484 5.49957 3.48053 5.39426 3.293 5.20679Z" fill="white" />
                                    </svg>
                                    <span className='text-sm font-semibold text-[#FFFFFF]'>Finish & Review</span>
                                </button>
                            </>
                    }
                    <div className="relative flex justify-center items-center">
                        <>
                            <div ref={btnRef} onClick={() => setDropdown(!dropdown)} className="flex items-center text-base font-medium text-[#111928] hover:cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                </svg>
                            </div>
                            <ul id="navbarDropdown" className={`origin-top-right z-50 absolute right-1 top-5 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${dropdown ? "block" : "hidden"}`}>
                                {props.contract.phase === "Negotiating" &&
                                    <li>
                                        <div onClick={() => {
                                            setListViwerData({
                                                ...listViwerData,
                                                status: true,
                                            })
                                        }} className="flex items-center gap-2 px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 rounded-t-md">
                                            <svg xmlns="http://www.w3.org/2000/svg" className='h-3 cursor-pointer w-auto' fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                            </svg>
                                            Viewers
                                        </div>
                                    </li>
                                }
                                <li>
                                    <div onClick={() => generateDocx()} className="flex items-center gap-2 px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 rounded-t-md">
                                        <svg className='h-3 cursor-pointer w-auto' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title></title> <g id="Complete"> <g id="download"> <g> <path d="M3,12.3v7a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2v-7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path> <g> <polyline data-name="Right" fill="none" id="Right-2" points="7.9 12.3 12 16.3 16.1 12.3" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></polyline> <line fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="12" x2="12" y1="2.7" y2="14.2"></line> </g> </g> </g> </g> </g>
                                        </svg>
                                        Download
                                    </div>
                                </li>
                                <li>
                                    <div onClick={async () => {
                                        let printWindow = window.open('', '', 'height=1000,width=1000');
                                        let css = `<style>
                                        .contractTitle {
                                            mso-style-name: "Document Title";
                                            mso-style-priority: 99;
                                            mso-style-unhide: no;
                                            mso-style-next: "Body Text";
                                            margin-top: 0cm;
                                            margin-right: 0cm;
                                            margin-left: 0cm;
                                            text-align: center;
                                            mso-pagination: widow-orphan;
                                            font-size: 12.0pt;
                                            font-family: "Times New Roman", serif;
                                            mso-fareast-font-family: "Times New Roman";
                                            text-transform: uppercase;
                                            mso-ansi-language: EN-US;
                                            mso-fareast-language: EN-US;
                                            mso-bidi-font-weight: normal;
                                        }
                                        
                                        .content {
                                            mso-style-unhide: no;
                                            mso-style-qformat: yes;
                                            mso-style-parent: "";
                                            margin: 0cm;
                                            margin-bottom: .0001pt;
                                            mso-pagination: widow-orphan;
                                            font-size: 12.0pt;
                                            font-family: "Times New Roman", serif;
                                            mso-fareast-font-family: "Times New Roman";
                                            mso-ansi-language: EN-US;
                                            mso-fareast-language: EN-US;
                                        }
                                        
                                        .variables {
                                            color: #2F5496;
                                            mso-themecolor: accent1;
                                            mso-themeshade: 191
                                        }
                                        
                                        ol.numbers {
                                            counter-reset: counter1;
                                        }
                                        
                                        ol.numbers li::before {
                                            content: counter(counter1);
                                            counter-increment: counter1;
                                        }
                                        
                                        ol.lower-alpha {
                                            counter-reset: counter2;
                                        }
                                        
                                        ol.lower-alpha li {
                                            display: flex;
                                            gap: 10px;
                                        }
                                        
                                        ol.lower-alpha li::before {
                                            content: "(" counter(counter2, lower-alpha)")";
                                            counter-increment: counter2;
                                        }
                                        
                                        ol.none {
                                            counter-reset: counter3;
                                        }
                                        
                                        ol.none li {
                                            display: flex;
                                            gap: 26px;
                                        }
                                        
                                        ol.none li::before {
                                            content: "";
                                            counter-increment: counter3;
                                        }</style>`

                                        printWindow.document.write('<html><head><title>' + document.title + '</title>');
                                        printWindow.document.write('</head><body >');
                                        printWindow.document.write(document.getElementById('contract').innerHTML);
                                        printWindow.document.write('</body></html>');
                                        printWindow.document.write(css);
                                        printWindow.document.close();

                                        printWindow.print();
                                        printWindow.close();
                                    }} className="relative group px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 rounded-b-md">
                                        <span className="z-50 absolute top-[42px] right-[0px] w-80 scale-0 rounded bg-white border border-gray-300 shadow-lg p-2 text-xs text-black group-hover:scale-100">
                                            For the cleanest printing experience, please download the contract and print from Microsoft Word
                                        </span>
                                        <div className='flex items-center gap-2'>
                                            <svg xmlns="http://www.w3.org/2000/svg" className='h-3 cursor-pointer w-auto' fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                                                <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                                            </svg>
                                            Print
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </>
                    </div>
                    {/* <div id='topHeaderDropdown' ref={btnRef} onClick={() => setDropdown(!dropdown)}>
                        <svg className='hover:cursor-pointer' width="6" height="21" viewBox="0 0 6 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.0001 5.70002C2.36358 5.70002 1.75313 5.44717 1.30304 4.99708C0.852955 4.54699 0.600098 3.93654 0.600098 3.30002C0.600098 2.6635 0.852955 2.05306 1.30304 1.60297C1.75313 1.15288 2.36358 0.900024 3.0001 0.900024C3.63662 0.900024 4.24707 1.15288 4.69715 1.60297C5.14724 2.05306 5.4001 2.6635 5.4001 3.30002C5.4001 3.93654 5.14724 4.54699 4.69715 4.99708C4.24707 5.44717 3.63662 5.70002 3.0001 5.70002ZM3.0001 12.9C2.36358 12.9 1.75313 12.6472 1.30304 12.1971C0.852955 11.747 0.600098 11.1365 0.600098 10.5C0.600098 9.86351 0.852955 9.25306 1.30304 8.80297C1.75313 8.35288 2.36358 8.10002 3.0001 8.10002C3.63662 8.10002 4.24707 8.35288 4.69715 8.80297C5.14724 9.25306 5.4001 9.86351 5.4001 10.5C5.4001 11.1365 5.14724 11.747 4.69715 12.1971C4.24707 12.6472 3.63662 12.9 3.0001 12.9ZM3.0001 20.1C2.36358 20.1 1.75313 19.8472 1.30304 19.3971C0.852955 18.947 0.600098 18.3365 0.600098 17.7C0.600098 17.0635 0.852955 16.4531 1.30304 16.003C1.75313 15.5529 2.36358 15.3 3.0001 15.3C3.63662 15.3 4.24707 15.5529 4.69715 16.003C5.14724 16.4531 5.4001 17.0635 5.4001 17.7C5.4001 18.3365 5.14724 18.947 4.69715 19.3971C4.24707 19.8472 3.63662 20.1 3.0001 20.1Z" fill="#111928" />
                        </svg>
                        <ul className={`origin-top-right z-10 absolute right-4 mt-3.5 w-38 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${dropdown ? "block" : "hidden"}`}>
                            <li><button  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">Download as word</button></li>
                        </ul>
                    </div> */}
                </div>
            </div>
            {
                props.signWorkflow ?
                    <div
                        tabIndex={-1}
                        className="flex justify-center items-center fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full overflow-auto"
                    >
                        <div className='px-8 py-6 w-[45%] bg-[#FFFFFF] rounded-[10px]' style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                            <div style={{ display: "flex", alignItems: "center", marginLeft: "auto", marginBottom: "3px" }}>
                                <div className="font-semibold text-2xl text-[#111928] mb-1">Ready to Sign?</div>
                                <div className='ml-[auto] mb-1 cursor-pointer'>
                                    <svg onClick={() => { props.setSignWorkflow(false) }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                            <p className='text-sm font-normal text-[#6B7280]'>
                                Since you have accepted all of your counterparty’s changes, this contract is ready to be signed.
                            </p>
                            <div className='my-8'>
                                <div onClick={() => {
                                    document.getElementById('initiateSignatureFlowYes').checked = true
                                    document.getElementById('initiateSignatureFlowNo').checked = false
                                }} className='flex items-center border p-3 mb-3 cursor-pointer'>
                                    <input id='initiateSignatureFlowYes' type="radio" value="Yes" name="initiateSignatureFlowOptions" className='focus:ring-[white]' />
                                    <p className='ml-3 text-sm font-normal text-[#6B7280]'>
                                        Yes, I want to start the signing workflow.
                                    </p>
                                </div>
                                <div onClick={() => {
                                    document.getElementById('initiateSignatureFlowYes').checked = false
                                    document.getElementById('initiateSignatureFlowNo').checked = true
                                }} className='flex items-center border p-3 cursor-pointer'>
                                    <input id='initiateSignatureFlowNo' type="radio" value="No" name="initiateSignatureFlowOptions" className='focus:ring-[white]' />
                                    <p className='ml-3 text-sm font-normal text-[#6B7280]'>
                                        No, I want to send this contract for further review or negotiation to my side or the other side.
                                    </p>
                                </div>
                            </div>
                            <button onClick={() => {
                                if (document.getElementById('initiateSignatureFlowYes').checked) {
                                    props.setInitiateSignWorkflow(true)
                                    props.setActiveStep(props.steps.length)
                                }
                                else if (document.getElementById('initiateSignatureFlowNo').checked) {
                                    props.setInitiateSignWorkflow(false)
                                    props.setActiveStep(props.steps.length)
                                }
                                else {
                                    alertError('Please select an option')
                                    return
                                }
                                props.setSignWorkflow(false)
                            }} className='flex items-center block mr-3 ml-auto text-[#111928] text-sm font-semibold bg-[#1C64F2] text-[white] border border-[#D1D5DB] rounded-[12px] px-[17px] py-[9px]' >
                                <svg className="mr-2" width="16" height="12" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.7931 7.50003L12.9396 8.35358L8.65013 12.643C8.56064 12.737 8.51118 12.8622 8.51231 12.9921C8.51345 13.1232 8.56603 13.2486 8.65874 13.3413C8.75144 13.434 8.87685 13.4866 9.00794 13.4877C9.13783 13.4889 9.26301 13.4394 9.35703 13.3499L15.3534 7.35353C15.3534 7.35351 15.3534 7.35349 15.3534 7.35348C15.4471 7.25972 15.4998 7.13259 15.4998 7.00003C15.4998 6.86747 15.4471 6.74034 15.3534 6.64658L13.7931 7.50003ZM13.7931 7.50003H12.586H1C0.867392 7.50003 0.740215 7.44735 0.646446 7.35358C0.552678 7.25982 0.5 7.13264 0.5 7.00003C0.5 6.86742 0.552678 6.74025 0.646446 6.64648C0.740215 6.55271 0.867392 6.50003 1 6.50003H12.586H13.7931L12.9396 5.64648L8.64661 1.35353C8.64659 1.35351 8.64657 1.35349 8.64655 1.35348C8.55285 1.25972 8.50021 1.13259 8.50021 1.00003C8.50021 0.867498 8.55283 0.740391 8.6465 0.646637C8.74026 0.552902 8.86742 0.500244 9 0.500244C9.13256 0.500244 9.25969 0.552882 9.35345 0.646584C9.35346 0.646602 9.35348 0.646619 9.3535 0.646637L15.3534 6.64653L13.7931 7.50003Z" fill="white" stroke="white" />
                                </svg>
                                Continue
                            </button>
                        </div>
                    </div>
                    : null
            }
            {userTemplatesModal && <UserTemplates onChange={setUserTemplatesModal} contractId={props.contractId} />}
            {
                listViwerData.status && (
                    <ListViwer listViwerData={listViwerData} setListViwerData={setListViwerData} />
                )
            }
            {versionHistoryVisibility &&
                <VersionHistory
                    phase={props.contract.phase}
                    versionHistoryData={versionHistoryData}
                    contractTemplateName={contractTemplateName}
                    setVersionHistoryVisibility={setVersionHistoryVisibility}
                    setVersionHistoryViewMoreVisibility={setVersionHistoryViewMoreVisibility}
                />
            }

            {
                versionHistoryViewMoreVisibility.status &&
                <VersionHistoryViewMore
                    data={versionHistoryViewMoreVisibility.data}
                    setVersionHistoryViewMoreVisibility={setVersionHistoryViewMoreVisibility}
                    setVersionHistoryVisibility={setVersionHistoryVisibility} />

            }
            {
                paymentModalStatus && <PaymentModal setPaymentModalStatus={setPaymentModalStatus} contractId={props.contract.uuid} />
            }
        </>
    )
}

export default TopHeader
