// Children + Arrow + Disabled

import { useState } from "react";
import { useDispatch } from "react-redux";

const YesNoButtonGroup = ({ options, dispatch_handler, collapsibleHandler }) => {
    const dispatch = useDispatch()
    const [selectedButton, setSelectedButton] = useState(-1);
    return (
        <>
            <div className="inline-flex rounded-full" role="group"></div>
            <button id={options ? options[0].opt_id : null} type="button"
                onClick={() => {
                    setSelectedButton(0)
                    dispatch_handler(options ? options[0].actions : null)
                    dispatch({ type: 'CHANGE_TOGGLE' })
                }}
                className={selectedButton == 0 ? "py-1 px-7 text-sm font-medium z-10 text-white bg-[#6B7280] rounded-l-full border border-gray-200" : "py-1 px-7 text-sm font-medium text-gray-500 bg-white rounded-l-full border border-gray-200 shadow hover:bg-gray-100 hover:text-blue-700"}>
                Yes
            </button>
            <button id={options ? options[1].opt_id : null} type="button"
                onClick={() => {
                    setSelectedButton(1)
                    dispatch_handler(options ? options[1].actions : null)
                    dispatch({ type: 'CHANGE_TOGGLE' })
                }}
                className={selectedButton == 1 ? "py-1 px-7 text-sm font-medium z-10 text-white bg-purple-600 rounded-r-full border border-gray-200" : "py-1 px-7 text-sm font-medium text-gray-500 bg-white rounded-r-full border border-gray-200 shadow hover:bg-gray-100 hover:text-blue-700"}>
                No
            </button>
            <div />
        </>
    )
}

export default YesNoButtonGroup;
