import React from "react"

const Breadcrumbs_1 = (props) => {
    return (
        <>
            <nav className="flex" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-5">
                    {props.stages.map((item, index) => {
                        return (
                            <>
                                <li>
                                    {props.currentStage === index ?
                                        <div className="flex items-center text-[#3F83F8] hover:text-blue-600 hover:cursor-pointer">
                                            <div className="text-sm font-semibold">{item}</div>
                                        </div> :
                                        <div onClick={() => { props.handleSaveAndContinue(index) }} className="flex items-center text-[#6B7280] hover:text-blue-600 hover:cursor-pointer">
                                            <div className="text-sm font-semibold">{item}</div>
                                        </div>
                                    }
                                </li>
                                {index < props.stages.length - 1 ?
                                    <svg width="6" height="13" viewBox="0 0 6 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.822816 0.455919C0.977181 0.400227 1.15595 0.390966 1.3198 0.430173C1.48365 0.469379 1.61916 0.553842 1.69651 0.664982L5.60276 6.28998C5.64813 6.35517 5.67175 6.42707 5.67175 6.49998C5.67175 6.57289 5.64813 6.6448 5.60276 6.70998L1.69651 12.335C1.65862 12.3905 1.60583 12.4401 1.5412 12.4808C1.47657 12.5216 1.40137 12.5527 1.31994 12.5725C1.23851 12.5922 1.15246 12.6002 1.06675 12.5958C0.981038 12.5915 0.897363 12.575 0.820548 12.5473C0.743733 12.5196 0.675297 12.4812 0.619185 12.4344C0.563074 12.3875 0.520398 12.3331 0.493616 12.2743C0.466835 12.2156 0.456478 12.1535 0.463144 12.0919C0.469809 12.0302 0.493364 11.9701 0.532451 11.915L4.29157 6.49998L0.532451 1.08498C0.455102 0.973839 0.442239 0.845125 0.496692 0.727154C0.551146 0.609183 0.668455 0.511617 0.822816 0.455919Z" fill="#6B7280" />
                                    </svg> : null
                                }
                            </>
                        )
                    })}
                </ol>
            </nav>
        </>
    )
}

export default Breadcrumbs_1